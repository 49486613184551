import { Col, Row } from 'react-bootstrap';

export default function CustomerListTitle() {
  return (
    <Row className="d-none d-lg-flex header-customer-list mx-2 mt-4">
      <Col lg={9}>
        <Row>
          <Col sm={6}>
            <p>Company Name</p>
          </Col>
          <Col sm={6}>
            <p>Customer Url</p>
          </Col>
        </Row>
      </Col>
      <Col lg={3} className="d-flex justify-content-end">
        <p>Actions</p>
      </Col>
    </Row>
  );
}

CustomerListTitle.propTypes = {};

CustomerListTitle.defaultProps = {};
