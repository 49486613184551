import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { GrClose } from 'react-icons/gr';
import { connect } from 'react-redux';
import { useDebounce } from 'react-use';
import commonMethods from '../../../helper/common-methods';
import { TABLE_DATE_FORMAT } from '../../../helper/constant-values';
import { getTokenTransactionByAdmin } from '../../../redux/actions/token-transaction-action';
import { browserViewProps } from '../../common/common-props';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import MenuBarWrapper from '../../common/menu-bar-wrapper/menu-bar-wrapper';
import CustomPagination from '../../common/pagination/custom-pagination';
import { tokenTransactionListProps } from './token-transaction-container-common-props';
import './token-transaction-container.css';
import TokenTransactionListInfo from './token-transaction-list/token-transaction-info';
import TokenTransactionListTitle from './token-transaction-list/token-transaction-list-title';
import DateFilter from './token-transction-filter/date-filter/date-filter';
import SortTokenTransaction from './token-transction-filter/sort-token-transaction';

function TokenTransactionContainer(props) {
  const { tokenTransactionsState, browserState, getTokenTransaction } = props;

  const [searchText, setSearchText] = useState('');
  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [dataLoading, setDataLoading] = useState(false);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);
  const [isShortAscending, setIsShortAscending] = useState(false);
  const [shortByColumn, setShortByColumn] = useState('createdAt');
  const [showFilterLabel, setShowFilterLabel] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const onSortTypeChange = (e) => {
    if (e.target.value === 'Ascending') {
      setIsShortAscending(true);
    } else {
      setIsShortAscending(false);
    }
  };

  const onSortByChange = (e) => {
    setShortByColumn(e.target.value);
  };

  const onHandelFilterData = (filteredStartDate, filteredEndDate) => {
    setStartDate(filteredStartDate);
    setEndDate(filteredEndDate);
    setShowFilterLabel(true);
  };

  const clearFilterDate = () => {
    setShowFilterLabel((preValue) => !preValue);
    setStartDate('');
    setEndDate('');
  };

  const getPaginationQuery = ({
    pageChanged = false,
    paramsData = {},
    customMethod = null,
    queryData = {},
    payload = null,
  }) => {
    const params = { ...paramsData };
    const query = {
      page,
      itemPerPage,
      searchText,
      timeZone: momentTimeZone.tz.guess(),
      isShortAscending,
      shortByColumn,
      startDate,
      endDate,
      ...queryData,
    };

    return {
      isMobileView: browserState.isMobile,
      params,
      query,
      page,
      payload,
      itemPerPage,
      getMethod: customMethod || getTokenTransaction,
      pageChanged,
      data: tokenTransactionsState.data,
    };
  };

  const onLoadMoreClick = () => {
    setDataLoading(true);
    if (tokenTransactionsState.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery({ pageChanged: true }));
  }, [page]);

  useDebounce(
    () => {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    },
    300,
    [
      itemPerPage,
      mobileLastPageLoaded,
      searchText,
      isShortAscending,
      shortByColumn,
      startDate,
      endDate,
    ]
  );

  useEffect(() => {
    if (!browserState.isMobile) {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    }
  }, [browserState.isMobile]);

  useEffect(() => {
    setDataLoading(false);
  }, [tokenTransactionsState]);

  return (
    <MenuBarWrapper navBarIndex={3} headerTitle="Token Transaction">
      <Container className="container-uxl pb-3 px-1">
        <Row className="pb-2">
          <Col xs={12} sm={6} className="mb-2">
            <h4>Token Transaction History</h4>
          </Col>

          <Col xs={12} sm={6} className="d-flex justify-content-end">
            <DateFilter
              onHandelFilterData={onHandelFilterData}
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
        </Row>
        <Row>
          <SortTokenTransaction
            onSortByChange={onSortByChange}
            onSortTypeChange={onSortTypeChange}
            shortByColumn={shortByColumn}
          />

          <Col xs={12} md={6} xl={4} className="pt-2 pt-xl-0">
            <Form.Control
              type="text"
              placeholder="Search by Username"
              value={searchText}
              size="lg"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </Col>
        </Row>

        {showFilterLabel && (
          <Row>
            <Col
              xs={12}
              md={{ span: 9, offset: 3 }}
              lg={{ span: 7, offset: 5 }}
              className="d-flex justify-content-end"
            >
              <div className="date-filter-label-token-transction text-secondary mt-3">
                <span>
                  Showing Date: from {moment(startDate).format(TABLE_DATE_FORMAT)} to{' '}
                  {moment(endDate).format(TABLE_DATE_FORMAT)} {'  '}
                </span>
                <GrClose onClick={clearFilterDate} className="cursor-pointer ml-2" />
              </div>
            </Col>
          </Row>
        )}
      </Container>

      <Container className="container-uxl token-transaction-list-for-admin px-sm-0">
        <TokenTransactionListTitle />

        {tokenTransactionsState.data.map((item) => (
          <TokenTransactionListInfo key={item.id} tokenTransactionItem={item} />
        ))}

        {tokenTransactionsState.totalItems > 10 && (
          <Col className="px-0 d-none d-md-block border-top bg-white">
            <CustomPagination
              itemPerPage={itemPerPage}
              page={page}
              setPage={setPage}
              setItemPerPage={setItemPerPage}
              totalPages={tokenTransactionsState.totalPages}
              totalItems={tokenTransactionsState.totalItems}
            />
          </Col>
        )}

        {tokenTransactionsState.totalItems > tokenTransactionsState.data.length && (
          <Col
            sm={11}
            className="bg-light justify-content-center justify-content-lg-start pt-4 d-sm-none"
          >
            <Button
              size="lg"
              variant="secondary"
              disabled={dataLoading}
              onClick={onLoadMoreClick}
              block
            >
              <DataSavingSpinner
                savingText="Loding"
                defaultText="Load More"
                dataSaving={dataLoading}
              />
            </Button>
          </Col>
        )}
      </Container>
    </MenuBarWrapper>
  );
}

TokenTransactionContainer.propTypes = {
  tokenTransactionsState: tokenTransactionListProps.isRequired,
  browserState: browserViewProps.isRequired,
  getTokenTransaction: PropTypes.func.isRequired,
};

TokenTransactionContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  tokenTransactionsState: state.tokenTransactions.tokenTransactionForAdmin,
  browserState: state.browserData.view,
});

const mapDispatchToProps = {
  getTokenTransaction: getTokenTransactionByAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenTransactionContainer);
