import { callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const paymentService = {
  getPaymentHistoriesForAdmin: async (query) => {
    const { method, endpoint } = endpoints.getPaymentHistoriesForAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, {}, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  paymentRefundByAdmin: async (data, query) => {
    const { method, endpoint } = endpoints.paymentRefundByAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, {}, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data });

    return response;
  },

  getDefaultCardInfoByConsumer: async (params) => {
    const { method, endpoint } = endpoints.getDefaultPaymentInfoByConsumer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  addStripeDefaultPaymentInfoByConsumer: async (data, params) => {
    const { method, endpoint } = endpoints.addStripeDefaultPaymentInfoByConsumer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data });

    return response;
  },

  updateStripeDefaultPaymentInfoByConsumer: async (data, params) => {
    const { method, endpoint } = endpoints.updateStripeDefaultPaymentInfoByConsumer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data });

    return response;
  },

  deleteDefaultPaymentInfoByConsumer: async (params) => {
    const { method, endpoint } = endpoints.deleteDefaultPaymentInfoByConsumer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  getPaypalDefaultPaymentInfoPage: async (params) => {
    const { method, endpoint } = endpoints.getPaypalDefaultPaymentInfoPage;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  confirmPaypalDefaultPaymentInfo: async (data, params) => {
    const { method, endpoint } = endpoints.confirmPaypalDefaultPaymentInfo;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data });

    return response;
  },
};

export default paymentService;
