import PropTypes from 'prop-types';
import { Button, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EditIcon, EyeIcon } from '../../../../helper/icons/common-icons';

export default function ActionButtons(props) {
  const { onHandleEdit, amount } = props;

  return (
    <>
      <Col className="px-0">
        <OverlayTrigger
          transition={false}
          rootClose
          placement="bottom-start"
          overlay={<Tooltip>{amount >= 0 ? 'Edit' : 'View'}</Tooltip>}
        >
          <Button
            tabIndex={0}
            className="border rounded-circle mr-2 py-2"
            variant="outline-secondary"
            size="sm"
            onClick={onHandleEdit}
          >
            {amount >= 0 && <EditIcon width="1.2rem" height="1.2rem" />}
            {amount < 0 && <EyeIcon width="1.2rem" height="1.2rem" />}
          </Button>
        </OverlayTrigger>
      </Col>
    </>
  );
}

ActionButtons.propTypes = {
  onHandleEdit: PropTypes.func.isRequired,
  amount: PropTypes.number,
};

ActionButtons.defaultProps = {
  amount: 0,
};
