import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import { singlePaymentHistoryProps } from '../credit-refund-common-props';

export default function CreditRefund(props) {
  const { paymentHistoryItem, dataSaving, onCloseModal, onSubmitModal } = props;

  const [fullRefundChecked, setFullRefundChecked] = useState(true);
  const [partialRefundChecked, setPartialRefundChecked] = useState(false);
  const [partialRefundValue, setPartialRefundValue] = useState(1);
  const [partialRefundError, setPartialRefundError] = useState('');

  const paymentAmount = paymentHistoryItem.amount + paymentHistoryItem.refundedAmount;

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {};

    if (fullRefundChecked) {
      data.isFullRefund = true;
    } else if (partialRefundChecked && !partialRefundError) {
      data.isFullRefund = false;
      data.amount = partialRefundValue;
    }

    data.paymentHistoryId = paymentHistoryItem.id;
    data.consumerId = paymentHistoryItem.consumerId;

    onSubmitModal(data);
  };

  const onFullRefundRadioClick = () => {
    setPartialRefundChecked(false);
    setFullRefundChecked(true);
    setPartialRefundValue(1);
    setPartialRefundError('');
  };

  const onPartialRefundRadioClick = () => {
    setPartialRefundChecked(true);
    setFullRefundChecked(false);
    setPartialRefundError('');
  };

  const onChangePartialRefundValue = (e) => {
    const re = /^\d+(\.\d{1,2})?$/;
    const { value } = e.target;

    if (value === '' || re.test(value)) {
      if (value !== '' && +value > +paymentAmount) {
        setPartialRefundError('* Partial refund amount can not be greater than available amount.');
      } else if (value === '') {
        setPartialRefundError('* Partial refund amount can not be empty.');
      } else if (+value === 0) {
        setPartialRefundError('* Invalid amount.');
      } else {
        setPartialRefundError('');
      }

      setPartialRefundValue(value || '');
    } else {
      setPartialRefundValue(value);
      setPartialRefundError('* Invalid amount.');
    }
  };

  return (
    <>
      {paymentHistoryItem && (
        <Form onSubmit={onSubmit}>
          <Row className="text-dark">
            <Col xs={12} className="pb-3 font-weight-bold">
              Username: <span className="font-weight-normal">{paymentHistoryItem.username}</span>
            </Col>

            {+paymentAmount >= 0 && (
              <Col xs={12} className="pb-3 font-weight-bold">
                Available Amount:{' '}
                <span className="font-weight-normal">${paymentAmount.toFixed(2)}</span>
              </Col>
            )}

            <Col xs={12} className="pb-3 font-weight-bold">
              {+paymentAmount >= 0 ? 'Already Refunded Amount: ' : 'Refunded Amount: '}
              <span className="font-weight-normal">
                $
                {+paymentAmount >= 0
                  ? (-1 * paymentHistoryItem.refundedAmount).toFixed(2)
                  : (-1 * paymentHistoryItem.amount).toFixed(2)}
              </span>
            </Col>

            {+paymentAmount === 0 && (
              <Col xs={12} className="pb-3">
                The available amount is insufficient to refund.
              </Col>
            )}

            {+paymentAmount < 0 && (
              <Col xs={12} className="pb-3">
                This is a refunded payment.
              </Col>
            )}

            {+paymentAmount > 0 && (
              <>
                <Col xs={12} className="pb-3 font-weight-bold">
                  Refund Method:
                </Col>

                <Col xs={12} className="ml-3">
                  <Form.Check.Input
                    type="radio"
                    checked={fullRefundChecked}
                    onChange={onFullRefundRadioClick}
                    className="credit-refund-ckeck-input"
                  />
                  <Form.Check.Label className="ml-2">Full Refund</Form.Check.Label>
                </Col>

                <Col xs={12} className="ml-3 py-2">
                  <Form.Check.Input
                    type="radio"
                    checked={partialRefundChecked}
                    onChange={onPartialRefundRadioClick}
                    className="credit-refund-ckeck-input"
                  />
                  <Form.Check.Label className="ml-2">Partial Refund</Form.Check.Label>
                </Col>

                <Col xs={12} className="">
                  {partialRefundChecked && (
                    <Form.Control
                      type="text"
                      className="mt-2"
                      placeholder="$ Amount"
                      value={partialRefundValue}
                      disabled={fullRefundChecked}
                      onChange={onChangePartialRefundValue}
                      maxLength={7}
                    />
                  )}
                </Col>
                <Col xs={12} className="ml-3 text-primary pb-2">
                  {partialRefundError}
                  {!partialRefundError && <br />}
                </Col>
              </>
            )}
          </Row>

          {+paymentAmount > 0 && (
            <Row className="justify-content-end mb-2">
              <Col sm={6} md={5} lg={4}>
                <Button
                  size="lg"
                  variant="outline-secondary"
                  block
                  disabled={dataSaving}
                  onClick={onCloseModal}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm={6} md={5} lg={4} className="pt-3 pt-sm-0">
                <Button
                  size="lg"
                  onClick={onSubmit}
                  type="submit"
                  variant="secondary"
                  block
                  disabled={!!(dataSaving || partialRefundError)}
                >
                  <DataSavingSpinner
                    savingText="Processing"
                    defaultText="Make Refund"
                    dataSaving={dataSaving}
                  />
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      )}
    </>
  );
}

CreditRefund.propTypes = {
  paymentHistoryItem: singlePaymentHistoryProps.isRequired,
  dataSaving: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onSubmitModal: PropTypes.func.isRequired,
};

CreditRefund.defaultProps = {
  dataSaving: false,
};
