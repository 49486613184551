import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { RingLoader } from 'react-spinners';
import { v4 as uuidv4 } from 'uuid';
import colors from '../../../../helper/colors';
import commonMethods from '../../../../helper/common-methods';
import {
  DEFAULT_DATE_TIME_WITHOUT_SECOND_FORMAT,
  // eslint-disable-next-line prettier/prettier
  TABLE_DATE_TIME_FORMAT
} from '../../../../helper/constant-values';
import {
  copyAudioByCustomer,
  deleteAudioByCustomer,
  getAudiosForCustomer,
  // eslint-disable-next-line prettier/prettier
  updateAudioByCustomer
} from '../../../../redux/actions/audio-action';
import { browserViewProps, userInformationProps } from '../../../common/common-props';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import CustomPagination from '../../../common/pagination/custom-pagination';
import FilesHeaderButton from '../category-common-components/files-header-buttons';
import { audioFilesProps, uploadedFilesProps } from '../category-common-props';
import AudioListHeading from './audio-heading';
import AddAudioModal from './audio-modify-modal';
import AudioListRow from './audio-row';

const AudioListHeaderButtons = [
  { name: 'Expired Files', id: 1, isExpired: true },
  { name: 'Active Files', id: 2, isActive: true },
  { name: 'Unexpired Files', id: 3, isUnexpired: true },
];

function AudioContainer(props) {
  const {
    customerAudios,
    browserState,
    userInfoState,
    setShowAddAudioModal,
    showAddAudioModal,
    uploadedAudioData,
    queryUploadedAudioData,
    addUploadedAudioData,
    updateUploadedAudioData,
    deleteUploadedAudioData,
    getAudios,
    deleteAudio,
    copyAudioFunc,
    updateAudio,
  } = props;

  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [dataLoading, setDataLoading] = useState(false);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);
  const [selectedHeaderButtonId, setSelectedHeaderButtonId] = useState(2);
  const [showFileTypeDropDown, setShowFileTypeDropDown] = useState(false);
  const [fileTypeChanging, setFileTypeChanging] = useState(false);
  const [audioDuration, setAudioDuration] = useState('0:00');
  const [selectedAudioFile, setSelectedAudioFile] = useState(null);
  const [audioFileList, setAudioFileList] = useState(uploadedAudioData);

  const { customerUrl, categoryId } = useParams();

  const getPaginationQuery = ({
    pageChanged = false,
    paramsData = {},
    customMethod = null,
    queryData = {},
    payload = null,
  }) => {
    const selectedHeaderButton = AudioListHeaderButtons.find(
      (item) => item.id === selectedHeaderButtonId
    );

    const params = { customerUrl, ...paramsData };
    const query = {
      page,
      itemPerPage,
      consumerCategoryId: +categoryId,
      ..._.omit(selectedHeaderButton, ['id', 'name']),
      ...queryData,
    };

    return {
      isMobileView: browserState.isMobile,
      params,
      query,
      page,
      payload,
      itemPerPage,
      getMethod: customMethod || getAudios,
      pageChanged,
      data: customerAudios.data,
    };
  };

  const onFileTypeChange = (index) => {
    if (selectedHeaderButtonId !== +index) {
      setSelectedHeaderButtonId(+index);
      setPage(1);
      setItemPerPage(20);
      setMobileLastPageLoaded(false);
      setFileTypeChanging(true);
    }
  };

  // Used to show/hide the category(audio & short text) dropdown
  const onToggleCategoryDropDown = () => {
    setShowFileTypeDropDown(!showFileTypeDropDown);
  };

  const onAddAudioModalClose = () => {
    setShowAddAudioModal(false);
    setSelectedAudioFile(null);
  };

  const getUploadedAudioQuery = () => ({
    page,
    itemPerPage,
    isMobile: browserState.isMobile,
  });

  const getUploadedAudioData = () => {
    queryUploadedAudioData(getUploadedAudioQuery());
  };

  const getDateTimeForApi = (dateTime) =>
    commonMethods.getDateFromDateTime(
      dateTime,
      DEFAULT_DATE_TIME_WITHOUT_SECOND_FORMAT,
      TABLE_DATE_TIME_FORMAT
    );

  const onAddAudioSubmit = (data) => {
    const audioData = {
      id: uuidv4(),
      ...data,
      startDateTime: getDateTimeForApi(data.startDateTime),
      endDateTime: getDateTimeForApi(data.endDateTime),
      duration: audioDuration,
      selectedAudioFile,
      uploadPercentage: 0,
      audioFileName: '',
      copyAudio: false,
      audioUploadProcessing: true,
    };

    onAddAudioModalClose();
    addUploadedAudioData(audioData, getUploadedAudioQuery());
    setSelectedHeaderButtonId(4);
  };

  const updateUploadFilesList = (id, data) => {
    updateUploadedAudioData(id, data, getUploadedAudioQuery());
  };

  const onLoadMoreClick = () => {
    setDataLoading(true);
    if (audioFileList.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  const onDeleteAudio = (audioId) =>
    commonMethods
      .updatePaginationData(
        getPaginationQuery({
          paramsData: { audioId },
          queryData: { sendList: !browserState.isMobile },
          customMethod: deleteAudio,
        })
      )
      .then((response) => {
        deleteUploadedAudioData(audioId, getUploadedAudioQuery());

        return response;
      });

  const onEditAudio = (data, params) =>
    updateAudio(data, params).then((response) => {
      updateUploadedAudioData(
        response.data.id,
        {
          ...response.data,
          uploadPercentage: 100,
          copyAudio: false,
          audioUploadProcessing: false,
        },
        getUploadedAudioQuery()
      );
    });

  const onCopyAudio = (payload, params) =>
    commonMethods
      .updatePaginationData(
        getPaginationQuery({
          paramsData: params,
          payload,
          queryData: { sendList: !browserState.isMobile },
          customMethod: copyAudioFunc,
        })
      )
      .then((response) => {
        if (selectedHeaderButtonId === 4) {
          addUploadedAudioData(
            {
              ...response.data.data[0],
              uploadPercentage: 100,
              copyAudio: false,
              audioUploadProcessing: false,
            },
            getUploadedAudioQuery()
          );
        }
      });

  useEffect(() => {
    setAudioFileList(selectedHeaderButtonId === 4 ? uploadedAudioData : customerAudios);
  }, [uploadedAudioData, customerAudios, selectedHeaderButtonId]);

  useEffect(() => {
    if (selectedHeaderButtonId === 4) {
      getUploadedAudioData();
    } else {
      commonMethods.updatePaginationData(getPaginationQuery({ pageChanged: true }));
    }
  }, [page]);

  useEffect(() => {
    if (selectedHeaderButtonId === 4) {
      getUploadedAudioData();
    } else {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    }
  }, [itemPerPage, mobileLastPageLoaded, selectedHeaderButtonId]);

  useEffect(() => {
    if (!browserState.isMobile) {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    }
  }, [browserState.isMobile]);

  useEffect(() => {
    setDataLoading(false);
    setFileTypeChanging(false);
  }, [audioFileList]);

  useEffect(() => {
    if (AudioListHeaderButtons.length === 3 && uploadedAudioData.data.length > 0) {
      AudioListHeaderButtons.push({ name: 'Uploaded Files', id: 4 });
    }
    if (AudioListHeaderButtons.length === 4 && uploadedAudioData.data.length === 0) {
      AudioListHeaderButtons.pop();
      setSelectedHeaderButtonId(2);
    }
  }, [uploadedAudioData]);

  useEffect(() => {
    if (selectedAudioFile === null) return;
    commonMethods.getAudioDurationOfUploadedFile(setAudioDuration, selectedAudioFile);
  }, [selectedAudioFile]);

  return (
    <>
      <AddAudioModal
        headerTitle="Add Audio"
        timeZone={userInfoState.timeZone}
        submitButonText="Save Changes"
        showModal={showAddAudioModal}
        onCloseModal={onAddAudioModalClose}
        setSelectedAudioFile={setSelectedAudioFile}
        onSubmit={onAddAudioSubmit}
        addAudio
      />

      <Row className="mt-4 mt-sm-0 bg-white rounded-lg">
        <FilesHeaderButton
          isAudio
          onHandleAddButton={() => {
            setShowAddAudioModal(true);
          }}
          onFileTypeChange={onFileTypeChange}
          onToggleDropDown={onToggleCategoryDropDown}
          showFileTypeDropDown={showFileTypeDropDown}
          selectedFileType={selectedHeaderButtonId}
          headerButtons={AudioListHeaderButtons}
        />

        {!fileTypeChanging && (
          <>
            <AudioListHeading />

            {audioFileList.data.map((item) => (
              <AudioListRow
                key={item.id}
                audioItem={item}
                timeZone={userInfoState.timeZone}
                onDeleteAudio={onDeleteAudio}
                onEditAudio={onEditAudio}
                onCopyAudio={onCopyAudio}
                updateUploadedAudioData={updateUploadFilesList}
                deleteUploadedAudioData={deleteUploadedAudioData}
                selectedHeaderButtonId={selectedHeaderButtonId}
              />
            ))}

            {audioFileList.totalItems > 10 && (
              <Col className="px-0 border-top">
                <CustomPagination
                  itemPerPage={itemPerPage}
                  page={page}
                  setPage={setPage}
                  setItemPerPage={setItemPerPage}
                  totalPages={audioFileList.totalPages}
                  totalItems={audioFileList.totalItems}
                />
              </Col>
            )}

            {audioFileList.totalItems > audioFileList.data.length && (
              <Col
                sm={11}
                className="bg-light justify-content-center justify-content-lg-start pt-4 d-sm-none"
              >
                <Button size="lg" disabled={dataLoading} onClick={onLoadMoreClick} block>
                  <DataSavingSpinner
                    savingText="Loding"
                    defaultText="Load More"
                    dataSaving={dataLoading}
                  />
                </Button>
              </Col>
            )}
          </>
        )}
      </Row>

      <Col className="pb-5 mb-5 bg-light d-sm-none" />

      {fileTypeChanging && (
        <Row className="d-flex justify-content-center bg-white">
          <RingLoader loading size={100} color={colors.secondary} />
        </Row>
      )}
    </>
  );
}

AudioContainer.propTypes = {
  customerAudios: audioFilesProps.isRequired,
  getAudios: PropTypes.func.isRequired,
  browserState: browserViewProps.isRequired,
  userInfoState: userInformationProps.isRequired,
  deleteAudio: PropTypes.func.isRequired,
  updateAudio: PropTypes.func.isRequired,
  copyAudioFunc: PropTypes.func.isRequired,
  uploadedAudioData: uploadedFilesProps.isRequired,
  queryUploadedAudioData: PropTypes.func.isRequired,
  setShowAddAudioModal: PropTypes.func.isRequired,
  showAddAudioModal: PropTypes.bool.isRequired,
  addUploadedAudioData: PropTypes.func.isRequired,
  updateUploadedAudioData: PropTypes.func.isRequired,
  deleteUploadedAudioData: PropTypes.func.isRequired,
};

AudioContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  customerAudios: state.audio.audiosForCustomer,
  browserState: state.browserData.view,
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  getAudios: getAudiosForCustomer,
  deleteAudio: deleteAudioByCustomer,
  updateAudio: updateAudioByCustomer,
  copyAudioFunc: copyAudioByCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioContainer);
