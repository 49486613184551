import { GET_USER_INFORMATION_AND_SUBSCRIPTION_CATEGORY } from '../types';

const initialState = {
  customerDetails: {
    companyName: '',
    details: '',
    imageUrl: '',
    logoUrl: '',
    subscriptionCategory: [],
  },
};

const consumerLandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFORMATION_AND_SUBSCRIPTION_CATEGORY:
      return {
        ...state,
        customerDetails: action.data,
      };

    default:
      return state;
  }
};

export default consumerLandingReducer;
