import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { CloseCircleIcon } from '../../../helper/icons/common-icons';
import './audio-player-style.css';
import CustomAudioPlayer from './custom-audio-player';

export default function AudioPlayerModal(props) {
  const { name, description, showModal, modalShowHideToggle, audioSrc, headerTitle } = props;

  const [show, setShow] = useState(false);
  const onClose = () => {
    setShow(false);
    modalShowHideToggle(false);
  };

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={onClose}
      size="lg"
      className="px-2"
      animation={false}
      centered
    >
      <Modal.Body className="text-center">
        <Container>
          <Row className="mb-3 py-3 border-bottom">
            <Col xs={7} className="d-flex justify-content-start">
              <span className="h5">{headerTitle}</span>
            </Col>
            <Col className="d-flex justify-content-end">
              <CloseCircleIcon
                width="1.5em"
                height="1.5em"
                className="cursor-pointer text-muted"
                onClick={onClose}
              />
            </Col>
          </Row>

          <CustomAudioPlayer name={name} description={description} audioSrc={audioSrc} />
        </Container>
      </Modal.Body>
    </Modal>
  );
}

AudioPlayerModal.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  showModal: PropTypes.bool,
  modalShowHideToggle: PropTypes.func,
  audioSrc: PropTypes.string.isRequired,
  headerTitle: PropTypes.string,
};

AudioPlayerModal.defaultProps = {
  showModal: false,
  headerTitle: 'Test Player',
  modalShowHideToggle: () => {},
  description: '',
};
