import PropTypes from 'prop-types';
import { useState } from 'react';
import { Accordion, Col } from 'react-bootstrap';
import { packageListProps } from '../../customer-common-props';
import PackageRow from './package-row';

export default function CustomerPackage(props) {
  const { packageList, onUpdatePackageSubmit, customerId, onDeletePackageSubmit } = props;

  const [clickedId, setClickedId] = useState(0);

  const onTogglePackageRow = (id) => {
    if (id === clickedId) {
      setClickedId(0);
    } else {
      setClickedId(id);
    }
  };

  return (
    <Col md={12} className="pt-2 pb-4">
      <Accordion>
        {packageList.data.map((item) => (
          <PackageRow
            packageItem={item}
            eventKey={item.id}
            key={item.id}
            onToggle={onTogglePackageRow}
            clickedIndex={clickedId}
            onUpdatePackageSubmit={onUpdatePackageSubmit}
            onDeletePackageSubmit={onDeletePackageSubmit}
            customerId={customerId}
          />
        ))}
      </Accordion>
    </Col>
  );
}

CustomerPackage.propTypes = {
  packageList: packageListProps.isRequired,
  onUpdatePackageSubmit: PropTypes.func.isRequired,
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onDeletePackageSubmit: PropTypes.func.isRequired,
};

CustomerPackage.defaultProps = {};
