import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import ImageComponent from './image-component';
import InfoPanelDetails from './info-panel-details';

export default function NonCustomerInfoPanel(props) {
  const { title, details, imageUrl, buttonText } = props;
  return (
    <Row className="justify-content-center py-md-5 pb-sm-5">
      <Col lg={{ span: 6, order: 2 }} md={{ span: 12, order: 1 }} xs={12} className="py-md-5">
        <ImageComponent imageUrl={imageUrl} isCusomerPanel={false} />
      </Col>
      <Col lg={{ span: 6, order: 1 }} md={{ span: 12, order: 2 }} xs={12} className="py-md-5">
        <InfoPanelDetails details={details} title={title} buttonText={buttonText} header={false} />
      </Col>
    </Row>
  );
}

NonCustomerInfoPanel.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

NonCustomerInfoPanel.defaultProps = {};
