import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { OTP_TYPE_EMAIL, OTP_TYPE_PASSWORD } from '../../helper/constant-values';
import getUserInformationAndSubscriptionCategory from '../../redux/actions/consumer-landing-action';
import PageNotFoundPage from '../common/page-not-found';
import AuthWrapper from './auth-wrapper';
import Registration from './consumer-registration/consumer-registration';
import Login from './customer-consumer-login/customer-consumer-login';
import ForgetPassword from './forgot-password/forgot-password';
import NewPassword from './forgot-password/new-password';
import { customerInformationProps } from './login-registration-common-props';
import OtpVerification from './otp-verfication/otp-verification';

function LoginRegistrationContainer(props) {
  const { customerInformation, getCustomerInformation } = props;
  const { logoUrl, subscriptionCategory } = customerInformation.customerDetails;

  const location = useLocation();
  const history = useHistory();
  const { customerUrl } = useParams();

  const [showPageNotFound, setShowPageNotFound] = useState(false);
  const [loginPage, setLoginPage] = useState(true);
  const [signupPage, setSignupPage] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [verifySignupEmail, setVerifySignupEmail] = useState(false);
  const [verifyLoginEmail, setVerifyLoginEmail] = useState(false);
  const [verifyForgotPassword, setVerifyForgotPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [loginUsername, setLoginUsername] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [backFromVerification, setBackFromVerification] = useState(false);
  const [backButtonLabel, setBackButtonLabel] = useState('Back');
  const [pageHeader, setPageHeader] = useState('Login to your Account');
  const [isRegistration, setIsRegistration] = useState(false);

  const goToLoginPage = () => {
    setLoginEmail('');
    setLoginUsername('');
    setNewPassword(false);
    setForgotPassword(false);
    setSignupPage(false);
    setLoginPage(true);
    setVerifySignupEmail(false);
    setVerifyLoginEmail(false);
    setVerifyForgotPassword(false);
    setBackFromVerification(false);
    setBackButtonLabel('Back');
    setPageHeader('Login to your Account');
    setIsRegistration(false);
  };

  const goToForgetPasswordPage = () => {
    setForgotPassword(true);
    setLoginPage(false);
    setVerifyForgotPassword(false);
    setBackButtonLabel('Back to Login');
    setPageHeader('Forgot Password');
    setIsRegistration(false);
  };

  const goToNewPasswordPage = () => {
    setVerifyForgotPassword(false);
    setNewPassword(true);
    setPageHeader('Enter New Password');
    setIsRegistration(false);
  };

  const goToRegistrationPage = () => {
    setLoginPage(false);
    setSignupPage(true);
    setVerifySignupEmail(false);
    setPageHeader('Create your Account');
    setIsRegistration(true);
  };

  const verificationAfterSignUp = () => {
    setVerifySignupEmail(true);
    setSignupPage(false);
    setPageHeader('Verify your Email');
    setIsRegistration(true);
  };

  const verificationAfterForgotPassword = () => {
    setForgotPassword(false);
    setVerifyForgotPassword(true);
    setPageHeader('Enter Verification Code');
    setIsRegistration(false);
  };

  const onUnverifiedEmail = ({ username, email }) => {
    setLoginUsername(username);
    setLoginEmail(email);
    setVerifyLoginEmail(true);
    setLoginPage(false);
    setPageHeader('Verify your Email');
  };

  const onBackButtonClick = () => {
    if (forgotPassword || verifyLoginEmail || verifyForgotPassword || newPassword) {
      goToLoginPage();
    } else if (verifySignupEmail) {
      goToRegistrationPage();
      setBackFromVerification(true);
    } else {
      history.push(`/${customerUrl}`);
    }
  };

  useEffect(() => {
    getCustomerInformation({ customerUrl }).catch(() => {
      setShowPageNotFound(true);
    });

    if (location.state && location.state.clickedRegistration) {
      goToRegistrationPage();
    }
  }, []);

  return (
    <>
      {showPageNotFound && <PageNotFoundPage />}

      {!showPageNotFound && (
        <AuthWrapper
          backButtonLabel={backButtonLabel}
          pageHeader={pageHeader}
          logo={logoUrl}
          onBackButtonClick={onBackButtonClick}
          isRegistration={isRegistration}
          showBackButton={!loginPage && !signupPage}
        >
          {loginPage && (
            <Login
              goToforgetPassword={goToForgetPasswordPage}
              goToRegistrationPage={goToRegistrationPage}
              onUnverifiedEmail={onUnverifiedEmail}
              backFromVerification={backFromVerification}
            />
          )}

          {signupPage && (
            <Registration
              verificationAfterSignUp={verificationAfterSignUp}
              goToLoginPage={goToLoginPage}
              consumerCategory={subscriptionCategory}
              backFromVerification={backFromVerification}
            />
          )}

          {forgotPassword && (
            <ForgetPassword
              goToVerificationPage={verificationAfterForgotPassword}
              backFromVerification={backFromVerification}
            />
          )}

          {newPassword && <NewPassword goToLoginPage={goToLoginPage} />}

          {verifyLoginEmail && (
            <OtpVerification
              loginUsername={loginUsername}
              loginEmail={loginEmail}
              onVerification={goToLoginPage}
              otpType={OTP_TYPE_EMAIL}
            />
          )}

          {verifySignupEmail && (
            <OtpVerification
              onVerification={goToLoginPage}
              otpType={OTP_TYPE_EMAIL}
              isRegistration
            />
          )}

          {verifyForgotPassword && (
            <OtpVerification onVerification={goToNewPasswordPage} otpType={OTP_TYPE_PASSWORD} />
          )}
        </AuthWrapper>
      )}
    </>
  );
}

LoginRegistrationContainer.propTypes = {
  getCustomerInformation: PropTypes.func.isRequired,
  customerInformation: customerInformationProps.isRequired,
};

LoginRegistrationContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  customerInformation: state.consumerLanding,
});

const mapDispatchToProps = {
  getCustomerInformation: getUserInformationAndSubscriptionCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginRegistrationContainer);
