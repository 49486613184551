import PropTypes from 'prop-types';

export const revenueReportProps = PropTypes.shape({
  totalRevenue: PropTypes.number.isRequired,
  revenueData: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      dayAmount: PropTypes.number,
    })
  ).isRequired,
});

export const usageReportProps = PropTypes.shape({
  totalAudioPlays: PropTypes.number.isRequired,
  totalSmsSunbrusts: PropTypes.number.isRequired,
  usageData: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      audioPlays: PropTypes.string,
      smsSunbrusts: PropTypes.string,
    })
  ).isRequired,
});

export const grossSaleProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  packageSoldQuantity: PropTypes.number.isRequired,
  tokenCount: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
});

export const grossSalePaginationProps = PropTypes.shape({
  totalItems: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(grossSaleProps.isRequired).isRequired,
});

export const browserViewProps = PropTypes.shape({
  isMobile: PropTypes.bool.isRequired,
  isTab: PropTypes.bool.isRequired,
  isLaptop: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isExtraLargeScreen: PropTypes.bool.isRequired,
});

export const grossSalesArrayProps = PropTypes.arrayOf(grossSaleProps.isRequired);
