import { axios, callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const customerService = {
  getCustomerDetailsByUrl: async ({ url }) => {
    const { method, endpoint } = endpoints.getCustomerDetailsByUrl;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, { url });
    const response = await axios({ method, url: requestUrl });

    return response;
  },

  getUserInformationAndSubscriptionCategory: async ({ customerUrl }) => {
    const { method, endpoint } = endpoints.consumerLanding;
    const url = utils.buildUrl(baseUrl + endpoint, {
      customerUrl,
    });

    const response = await axios({ method, url });

    return response;
  },

  getCustomerListForAdmin: async (query) => {
    const { method, endpoint } = endpoints.getCustomerListForAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, {}, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  getCustomerDetailsForAdmin: async (params) => {
    const { method, endpoint } = endpoints.getCustomerDetailsForAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  createCustomerByAdmin: async (data) => {
    const { method, endpoint } = endpoints.createCustomerByAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, {});
    const response = await callAxiosWithAuth({ method, url: requestUrl, data });

    return response;
  },

  updateCustomerByAdmin: async (data, params) => {
    const { method, endpoint } = endpoints.updateCustomerByAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data });

    return response;
  },

  deleteCustomerByAdmin: async (params, query) => {
    const { method, endpoint } = endpoints.deleteCustomerByAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },
};

export default customerService;
