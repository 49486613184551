import PropTypes from 'prop-types';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { grossSaleProps } from '../revenue-common-props';

export default function SalesTableItem({ item, clickedIndex, dataIndex, onHandleCollapse }) {
  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={item.id}
        onClick={() => onHandleCollapse(dataIndex)}
        className={dataIndex === clickedIndex ? 'bg-light' : 'bg-white'}
      >
        <Row>
          <Col xs={6}> Package Name </Col>{' '}
          <Col xs={6} className="text-right">
            {item.name}&nbsp;
            {dataIndex !== clickedIndex && <IoIosArrowDown size="1.1rem" />}
            {dataIndex === clickedIndex && <IoIosArrowUp size="1.1rem" />}
          </Col>
        </Row>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={item.id}>
        <Card.Body className="px-3 pt-1 bg-light">
          <Row className="py-2">
            <Col xs={8} className="pr-0">
              Packages Sold
            </Col>
            <Col xs={4} className="text-right">
              {item.packageSoldQuantity.toLocaleString()}
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8} className="pr-0">
              Token Count
            </Col>
            <Col xs={4} className="text-right">
              {item.tokenCount.toLocaleString()}
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs={8} className="pr-0">
              Dollar Amount
            </Col>
            <Col xs={4} className="text-right">
              {`$${item.totalAmount.toLocaleString()}`}
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

SalesTableItem.propTypes = {
  item: grossSaleProps.isRequired,
  clickedIndex: PropTypes.number.isRequired,
  dataIndex: PropTypes.number.isRequired,
  onHandleCollapse: PropTypes.func.isRequired,
};

SalesTableItem.defaultProps = {};
