import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { forgotPasswordValidationSchema } from '../../../helper/schema-objects';
import { forgotPassword } from '../../../redux/actions/auth-action';
import FormComponent from '../../common/form-component/form-component';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import VerificationCodeConfirmModal from '../../common/modals/verification-code-send-confirmation-modal/verification-code-confirmation-modal';
import './forgot-password.css';

function ForgotPassword(props) {
  const {
    goToVerificationPage,
    userForgotPassword,
    backFromVerification,
    forgotPasswordPreviousState,
  } = props;

  const [disableContinueButton, setDisableContinueButton] = useState(false);
  const [showVerificationConfirmModal, setShowVerificationConfirmModal] = useState(false);
  const [forgotPasswordValues, setForgotPasswordValues] = useState({});
  const [forgotPasswordMethods, setForgotPasswordMethods] = useState({});

  const { customerUrl } = useParams();

  let initialValues = {
    emailOrUsername: '',
  };

  if (backFromVerification) {
    initialValues = {
      ...initialValues,
      ...forgotPasswordPreviousState,
    };
  }

  const onVerificationModalCancel = () => {
    setShowVerificationConfirmModal(false);
    setForgotPasswordValues({});
    setForgotPasswordMethods({});
  };

  const onVerificationModalProceed = () => {
    const userData = {};
    userData.emailOrUsername = forgotPasswordValues.emailOrUsername.trim();

    setDisableContinueButton(true);

    userForgotPassword(userData, { customerUrl }, forgotPasswordValues)
      .then(() => {
        setDisableContinueButton(false);
        goToVerificationPage();
      })
      .catch((error) => {
        setDisableContinueButton(false);

        if (!error.response) {
          return;
        }

        const { invalidUser, message } = error.response.data;

        if (invalidUser) {
          forgotPasswordMethods.setErrors({
            emailOrUsername: message,
          });
        }
      });
  };

  const onSubmit = (values, formikFunctions) => {
    setForgotPasswordValues(values);
    setForgotPasswordMethods(formikFunctions);
    setShowVerificationConfirmModal(true);
  };

  return (
    <>
      <VerificationCodeConfirmModal
        isPassowrdEmailVerification
        isForgotPasswordPage
        verificationMedium={forgotPasswordValues.emailOrUsername}
        showModal={showVerificationConfirmModal}
        onModalCancel={onVerificationModalCancel}
        onModalProceed={onVerificationModalProceed}
      />

      <div className="px-2 pl-lg-0">
        <Row className="justify-content-center justify-content-lg-start pb-4">
          <Col sm={11} md={7} lg={12}>
            <p className="text-center text-lg-left">Please enter your email or username</p>
          </Col>
        </Row>

        <Formik
          initialValues={initialValues}
          validationSchema={forgotPasswordValidationSchema}
          onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Row className="justify-content-center justify-content-lg-start">
                <FormComponent
                  type="text"
                  placeholder="Email or Username"
                  name="emailOrUsername"
                  formik={formik}
                  autoFocusInput
                  showLabel
                />
              </Row>
              <Row className="justify-content-center justify-content-lg-start mt-3 pt-lg-3">
                <Col sm={11} lg={6}>
                  <Button
                    size="lg"
                    variant="secondary"
                    block
                    onClick={formik.handleSubmit}
                    type="submit"
                    disabled={!!(formik.errors.emailOrUsername || disableContinueButton)}
                  >
                    <DataSavingSpinner defaultText="Continue" dataSaving={disableContinueButton} />
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

ForgotPassword.propTypes = {
  goToVerificationPage: PropTypes.func.isRequired,
  userForgotPassword: PropTypes.func.isRequired,
  backFromVerification: PropTypes.bool,
  forgotPasswordPreviousState: PropTypes.shape({
    emailOrUsername: PropTypes.string.isRequired,
  }).isRequired,
};

ForgotPassword.defaultProps = {
  backFromVerification: false,
};

const mapStateToProps = (state) => ({
  forgotPasswordPreviousState: state.auth.forgotPasswordPrevious,
});

const mapDispatchToProps = {
  userForgotPassword: forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
