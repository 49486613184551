import { Formik } from 'formik';
import _ from 'lodash';
import { serialize } from 'object-to-formdata';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addCustomerCategoryValidationSchema } from '../../../../../helper/schema-objects';
import CustomCheckbox from '../../../../common/buttons/custom-checkbox';
import FormComponent from '../../../../common/form-component/form-component';
import DataSavingSpinner from '../../../../common/loader-spinner/data-saving-spinner';
import {
  // eslint-disable-next-line prettier/prettier
  singleCategoryWithDeliverMediumProps
} from '../../customer-common-props';

function CustomerCategoryForm(props) {
  const {
    categoryLogo,
    categoryLogoError,
    isAddForm,
    categoryItem,
    onCategorySubmit,
    onCloseModal,
    customerId,
  } = props;

  const getIsDisableByMediumName = (name) =>
    categoryItem.deliveryMedium.find((item) => item.name === name)?.isDisable;

  const [dataSaving, setDataSaving] = useState(false);
  const [checkedSms, setCheckedSms] = useState(
    isAddForm ? false : !getIsDisableByMediumName('SMS')
  );
  const [checkedEmail, setCheckedEmail] = useState(
    isAddForm ? false : !getIsDisableByMediumName('Email')
  );

  const getIdByMediumName = (name) =>
    categoryItem.deliveryMedium.find((item) => item.name === name)?.id;

  const getNumberOfTokenByMediumName = (name) =>
    categoryItem.deliveryMedium.find((item) => item.name === name)?.numberOfTokens;

  const initialValues = {
    name: isAddForm ? '' : categoryItem.name,
    sortOrder: isAddForm ? '' : categoryItem.sortOrder,
    numberOfTokensAudio: isAddForm ? '' : getNumberOfTokenByMediumName('Audio'),
    numberOfTokensSMS: isAddForm ? '' : getNumberOfTokenByMediumName('SMS') || '',
    numberOfTokensEmail: isAddForm ? '' : getNumberOfTokenByMediumName('Email') || '',
  };

  const getNumberOfTokenSchemaName = (name) => {
    if (name === 'Audio') return 'numberOfTokensAudio';
    if (name === 'SMS') return 'numberOfTokensSMS';
    if (name === 'Email') return 'numberOfTokensEmail';
    return '';
  };

  const checkboxClick = (name, { setFieldValue, setFieldTouched }) => {
    if (name === 'SMS') {
      if (checkedSms) {
        setFieldValue(getNumberOfTokenSchemaName(name), '');
        setFieldTouched(getNumberOfTokenSchemaName(name), false);
      }

      setCheckedSms(!checkedSms);
    }
    if (name === 'Email') {
      if (checkedEmail) {
        setFieldValue(getNumberOfTokenSchemaName(name), '');
        setFieldTouched(getNumberOfTokenSchemaName(name), false);
      }

      setCheckedEmail(!checkedEmail);
    }
  };

  const getCkeckboxCheckedValue = (name) => {
    if (name === 'SMS') {
      return checkedSms;
    }
    if (name === 'Email') {
      return checkedEmail;
    }
    return true;
  };

  const onSubmit = (values, { setErrors }) => {
    const categoryData = {
      ..._.pick(values, ['name', 'sortOrder']),
      customerId,
      deliveryMedium: [
        {
          name: 'audio',
          numberOfTokens: values.numberOfTokensAudio,
          isDisable: false,
        },
        {
          name: 'sms',
          numberOfTokens: checkedSms ? values.numberOfTokensSMS : 0,
          isDisable: !checkedSms,
        },
        {
          name: 'email',
          numberOfTokens: checkedEmail ? values.numberOfTokensEmail : 0,
          isDisable: !checkedEmail,
        },
      ],
    };

    if (!isAddForm) {
      categoryData.id = categoryItem.id;
      categoryData.deliveryMedium[0].id = getIdByMediumName('Audio');
      categoryData.deliveryMedium[1].id = getIdByMediumName('SMS');
      categoryData.deliveryMedium[2].id = getIdByMediumName('Email');
    }

    const formData = serialize(categoryData, { indices: true });
    if (categoryLogo) formData.append('categoryLogo', categoryLogo, categoryLogo.name);

    setDataSaving(true);

    const params = { consumerCategoryId: categoryItem.id };

    return onCategorySubmit(formData, params)
      .then(() => {
        setDataSaving(false);
        onCloseModal();
      })
      .catch((error) => {
        if (!error.response) {
          setDataSaving(false);
          return;
        }

        setDataSaving(false);

        const { isExistingName, message } = error.response.data;

        if (isExistingName) {
          setErrors({
            name: message,
          });
        }
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={addCustomerCategoryValidationSchema({
          isDisabledSms: !checkedSms,
          isDisabledEmail: !checkedEmail,
        })}
        onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
        enableReinitialize
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Row className="justify-content-center">
              <Col sm={12} className="mb-3 text-center">
                <h5>Category Information</h5>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <FormComponent
                placeholder="Category Name"
                name="name"
                inputColumnClass="col-12"
                errorColumnClass="col-12"
                formik={formik}
                autoFocusInput
                showLabel
              />
            </Row>

            <Row className="justify-content-center">
              <FormComponent
                placeholder="Sort Order"
                name="sortOrder"
                inputColumnClass="col-12"
                errorColumnClass="col-12"
                formik={formik}
                type="number"
                showLabel
              />
            </Row>

            <Row className="justify-content-center">
              <Col sm={12} className="my-3 text-center">
                <h5>Delivey Medium Information</h5>
              </Col>
            </Row>

            {['Audio', 'Email', 'SMS'].map((item) => (
              <Row className="justify-content-center mt-1" key={item}>
                <Col xs={4} sm={3} lg={2} className="pt-2">
                  {item !== 'Audio' && (
                    <CustomCheckbox
                      className="mr-2 text-secondary"
                      checked={getCkeckboxCheckedValue(item)}
                      onClick={() => checkboxClick(item, formik)}
                    />
                  )}
                  {item === 'Audio' && <span className="mr-2">&emsp;&ensp;</span>}
                  {item}
                </Col>
                <Col xs={2} className="text-right pt-2">
                  Tokens:
                </Col>
                <FormComponent
                  placeholder="Number of tokens"
                  name={getNumberOfTokenSchemaName(item)}
                  inputColumnClass="col-6 col-sm-7 col-lg-8"
                  errorColumnClass="col-12 col-sm-7 col-lg-8 offset-sm-5 offset-lg-4"
                  formik={formik}
                  type="number"
                  disabled={!getCkeckboxCheckedValue(item)}
                />
              </Row>
            ))}

            <Row className="justify-content-center mt-1 mb-2">
              <Col lg={8} className="pt-3 pt-sm-0">
                <Button
                  size="lg"
                  block
                  variant="secondary"
                  onClick={formik.handleSubmit}
                  type="submit"
                  disabled={
                    !!(
                      formik.errors.name ||
                      formik.errors.sortOrder ||
                      formik.errors.numberOfTokensAudio ||
                      (checkedSms && formik.errors.numberOfTokensSMS) ||
                      (checkedEmail && formik.errors.numberOfTokensEmail) ||
                      (isAddForm && categoryLogo === null) ||
                      dataSaving ||
                      categoryLogoError
                    )
                  }
                >
                  <DataSavingSpinner
                    savingText="Saving Changes"
                    defaultText="Save Changes"
                    dataSaving={dataSaving}
                  />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}

CustomerCategoryForm.propTypes = {
  categoryLogo: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  categoryLogoError: PropTypes.string.isRequired,
  isAddForm: PropTypes.bool,
  categoryItem: singleCategoryWithDeliverMediumProps,
  onCategorySubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

CustomerCategoryForm.defaultProps = {
  isAddForm: false,
  categoryItem: {
    id: 0,
    name: '',
    logoSvg: '',
    imageUrl: '',
    customerId: 0,
    deliveryMedium: [
      {
        id: 0,
        isDisable: false,
        name: '',
        numberOfTokens: 0,
      },
    ],
  },
  categoryLogo: null,
};

const mapStateToProps = (state) => ({
  customerDetailState: state.customers.customerDetailsForAdmin,
  customerDetailPreviousState: state.customers.customerDetailsForAdmin,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCategoryForm);
