import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import config from '../../../../config/default';
import colors from '../../../../helper/colors';
import { ICON_SIZE } from '../../../../helper/constant-values';
import useModalAndToolTip from '../../../../helper/hooks/useModalAndToolTip';
import { CloseCircleIcon } from '../../../../helper/icons/common-icons';
import Dropzone from '../../../common/file-upload/dropzone';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';

export default function ShortTextUploadModal(props) {
  const { showModal, onCloseModal, setSelectedTextFile, dataSaving, onSubmit } = props;

  const [textFileError, setTextFileError] = useState('');
  const [textFileSelected, setTextFileSelected] = useState(false);

  const maximumTextFileSize = config.files.maximumBulkTextUploadSize;

  const onAudioFileSelect = (file) => {
    if (file === null) {
      setTextFileError('');
      setTextFileSelected(false);
    } else if (file.size > maximumTextFileSize) {
      setTextFileError('* Maximum 5MB text file is allowed.');
      setTextFileSelected(false);
    } else {
      setSelectedTextFile(file);
      setTextFileSelected(true);
    }
  };

  const onHandleSubmit = () => {
    onSubmit();
  };

  useModalAndToolTip(showModal);

  return (
    <Modal
      show={showModal}
      onHide={onCloseModal}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      className="service-modal-dialog"
      centered
    >
      <Modal.Header>
        <span className="h5">Bulk Text Upload</span>
        <CloseCircleIcon
          className="text-muted cursor-pointer"
          width={ICON_SIZE}
          height={ICON_SIZE}
          onClick={onCloseModal}
        />
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row className="justify-content-center">
            <Dropzone
              supportedFiles="txt"
              fileMaxSize="5MB"
              acceptedFileTypes=".txt"
              backgroundColor={colors.secondary}
              borderAndButtonVarient="secondary"
              onAudioFileSelect={onAudioFileSelect}
            />
          </Row>

          <Row className="justify-content-center pb-4">
            <Col>
              <span className="text-primary">{textFileError}</span>
              {!textFileError && <br />}
            </Col>
          </Row>

          <Row className="justify-content-end mb-2">
            <Col sm={6} md={5} lg={4}>
              <Button
                size="lg"
                variant="outline-secondary"
                block
                disabled={dataSaving}
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </Col>
            <Col sm={6} md={5} lg={4} className="pt-3 pt-sm-0">
              <Button
                size="lg"
                onClick={onHandleSubmit}
                type="submit"
                variant="secondary"
                block
                disabled={!!(dataSaving || !textFileSelected)}
              >
                <DataSavingSpinner
                  savingText="Processing"
                  defaultText="Save Changes"
                  dataSaving={dataSaving}
                />
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

ShortTextUploadModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  setSelectedTextFile: PropTypes.func,
  dataSaving: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

ShortTextUploadModal.defaultProps = {
  setSelectedTextFile: () => {},
  dataSaving: false,
};
