import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Figure, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { RingLoader } from 'react-spinners';
import defaultPic from '../../../../../assets/image/gray.jpg';
import colors from '../../../../../helper/colors';
import commomMethods from '../../../../../helper/common-methods';
import { ICON_SIZE } from '../../../../../helper/constant-values';
import { EditIcon } from '../../../../../helper/icons/common-icons';
import routes from '../../../../../helper/route-details';
import { getCustomerDetailsForAdmin } from '../../../../../redux/actions/customer-actions';
import { customerDetailsProps } from '../../customer-common-props';
import CustomerInfo from './customer-info';

function CustomerInfoContainer(props) {
  const { customerDetailsSatate, getCustomerDetails } = props;

  const [dataLoading, setDataLoading] = useState(false);

  const { customerId } = useParams();
  const history = useHistory();

  const goToEditUserProfile = () => {
    history.push(routes.admin.customers.update(customerId));
  };

  useEffect(() => {
    setDataLoading(true);

    return getCustomerDetails({ customerId })
      .then(() => {
        setDataLoading(false);
      })
      .catch(() => {
        setDataLoading(false);
      });
  }, []);

  return (
    <>
      {dataLoading && (
        <Col xs={12} className="d-flex justify-content-center">
          <RingLoader loading size={100} color={colors.secondary} />
        </Col>
      )}

      {!dataLoading && (
        <>
          <Col lg={3} className="d-flex justify-content-center pt-lg-3">
            <Figure>
              <Figure.Image
                width={200}
                height={100}
                src={commomMethods.getImageApi(customerDetailsSatate.logoUrl) || defaultPic}
                fluid={false}
              />
              <h5 className="text-center pt-4">{customerDetailsSatate.companyName} Logo</h5>
            </Figure>
          </Col>
          <Col lg={1} className="d-none d-lg-block border-left vertical-divider" />
          <Col lg={1} className="d-block d-lg-none border-top ml-4 mr-4 mb-2 mb-sm-4" />
          <Col lg={8} className="mr-2 ml-2 ml-lg-5">
            <Row className="pb-3">
              <Col>
                <h5 className="pt-3 pb-2">Customer Information</h5>
              </Col>
              <Col>
                <Button
                  variant="outline-secondary"
                  className="d-none d-sm-block float-right"
                  size="lg"
                  onClick={goToEditUserProfile}
                >
                  <EditIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
                  Edit Customer
                </Button>
              </Col>
            </Row>

            <CustomerInfo />

            <Row className="d-block d-sm-none">
              <Col>
                <Button
                  variant="outline-secondary"
                  className="mb-3"
                  size="lg"
                  block
                  onClick={goToEditUserProfile}
                >
                  <EditIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
                  Edit Customer
                </Button>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </>
  );
}

CustomerInfoContainer.propTypes = {
  customerDetailsSatate: customerDetailsProps.isRequired,
  getCustomerDetails: PropTypes.func.isRequired,
};

CustomerInfoContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  customerDetailsSatate: state.customers.customerDetailsForAdmin,
});

const mapDispatchToProps = {
  getCustomerDetails: getCustomerDetailsForAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfoContainer);
