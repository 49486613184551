/* eslint-disable import/prefer-default-export */
import commonMethods from '../../helper/common-methods';
import { showNotification } from '../../helper/notifications';
import customerService from '../../services/customer';
import {
  CUSTOMER_DELETE_BY_ADMIN,
  CUSTOMER_DETAILS_BY_ADMIN,
  // eslint-disable-next-line prettier/prettier
  CUSTOMER_LIST_BY_ADMIN
} from '../types';

export function getCustomerListForAdmin(params, query, actionData) {
  return (dispatch) =>
    customerService
      .getCustomerListForAdmin(query)
      .then((response) => {
        dispatch({
          type: CUSTOMER_LIST_BY_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function getCustomerDetailsForAdmin(params) {
  return (dispatch) =>
    customerService
      .getCustomerDetailsForAdmin(params)
      .then((response) => {
        dispatch({
          type: CUSTOMER_DETAILS_BY_ADMIN,
          data: response.data,
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function createCustomerByAdmin(data) {
  return (dispatch) =>
    customerService
      .createCustomerByAdmin(data)
      .then((response) => {
        dispatch({
          type: CUSTOMER_DETAILS_BY_ADMIN,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Customer info created successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function updateCustomerByAdmin(data, params) {
  return (dispatch) =>
    customerService
      .updateCustomerByAdmin(data, params)
      .then((response) => {
        dispatch({
          type: CUSTOMER_DETAILS_BY_ADMIN,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Customer info updated successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function deleteCustomerByAdmin(params, query, actionData) {
  return (dispatch) =>
    customerService
      .deleteCustomerByAdmin(params, query)
      .then((response) => {
        dispatch({
          type: CUSTOMER_DELETE_BY_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });

        showNotification({
          type: 'success',
          text: 'Customer deleted successfully.',
        });

        return response.data;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}
