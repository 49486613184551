import PropTypes from 'prop-types';
import './info-panel-style.css';

export default function InfoPanelDetails(props) {
  const { details, title, buttonText, header, isCustomerInfo } = props;

  return (
    <div className="container-fluid py-lg-4 align-self-center">
      <div className="row">
        <div
          className={
            header
              ? 'col-12 mt-0 mt-md-0 pt-0 pt-lg-5 mt-lg-5 info-header text-center'
              : 'col-12 mt-4 mt-md-0 info-header'
          }
        >
          <h1 className={header ? 'font-weight-bold header-text' : 'font-weight-bold'}>{title}</h1>
        </div>
      </div>
      <div className="row">
        <div
          className={
            header
              ? 'col-12 px-md-5 pb-5 col-lg-6 offset-lg-3 mb-lg-5 header-details text-center px-lg-5'
              : 'col-12 col-md-12 col-lg-11 mt-md-1 mt-lg-2'
          }
        >
          <p className={header ? 'text-muted' : null}>{details}</p>
        </div>
      </div>

      {isCustomerInfo === true ? null : (
        <div className="mt-3 mt-md-1 mt-lg-5 panel-button">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-5">
              <div className="btn btn-lg btn-danger">{buttonText}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

InfoPanelDetails.propTypes = {
  details: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  header: PropTypes.bool.isRequired,
  isCustomerInfo: PropTypes.bool,
};

InfoPanelDetails.defaultProps = {
  isCustomerInfo: false,
  buttonText: '',
};
