import { Col, Container } from 'react-bootstrap';

export default function ServiceInfo() {
  return (
    <Col>
      <Container>
        <p>
          Deepen your illumination and inspiration! If you love Rob Brezsny&apos;s weekly FREE WILL
          ASTROLOGY horoscopes, you may enjoy his additional offerings:
        </p>
        <ol>
          <li>
            EXPANDED AUDIO HOROSCOPES, weekly love letters in audio form-designed to soothe and
            encourage you and
          </li>
          and
          <li>SUNBURSTS, inspirational text message oracles to provide you with a daily boost</li>
        </ol>
        <p>Sign up for both services! Get the full range of Rob&apos;s nurturing advice.</p>
        <p>*</p>
        <p>
          The Expanded Audio LOVE LETTERS are not repeats of Rob&apos;s written column, but fresh
          explorations of your astrological omens. They are crafted to help you tune in to your
          soul&apos;s code-and express your uniqueness in ever-more satisfying ways.
        </p>
        <p>
          SUNBURSTS are Rob&apos;s daily text messages: short, sweet bursts of blessings. They offer
          motivational prods and give you news you can use about yourself.
        </p>
        <p>*</p>
        <p>
          Rob&apos;s Expanded Audio LOVE LETTERS are 4 to 5 minutes long. They are updated each
          Tuesday just after midnight Eastern Time.
        </p>
        <p>
          SUNBURSTS are delivered each morning, seven days a week, to your cell phone or other
          mobile device.
        </p>
      </Container>
    </Col>
  );
}

ServiceInfo.propTypes = {};

ServiceInfo.defaultProps = {};
