import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Col, Dropdown, Row } from 'react-bootstrap';
import colors from '../../../helper/colors';
import { ICON_SIZE } from '../../../helper/constant-values';
import { ArrowDownIcon } from '../../../helper/icons/common-icons';
import CustomToggle from './custom-toggle';
import './dropdown-with-icon.css';

export default function DropDownWithIcons(props) {
  const {
    categoryList,
    categoryImages,
    onCategoryChange,
    onToggleDropDown,
    showCategoryDropDown,
    selectedCategory,
    isLogo,
    isSvgImage,
    isAdmin,
  } = props;

  const [selectCategoryItem, setSelectCategoryItem] = useState(categoryList[0]);

  const dropdownBackgroundColor = isAdmin ? colors.secondary : colors.primary;

  const findCategory = (Id) => {
    const item = categoryList.find((x) => x.id === parseInt(Id, 10));

    return item;
  };

  useEffect(() => {
    const item = categoryList.find((x) => parseInt(x.id, 10) === parseInt(selectedCategory, 10));

    setSelectCategoryItem(item);
  }, [selectedCategory, categoryList]);

  return (
    <>
      <Dropdown show={showCategoryDropDown} onSelect={onCategoryChange} onToggle={onToggleDropDown}>
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-custom-components"
          className="btn-block"
          block
        >
          <div
            className={`d-flex justify-content-between py-2 bg-light border border-2 rounded ${
              isAdmin ? 'border-secondary' : 'border-primary'
            }`}
          >
            <Row>
              {isLogo && (
                <span className="pl-3 padding-top-2">
                  {categoryImages[selectCategoryItem?.name]}
                </span>
              )}

              {Object.keys(categoryImages).length !== 0 && !isLogo && !isSvgImage && (
                <img
                  src={categoryImages[selectCategoryItem?.name]}
                  alt="logo"
                  width="35px"
                  className="mx-2"
                />
              )}

              {isSvgImage && (
                <>
                  <Col xs={3}>
                    <div
                      className="category-svg-logo text-muted pl-3 padding-top-2"
                      dangerouslySetInnerHTML={{
                        __html: selectCategoryItem?.logoSvg,
                      }}
                    />
                  </Col>
                  <Col className="padding-top-2">
                    <span className="pl-2 text-muted">{selectCategoryItem?.name}</span>
                  </Col>
                </>
              )}

              {!isSvgImage && !isLogo && (
                <span className="pl-4 padding-top-2 text-muted">{selectCategoryItem?.name}</span>
              )}

              {!isSvgImage && isLogo && (
                <span className="pl-2 padding-top-2 text-muted">{selectCategoryItem?.name}</span>
              )}
            </Row>
            <ArrowDownIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-2 mt-1" />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="category-dropdown-menu pt-0">
          {categoryList.map((category) => (
            <Dropdown.Item
              key={category.id}
              eventKey={category.id}
              active={selectedCategory === category.id}
              style={{
                backgroundColor:
                  selectedCategory === category.id ? dropdownBackgroundColor : 'transparent',
              }}
            >
              <Row className="ml-n4">
                {isLogo && categoryImages[category.name]}

                {Object.keys(categoryImages).length !== 0 && !isLogo && !isSvgImage && (
                  <img
                    src={categoryImages[category.name]}
                    alt="logo"
                    width="35px"
                    className="mx-2"
                  />
                )}

                {isSvgImage && (
                  <div
                    className="category-svg-logo text-muted"
                    dangerouslySetInnerHTML={{
                      __html: findCategory(category.id)?.logoSvg,
                    }}
                  />
                )}

                <span>&nbsp; {category.name} </span>
              </Row>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

DropDownWithIcons.propTypes = {
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      logoSvg: PropTypes.string,
    })
  ).isRequired,
  categoryImages: PropTypes.objectOf(PropTypes.element),
  onCategoryChange: PropTypes.func.isRequired,
  onToggleDropDown: PropTypes.func.isRequired,
  showCategoryDropDown: PropTypes.bool.isRequired,
  selectedCategory: PropTypes.number.isRequired,
  isLogo: PropTypes.bool,
  isSvgImage: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

DropDownWithIcons.defaultProps = {
  categoryImages: {},
  isLogo: false,
  isSvgImage: false,
  isAdmin: false,
};
