import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import info9000Logo from '../../../assets/image/info9000Logo.png';
import commonMethods from '../../../helper/common-methods';
import { ADMIN, CONSUMER } from '../../../helper/constant-values';
import { MenuIcon, PlusCircleIcon } from '../../../helper/icons/common-icons';
import { userInformationProps } from '../common-props';
import MoveToDashboard from '../navbar/move-to-dashboard';
import './header.css';

function Header(props) {
  const { headerTitle, onToggleSidebar, userInfoState, onPurchasingToken } = props;

  const headerLogo =
    userInfoState.type === ADMIN
      ? info9000Logo
      : commonMethods.getImageApi(userInfoState.customerLogo);
  const headerLogoWidthe = userInfoState.type === ADMIN ? '15%' : '50%';
  return (
    <Navbar bg="xl" expand="none">
      <MenuIcon
        width="1.8em"
        height="1.8em"
        className="d-block d-sm-none text-dark"
        onClick={() => onToggleSidebar(true)}
      />
      <MoveToDashboard>
        <img
          className="img-fluid d-block d-sm-none d-flex justify-content-start header-img"
          width={headerLogoWidthe}
          src={headerLogo}
          alt="logo"
        />
      </MoveToDashboard>

      <Navbar.Brand href="#home" className="headline-class pl-2 d-none d-sm-block">
        <h4 className="font-weight-bold">{headerTitle}</h4>
      </Navbar.Brand>

      <div className="px-2 px-md-3 px-lg-4">
        {userInfoState.type === CONSUMER && (
          <>
            <span className="d-none d-sm-inline text-info h5">Available Tokens: </span>
            <h5 className="d-inline font-weight-bold">{userInfoState.tokenBalance}</h5>{' '}
            <PlusCircleIcon
              className="text-warning cursor-pointer mt-1"
              width="2em"
              height="2em"
              onClick={onPurchasingToken}
            />
          </>
        )}
      </div>
    </Navbar>
  );
}

Header.propTypes = {
  headerTitle: PropTypes.string,
  onToggleSidebar: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
  onPurchasingToken: PropTypes.func.isRequired,
};

Header.defaultProps = {
  headerTitle: 'Header Title',
};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
