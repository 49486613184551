import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CopyIcon, DeleteIcon, EditIcon } from '../../../../helper/icons/common-icons';

export default function ActionButtons(props) {
  const { onHandleEdit, onHandleCopy, onHandleDelete, disabled } = props;

  return (
    <>
      <OverlayTrigger
        transition={false}
        rootClose
        placement="bottom-start"
        overlay={<Tooltip>Edit</Tooltip>}
      >
        <Button
          tabIndex={0}
          className="border rounded-circle mr-2 action-buttons-upload py-2"
          variant="outline-dark"
          size="sm"
          onClick={onHandleEdit}
          disabled={disabled}
        >
          <EditIcon width="1.2rem" height="1.2rem" />
        </Button>
      </OverlayTrigger>

      <OverlayTrigger
        transition={false}
        rootClose
        placement="bottom-start"
        overlay={<Tooltip>Copy</Tooltip>}
      >
        <Button
          tabIndex={-1}
          className="border rounded-circle mr-2 action-buttons-upload py-2"
          variant="outline-dark"
          size="sm"
          onClick={onHandleCopy}
          disabled={disabled}
        >
          <CopyIcon width="1.2rem" height="1.2rem" />
        </Button>
      </OverlayTrigger>

      <OverlayTrigger
        transition={false}
        rootClose
        placement="bottom-start"
        overlay={<Tooltip>Delete</Tooltip>}
      >
        <Button
          tabIndex={-2}
          className="border rounded-circle action-buttons-upload py-2"
          variant="outline-dark"
          size="sm"
          onClick={onHandleDelete}
          disabled={disabled}
        >
          <DeleteIcon width="1.2rem" height="1.2rem" />
        </Button>
      </OverlayTrigger>
    </>
  );
}

ActionButtons.propTypes = {
  onHandleEdit: PropTypes.func.isRequired,
  onHandleCopy: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ActionButtons.defaultProps = {
  disabled: false,
};
