import customerService from '../../services/customer';
import { GET_USER_INFORMATION_AND_SUBSCRIPTION_CATEGORY } from '../types';

export default function getUserInformationAndSubscriptionCategory(query) {
  return (dispatch) =>
    customerService
      .getUserInformationAndSubscriptionCategory(query)
      .then((response) => {
        dispatch({
          type: GET_USER_INFORMATION_AND_SUBSCRIPTION_CATEGORY,
          data: response.data,
        });
      })
      .catch(() => Promise.reject());
}
