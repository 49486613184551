import PropTypes from 'prop-types';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

function DefaultPaymentInfoRow(props) {
  const { title, value } = props;

  return (
    <Row className="pb-2">
      <Col xs={6} md={5} lg={4}>
        <p className="text-dark font-weight-bold">{title}</p>
      </Col>
      <Col xs={6} md={7} lg={8} className="d-flex justify-content-end justify-content-sm-start">
        <OverlayTrigger transition={false} placement="bottom" overlay={<Tooltip>{value}</Tooltip>}>
          <span className="flex-child text-info text-truncate">{value}</span>
        </OverlayTrigger>
      </Col>
    </Row>
  );
}

DefaultPaymentInfoRow.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

DefaultPaymentInfoRow.defaultProps = {};

export default DefaultPaymentInfoRow;
