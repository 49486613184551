import { Col, Row } from 'react-bootstrap';

export default function ShortTextHeading() {
  return (
    <Col sm={12} className="d-none d-sm-inline py-3 border-top heading-text">
      <Row className="d-none d-lg-flex">
        <Col lg={3} xl={3}>
          <span>Message Name</span>
        </Col>
        <Col lg={4} xl={4}>
          <span>Text</span>
        </Col>
        <Col lg={2} xl={3}>
          <span>Schedule Date & Time</span>
        </Col>
        <Col className="text-right mr-3">
          <span>Actions</span>
        </Col>
      </Row>

      <Row className="d-lg-none">
        <Col sm={4} className="pr-0">
          <span>Message Name</span>
        </Col>
        <Col sm={3} className="ml-n2 mr-3 px-0">
          <span>Text</span>
        </Col>
        <Col sm={3} className="mr-n3">
          <span>Schedule Date & Time</span>
        </Col>
        <Col sm={2} className="text-right pl-0">
          <span>Actions</span>
        </Col>
      </Row>
    </Col>
  );
}

ShortTextHeading.propTypes = {};

ShortTextHeading.defaultProps = {};
