import momentTimeZone from 'moment-timezone';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { RingLoader } from 'react-spinners';
import colors from '../../../../helper/colors';
import commonMethods from '../../../../helper/common-methods';
import { TABLE_DATE_FORMAT } from '../../../../helper/constant-values';
import { PlayIcon } from '../../../../helper/icons/common-icons';
import {
  addConsumerPlayedAudio,
  chargeAudioForConsumer,
  // eslint-disable-next-line prettier/prettier
  removeConsumerPlayedAudio
} from '../../../../redux/actions/audio-action';
import TestPlayerModal from '../../../common/audio-player/audio-player-modal';
import { browserViewProps, userInformationProps } from '../../../common/common-props';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import WarningModal from '../../../common/modals/warning-success-modal';
import CustomPagination from '../../../common/pagination/custom-pagination';
import { audioForConsumerProps } from '../consumer-dashborad-common-props';
import './audio.css';
import SingleAudio from './single-audio';

function Audio(props) {
  const {
    setItemPerPage,
    setPage,
    itemPerPage,
    page,
    selectedCategory,
    onLoadMore,
    audiosForConsumerState,
    userInfoState,
    audioSession,
    consumerPlayedAudioState,
    audioTokenCharge,
    browserState,
    chargeForAudio,
    removePlayedAudio,
    addPlayedAudio,
  } = props;

  const [audioIndex, setAudioIndex] = useState(0);
  const [latestAudioIndex, setLatestAudioIndex] = useState(0);
  const [showTestPlay, setShowTestPlay] = useState(false);
  const [showUseTokenModal, setShowUseTokenModal] = useState(false);
  const [showLowTokenModal, setShowLowTokenModal] = useState(false);
  const [disableUseTokenModal, setDisableUseTokenModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [categoryChanging, setCategoryChanging] = useState(false);
  const [testAudioPlayed, setTestAudioPlayed] = useState(false);

  const { customerUrl } = useParams();
  const history = useHistory();

  const audioApi = commonMethods.getAudioAPI({
    customerUrl,
    consumerId: userInfoState.id,
    testAudio: true,
    isTestAudioPlayed: userInfoState.isTestAudioPlayed,
  });

  const modalShowHideToggle = (value) => {
    setShowTestPlay(value);
  };

  const onUseTokenModalProceed = () => {
    setDisableUseTokenModal(true);

    const query = {
      session: audioSession,
    };

    const params = {
      customerUrl,
      consumerId: userInfoState.id,
      audioId: latestAudioIndex,
    };

    chargeForAudio(params, query)
      .then(() => {
        setShowUseTokenModal(false);
        setAudioIndex(latestAudioIndex);
        setDisableUseTokenModal(false);
      })
      .catch(() => {
        setShowUseTokenModal(false);
        setDisableUseTokenModal(false);
      });
  };

  const onUseTokenModalCancel = () => {
    if (!disableUseTokenModal) {
      setShowUseTokenModal(false);
      removePlayedAudio(latestAudioIndex);
    }
  };

  const onLowTokenModalProceed = () => {
    setShowLowTokenModal(false);
    history.push(`/${customerUrl}/packages`);
  };

  const onLowTokenModalCancel = () => {
    setShowLowTokenModal(false);
  };

  const onPlayAudio = (audioId) => {
    setLatestAudioIndex(audioId);
    const audioPlayedBefore = consumerPlayedAudioState.find((item) => item === audioId);

    if (!audioPlayedBefore) {
      if (userInfoState.tokenBalance < audioTokenCharge) {
        setShowLowTokenModal(true);
      } else {
        setShowUseTokenModal(true);
        addPlayedAudio(audioId);
      }
    } else {
      setAudioIndex(audioId);
    }
  };

  const getDateForSingleAudio = (date) =>
    commonMethods.getDateTimeFromUtc(momentTimeZone.tz.guess(), TABLE_DATE_FORMAT, date);

  const onLoadMoreClick = () => {
    setDataLoading(true);
    onLoadMore();
  };

  useEffect(() => {
    if (!browserState.isMobile) {
      setAudioIndex(0);
    }
  }, [page]);

  useEffect(() => {
    setAudioIndex(0);
    setCategoryChanging(true);
  }, [selectedCategory]);

  useEffect(() => {
    if (browserState.isMobile) {
      setDataLoading(false);
    }
    setCategoryChanging(false);
  }, [audiosForConsumerState]);

  return (
    <div className="audio-files">
      <TestPlayerModal
        showModal={showTestPlay}
        modalShowHideToggle={modalShowHideToggle}
        name="Test Audio"
        description="Play audio to your test environment"
        audioSrc={audioApi}
      />

      <WarningModal
        heading="Use token to play reading?"
        description={`Use ${audioTokenCharge} ${
          audioTokenCharge === 1 ? 'token' : 'tokens'
        } to play the audio`}
        leftBtnText="Cancel"
        rightBtnText="Proceed"
        showModal={showUseTokenModal}
        onHideModal={onUseTokenModalCancel}
        onModalSubmit={onUseTokenModalProceed}
        processing={disableUseTokenModal}
        showInfoIcon
      />

      <WarningModal
        heading="Please Purchase Tokens"
        descriptionList={[
          'Sorry, but you do not have enough tokens to play this reading.',
          'Please purchase more tokens to continue.',
        ]}
        leftBtnText="Cancel"
        rightBtnText="Purchase Tokens"
        showModal={showLowTokenModal}
        onHideModal={onLowTokenModalCancel}
        onModalSubmit={onLowTokenModalProceed}
      />

      <Row>
        <Col>
          <Row className="mt-3 mb-2">
            <Col xs={7}>
              <h4 className="text-left">Select Reading to Play</h4>
              <p>Select a reading below. Weekly Readings updated every Tuesday.</p>
            </Col>
            <Col xs={5}>
              <Button
                className={`float-right test-button py-1 ${
                  !userInfoState.isTestAudioPlayed && !testAudioPlayed ? 'glow-on-test-button' : ''
                }`}
                autoFocus
                onClick={() => {
                  setShowTestPlay(true);
                  setTestAudioPlayed(true);
                }}
              >
                Test Player <PlayIcon with="1.2em" height="1.2em" />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="bg-white rounded-bottom">
        {!categoryChanging &&
          audiosForConsumerState.data.map((audio) => (
            <SingleAudio
              key={audio.id}
              name={audio.audioName}
              audioFileName={audio.audioFileName}
              date={getDateForSingleAudio(audio.startDateTime)}
              audioId={audio.id}
              onPlay={onPlayAudio}
              showUseTokenModal={showUseTokenModal}
              audioIndex={audioIndex}
              consumerId={userInfoState.id}
              showTestPlay={showTestPlay}
              audioSession={audioSession}
              consumerPlayedAudioState={consumerPlayedAudioState}
            />
          ))}

        {!categoryChanging && audiosForConsumerState.totalItems >= 10 && (
          <CustomPagination
            setPage={setPage}
            setItemPerPage={setItemPerPage}
            itemPerPage={itemPerPage}
            page={page}
            totalPages={audiosForConsumerState.totalPages}
            totalItems={audiosForConsumerState.totalItems}
          />
        )}

        {categoryChanging && (
          <div className="d-flex justify-content-center">
            <RingLoader loading size={100} color={colors.secondary} />
          </div>
        )}
      </div>
      {!categoryChanging && audiosForConsumerState.totalItems > audiosForConsumerState.data.length && (
        <Row className="justify-content-center justify-content-lg-start pt-4 d-sm-none">
          <Col sm={11}>
            <Button size="lg" block disabled={dataLoading} onClick={onLoadMoreClick}>
              <DataSavingSpinner
                savingText="Loding"
                defaultText="Load More"
                dataSaving={dataLoading}
              />
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
}

Audio.propTypes = {
  setPage: PropTypes.func.isRequired,
  setItemPerPage: PropTypes.func.isRequired,
  itemPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  audiosForConsumerState: audioForConsumerProps.isRequired,
  userInfoState: userInformationProps.isRequired,
  selectedCategory: PropTypes.number.isRequired,
  audioSession: PropTypes.string.isRequired,
  consumerPlayedAudioState: PropTypes.arrayOf(PropTypes.number).isRequired,
  addPlayedAudio: PropTypes.func.isRequired,
  removePlayedAudio: PropTypes.func.isRequired,
  audioTokenCharge: PropTypes.number.isRequired,
  chargeForAudio: PropTypes.func.isRequired,
  browserState: browserViewProps.isRequired,
};

Audio.defaultProps = {};

const mapStateToProps = (state) => ({
  audiosForConsumerState: state.audio.audiosForConsumer,
  consumerPlayedAudioState: state.audio.consumerPlayedAudio,
  userInfoState: state.auth.userInfo,
  browserState: state.browserData.view,
});

const mapDispatchToProps = {
  addPlayedAudio: addConsumerPlayedAudio,
  removePlayedAudio: removeConsumerPlayedAudio,
  chargeForAudio: chargeAudioForConsumer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Audio);
