import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';

export default function FooterButtons(props) {
  const {
    leftText,
    rightText,
    leftVariant,
    rightVariant,
    onHandleLeft,
    onHandleRight,
    isDisable,
    processing,
  } = props;

  return (
    <Row className="justify-content-center mt-5 mb-md-3">
      <Col lg={4} md={6} className="mb-3 mb-md-2">
        <Button variant={leftVariant} size="lg" onClick={onHandleLeft} block disabled={processing}>
          {leftText}
        </Button>
      </Col>
      <Col lg={4} md={6}>
        <Button
          variant={rightVariant}
          size="lg"
          onClick={onHandleRight}
          disabled={isDisable || processing}
          block
        >
          <DataSavingSpinner
            savingText="Processing"
            defaultText={rightText}
            dataSaving={processing}
          />
        </Button>
      </Col>
    </Row>
  );
}

FooterButtons.propTypes = {
  leftText: PropTypes.string.isRequired,
  rightText: PropTypes.string.isRequired,
  leftVariant: PropTypes.string.isRequired,
  rightVariant: PropTypes.string.isRequired,
  onHandleLeft: PropTypes.func.isRequired,
  onHandleRight: PropTypes.func.isRequired,
  isDisable: PropTypes.bool,
  processing: PropTypes.bool,
};

FooterButtons.defaultProps = {
  isDisable: false,
  processing: false,
};
