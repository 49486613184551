import { Col, Row } from 'react-bootstrap';

export default function AudioHeading() {
  return (
    <Col sm={12} className="d-none d-sm-inline py-3 border-top heading-text">
      <Row className="d-none d-lg-flex">
        <Col>
          <span>Audio Files</span>
        </Col>
        <Col>
          <span>Duration</span>
        </Col>
        <Col>
          <span>Start Date & Time</span>
        </Col>
        <Col>
          <span>End Date & Time</span>
        </Col>
        <Col className="text-right mr-3">
          <span>Actions</span>
        </Col>
      </Row>

      <Row className="d-lg-none">
        <Col sm={3} md={4} className="pr-0">
          <span>Audio Files</span>
        </Col>
        <Col sm={1} className="ml-n2 mr-3 px-0">
          <span>Duration</span>
        </Col>
        <Col sm={3} className="mr-n3">
          <span>Start Date & Time</span>
        </Col>
        <Col sm={3}>
          <span>End Date & Time</span>
        </Col>
        <Col sm={2} md={1} className="text-right px-0">
          <span>Actions</span>
        </Col>
      </Row>
    </Col>
  );
}

AudioHeading.propTypes = {};

AudioHeading.defaultProps = {};
