/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import React from 'react';
import { DefaultToastContainer } from 'react-toast-notifications';
import CustomAlert from './custom-alert';

// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// toast.configure();

export const NOTIFICATION_TYPE = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warn',
  ERROR: 'error',
};

export const removeNotification = (toastId) => {
  const removeToast = _.get(window, '__react_toast_provider.current.remove');

  if (toastId) {
    removeToast(toastId);
  }
};

export const showNotification = ({ type = 'info', text, autoDismiss = true }) => {
  const types = ['info', 'success', 'warn', 'error'];
  const addToast = _.get(window, '__react_toast_provider.current.add');

  if (!text || !types.includes(type) || !addToast) return;

  const toast = addToast(text, {
    appearance: type,
    autoDismiss,
  });

  return toast;

  // const settings = {
  //     position: toast.POSITION.BOTTOM_LEFT,
  //     autoClose: 4000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true
  // };

  // toast[type](`${text}`, settings);
};

export const CustomToastContainer = (props) => {
  const notificationStyle = { zIndex: 9999, right: 0, left: 'auto' };

  return <DefaultToastContainer {...props} style={notificationStyle} />;
};

export const PracticeSMSPhoneNumberMissingAlert = (props) => {
  const warningMessage = (
    <span>An SMS phone number has not yet been set up for your practice.</span>
  );

  const emailSupportMessage = (
    <span>
      {` Please email `}
      <a href="mailto:support@practicenumbers.com">support@practicenumbers.com</a>
      {` for help`}
    </span>
  );

  const supportChatMessage = (
    <span>
      {` or `}
      <a
        style={{ cursor: 'pointer' }}
        onClick={() => window.Intercom('showNewMessage', "I'd like to set up an SMS number.")}
      >
        Click here
      </a>
      {` to start a support chat.`}
    </span>
  );

  const message = (
    <div>
      {warningMessage}
      {emailSupportMessage}
      {supportChatMessage}
    </div>
  );

  return <CustomAlert {...props} message={message} hasTimeout={false} />;
};
