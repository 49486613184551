import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';
import { OTP_LENGTH } from '../../../../helper/constant-values';
import {
  otpResend,
  otpValidityCheck,
  // eslint-disable-next-line prettier/prettier
  otpVerification
} from '../../../../redux/actions/auth-action';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import { forgotPasswordProps } from '../../login-registration-common-props';
import './admin-otp-verification.css';

function AdminOtpVerification(props) {
  const { forgotPasswordState, resendOtp, otpValidity, onVerification, otpType, onEditIconClick } =
    props;

  const { username: forgotPasswordUsername, email: forgotPasswordEmail } = forgotPasswordState;

  const [otp, setOtp] = useState('');
  const [resendCount, setResendCount] = useState(false);
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [continueButtonLoading, setContinueButtonLoading] = useState(false);
  const [hideResendButton, setHideResendButton] = useState(false);
  const [invalidOTPError, setInvalidOTPError] = useState(false);
  const [invalidOTPErrorText, setInvalidOTPErrorText] = useState('');

  const email = forgotPasswordEmail;
  const username = forgotPasswordUsername;

  const onOtpChange = (otpValue) => {
    setOtp(otpValue);
    setInvalidOTPError(false);
    setInvalidOTPErrorText('');

    const regexForDigit = /^\d+$/;

    if (otpValue.length > 0 && !regexForDigit.test(otpValue)) {
      setInvalidOTPError(true);
      setInvalidOTPErrorText('Verification Code must be a numeric value');
      setDisableContinueButton(true);
    } else if (otpValue.length < OTP_LENGTH) {
      setDisableContinueButton(true);
    } else {
      setDisableContinueButton(false);
    }
  };

  const onResendOtp = () => {
    const otpData = {
      username,
      [otpType]: true,
    };

    setHideResendButton(true);

    resendOtp(otpData)
      .then(() => {
        setInvalidOTPError(false);
        setHideResendButton(false);
        setOtp('');
        setResendCount(resendCount + 1);
      })
      .catch(() => {
        setHideResendButton(false);
      });
  };

  const onOtpSubmit = () => {
    const otpData = {
      username,
    };

    otpData.passwordToken = otp;

    const otpAction = otpValidity;

    setContinueButtonLoading(true);

    otpAction(otpData)
      .then(() => {
        onVerification();
        setContinueButtonLoading(false);
      })
      .catch((error) => {
        setContinueButtonLoading(false);

        if (error.response.data.isInvalidShortCode) {
          setInvalidOTPError(true);
          setInvalidOTPErrorText(error.response.data.message);
          setDisableContinueButton(true);
        }
      });
  };

  return (
    <>
      <Form onSubmit={(evt) => evt.preventDefault()} className="px-2 pl-lg-0">
        <Row className="justify-content-center justify-content-lg-start">
          <Col sm={11} md={7} lg={12}>
            <p className="text-center">
              We just sent you a verification code to{' '}
              <span className="text-secondary">{email}</span>
              <FontAwesomeIcon
                icon={faPen}
                className="pl-1 cursor-pointer"
                onClick={onEditIconClick}
              />
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center pt-4 pb-2">
          <Col sm={11} lg={7} xl={6}>
            <OtpInput
              key={resendCount}
              onChange={onOtpChange}
              numInputs={6}
              value={otp}
              containerStyle="ml-md-2 ml-3"
              inputStyle={
                invalidOTPError ? 'admin-otp-box border-secondary ml-n2' : 'admin-otp-box ml-n2'
              }
              shouldAutoFocus
              isInputNum
            />
          </Col>
        </Row>

        {invalidOTPError ? (
          <Row className="justify-content-center pl-2 pl-lg-0">
            <Col sm={11} lg={7} xl={6} className="text-primary">
              {invalidOTPErrorText}
            </Col>
          </Row>
        ) : (
          <br />
        )}

        <Row className="justify-content-center pt-4">
          <Col sm={11} lg={7} xl={6}>
            <Button
              size="lg"
              variant="secondary"
              block
              type="submit"
              onClick={onOtpSubmit}
              disabled={disableContinueButton || continueButtonLoading}
            >
              <DataSavingSpinner
                savingText="Verifying"
                defaultText="Continue"
                dataSaving={continueButtonLoading}
              />
            </Button>
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">
          <Col sm={11} lg={7} xl={6}>
            {!hideResendButton ? (
              <div
                aria-hidden="true"
                className="text-secondary font-weight-bold cursor-pointer text-center text-lg-left"
                onClick={onResendOtp}
              >
                Resend Code
              </div>
            ) : (
              <FontAwesomeIcon icon={faSpinner} size="lg" spin />
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

AdminOtpVerification.propTypes = {
  resendOtp: PropTypes.func.isRequired,
  otpValidity: PropTypes.func.isRequired,
  onEditIconClick: PropTypes.func.isRequired,
  onVerification: PropTypes.func.isRequired,
  otpType: PropTypes.string.isRequired,
  forgotPasswordState: forgotPasswordProps.isRequired,
};

AdminOtpVerification.defaultProps = {};

const mapStateToProps = (state) => ({
  forgotPasswordState: state.auth.forgotPassword,
});

const mapDispatchToProps = {
  verifyOtp: otpVerification,
  resendOtp: otpResend,
  otpValidity: otpValidityCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminOtpVerification);
