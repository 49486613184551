import MenuBarWrapper from '../../common/menu-bar-wrapper/menu-bar-wrapper';
import EmailMessage from './email-message-form';
import './support-email-message.css';

export default function SupportEmailMessage() {
  return (
    <MenuBarWrapper navBarIndex={4} headerTitle="Contact Support">
      <EmailMessage />
    </MenuBarWrapper>
  );
}

SupportEmailMessage.propTypes = {};

SupportEmailMessage.defaultProps = {};
