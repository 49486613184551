import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  // eslint-disable-next-line prettier/prettier
  useStripe
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import stripeLogo from '../../../../../../assets/image/stripe.png';
import { showNotification } from '../../../../../../helper/notifications';
import { packagePurchaseByStripe } from '../../../../../../redux/actions/package';
import CustomCheckbox from '../../../../../common/buttons/custom-checkbox';
import { userInformationProps } from '../../../../../common/common-props';
import DataSavingSpinner from '../../../../../common/loader-spinner/data-saving-spinner';
import '../../../consumer-package-style.css';

function StripePayment(props) {
  const stripe = useStripe();
  const elements = useElements();

  const {
    packageId,
    userInfoState,
    paymentProcessing,
    setPaymentProcessing,
    packagePayment,
    goToPackagePage,
  } = props;

  const [name, setName] = useState('');
  const { customerUrl } = useParams();

  const [disablePayment, setDisablePayment] = useState(false);
  const [isCardNumberError, setIsCardNumberError] = useState(false);
  const [isCardCVVError, setIsCardCVVError] = useState(false);
  const [isCardExpiryError, setIsCardExpiryError] = useState(false);
  const [isCardNameError, setIsCardNameError] = useState(false);
  const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState();
  const [cardNameErrorMessage, setCardNameErrorMessage] = useState();
  const [cardCVVErrorMessage, setCardCVVeErrorMessage] = useState();
  const [cardExpiryErrorMessage, setCardExpiryErrorMessage] = useState();
  const [checkedSavePaymentInformation, setCheckedSavePaymentInformation] = useState(false);

  const { id: consumerId } = userInfoState;

  const onChangeCardNumber = (event) => {
    if (event.error) {
      setIsCardNumberError(true);
      setCardNumberErrorMessage(event.error.message);
    } else setIsCardNumberError(false);
  };

  const onChangeCardCVV = (event) => {
    if (event.error) {
      setIsCardCVVError(true);
      setCardCVVeErrorMessage(event.error.message);
    } else setIsCardCVVError(false);
  };

  const onChangeCardExpaireDate = (event) => {
    if (event.error) {
      setIsCardExpiryError(true);
      setCardExpiryErrorMessage(event.error.message);
    } else setIsCardExpiryError(false);
  };

  const onChangeName = (event) => {
    const { value } = event.target;

    const regexForAnyDigit = /^[a-zA-Z ]*$/;

    if (!regexForAnyDigit.test(value)) {
      setIsCardNameError(true);
      setCardNameErrorMessage('Please enter a valid Name');
    } else {
      setIsCardNameError(false);
      setName(value);
    }
  };

  const onCheckSaveCardInfo = () => {
    setCheckedSavePaymentInformation(!checkedSavePaymentInformation);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return;
    }

    setPaymentProcessing(true);

    stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name,
        },
      })
      .then((payload) => {
        const paymentData = {
          paymentId: payload.paymentMethod.id,
          packageId,
          saveCardAsDefault: checkedSavePaymentInformation,
        };

        const query = {
          customerUrl,
          consumerId,
        };

        packagePayment(paymentData, query)
          .then(() => {
            setPaymentProcessing(false);
            goToPackagePage();
          })
          .catch(() => {
            setPaymentProcessing(false);
          });
      })
      .catch(() => {
        setPaymentProcessing(false);

        showNotification({
          type: 'error',
          text: 'Error while purchasing packages',
        });
      });
  };

  const inputStyle = {
    base: {
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  };

  useEffect(() => {
    if (!stripe || !elements) {
      setDisablePayment(true);
    } else {
      setDisablePayment(false);
    }
  }, [stripe, elements]);

  useEffect(() => {
    if (isCardNumberError || isCardCVVError || isCardExpiryError || isCardNameError)
      setDisablePayment(true);
    else setDisablePayment(false);
  }, [isCardNumberError, isCardCVVError, isCardExpiryError, isCardNameError]);

  return (
    <form onSubmit={onSubmit}>
      <Row className="border rounded-lg py-3 mt-3">
        <Col>
          <Row className="py-3">
            <Col className="px-lg-5 pl-md-5">
              <Form.Label>Credit or Debit Card</Form.Label>
              <CardNumberElement
                options={{
                  style: inputStyle,
                }}
                onChange={onChangeCardNumber}
              />
              {isCardNumberError ? (
                <div className="error-label">
                  <span className="small">* {cardNumberErrorMessage}</span>
                </div>
              ) : (
                <div className="empty-label" />
              )}

              <Form.Label>Name</Form.Label>
              <input
                type="text"
                className="card-input-name"
                placeholder="Name"
                width="500px"
                onChange={onChangeName}
              />
              {isCardNameError ? (
                <div className="error-label">
                  <span className="small">* {cardNameErrorMessage}</span>
                </div>
              ) : (
                <div className="empty-label" />
              )}

              <Form.Label>CVV</Form.Label>
              <CardCvcElement
                options={{
                  style: inputStyle,
                  placeholder: 'CVV',
                }}
                onChange={onChangeCardCVV}
              />
              {isCardCVVError ? (
                <div className="error-label">
                  <span className="small">* {cardCVVErrorMessage}</span>
                </div>
              ) : (
                <div className="empty-label" />
              )}

              <Form.Label>Expiration Date</Form.Label>
              <CardExpiryElement
                options={{
                  style: inputStyle,
                }}
                onChange={onChangeCardExpaireDate}
              />
              {isCardExpiryError ? (
                <div className="error-label">
                  <span className="small">* {cardExpiryErrorMessage}</span>
                </div>
              ) : (
                <div className="empty-label" />
              )}

              <div className="d-inline">
                <CustomCheckbox
                  checked={checkedSavePaymentInformation}
                  onClick={onCheckSaveCardInfo}
                />
                <span className="pl-1 checkbox-label-subscription">
                  Save this card information as default payment.
                </span>
              </div>
            </Col>
          </Row>

          <Row className="pt-4">
            <Col className="d-flex justify-content-sm-end justify-content-between">
              <Button
                type="submit"
                variant="warning"
                className="px-4"
                disabled={disablePayment || paymentProcessing}
              >
                <DataSavingSpinner
                  savingText="Processing"
                  defaultText="Order Tokens"
                  dataSaving={paymentProcessing}
                />
              </Button>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col xs={12}>
              <img src={stripeLogo} alt="stripeLogo" />{' '}
              <span> &nbsp; Credit card processing by Stripe</span>
            </Col>
            <Col className="py-3">Your credit card statement will show as: Free Will Astrology</Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
}

StripePayment.propTypes = {
  packagePayment: PropTypes.func.isRequired,
  packageId: PropTypes.number.isRequired,
  userInfoState: userInformationProps.isRequired,
  setPaymentProcessing: PropTypes.func.isRequired,
  paymentProcessing: PropTypes.bool.isRequired,
  goToPackagePage: PropTypes.func.isRequired,
};

StripePayment.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  packagePayment: packagePurchaseByStripe,
};

export default connect(mapStateToProps, mapDispatchToProps)(StripePayment);
