import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import routeDetails from '../../../../helper/route-details';
import { confirmPaypalDefaultPaymentInfo } from '../../../../redux/actions/payments-action';
import LoaderSpinner from '../../../common/loader-spinner/loader-spinner';
import { userInformationProps } from '../../profile-common-props';

function PaypalDefaultPaymentAddSuccess(props) {
  const { userInfoState, confirmDefaultPayment } = props;

  const [dataLoading, setDataLoading] = useState(false);

  const { customerUrl } = useParams();
  const location = useLocation();
  const history = useHistory();

  const gotoProfile = () => {
    history.push(routeDetails.consumer.profile.default(customerUrl));
  };

  useEffect(() => {
    setDataLoading(true);
    const queryParams = new URLSearchParams(location.search);

    history.replace(location.pathname, {
      search: '',
    });

    const body = {
      approvalToken: queryParams.get('approval_token_id'),
    };

    const params = {
      customerUrl,
      consumerId: userInfoState.id,
    };

    confirmDefaultPayment(body, params).then(gotoProfile).catch(gotoProfile);
  }, []);

  return <>{dataLoading && <LoaderSpinner />}</>;
}

PaypalDefaultPaymentAddSuccess.propTypes = {
  userInfoState: userInformationProps.isRequired,
  confirmDefaultPayment: PropTypes.func.isRequired,
};

PaypalDefaultPaymentAddSuccess.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  confirmDefaultPayment: confirmPaypalDefaultPaymentInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaypalDefaultPaymentAddSuccess);
