import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import DataSavingSpinner from '../loader-spinner/data-saving-spinner';

export default function FooterButtons(props) {
  const {
    leftText,
    rightText,
    leftVariant,
    rightVariant,
    onHandleLeft,
    onHandleRight,
    disabledButtons,
    processing,
    savingRightText,
  } = props;

  return (
    <Row className="justify-content-center mt-5 mb-md-3">
      <Col lg={4} md={6} className="mb-3 mb-md-2">
        <Button
          variant={leftVariant}
          size="lg"
          onClick={onHandleLeft}
          block
          disabled={processing || disabledButtons}
        >
          {leftText}
        </Button>
      </Col>
      <Col lg={4} md={6}>
        <Button
          variant={rightVariant}
          size="lg"
          onClick={onHandleRight}
          block
          disabled={processing || disabledButtons}
        >
          <DataSavingSpinner
            savingText={savingRightText}
            defaultText={rightText}
            dataSaving={processing}
          />
        </Button>
      </Col>
    </Row>
  );
}

FooterButtons.propTypes = {
  leftText: PropTypes.string.isRequired,
  rightText: PropTypes.string.isRequired,
  leftVariant: PropTypes.string.isRequired,
  rightVariant: PropTypes.string.isRequired,
  onHandleLeft: PropTypes.func.isRequired,
  onHandleRight: PropTypes.func.isRequired,
  disabledButtons: PropTypes.bool.isRequired,
  processing: PropTypes.bool,
  savingRightText: PropTypes.string,
};

FooterButtons.defaultProps = {
  processing: false,
  savingRightText: 'Processing',
};
