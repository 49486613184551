import { INNER_WIDH_CHANGE } from '../types';

export default function toggleMobileView(data) {
  return (dispatch) => {
    dispatch({
      type: INNER_WIDH_CHANGE,
      data,
    });
  };
}
