import { callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const consumerCategoryService = {
  get: async (params, query) => {
    const { method, endpoint } = endpoints.getConsumerCategories;

    const url = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url });

    return response;
  },

  getConsumerCategoryListForAdmin: async (query) => {
    const { method, endpoint } = endpoints.getConsumerCategoryListForAdmin;

    const url = utils.buildUrl(baseUrl + endpoint, {}, query);
    const response = await callAxiosWithAuth({ method, url });

    return response;
  },

  addConsumerCategoryByAdmin: async (data, query) => {
    const { method, endpoint } = endpoints.addConsumerCategoryByAdmin;

    const url = utils.buildUrl(baseUrl + endpoint, {}, query);
    const response = await callAxiosWithAuth({ method, url, data });

    return response;
  },

  updateConsumerCategoryByAdmin: async (data, params) => {
    const { method, endpoint } = endpoints.updateConsumerCategoryByAdmin;

    const url = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url, data });

    return response;
  },

  deleteConsumerCategoryByAdmin: async (params, query) => {
    const { method, endpoint } = endpoints.deleteConsumerCategoryByAdmin;

    const url = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url });

    return response;
  },
};

export default consumerCategoryService;
