import { Col, Row } from 'react-bootstrap';

export default function PaymentHistoryListTitle() {
  return (
    <Row className="d-none d-lg-flex header mx-2 mt-4">
      <Col lg={11}>
        <Row>
          <Col sm={2}>
            <p>Date Time</p>
          </Col>
          <Col sm={3}>
            <p>Username</p>
          </Col>
          <Col sm={2}>
            <p>Payment Method</p>
          </Col>
          <Col sm={3}>
            <p>Payment Id</p>
          </Col>
          <Col sm={2} className="d-flex">
            <p>Amount</p>
          </Col>
        </Row>
      </Col>
      <Col lg={1} className="d-flex justify-content-end">
        <p>Credit Refund</p>
      </Col>
    </Row>
  );
}

PaymentHistoryListTitle.propTypes = {};

PaymentHistoryListTitle.defaultProps = {};
