import {
  GET_REVENUE_REPORT_FOR_CUSTOMER,
  GET_USAGE_REPORT_FOR_CUSTOMER,
  GET_GROSS_SALES_FOR_CUSTOMER,
} from '../types';

const initialState = {
  revenueCustomer: {
    totalRevenue: 0,
    revenueData: [],
  },
  usageCustomer: {
    totalAudioPlays: 0,
    totalSmsSunbrusts: 0,
    usageData: [],
  },
  grossSalesCustomer: {
    totalItems: 0,
    data: [],
    totalPages: 1,
    currentPage: 1,
  },
};

const revenueReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVENUE_REPORT_FOR_CUSTOMER:
      return {
        ...state,
        revenueCustomer: action.data,
      };
    case GET_USAGE_REPORT_FOR_CUSTOMER:
      return {
        ...state,
        usageCustomer: action.data,
      };
    case GET_GROSS_SALES_FOR_CUSTOMER: {
      let newData = {};

      if (action.noPagination || !action.isMobile) {
        newData = action.data;
      } else if (!action.noPagination && action.isMobile) {
        newData = {
          ...action.data,
          data: [...state.grossSalesCustomer.data, ...action.data.data],
        };
      }

      return {
        ...state,
        grossSalesCustomer: newData,
      };
    }

    default:
      return state;
  }
};

export default revenueReportReducer;
