import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import commonMethods from '../../../../helper/common-methods';
import { ICON_SIZE, TABLE_DATE_TIME_FORMAT } from '../../../../helper/constant-values';
import useModalAndToolTip from '../../../../helper/hooks/useModalAndToolTip';
import { CloseCircleIcon } from '../../../../helper/icons/common-icons';
import { shortTextModifyValidationSchema } from '../../../../helper/schema-objects';
import FormComponent from '../../../common/form-component/form-component';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import DateTimePicker from '../category-common-components/date-time-picker';

export default function ShortTextModifyModal(props) {
  const {
    showModal,
    onCloseModal,
    headerTitle,
    submitButonText,
    timeZone,
    shortTextItem,
    dataSaving,
    onSubmit,
    copyShortText,
  } = props;

  const [textareaContent, setTextareaContent] = useState(shortTextItem.textContent);

  const initialValues = {
    textContent: shortTextItem.textContent,
    messageName: shortTextItem.messageName,
    disburseDateTime: shortTextItem.disburseDateTime
      ? commonMethods.getDateTimeFromUtc(
          timeZone,
          TABLE_DATE_TIME_FORMAT,
          shortTextItem.disburseDateTime
        )
      : '',
  };

  const onInputChange = (e, formik) => {
    formik.handleChange(e);

    formik.setFieldTouched('disburseDateTime', true);

    if (e.target.name === 'textContent') {
      setTextareaContent(e.target.value);
    }
  };

  useEffect(() => {
    setTextareaContent(shortTextItem.textContent);
  }, [shortTextItem.textContent]);

  useModalAndToolTip(showModal);

  return (
    <Modal
      show={showModal}
      onHide={onCloseModal}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      className="service-modal-dialog"
      centered
    >
      <Modal.Header>
        <Col xs={10} className="text-truncate">
          <span className="h5">{headerTitle}</span>
        </Col>
        <Col xs={2} className="mt-2 mt-sm-0 text-right">
          <CloseCircleIcon
            className="text-muted cursor-pointer"
            width={ICON_SIZE}
            height={ICON_SIZE}
            onClick={onCloseModal}
          />
        </Col>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Formik
            initialValues={initialValues}
            validationSchema={shortTextModifyValidationSchema(timeZone)}
            onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
            enableReinitialize
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Row className="justify-content-center">
                  <FormComponent
                    type="text"
                    placeholder="Message Name"
                    name="messageName"
                    inputColumnClass="col-12"
                    errorColumnClass="col-12"
                    formik={formik}
                    autoFocusInput
                    showInitialError
                    showLabel
                  />
                </Row>

                <Row className="justify-content-center">
                  <Col className="mt-2 date-time-picker-audio-parent">
                    <Form.Label>Disburse Date & Time</Form.Label>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col className="px-0 date-time-picker-short-text-parent">
                    <DateTimePicker
                      formik={formik}
                      timeZone={timeZone}
                      initialDateTimeUtc={shortTextItem.disburseDateTime}
                      fieldName="disburseDateTime"
                      iconToolTip="Select Disburse Date & Time"
                      placeholder="Disburse Date & Time"
                    />

                    <FormComponent
                      type="text"
                      name="disburseDateTime"
                      inputColumnClass="d-none"
                      errorColumnClass="col-12"
                      formik={formik}
                      showInitialError
                    />
                  </Col>
                </Row>

                {!copyShortText && (
                  <Row className="justify-content-center pb-4">
                    <FormComponent
                      type="textarea"
                      placeholder="Message"
                      name="textContent"
                      inputColumnClass="col-12"
                      errorColumnClass="d-none d-sm-flex col-sm-7 col-lg-8"
                      formik={{ ...formik, ...{ handleChange: (e) => onInputChange(e, formik) } }}
                      autoFocusInput
                      showLabel
                    />

                    <Col className="text-right text-muted">
                      <small>Word Counts: {textareaContent.length}/160 (Max)</small>
                    </Col>
                  </Row>
                )}

                {copyShortText && (
                  <Container className="pb-4">
                    <Row className="justify-content-center py-2 bg-light rounded-lg">
                      <Col>
                        <p className="pb-2 border-bottom">Text</p>
                        <p>{textareaContent}</p>
                      </Col>
                    </Row>
                  </Container>
                )}

                <Row className="justify-content-end mb-2">
                  <Col sm={6} md={5} lg={4}>
                    <Button
                      size="lg"
                      variant="outline-primary"
                      block
                      disabled={dataSaving}
                      onClick={onCloseModal}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col sm={6} md={5} lg={4} className="pt-3 pt-sm-0">
                    <Button
                      size="lg"
                      onClick={formik.handleSubmit}
                      type="submit"
                      block
                      disabled={
                        !!(
                          formik.errors.messageName ||
                          formik.errors.textContent ||
                          formik.errors.disburseDateTime ||
                          dataSaving
                        )
                      }
                    >
                      <DataSavingSpinner
                        savingText="Processing"
                        defaultText={submitButonText}
                        dataSaving={dataSaving}
                      />
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

ShortTextModifyModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  submitButonText: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  shortTextItem: PropTypes.shape({
    id: PropTypes.number,
    textContent: PropTypes.string,
    messageName: PropTypes.string,
    disburseDateTime: PropTypes.string,
  }),
  dataSaving: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  copyShortText: PropTypes.bool,
};

ShortTextModifyModal.defaultProps = {
  shortTextItem: {
    id: 0,
    textContent: '',
    messageName: '',
    disburseDateTime: '',
  },
  dataSaving: false,
  copyShortText: false,
};
