import PropTypes from 'prop-types';

export const userInformationProps = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  customerUrl: PropTypes.string,
  customerLogo: PropTypes.string,
  tokenBalance: PropTypes.number,
  profilePic: PropTypes.string,
  email: PropTypes.string.isRequired,
  defaultCategoryId: PropTypes.number,
  smsPhoneNumber: PropTypes.string,
  timeZone: PropTypes.string,
  stripeId: PropTypes.string,
});

export const consumerProfileProps = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired,
  smsPhoneNumber: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  passwordLastUpdatedAt: PropTypes.string.isRequired,
  defaultCategory: PropTypes.string.isRequired,
  defaultCategoryId: PropTypes.number.isRequired,
  autoRenewPackage: PropTypes.string.isRequired,
  tokenBalance: PropTypes.number.isRequired,
  thresholdToken: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

export const customerProfileProps = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  passwordLastUpdatedAt: PropTypes.string.isRequired,
});

export const adminProfileProps = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired,
  passwordLastUpdatedAt: PropTypes.string.isRequired,
});

export const customerProfilePreviousProps = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
});

export const consumerProfilePreviousProps = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired,
  smsPhoneNumber: PropTypes.string.isRequired,
  defaultCategoryId: PropTypes.number.isRequired,
});

export const adminProfilePreviousProps = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired,
});

export const formikProps = PropTypes.shape({
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
});

export const defaultPaymentInfoProps = PropTypes.shape({
  expMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastFourDigit: PropTypes.string,
  cardBrand: PropTypes.string,
  noDefaultPayment: PropTypes.bool,
  isStripePayment: PropTypes.bool,
  isPaypalPayment: PropTypes.bool,
  fullName: PropTypes.string,
  email: PropTypes.string,
});
