import { callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const audioService = {
  getAudiosForConsumer: async (params, query) => {
    const { method, endpoint } = endpoints.getAudiosForConsumer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  chargeAudioForConsumer: async (params, query) => {
    const { method, endpoint } = endpoints.chargeAudioForConsumer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  getAudiosForCustomer: async (params, query) => {
    const { method, endpoint } = endpoints.getAudiosForCustomer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  deleteAudioByCustomer: async (params, query) => {
    const { method, endpoint } = endpoints.deleteAudioByCustomer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  updateAudioByCustomer: async (data, params) => {
    const { method, endpoint } = endpoints.updateAudioByCustomer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data });

    return response;
  },

  copyAudioByCustomer: async (data, params, query) => {
    const { method, endpoint } = endpoints.copyAudioByCustomer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data });

    return response;
  },

  addAudioByCustomer: async (data, params, onUploadProgress) => {
    const { method, endpoint } = endpoints.addAudioByCustomer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data, onUploadProgress });

    return response;
  },
};

export default audioService;
