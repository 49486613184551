import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { CONSUMER, CUSTOMER } from '../../../helper/constant-values';
import {
  subscribeToCategory,
  // eslint-disable-next-line prettier/prettier
  unsubscribeToCategory
} from '../../../redux/actions/consumer-category-action';
import WarningModal from '../../common/modals/warning-success-modal';
import {
  singleSubscriptionProps,
  // eslint-disable-next-line prettier/prettier
  userInformationProps
} from './consumer-subscription-common-props';
import SubscriptionInfoModal from './subscription-modals/subscription-info-modal';

function SubscriptionCard(props) {
  const { category, userInfoState, subscribe, unsubscribe } = props;

  const [showSubscribeInfoModal, setShowSubscribeInfoModal] = useState(false);
  const [processingSubscription, setProcessingSubscription] = useState(false);
  const [showSmsPhoneNumberModal, setShowSmsPhoneNumberModal] = useState(false);

  const history = useHistory();
  const { customerUrl } = useParams();

  const onSubscribeBtn = () => {
    setShowSubscribeInfoModal(true);
  };

  const onCancelSubscription = () => {
    const params = { customerUrl, consumerId: userInfoState.id, consumerCategoryId: category.id };

    setProcessingSubscription(true);

    unsubscribe(params)
      .then(() => {
        setShowSubscribeInfoModal(false);
        setProcessingSubscription(false);
      })
      .catch(() => {
        setShowSubscribeInfoModal(false);
        setProcessingSubscription(false);
      });
  };

  const onHideSubscriptionConfirmationModal = () => {
    if (!processingSubscription) {
      setShowSubscribeInfoModal(false);
    }
  };

  const onConfirmSubscription = (checkedSms) => {
    if (!userInfoState.smsPhoneNumber && checkedSms) {
      setShowSubscribeInfoModal(false);
      setShowSmsPhoneNumberModal(true);
    } else if (!checkedSms) {
      onCancelSubscription();
    } else {
      setProcessingSubscription(true);

      const params = { customerUrl, consumerId: userInfoState.id, consumerCategoryId: category.id };
      const mediums = [];

      if (checkedSms) mediums.push('sms');

      subscribe({ mediums }, params)
        .then(() => {
          setShowSubscribeInfoModal(false);
          setProcessingSubscription(false);
        })
        .catch(() => {
          setShowSubscribeInfoModal(false);
          setProcessingSubscription(false);
        });
    }
  };

  const goToProfilePage = () => {
    if (userInfoState.type === CONSUMER) {
      history.push(`/${customerUrl}/profile`);
    } else if (userInfoState.type === CUSTOMER) {
      history.push(`/${customerUrl}/customer/profile`);
    }
  };

  return (
    <>
      <WarningModal
        heading="Add SMS Phone Number!"
        description="You have not added an SMS phone number to your account. To avail of the SMS service, you need to add an SMS phone number."
        leftBtnText="Back"
        rightBtnText="Goto My Profile"
        showModal={showSmsPhoneNumberModal}
        onHideModal={() => setShowSmsPhoneNumberModal(false)}
        onModalSubmit={goToProfilePage}
      />

      <Card className="bg-light rounded-lg h-100">
        <Card.Header className="bg-light border-0 text-center d-flex justify-content-center">
          <div
            className="card-img-top mb-n3 w-50 text-muted"
            dangerouslySetInnerHTML={{ __html: category.logoSvg }}
          />
        </Card.Header>
        <Card.Body className="d-flex flex-column justify-content-between">
          <Card.Text className="pt-2">
            <span className="h4">{category.name}</span>
          </Card.Text>

          <Button variant="outline-primary" size="lg" onClick={onSubscribeBtn} block>
            <span className="font-weight-bold">View Subscription Info</span>
          </Button>

          {showSubscribeInfoModal && (
            <SubscriptionInfoModal
              showModal={showSubscribeInfoModal}
              hideModal={onHideSubscriptionConfirmationModal}
              category={category}
              onConfirmSubscription={onConfirmSubscription}
              processingSubscription={processingSubscription}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
}

SubscriptionCard.propTypes = {
  category: singleSubscriptionProps.isRequired,
  userInfoState: userInformationProps.isRequired,
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
};

SubscriptionCard.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  subscribe: subscribeToCategory,
  unsubscribe: unsubscribeToCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCard);
