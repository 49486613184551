import PropTypes from 'prop-types';
import { CheckboxOffIcon, CheckboxOnIcon } from '../../../helper/icons/common-icons';

export default function CustomCheckbox(props) {
  const { checked, className, onClick } = props;

  return (
    <>
      {!checked && <CheckboxOffIcon className={`text-dark ${className}`} onClick={onClick} />}
      {checked && <CheckboxOnIcon className={`text-primary ${className}`} onClick={onClick} />}
    </>
  );
}

CustomCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

CustomCheckbox.defaultProps = {
  className: '',
};
