const MOBILE_VIEW_PORT_WIDTH = 576;
const TAB_VIEW_PORT_WIDTH = 768;
const LAPTOP_VIEW_PORT_WIDTH = 992;
const DESKTOP_VIEW_PORT_WIDTH = 1200;
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const TABLE_DATE_FORMAT = 'll';
const TABLE_DATE_TIME_FORMAT = 'll HH:mm';
const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DEFAULT_DATE_TIME_WITHOUT_SECOND_FORMAT = 'YYYY-MM-DD HH:mm';
const OTP_LENGTH = 6;
const USER_DATA = 'user-data';
const CUSTOMER = 'customer';
const CONSUMER = 'consumer';
const ADMIN = 'admin';
const AUDIO = 'Audio';
const OTP_TYPE_EMAIL = 'isEmail';
const OTP_TYPE_SMS_PHONE_NUMBER = 'isSmsPhoneNumber';
const OTP_TYPE_PASSWORD = 'isPassword';
const PLEASE_TRY_AGAIN = 'An error occurred. Please try again.';
const ICON_SIZE = '1.5em';
const DATE_MDY_FORMAT = 'MM-DD-YYYY';

module.exports = {
  MOBILE_VIEW_PORT_WIDTH,
  USER_DATA,
  CUSTOMER,
  CONSUMER,
  ADMIN,
  OTP_TYPE_EMAIL,
  OTP_TYPE_SMS_PHONE_NUMBER,
  OTP_TYPE_PASSWORD,
  PLEASE_TRY_AGAIN,
  TAB_VIEW_PORT_WIDTH,
  LAPTOP_VIEW_PORT_WIDTH,
  DESKTOP_VIEW_PORT_WIDTH,
  AUDIO,
  DEFAULT_DATE_FORMAT,
  OTP_LENGTH,
  ICON_SIZE,
  TABLE_DATE_FORMAT,
  TABLE_DATE_TIME_FORMAT,
  DEFAULT_DATE_TIME_WITHOUT_SECOND_FORMAT,
  DATE_MDY_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
};
