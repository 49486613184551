import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';

export default function SortTokenTransaction(props) {
  const { onSortTypeChange, shortByColumn, onSortByChange } = props;

  return (
    <>
      <Col xs={12} md={6} xl={4} className="pt-3 pt-lg-0">
        <span className="d-inline font-weight-bold">Sort By:&nbsp;</span>
        <Form.Control
          as="select"
          className="default-select d-inline w-100 w-xl-75 bg-white"
          size="lg"
          onChange={onSortByChange}
        >
          <option value="createdAt">Date Time</option>
          <option value="username">Username</option>
          <option value="description">Description</option>
          <option value="tokenDebit">Token Debit</option>
          <option value="tokenCredit">Token Credit</option>
          <option value="newTokenBalance">Token Balance</option>
        </Form.Control>
      </Col>

      {shortByColumn && (
        <Col xs={12} md={6} xl={4} className="pt-3 pt-lg-0">
          <span className="d-inline font-weight-bold">Sort Type:&nbsp;</span>

          <Form.Control
            as="select"
            className="default-select d-inline w-100 w-xl-60 bg-white"
            size="lg"
            onChange={onSortTypeChange}
            defaultValue="Descending"
          >
            <option value="Ascending">Ascending</option>
            <option value="Descending">Descending</option>
          </Form.Control>
        </Col>
      )}
    </>
  );
}

SortTokenTransaction.propTypes = {
  onSortTypeChange: PropTypes.func.isRequired,
  shortByColumn: PropTypes.string.isRequired,
  onSortByChange: PropTypes.func.isRequired,
};

SortTokenTransaction.defaultProps = {};
