import { callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const tokenTransactionsService = {
  getTokenTransactionByAdmin: async (query) => {
    const { method, endpoint } = endpoints.getTokenTransactionByAdmin;

    const url = utils.buildUrl(baseUrl + endpoint, {}, query);

    const response = await callAxiosWithAuth({ method, url });
    return response;
  },
};

export default tokenTransactionsService;
