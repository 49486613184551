import momentTimeZone from 'moment-timezone';
import { phone } from 'phone';
import * as Yup from 'yup';
import config from '../config/default';
import commonMethods from './common-methods';
import { TABLE_DATE_TIME_FORMAT } from './constant-values';

const commonObjects = {
  emailOrUsername: Yup.string()
    .max(254, 'UserName or Email can be maximum 254 characters.')
    .required('Email or Username is required.')
    .test(
      'white-space-in-string',
      'Username or Email can not contain any white space.',
      (value) => value === undefined || value.trim().indexOf(' ') === -1
    )
    .test(
      'only-white-space',
      'Username or Email can not contain only white space.',
      (value) => value !== undefined && value.trim() !== ''
    ),

  fullName: Yup.string()
    .required('Name is required.')
    .min(3, 'Name must have at least 3 characters.')
    .max(100, 'Names can be maximum 100 characters.')
    .test('fullNameValidation', 'Please enter a valid Name', (value) => {
      const regexForAnyDigit = /^[a-zA-Z ]*$/;

      if (!regexForAnyDigit.test(value)) {
        return false;
      }

      return true;
    }),

  username: Yup.string()
    .min(3, 'Username must have at least 3 characters.')
    .required('Username is required')
    .test(
      'white-space-in-string',
      'Username can not contain any white space.',
      (value) => value === undefined || value.trim().indexOf(' ') === -1
    )
    .test(
      'only-white-space',
      'Username can not contain only white space.',
      (value) => value !== undefined && value.trim() !== ''
    )
    .max(16, 'Username can be maximum 16 characters.'),

  url: Yup.string()
    .min(3, 'Customer url must have at least 3 characters')
    .required('Customer url is required')
    .test(
      'white-space-in-string',
      'Customer url can not contain any white space.',
      (value) => value === undefined || value.trim().indexOf(' ') === -1
    )
    .test(
      'only-white-space',
      'Customer url can not contain only white space.',
      (value) => value !== undefined && value.trim() !== ''
    )
    .test(
      'only-alpha-numeric-url',
      `Only alphanumeric characters and ' - ' are allowed.`,
      (value) => {
        const alphanumericRegex = /^[a-zA-Z0-9-]+$/;

        return value !== undefined && alphanumericRegex.test(value);
      }
    )
    .max(20, 'Customer url can be maximum 20 characters.'),

  smsPhoneNumber: Yup.string()
    .max(31, 'SMS phone number can be maximum 31 characters.')
    .test(
      'usCanadaPhoneNumberValidation',
      'Please enter valid US or Canada phone number.',
      (number) => {
        if (!number) {
          return true;
        }

        let phoneValidationData = phone(number, { country: 'USA' });
        if (phoneValidationData.isValid) {
          return true;
        }

        phoneValidationData = phone(number, { country: 'CA' });

        return phoneValidationData.isValid;
      }
    ),

  contactNumber: Yup.string()
    .max(31, 'Contact Phone Number can be maximum 31 characters.')
    .required('Contact Phone Number is required')
    .test('contactNumberValidation', 'Please enter a valid number', (number) => {
      const regexForContact = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;

      if (!regexForContact.test(number)) {
        return false;
      }

      return true;
    }),

  email: Yup.string()
    .email('Must be a valid email.')
    .max(254, 'Email can be maximum 254 characters.')
    .required('Email is required'),

  password: (text = 'Password') =>
    Yup.string()
      .required(`${text} is required`)
      .min(8, 'Password must be at least 8 characters long.')
      .max(100, 'Password must be less than 100 characters.'),

  confirmPassword: (ref = 'password') =>
    Yup.string()
      .required('Confirm Password is required')
      .max(100, 'Password must be less than 100 characters.')
      .oneOf([Yup.ref(`${ref}`), null], 'Passwords must match'),

  thresholdToken: Yup.number()
    .integer('Threshold token must be an integer.')
    .positive('Threshold token must be a positive integer.')
    .required('Threshold token is required.')
    .max(1000, 'Threshold token can be maximum 1000.'),

  numberOfTokens: Yup.number('Must be a number')
    .integer('Number of token must be an integer.')
    .positive('Number of token must be a positive integer.')
    .required('Number of token is required.')
    .max(1000000, 'Number of token can be maximum 1000000.'),

  companyName: Yup.string()
    .required('Company name is required.')
    .min(2, 'Company name must have at least 2 characters.')
    .max(30, 'Company name can be maximum 30 characters.'),

  companyDetails: Yup.string()
    .required('Company details is required.')
    .min(2, 'Company details must have at least 2 characters.')
    .max(500, 'Company details can be maximum 500 characters.'),

  decimalYup: (value, createError, name, digitAfterDecimal) => {
    const floatValues = /^\d+(\.\d+)?$/;
    if (value && value.toString().match(floatValues)) {
      const floatValuesLimit = new RegExp(`^\\d+(\\.\\d{1,${digitAfterDecimal}})?$`);

      if (!value.toString().match(floatValuesLimit)) {
        return createError({
          message: `${name} can have maximum ${digitAfterDecimal} digis after floating point.`,
        });
      }

      return true;
    }

    return false;
  },
};

export const loginValidationSchema = Yup.object().shape({
  emailOrUsername: commonObjects.emailOrUsername,

  password: Yup.string()
    .required('Password is required')
    .max(100, 'Password can be maximum 100 characters.'),
});

export const registrationValidationSchema = Yup.object().shape({
  username: commonObjects.username,

  fullName: commonObjects.fullName,

  contactNumber: commonObjects.contactNumber,

  defaultCategoryId: Yup.string().required('Astrological sign is required'),

  email: commonObjects.email,

  password: commonObjects.password(),

  confirmPassword: commonObjects.confirmPassword(),
});

export const forgotPasswordValidationSchema = Yup.object().shape({
  emailOrUsername: commonObjects.emailOrUsername,
});

export const newPasswordValidationSchema = Yup.object().shape({
  newPassword: commonObjects.password(),
  confirmNewPassword: commonObjects.confirmPassword('newPassword'),
});

export const changePasswordOfProfileValidationSchema = Yup.object().shape({
  currentPassword: commonObjects.password('Current Password'),

  newPassword: commonObjects.password('New Password'),

  confirmNewPassword: commonObjects.confirmPassword('newPassword'),
});

export const customerProfileValidationSchema = Yup.object().shape({
  fullName: commonObjects.fullName,

  contactNumber: commonObjects.contactNumber,

  timeZone: Yup.string().required('Time Zone is required'),

  email: commonObjects.email,
});

export const consumerProfileValidationSchema = Yup.object().shape({
  fullName: commonObjects.fullName,

  contactNumber: commonObjects.contactNumber,

  defaultCategoryId: Yup.string().required('Astrological sign is required'),

  smsPhoneNumber: commonObjects.smsPhoneNumber,

  email: commonObjects.email,
});

export const supportEmailMessageValidationSchema = Yup.object().shape({
  description: Yup.string()
    .required('Message is required.')
    .max(1000, 'Message must be less than 1000 characters.'),

  consumerEmail: commonObjects.email,
});

export const adminProfileValidationSchema = Yup.object().shape({
  fullName: commonObjects.fullName,

  contactNumber: commonObjects.contactNumber,

  email: commonObjects.email,
});

export const consumerProfileEidtByAdminValidationSchema = Yup.object().shape({
  contactNumber: commonObjects.smsPhoneNumber,

  email: commonObjects.email,
});

export const audioModifyValidationSchema = Yup.object().shape({
  audioName: Yup.string()
    .required('Audio name is required.')
    .min(2, 'Audio name must have at least 2 characters.')
    .max(100, 'Audio name can be maximum 100 characters.')
    .test('audioNameValidation', 'Audio name can not be empty.', (value) => {
      if (!value || !value.trim()) {
        return false;
      }

      return true;
    }),

  startDateTime: Yup.string().required('Start date & time are required'),

  endDateTime: Yup.string()
    .required('End date & time are required')
    .test('endDateValidation', 'End date must be after start date.', (value, context) => {
      const startDateTimeValue = context.parent.startDateTime;
      if (!startDateTimeValue) return true;

      const startDateTimeUtc = commonMethods.getUtcDateTime({
        dateTime: startDateTimeValue,
        inputFormat: TABLE_DATE_TIME_FORMAT,
      });

      const endDateTimeUtc = commonMethods.getUtcDateTime({
        dateTime: value,
        inputFormat: TABLE_DATE_TIME_FORMAT,
      });

      if (momentTimeZone(startDateTimeUtc).isAfter(endDateTimeUtc)) {
        return false;
      }

      return true;
    }),
});

export const shortTextModifyValidationSchema = (timeZone) =>
  Yup.object().shape({
    messageName: Yup.string()
      .required('Message name is required.')
      .min(2, 'Message name must have at least 2 characters.')
      .max(100, 'Message name can be maximum 100 characters.')
      .test('messageNameValidation', 'Please enter a valid audio name.', (value) => {
        if (!value || !value.trim()) {
          return false;
        }

        return true;
      }),

    disburseDateTime: Yup.string()
      .required('Disburse date & time are required')
      .test(
        'disburseDateTimeValidation',
        `Future disburese time must be after ${momentTimeZone
          .tz(timeZone)
          .add(config.shortText.scheduleDelay, 'minutes')
          .format(TABLE_DATE_TIME_FORMAT)}`,
        (value, { createError }) => {
          if (!timeZone) return true;

          const selectedTime = momentTimeZone.tz(value, TABLE_DATE_TIME_FORMAT, timeZone);
          const currentTime = momentTimeZone.tz(timeZone);
          const ahedTime = currentTime.clone().add(config.shortText.scheduleDelay, 'minutes');

          if (selectedTime.isBetween(currentTime, ahedTime)) {
            return createError({
              message: `Future disburese time must be after ${momentTimeZone
                .tz(timeZone)
                .add(config.shortText.scheduleDelay, 'minutes')
                .format(TABLE_DATE_TIME_FORMAT)}`,
            });
          }

          return true;
        }
      ),

    textContent: Yup.string()
      .required('Text message is required.')
      .min(2, 'Text message must have at least 2 characters.')
      .max(160, 'Text message can be maximum 160 characters.')
      .test('textContentValidation', 'Text message can not be empty.', (value) => {
        if (!value || !value.trim()) {
          return false;
        }

        return true;
      }),
  });

export const shortTextAddValidationSchema = (timeZone) =>
  Yup.object().shape({
    shortTextList: Yup.array().of(shortTextModifyValidationSchema(timeZone)),
  });

export const addCustomerDetailsValidationSchema = Yup.object().shape({
  username: commonObjects.username,

  fullName: commonObjects.fullName,

  contactNumber: commonObjects.contactNumber,

  timeZone: Yup.string().required('Time Zone is required'),

  email: commonObjects.email,

  url: commonObjects.url,

  password: commonObjects.password(),

  confirmPassword: commonObjects.confirmPassword(),

  thresholdToken: commonObjects.thresholdToken,

  companyName: commonObjects.companyName,

  details: commonObjects.companyDetails,
});

export const updateCustomerDetailsValidationSchema = Yup.object().shape({
  username: commonObjects.username,

  fullName: commonObjects.fullName,

  contactNumber: commonObjects.contactNumber,

  timeZone: Yup.string().required('Time Zone is required'),

  email: commonObjects.email,

  url: commonObjects.url,

  thresholdToken: commonObjects.thresholdToken,

  companyName: commonObjects.companyName,

  details: commonObjects.companyDetails,
});

export const addCustomerCategoryValidationSchema = ({ isDisabledSms, isDisabledEmail }) =>
  Yup.object().shape({
    name: Yup.string()
      .required('Category name is required.')
      .min(3, 'Category name must have at least 3 characters.')
      .max(30, 'Category name can be maximum 30 characters.'),

    sortOrder: Yup.number('Must be a number.')
      .integer('Sort Order must be an integer.')
      .positive('Sort Order must be a positive integer.')
      .required('Sort Order is required.')
      .min(1, 'Sort Order can be minimum 1.')
      .max(100, 'Sort Order can be maximum 100.'),

    numberOfTokensSMS: !isDisabledSms ? commonObjects.numberOfTokens : Yup.string(),

    numberOfTokensEmail: !isDisabledEmail ? commonObjects.numberOfTokens : Yup.string(),

    numberOfTokensAudio: commonObjects.numberOfTokens,
  });

export const addCustomerPackageValidationSchema = ({ isAutoRenewable }) => {
  const yupShape = {
    name: Yup.string()
      .required('Package name is required.')
      .min(3, 'Package name must have at least 3 characters.')
      .max(50, 'Package name can be maximum 50 characters.'),

    price: Yup.number('Must be a number')
      .test('is-decimal', 'Invalid Price', (value, { createError }) =>
        commonObjects.decimalYup(value, createError, 'Price', 2)
      )
      .required('Price is required.')
      .max(100000, 'Price can be maximum 100000.'),

    numberOfTokens: commonObjects.numberOfTokens,
  };

  if (isAutoRenewable) {
    yupShape.autoRenewPrice = Yup.number('Must be a number')
      .test('is-decimal', 'Invalid Price', (value, { createError }) =>
        commonObjects.decimalYup(value, createError, 'Price', 2)
      )
      .required('Auto-Purchase Price is required.')
      .max(100000, 'Auto-Purchase Price can be maximum 100000.');
  }

  return Yup.object().shape(yupShape);
};
