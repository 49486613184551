export default [
  {
    question: 'How can I listen to Expanded Audio Readings?',
    answer: `<p>The Free Will Astrology Expanded Audio readings, written and recorded by Rob Brezsny, are posted to the website on a weekly basis. The last four weekly readings are available for each astrological sign. A special three-part long-range forecast for the year ahead is posted at the beginning of each year and remains on the website for a longer period. There is also a single mid-year long range reading for the second half of the year that is posted in June.</p>
    One expanded audio reading costs seven tokents. The tokens cost $1 each when purchased one at a time (or seven at a time for one reading), and at a discount when purchasing larger token packages.`,
  },
  {
    question: 'How can I subscribe to the Daily Sunburst text messages?',
    answer: `<p>The Free Will Astrology Daily Sunbursts, written by Rob Brezsny, are text messages that are sent out every morning for each astrological sign.</p>
    <p>Sunbursts are purchased every day through your current token balance. The tokens cost $1 when purchased separately and are discounted when purchasing larger token packages. To receive Sunbursts continuously, you can select the Auto-Purchase token option. See the FAQ on purchasing tokens for more information.</p>
    PLEASE NOTE: we are only able to send the Daily Sunburst text message via SMS to mobile phones on US carriers. When you give us your SMS number, we will send you a confirmation code to verify that you are able to receive SMS messages from our system.`,
  },
  {
    question: 'How do I purchase tokens?',
    answer: `<p>You need seven tokens to listen to one of the weekly Audio readings. You need one token to receive each Daily Sunburst text message.</p>
    <p>To purchase tokens, click on the PLUS sign at the top right corner of the screen or on "Purchase Tokens" on the left sidebar menu. Discounts are available when purchasing tokens in larger packages.</p>
    <p>If you want to receive Sunbursts continuously, you can select the Auto-Purchase option. When Auto-Purchase is enabled, whenever the number of tokens in your account falls below three, the package you selected will be purchased automatically. You will be notified by email when the purchase has been made.</p>
    VERY IMPORTANT - Please note that if you ever have any issues with token payments, we will always be happy to resolve this for you and issue a prompt refund.
    `,
  },
  {
    question: 'How do I manage my account or view my account status?',
    answer: `<p>Click on the down arrow on the right side of your name and select "My Profile" to view or edit your account information.</p>
    <p>The "Tokens & Subscriptions" section shows you your current token balance, how many readings you can listen to with these tokens, your Sunburst subscriptions if you have any, and whether you are set up for Auto-Purchase to receive the Sunburst continuously.</p>
    <p>The "Personal Information" section includes your astrological sign, your email address, and separate phone numbers for contacting you and receiving SMS messages (the latter is validated to make sure you can receive SMS messages from our system).</p>
    You can edit your personal information here and can also change your password.`,
  },
  {
    question: 'What can I do if I need help with the website?',
    answer: `<p>First, scan through the FAQs to see if your question is answered here.</p>
    If not, click "Contact Us" on the left sidebar menu and send us a detailed message on the form provided explaining your question or issue. We will work with you to get it resolved as soon as possible.`,
  },
  {
    question: 'How can I send a message to Rob Brezsny?',
    answer: `Go to the main Freewill Astrology <a href="https://freewillastrology.com">website</a>, scroll down to the bottom of the page, and click on "Contact"`,
  },
];
