import _ from 'lodash';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import path from 'path';
import Resizer from 'react-image-file-resizer';
import { DEFAULT_DATE_FORMAT, PLEASE_TRY_AGAIN } from './constant-values';
import { showNotification } from './notifications';

const commonMethods = {
  getDateFromDateTime: (dateTime, format = 'L', inputFormat = null) => {
    if (inputFormat) return moment(dateTime, inputFormat).format(format);
    return moment(dateTime).format(format);
  },

  getDayMonthYearDifferenceWithCurrentDate: (dateTime) => {
    const now = moment(new Date());
    const duration = moment.duration(now.diff(dateTime));

    return [
      Math.floor(duration.days()),
      Math.floor(duration.months()),
      Math.floor(duration.years()),
    ];
  },

  getTimeZoneUSAndCA: () =>
    momentTimeZone.tz.zonesForCountry('US').concat(momentTimeZone.tz.zonesForCountry('CA')),

  getDateTimeFromUtc: (timeZone, outputFormat, time = momentTimeZone.utc()) =>
    momentTimeZone(time).tz(timeZone).format(outputFormat),

  getUtcDateTime: ({
    timeZone = momentTimeZone.tz.guess(),
    dateTime = momentTimeZone(),
    inputFormat = null,
  }) => {
    // eslint-disable-next-line no-param-reassign
    if (!dateTime) dateTime = momentTimeZone();

    if (inputFormat) return momentTimeZone.tz(dateTime, inputFormat, timeZone).utc();

    return momentTimeZone.tz(dateTime, timeZone).utc();
  },

  actionDefaultErrorResponse: (error) => {
    if (!error.response) {
      return Promise.reject();
    }
    const { data: errorData } = error.response;
    const text = errorData.message ? errorData.message : PLEASE_TRY_AGAIN;

    if (Array.isArray(errorData.errorMessageList)) {
      for (let index = 0; index <= Math.min(errorData.errorMessageList.length, 5); index += 1) {
        const element = errorData.errorMessageList[index];

        showNotification({
          type: 'error',
          text: element,
        });
      }

      if (errorData.errorMessageList.length > 5) {
        showNotification({
          type: 'error',
          text: 'More error message exists.',
        });
      }
    } else {
      showNotification({
        type: 'error',
        text,
      });
    }

    return Promise.reject(error);
  },

  getFormData: (data, file = null, fileName = 'image') => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    if (file) formData.append(fileName, file, file.name);

    return formData;
  },

  getYesterdayDate: (asJsDate = false) => {
    const yesterdayDate = moment().subtract(1, 'day');

    if (asJsDate) {
      return yesterdayDate.toDate();
    }
    return yesterdayDate.format(DEFAULT_DATE_FORMAT);
  },

  getTodayDate: (asJsDate = false) => {
    const date = moment();

    if (asJsDate) {
      return date.toDate();
    }
    return date.format(DEFAULT_DATE_FORMAT);
  },

  isImageFile: (fileName) => {
    const ext = path.extname(fileName).toLowerCase();
    if (ext === '.png' || ext === '.jpg' || ext === '.jpeg') {
      return true;
    }

    return false;
  },

  isSvgFile: (fileName) => {
    const ext = path.extname(fileName).toLowerCase();

    return ext === '.svg';
  },

  imageResize: (file, maxWidth = 300, maxHeight = 300) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(file, maxWidth, maxHeight, 'png', 100, 0, resolve, 'file');
    }),

  updatePaginationData: ({
    isMobileView = false,
    payload = null,
    params,
    query,
    page,
    itemPerPage,
    pageChanged = false,
    getMethod,
    data,
  }) => {
    const actionData = {};
    const currentNumberOfItems = pageChanged ? (page - 1) * itemPerPage : page * itemPerPage;

    const newQuery = _.cloneDeep(query);

    if (isMobileView && (page === 1 || data.length < currentNumberOfItems)) {
      // If we are in the mobile landscape view and lets's say we are in the second page.
      // If we move to the portrait view, we will only show the 2nd page data. Now if the user clicks load more button we need to load all the 3 page data.
      // For this reason we are putting noPagination = true to get all the data from page 1 to page 3
      // Also for first page we set noPagination = true, because it means all the data of first page
      newQuery.noPagination = true;
      actionData.noPagination = true;
      actionData.isMobile = isMobileView;
    } else {
      // Otherwise we will use regular pagination for both mobile and other device
      actionData.noPagination = false;
      actionData.isMobile = isMobileView;
    }

    return payload
      ? getMethod(payload, params, newQuery, actionData)
      : getMethod(params, newQuery, actionData);
  },

  getImageApi: (query, isPublic = false) => {
    if (!query) return query;
    return `${process.env.REACT_APP_API_URL}/v1/images${isPublic ? '/public' : ''}${query}`;
  },

  paginationStateUpdate: (state, action, stateName) => {
    let newData = {};

    if (action.noPagination || !action.isMobile) {
      newData = action.data;
    } else if (!action.noPagination && action.isMobile) {
      // This is used when user clicks load more button in mobile
      newData = {
        ...action.data,
        data: [...state[stateName].data, ...action.data.data],
      };
    }

    return {
      ...state,
      [stateName]: newData,
    };
  },

  deletePaginationState: (state, action, stateName) => {
    if (action.isMobile) {
      return {
        ...state,
        [stateName]: {
          ...state[stateName],
          data: _.filter(
            state[stateName].data,
            (currentObject) => +currentObject.id !== +action.data.id
          ),
        },
      };
    }

    return commonMethods.paginationStateUpdate(state, action, stateName);
  },

  addPaginationState: (state, action, stateName) => {
    if (action.isMobile) {
      return {
        ...state,
        [stateName]: {
          ...state[stateName],
          data: [action.data, ..._.cloneDeep(state[stateName].data).slice(0, -1)],
        },
      };
    }

    return commonMethods.paginationStateUpdate(state, action, stateName);
  },

  copyPaginationState: (state, action, stateName) =>
    commonMethods.addPaginationState(state, action, stateName),

  updateOnPaginationState: (state, action, stateName) => ({
    ...state,
    [stateName]: {
      ...state[stateName],
      data: _.map(state[stateName].data, (item) =>
        item.id === action.data.id ? action.data : item
      ),
    },
  }),

  getAudioAPI: ({
    customerUrl,
    consumerId,
    audioId = 0,
    audioSession = '',
    audioFileName = '',
    testAudio = false,
    isTestAudioPlayed = false,
  }) => {
    if (!consumerId) {
      return `${process.env.REACT_APP_API_URL}/v1/customers/${customerUrl}/audio/${audioId}/stream?audioFileName=${audioFileName}`;
    }
    if (testAudio) {
      return `${process.env.REACT_APP_API_URL}/v1/customers/${customerUrl}/consumers/${consumerId}/audio/0/stream?session=${audioSession}&isTestAudioPlayed=${isTestAudioPlayed}`;
    }
    return `${process.env.REACT_APP_API_URL}/v1/customers/${customerUrl}/consumers/${consumerId}/audio/${audioId}/stream?session=${audioSession}&audioFileName=${audioFileName}`;
  },

  getTimeFromSeconds: (seconds) => {
    const time = moment().startOf('day').seconds(seconds);
    const hours = parseInt(seconds / 3600, 10);
    const minutes = parseInt(seconds / 60, 10);

    if (hours !== 0) {
      return time.format('HH:mm:ss');
    }
    if (minutes > 10) {
      return time.format('mm:ss');
    }

    return time.format('m:ss');
  },

  getAudioDurationOfUploadedFile: (setAudioDuration, selectedAudioFile) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => {
          setAudioDuration(commonMethods.getTimeFromSeconds(media.duration));
        };
      }
    };

    reader.readAsDataURL(selectedAudioFile);
  },

  getBase64UrlFromSvgtext: (data) => (data ? `data:image/svg+xml;base64,${window.btoa(data)}` : ''),

  getPagingData: (dataList, { page, itemPerPage, isMobileView = false }) => {
    const totalItems = +dataList.length;
    const newArrayLength = isMobileView ? page * itemPerPage : itemPerPage;
    const startIndex = isMobileView ? 0 : itemPerPage * (page - 1);

    let data = dataList.slice(startIndex, startIndex + newArrayLength);

    if (!data) {
      data = [];
    }

    const totalPages = Math.ceil(totalItems / itemPerPage);

    return {
      totalItems,
      data,
      totalPages,
    };
  },

  capitalizeFirstLetter: (string) => string.charAt(0).toUpperCase() + string.slice(1),

  isNullOrEmpty: (string) => string === null || string === '',

  replacePaymnetMethodsWithCasing: (string) => {
    const newString = _.cloneDeep(string);

    return newString
      .replace('stripe', 'Stripe')
      .replace('paypal', 'PayPal')
      .replace('venmo', 'Venmo');
  },
};

export default commonMethods;
