/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import { Col, Container, Row } from 'react-bootstrap';
import { subscriptionListProps } from './consumer-subscription-common-props';
import SubscriptionCard from './subscription-card';

export default function SubscriptionList(props) {
  const { subscriptionList } = props;

  return (
    <Container className="container-uxl">
      <Row>
        <Col xs={12} sm={12} lg={12} className="pl-0">
          <h4 className="py-2">Select Sign</h4>
          <p>To setup or cancel a Sunbursts Subscription select your sign below.</p>
          <p>
            To see your current Sunbursts subscriptions{' '}
            <a href="/freewillastrology/profile">CLICK HERE to go to your Profile</a>.
          </p>
          <p>
            To receive Sunbursts continuously{' '}
            <a href="/freewillastrology/packages">CLICK HERE to setup an Auto-Purchase package</a>.
          </p>
        </Col>
      </Row>
      <Row className="bg-white rounded-lg py-2 px-2">
        {subscriptionList.map((item) => (
          <Col lg={4} md={6} className="py-2 px-2" key={item.id}>
            <SubscriptionCard category={item} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

SubscriptionList.propTypes = {
  subscriptionList: subscriptionListProps.isRequired,
};

SubscriptionList.defaultProps = {};
