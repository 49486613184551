import { Col, Row } from 'react-bootstrap';

export default function PackageInfoTitle() {
  return (
    <Row className="d-none d-sm-flex header mx-2 mt-4">
      <Col sm={3}>
        <p>Package Name</p>
      </Col>
      <Col sm={3} className="d-flex justify-content-end">
        <p>Tokens</p>
      </Col>
      <Col sm={3} className="d-flex justify-content-end">
        <p>Amount</p>
      </Col>
      <Col sm={3} className="d-flex justify-content-end text-right">
        <p>Auto-Purchase Amount</p>
      </Col>
    </Row>
  );
}

PackageInfoTitle.propTypes = {};

PackageInfoTitle.defaultProps = {};
