import { RingLoader } from 'react-spinners';
import colors from '../../../helper/colors';
import './loader-spinner-style.css';

export default function LoaderSpinner() {
  return (
    <>
      <div className="loader-spinner-class">
        <RingLoader loading size={100} color={colors.secondary} />
      </div>
    </>
  );
}
