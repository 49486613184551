import PropTypes from 'prop-types';
import { Button, ButtonGroup, Col, ToggleButton } from 'react-bootstrap';
import { AddIcon, UploadIcon } from '../../../../helper/icons/common-icons';
import DropDownWithIcons from '../../../common/dropdown/dropdown-with-icon';

export default function FilesHeaderButton(props) {
  const {
    isAudio,
    onFileTypeChange,
    onHandleAddButton,
    selectedFileType,
    onToggleDropDown,
    showFileTypeDropDown,
    headerButtons,
  } = props;

  const toggleButtonsRender = (buttons) => (
    <>
      {buttons.map((item) => (
        <ToggleButton
          key={item.id}
          type="radio"
          variant={selectedFileType === item.id ? 'primary' : 'outline-info'}
          name="radio"
          value={item.id}
          className={`header-button ${selectedFileType !== item.id ? 'primary-button' : ''}`}
          onClick={() => onFileTypeChange(item.id)}
        >
          {item.name}
        </ToggleButton>
      ))}
    </>
  );

  return (
    <>
      <Col className="py-4 d-none d-sm-flex d-lg-none" md={10}>
        <ButtonGroup toggle>{toggleButtonsRender(headerButtons.slice(0, 3))}</ButtonGroup>
      </Col>

      <Col className="py-4 d-none d-lg-flex" lg={9}>
        <ButtonGroup toggle>{toggleButtonsRender(headerButtons)}</ButtonGroup>
      </Col>

      <Col className="pt-2 d-sm-none pb-2">
        {headerButtons.length >= 3 && (
          <DropDownWithIcons
            categoryList={headerButtons}
            onCategoryChange={onFileTypeChange}
            onToggleDropDown={onToggleDropDown}
            showCategoryDropDown={showFileTypeDropDown}
            selectedCategory={selectedFileType}
          />
        )}

        {headerButtons.length < 3 && (
          <ButtonGroup className="pb-3" toggle>
            {toggleButtonsRender(headerButtons)}
          </ButtonGroup>
        )}
      </Col>

      <Col className="py-4 d-none d-sm-flex justify-content-end" md={2} lg={3}>
        <Button variant="primary" onClick={onHandleAddButton}>
          <span className="d-lg-inline d-none">{isAudio ? 'Upload Audio' : 'New Short Text'} </span>
          {isAudio ? (
            <UploadIcon height="1.5rem" width="1.5rem" className="mt-n1 ml-lg-2" />
          ) : (
            <AddIcon height="1.5rem" width="1.5rem" className="mt-n1 ml-lg-2" />
          )}
        </Button>
      </Col>

      {headerButtons.length > 3 && (
        <Col className="d-none d-sm-flex d-lg-none mt-n4 pb-2">
          <ButtonGroup toggle>{toggleButtonsRender(headerButtons.slice(3, 4))}</ButtonGroup>
        </Col>
      )}
    </>
  );
}

FilesHeaderButton.propTypes = {
  isAudio: PropTypes.bool,
  headerButtons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  onHandleAddButton: PropTypes.func.isRequired,
  onFileTypeChange: PropTypes.func.isRequired,
  selectedFileType: PropTypes.number.isRequired,
  onToggleDropDown: PropTypes.func.isRequired,
  showFileTypeDropDown: PropTypes.bool.isRequired,
};

FilesHeaderButton.defaultProps = {
  isAudio: false,
};
