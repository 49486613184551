import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Col, Figure, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import defaultProfilePic from '../../../assets/image/defaultProfilePic.png';
import config from '../../../config/default';
import commonMethods from '../../../helper/common-methods';
import { ADMIN, CONSUMER, CUSTOMER } from '../../../helper/constant-values';
import { BackIcon, CameraIcon } from '../../../helper/icons/common-icons';
import { getConsumerProfile, getCustomerProfile } from '../../../redux/actions/profile-action';
import { userInformationProps } from '../profile-common-props';
import AdminEditProfile from './admin-edit-profile';
import ConsumerEditProfile from './consumer-edit-profile';
import CustomerEditProfile from './customer-edit-profile';
import './profile-edit.css';

function EditProfile(props) {
  const {
    onUpdatingProfile,
    userInfoState,
    focusEmail,
    focusSmsPhoneNumber,
    backFromVerification,
    onBackArrowButtonClick,
    getCustomerProfileInfo,
    getConsumerProfileInfo,
  } = props;

  const [profilePicFile, setProfilePicFile] = useState('');
  const [profilePicUrl, setProfilePicUrl] = useState('');

  const [profileImageError, setProfileImageError] = useState('');

  const profilePicRef = useRef(null);
  const { customerUrl } = useParams();

  const maximumImageSize = config.files.maximumImageUploadSize;

  const backBtnColorClass =
    userInfoState.type === ADMIN ? 'profile-arrow-button-admin' : 'profile-arrow-button';

  const onCameraIconClick = () => {
    profilePicRef.current.click();
  };

  const onProfilePicUpload = async (event) => {
    const imageFile = event.target.files[0];

    if (!imageFile) return;

    if (!commonMethods.isImageFile(imageFile.name)) {
      setProfileImageError('* Selected file format is not supported.');
    } else if (imageFile.size > maximumImageSize) {
      setProfileImageError('* Maximum 4MB image file is allowed.');
      setProfilePicFile(imageFile);
    } else {
      setProfileImageError('');
      const file = await commonMethods.imageResize(imageFile);
      setProfilePicFile(file);
    }

    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfilePicUrl(reader.result);
      }
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  useEffect(() => {
    if (userInfoState.type === CUSTOMER) {
      getCustomerProfileInfo({ customerUrl });
    } else if (userInfoState.type === CONSUMER) {
      getConsumerProfileInfo({ customerUrl, consumerId: userInfoState.id });
    }
  }, []);

  return (
    <>
      <Row>
        <Col className="pl-0">
          <BackIcon
            className={`d-inline mb-2 mr-2 cursor-pointer ${backBtnColorClass}`}
            width="2.3em"
            height="2.3em"
            onClick={onBackArrowButtonClick}
          />
          <h4 className="pt-2 pl-1 d-inline">Edit Profile</h4>
        </Col>
      </Row>

      <Row className="mt-4 mb-4 mb-sm-0 pt-4 rounded-corner bg-white">
        <Col>
          <Row>
            <Col className="d-flex justify-content-center">
              <div className="upload-image-parent">
                <Figure className="mb-0">
                  <Figure.Image
                    width={134}
                    height={134}
                    src={
                      profilePicUrl ||
                      commonMethods.getImageApi(userInfoState.profilePic) ||
                      defaultProfilePic
                    }
                    fluid={false}
                    roundedCircle
                  />
                </Figure>

                <CameraIcon
                  width="2.8em"
                  height="2.8em"
                  className="upload-image cursor-pointer"
                  onClick={onCameraIconClick}
                />

                <input
                  className="d-none"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={onProfilePicUpload}
                  ref={profilePicRef}
                />
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={8} className="text-center">
              <span className="text-info">
                <small>* Supported file formats: .jpg, .jpeg, .png</small>
              </span>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={8} className="text-center">
              <span className="text-info">
                <small> * Recommended width & height ratio of image file: 1:1</small>
              </span>
            </Col>
          </Row>

          <Row className="justify-content-center pb-3">
            <Col sm={8} className="text-center">
              <span className="text-primary">{profileImageError}</span>
              {!profileImageError && <br />}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={8} className="mb-3 text-center">
              <h4 className="mt-1">Personal Information</h4>
            </Col>
          </Row>

          {userInfoState.type === ADMIN && (
            <AdminEditProfile
              onUpdatingProfile={onUpdatingProfile}
              focusEmail={focusEmail}
              backFromVerification={backFromVerification}
              profilePicFile={profilePicFile}
            />
          )}

          {userInfoState.type === CUSTOMER && (
            <CustomerEditProfile
              onUpdatingProfile={onUpdatingProfile}
              focusEmail={focusEmail}
              backFromVerification={backFromVerification}
              profilePicFile={profilePicFile}
              profileImageError={profileImageError}
            />
          )}

          {userInfoState.type === CONSUMER && (
            <ConsumerEditProfile
              onUpdatingProfile={onUpdatingProfile}
              focusEmail={focusEmail}
              focusSmsPhoneNumber={focusSmsPhoneNumber}
              backFromVerification={backFromVerification}
              profilePicFile={profilePicFile}
              profileImageError={profileImageError}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

EditProfile.propTypes = {
  onUpdatingProfile: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
  focusEmail: PropTypes.bool.isRequired,
  focusSmsPhoneNumber: PropTypes.bool.isRequired,
  backFromVerification: PropTypes.bool.isRequired,
  onBackArrowButtonClick: PropTypes.func.isRequired,
  getConsumerProfileInfo: PropTypes.func.isRequired,
  getCustomerProfileInfo: PropTypes.func.isRequired,
};

EditProfile.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  getConsumerProfileInfo: getConsumerProfile,
  getCustomerProfileInfo: getCustomerProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
