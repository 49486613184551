import { callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const profileService = {
  getConsumerProfile: async (params) => {
    const { method, endpoint } = endpoints.getConsumerProfile;

    const url = utils.buildUrl(baseUrl + endpoint, params);

    const response = await callAxiosWithAuth({ method, url });
    return response;
  },

  getConsumerProfileDefaultCardInfo: async (params) => {
    const { method, endpoint } = endpoints.getConsumerProfileDefaultCardInfo;

    const url = utils.buildUrl(baseUrl + endpoint, params);

    const response = await callAxiosWithAuth({ method, url });
    return response;
  },

  updateConsumerProfile: async (data, params) => {
    const { method, endpoint } = endpoints.updateConsumerProfile;

    const url = utils.buildUrl(baseUrl + endpoint, params);

    const response = await callAxiosWithAuth({ method, url, data }, true);
    return response;
  },

  getCustomerProfile: async (params) => {
    const { method, endpoint } = endpoints.getCustomerProfile;

    const url = utils.buildUrl(baseUrl + endpoint, params);

    const response = await callAxiosWithAuth({ method, url });
    return response;
  },

  updateCustomerProfile: async (data, params) => {
    const { method, endpoint } = endpoints.updateCustomerProfile;

    const url = utils.buildUrl(baseUrl + endpoint, params);

    const response = await callAxiosWithAuth({ method, url, data }, true);
    return response;
  },

  profilePasswordChange: async (data, params) => {
    const { method, endpoint } = endpoints.profilePasswordChange;

    const url = utils.buildUrl(baseUrl + endpoint, params);

    const response = await callAxiosWithAuth({ method, url, data });
    return response;
  },

  getAdminProfile: async () => {
    const { method, endpoint } = endpoints.getAdminProfile;

    const url = utils.buildUrl(baseUrl + endpoint);

    const response = await callAxiosWithAuth({ method, url });
    return response;
  },

  updateAdminProfile: async (data) => {
    const { method, endpoint } = endpoints.updateAdminProfile;

    const url = utils.buildUrl(baseUrl + endpoint);

    const response = await callAxiosWithAuth({ method, url, data }, true);
    return response;
  },

  adminProfilePasswordChange: async (data) => {
    const { method, endpoint } = endpoints.adminProfilePasswordChange;

    const url = utils.buildUrl(baseUrl + endpoint);

    const response = await callAxiosWithAuth({ method, url, data });
    return response;
  },
};

export default profileService;
