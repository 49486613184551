import PropTypes from 'prop-types';
import { useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { ICON_SIZE } from '../../../../../helper/constant-values';
import { ArrowDownIcon, ArrowUpIcon } from '../../../../../helper/icons/common-icons';
import WarningModal from '../../../../common/modals/warning-success-modal';
import { singlePackageProps } from '../../customer-common-props';
import CustomerPackageModifyModal from '../../modify-customer/customer-package/customer-package-modify-modal';
import ActionButtons from '../common-components/action-buttons';
import PackageRowBody from './package-row-body';

export default function PackageRow({
  packageItem,
  eventKey,
  onToggle,
  clickedIndex,
  onUpdatePackageSubmit,
  onDeletePackageSubmit,
  customerId,
}) {
  const [showEditPackageModal, setShowEditPackageModal] = useState(false);
  const [showDeletePackageModal, setShowDeletePackageModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const onHandleEdit = () => {
    setShowEditPackageModal(true);
  };

  const onHandleDelete = () => {
    setShowDeletePackageModal(true);
  };

  const onClosePackageModifyModal = () => {
    setShowEditPackageModal(false);
  };

  const onClosePackageDeleteModal = () => {
    setShowDeletePackageModal(false);
  };

  const onConfirmDelete = () => {
    setDataLoading(true);

    const params = {
      packageId: packageItem.id,
    };

    return onDeletePackageSubmit(params)
      .then(() => {
        setDataLoading(false);
      })
      .catch(() => {
        setDataLoading(false);
      });
  };

  return (
    <>
      {showEditPackageModal && (
        <CustomerPackageModifyModal
          showModal={showEditPackageModal}
          headerTitle="Edit Package"
          onCloseModal={onClosePackageModifyModal}
          onSubmit={onUpdatePackageSubmit}
          customerId={customerId}
          packageItem={packageItem}
        />
      )}

      <WarningModal
        heading="Delete Package"
        description={`Are you sure, you want to delete the package named ${packageItem.name}?`}
        leftBtnText="No"
        rightBtnText="Yes"
        showModal={showDeletePackageModal}
        onHideModal={onClosePackageDeleteModal}
        processing={dataLoading}
        onModalSubmit={onConfirmDelete}
      />

      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={eventKey}
          className="d-flex flex-row justify-content-between"
          onClick={() => onToggle(eventKey)}
        >
          <Col xs={6}>
            <p className="font-weight-bold">{packageItem.name}</p>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <Row>
              <ActionButtons onHandleEdit={onHandleEdit} onHandleDelete={onHandleDelete} />

              <Col xs={4} className="px-0 pb-1">
                <div className="mr-2 py-2 float-right">
                  {clickedIndex === eventKey ? (
                    <ArrowUpIcon width={ICON_SIZE} height={ICON_SIZE} />
                  ) : (
                    <ArrowDownIcon width={ICON_SIZE} height={ICON_SIZE} />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Accordion.Toggle>

        {clickedIndex === eventKey ? (
          <Card.Body>
            <PackageRowBody packageItem={packageItem} />
          </Card.Body>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
}

PackageRow.propTypes = {
  packageItem: singlePackageProps.isRequired,
  eventKey: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
  clickedIndex: PropTypes.number.isRequired,
  onUpdatePackageSubmit: PropTypes.func.isRequired,
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onDeletePackageSubmit: PropTypes.func.isRequired,
};

PackageRow.defaultProps = {};
