import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useDebounce } from 'react-use';
import commonMethods from '../../../helper/common-methods';
import {
  getPaymentHistoriesForAdmin,
  // eslint-disable-next-line prettier/prettier
  paymentRefundByAdmin
} from '../../../redux/actions/payments-action';
import { browserViewProps } from '../../common/common-props';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import MenuBarWrapper from '../../common/menu-bar-wrapper/menu-bar-wrapper';
import CustomPagination from '../../common/pagination/custom-pagination';
import CreditRefundModal from './consumer-edit/credit-refund-modal';
import PaymentHistoryListInfo from './consumer-list/payment-history-info';
import PaymentHistoryListTitle from './consumer-list/payment-history-list-title';
import { paymentHistoryListProps } from './credit-refund-common-props';
import './credit-refund-container.css';

function CreditRefundContainer(props) {
  const { paymentHistory, browserState, getPaymentHistories, paymentRefund } = props;

  const [searchText, setSearchText] = useState('');
  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [dataLoading, setDataLoading] = useState(false);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);
  const [showConsumerPaymentRefundModal, setShowConsumerPaymentRefundModal] = useState(false);
  const [selectedPaymentHistoryItem, setSelectedPaymentHistoryItem] = useState({
    id: 0,
    email: '',
    username: '',
    amount: 0,
    packageId: 0,
    paymentIntentId: '',
    refundedAmount: 0,
    numberOfTokens: 0,
  });

  const getPaginationQuery = ({
    pageChanged = false,
    paramsData = {},
    customMethod = null,
    queryData = {},
    payload = null,
  }) => {
    const params = { ...paramsData };
    const query = {
      page,
      itemPerPage,
      searchText,
      ...queryData,
    };

    return {
      isMobileView: browserState.isMobile,
      params,
      query,
      page,
      payload,
      itemPerPage,
      getMethod: customMethod || getPaymentHistories,
      pageChanged,
      data: paymentHistory.data,
    };
  };

  const onTokenEditModalSubmit = ({
    paymentHistoryId = 0,
    amount,
    isFullRefund = true,
    consumerId = 0,
  }) => {
    setDataLoading(true);

    const payload = {
      paymentHistoryId,
      amount,
      consumerId,
      isFullRefund,
    };

    return commonMethods
      .updatePaginationData(
        getPaginationQuery({
          payload,
          queryData: { sendList: !browserState.isMobile },
          customMethod: paymentRefund,
        })
      )
      .then(() => {
        setDataLoading(false);
        setShowConsumerPaymentRefundModal(false);
      })
      .catch(() => {
        setDataLoading(false);
        setShowConsumerPaymentRefundModal(false);
      });
  };

  const onRefundButtonClick = (selectedItem) => {
    setSelectedPaymentHistoryItem(selectedItem);
    setShowConsumerPaymentRefundModal(true);
  };

  const onLoadMoreClick = () => {
    setDataLoading(true);
    if (paymentHistory.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery({ pageChanged: true }));
  }, [page]);

  useDebounce(
    () => {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    },
    300,
    [itemPerPage, mobileLastPageLoaded, searchText]
  );

  useEffect(() => {
    if (!browserState.isMobile) {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    }
  }, [browserState.isMobile]);

  useEffect(() => {
    setDataLoading(false);
  }, [paymentHistory]);

  return (
    <MenuBarWrapper navBarIndex={2} headerTitle="Payments">
      <CreditRefundModal
        showModal={showConsumerPaymentRefundModal}
        paymentHistoryItem={selectedPaymentHistoryItem}
        dataSaving={dataLoading}
        onSubmitModal={onTokenEditModalSubmit}
        onCloseModal={() => {
          setShowConsumerPaymentRefundModal(false);
        }}
      />

      <Container className="container-uxl pb-3 px-1">
        <Row>
          <Col xs={12} sm={6} lg={8} className="mb-2">
            <h4 className="pt-2 pl-1">Payments History</h4>
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Search by Username"
              value={searchText}
              size="lg"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container className="container-uxl consumer-list-for-admin px-sm-0">
        <PaymentHistoryListTitle />

        {paymentHistory.data.map((item) => (
          <PaymentHistoryListInfo
            key={`${item.id}-${item.username}`}
            paymentHistoryItem={item}
            onRefund={onRefundButtonClick}
          />
        ))}

        {paymentHistory.totalItems > 10 && (
          <Col className="px-0 d-none d-md-block border-top bg-white">
            <CustomPagination
              itemPerPage={itemPerPage}
              page={page}
              setPage={setPage}
              setItemPerPage={setItemPerPage}
              totalPages={paymentHistory.totalPages}
              totalItems={paymentHistory.totalItems}
            />
          </Col>
        )}

        {paymentHistory.totalItems > paymentHistory.data.length && (
          <Col
            sm={11}
            className="bg-light justify-content-center justify-content-lg-start pt-4 d-sm-none"
          >
            <Button
              size="lg"
              variant="secondary"
              disabled={dataLoading}
              onClick={onLoadMoreClick}
              block
            >
              <DataSavingSpinner
                savingText="Loding"
                defaultText="Load More"
                dataSaving={dataLoading}
              />
            </Button>
          </Col>
        )}
      </Container>
    </MenuBarWrapper>
  );
}

CreditRefundContainer.propTypes = {
  paymentHistory: paymentHistoryListProps.isRequired,
  browserState: browserViewProps.isRequired,
  getPaymentHistories: PropTypes.func.isRequired,
  paymentRefund: PropTypes.func.isRequired,
};

CreditRefundContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  paymentHistory: state.payments.consumersPaymentHistoryForAdmin,
  browserState: state.browserData.view,
});

const mapDispatchToProps = {
  getPaymentHistories: getPaymentHistoriesForAdmin,
  paymentRefund: paymentRefundByAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditRefundContainer);
