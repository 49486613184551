import PropTypes from 'prop-types';
import { useState } from 'react';
import { Accordion, Col } from 'react-bootstrap';
import { categoryWithDeliverMediumListProps } from '../../customer-common-props';
import CategoryRow from './category-row';

export default function CustomerCategory(props) {
  const { categoryList, onUpdateCategorySubmit, customerId, onDeleteCategorySubmit } = props;

  const [clickedId, setClickedId] = useState(0);

  const onToggleCategoryRow = (id) => {
    if (id === clickedId) {
      setClickedId(0);
    } else {
      setClickedId(id);
    }
  };

  return (
    <Col md={12} className="pt-2 pb-4">
      <Accordion>
        {categoryList.data.map((item) => (
          <CategoryRow
            categoryItem={item}
            eventKey={item.id}
            key={item.id}
            onToggle={onToggleCategoryRow}
            clickedIndex={clickedId}
            onUpdateCategorySubmit={onUpdateCategorySubmit}
            onDeleteCategorySubmit={onDeleteCategorySubmit}
            customerId={customerId}
          />
        ))}
      </Accordion>
    </Col>
  );
}

CustomerCategory.propTypes = {
  categoryList: categoryWithDeliverMediumListProps.isRequired,
  onUpdateCategorySubmit: PropTypes.func.isRequired,
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onDeleteCategorySubmit: PropTypes.func.isRequired,
};

CustomerCategory.defaultProps = {};
