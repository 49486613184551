import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import 'react-pro-sidebar/dist/css/styles.css';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import info9000Logo from '../../assets/image/info9000Logo.png';
import constantValues, {
  OTP_TYPE_EMAIL,
  // eslint-disable-next-line prettier/prettier
  OTP_TYPE_SMS_PHONE_NUMBER
} from '../../helper/constant-values';
import routes from '../../helper/route-details';
import { userTokenVerification } from '../../redux/actions/auth-action';
import getUserInformationAndSubscriptionCategory from '../../redux/actions/consumer-landing-action';
import MenuBarWrapper from '../common/menu-bar-wrapper/menu-bar-wrapper';
import AuthWrapper from '../login-registration/auth-wrapper';
import { customerInformationProps } from '../login-registration/login-registration-common-props';
import OtpVerification from '../login-registration/otp-verfication/otp-verification';
import ChangePassword from './change-password/change-password';
import { userInformationProps } from './profile-common-props';
import EditProfile from './profile-edit/profile-edit';
import './profile-style.css';
import UpdateDefaultPayment from './profile/default-payment/update-default-payment';
import Profile from './profile/profile';

function ProfileContainer(props) {
  const { getCustomerInformation, customerInformation, userInfoState, updateUserInformation } =
    props;

  const { customerUrl } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [profile, setProfile] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [updateDefaultpayment, setUpdateDefaultPayment] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [verifySmsPhoneNumber, setVerifySmsPhoneNumber] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [updatedSmsPhoneNumber, setUpdatedSmsPhoneNumber] = useState('');
  const [focusEmail, setFocusEmail] = useState(false);
  const [focusSmsPhoneNumber, setFocusSmsPhoneNumber] = useState(false);
  const [backFromVerification, setBackFromVerification] = useState(false);

  const verificationPageLogo =
    userInfoState.type === constantValues.ADMIN
      ? info9000Logo
      : customerInformation.customerDetails.logoUrl;

  const pushHistoryDefaultPaymentUpdatePage = () => {
    let url = '';
    if (userInfoState.type === constantValues.CONSUMER) {
      url = routes.consumer.profile.updateDefaultPayment(customerUrl);
    }

    history.push(url, { defaultPaymentUpdatePage: true });
  };

  const pushHistoryProfilePage = () => {
    let url = '';
    if (userInfoState.type === constantValues.CONSUMER) {
      url = routes.consumer.profile.default(customerUrl);
    } else if (userInfoState.type === constantValues.CUSTOMER) {
      url = routes.customer.profile.default(customerUrl);
    } else {
      url = routes.admin.profile.default();
    }

    history.push(url);
  };

  const pushHistoryProfileEditPage = () => {
    let url = '';
    if (userInfoState.type === constantValues.CONSUMER) {
      url = routes.consumer.profile.edit(customerUrl);
    } else if (userInfoState.type === constantValues.CUSTOMER) {
      url = routes.customer.profile.edit(customerUrl);
    } else {
      url = routes.admin.profile.edit();
    }

    history.push(url, { editPage: true });
  };

  const pushHistoryProfileEditVerificationPage = (states) => {
    let url = '';
    if (userInfoState.type === constantValues.CONSUMER) {
      url = routes.consumer.profile.verification(customerUrl);
    } else if (userInfoState.type === constantValues.CUSTOMER) {
      url = routes.customer.profile.verification(customerUrl);
    } else {
      url = routes.admin.profile.verification();
    }

    history.push(url, { verificationPage: true, ...states });
  };

  const pushHistoryProfileChangePasswordPage = () => {
    let url = '';
    if (userInfoState.type === constantValues.CONSUMER) {
      url = routes.consumer.profile.changePassword(customerUrl);
    } else if (userInfoState.type === constantValues.CUSTOMER) {
      url = routes.customer.profile.changePassword(customerUrl);
    } else {
      url = routes.admin.profile.changePassword();
    }

    history.push(url, { changePasswordPage: true });
  };

  const removeVerificationFromHistory = (state = null) => {
    let url = '';
    if (userInfoState.type === constantValues.CONSUMER) {
      url = routes.consumer.profile.verification(customerUrl);
    } else if (userInfoState.type === constantValues.CUSTOMER) {
      url = routes.customer.profile.verification(customerUrl);
    } else {
      url = routes.admin.profile.verification();
    }

    history.replace(url, state);
  };

  const goToUserProfile = (pushInHistory = true) => {
    if (pushInHistory) {
      pushHistoryProfilePage();
    } else {
      setEditProfile(false);
      setChangePassword(false);
      setProfile(true);
      setBackFromVerification(false);
      setFocusEmail(false);
      setFocusSmsPhoneNumber(false);
      setUpdatedEmail('');
      setUpdatedSmsPhoneNumber('');
      setVerifyEmail(false);
      setVerifySmsPhoneNumber(false);
      setUpdateDefaultPayment(false);
    }
  };

  const goToEditUserProfile = (pushInHistory = true) => {
    if (pushInHistory) {
      pushHistoryProfileEditPage();
    } else {
      setProfile(false);
      setEditProfile(true);
      setVerifyEmail(false);
      setVerifySmsPhoneNumber(false);
      setUpdateDefaultPayment(false);
    }
  };

  const goToUpdateDefaultPayment = (pushInHistory = true) => {
    if (pushInHistory) {
      pushHistoryDefaultPaymentUpdatePage();
    } else {
      setProfile(false);
      setEditProfile(false);
      setVerifyEmail(false);
      setVerifySmsPhoneNumber(false);
      setUpdateDefaultPayment(true);
    }
  };

  const onUpdatingProfile = (
    { needEmailVerification, needSmsPhoneNumberVerification, email, smsPhoneNumber },
    pushInHistory = true
    // eslint-disable-next-line consistent-return
  ) => {
    if (pushInHistory && !needEmailVerification && !needSmsPhoneNumberVerification) {
      pushHistoryProfilePage();
    } else {
      if (pushInHistory) {
        return pushHistoryProfileEditVerificationPage({
          needEmailVerification,
          needSmsPhoneNumberVerification,
          email,
          smsPhoneNumber,
        });
      }
      if (needEmailVerification) {
        setVerifyEmail(true);
      }
      if (needSmsPhoneNumberVerification) {
        setVerifySmsPhoneNumber(true);
      }

      setUpdatedEmail(email);
      setUpdatedSmsPhoneNumber(smsPhoneNumber);
      setEditProfile(false);
      setFocusEmail(false);
      setFocusSmsPhoneNumber(false);
    }
  };

  const onEmailVerification = (pushInHistory = true) => {
    if (pushInHistory && !verifySmsPhoneNumber) {
      removeVerificationFromHistory();
      pushHistoryProfilePage();
    } else {
      removeVerificationFromHistory({ ...location.state, needEmailVerification: null });
      setVerifyEmail(false);
    }
  };

  const onSmsPhoneNumberVerification = (pushInHistory = true) => {
    if (pushInHistory) {
      removeVerificationFromHistory();
      pushHistoryProfilePage();
    }
  };

  const goToUserChangePassword = (pushInHistory = true) => {
    if (pushInHistory) {
      pushHistoryProfileChangePasswordPage();
    } else {
      setChangePassword(true);
      setProfile(false);
    }
  };

  const onBackButtonClick = () => {
    if (verifyEmail) setFocusEmail(true);
    else if (verifySmsPhoneNumber) setFocusSmsPhoneNumber(true);

    setBackFromVerification(true);
    goToEditUserProfile();
  };

  useEffect(() => {
    if (location.pathname.match('/profile/default-payment/update') !== null) {
      goToUpdateDefaultPayment(false);
    } else if (!location.state) {
      if (userInfoState.type !== constantValues.ADMIN) getCustomerInformation({ customerUrl });
      goToUserProfile(false);
      updateUserInformation();
    } else if (location.state.editPage) {
      goToEditUserProfile(false);
    } else if (location.state.changePasswordPage) {
      goToUserChangePassword(false);
    } else if (location.state.verificationPage) {
      onUpdatingProfile(location.state, false);
    } else if (location.state.defaultPaymentUpdatePage) {
      goToUpdateDefaultPayment(false);
    }
  }, [location.state]);

  return (
    <>
      {!verifyEmail && !verifySmsPhoneNumber && (
        <MenuBarWrapper headerTitle="Profile">
          <Container fluid>
            {profile && (
              <Profile
                goToEditUserProfile={goToEditUserProfile}
                goToUserChangePassword={goToUserChangePassword}
                goToUpdateDefaultPayment={goToUpdateDefaultPayment}
                goToUserProfile={goToUserProfile}
              />
            )}

            {editProfile && (
              <EditProfile
                onUpdatingProfile={onUpdatingProfile}
                focusEmail={focusEmail}
                focusSmsPhoneNumber={focusSmsPhoneNumber}
                backFromVerification={backFromVerification}
                onBackArrowButtonClick={goToUserProfile}
              />
            )}

            {changePassword && (
              <ChangePassword
                goToUserProfile={goToUserProfile}
                onBackArrowButtonClick={goToUserProfile}
                isAdmin={userInfoState.type === constantValues.ADMIN}
              />
            )}

            {updateDefaultpayment && <UpdateDefaultPayment goToUserProfile={goToUserProfile} />}
          </Container>
        </MenuBarWrapper>
      )}

      {verifyEmail && (
        <AuthWrapper
          pageHeader="Verify Your Email"
          logo={verificationPageLogo}
          onBackButtonClick={onBackButtonClick}
          isRegistration
        >
          <OtpVerification
            loginUsername={userInfoState.username}
            loginEmail={updatedEmail}
            onVerification={onEmailVerification}
            otpType={OTP_TYPE_EMAIL}
          />
        </AuthWrapper>
      )}

      {!verifyEmail && verifySmsPhoneNumber && (
        <AuthWrapper
          pageHeader="Verify Your Phone Number"
          logo={verificationPageLogo}
          onBackButtonClick={onBackButtonClick}
          isRegistration
        >
          <OtpVerification
            loginUsername={userInfoState.username}
            loginEmail={updatedEmail}
            loginSmsPhoneNumber={updatedSmsPhoneNumber}
            onVerification={onSmsPhoneNumberVerification}
            verifySmsPhoneNumber
            otpType={OTP_TYPE_SMS_PHONE_NUMBER}
          />
        </AuthWrapper>
      )}
    </>
  );
}

ProfileContainer.propTypes = {
  getCustomerInformation: PropTypes.func.isRequired,
  customerInformation: customerInformationProps.isRequired,
  userInfoState: userInformationProps.isRequired,
  updateUserInformation: PropTypes.func.isRequired,
};

ProfileContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  customerInformation: state.consumerLanding,
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  getCustomerInformation: getUserInformationAndSubscriptionCategory,
  updateUserInformation: userTokenVerification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
