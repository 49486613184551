import browserStorage from '../../helper/browser-storage';
import commonMethods from '../../helper/common-methods';
import { PLEASE_TRY_AGAIN, USER_DATA } from '../../helper/constant-values';
import { showNotification } from '../../helper/notifications';
import authService from '../../services/auth';
import {
  CONSUMER_SIGN_UP,
  CONSUMER_SIGN_UP_INIT,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_INIT,
  LOGIN_INIT,
  LOG_OUT,
  OTP_VERIFICATION,
  // eslint-disable-next-line prettier/prettier
  USER_TOKEN_VERIFICATION
} from '../types';

export function adminLogin(data, query) {
  return () =>
    authService
      .adminLogin(data, query)
      .then(() => {
        browserStorage.setUserData(USER_DATA, { customerUrl: '' });
        showNotification({
          type: 'success',
          text: 'Login is successful',
        });
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          text: 'Please enter correct admin username and password.',
        });

        return Promise.reject(error);
      });
}

export function consumerSignUp(data, query, signupCurrentValues) {
  return (dispatch) => {
    dispatch({
      type: CONSUMER_SIGN_UP_INIT,
      data: signupCurrentValues,
    });

    return authService
      .consumerSignUp(data, query)
      .then((response) => {
        dispatch({
          type: CONSUMER_SIGN_UP,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Registration is successful',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
  };
}

export function customerConsumerLogin(data, query, loginCurrentValues) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_INIT,
      data: loginCurrentValues,
    });

    return authService
      .consumerCustomerLogin(data, query)
      .then((response) => {
        const { customerUrl } = response.data;
        browserStorage.setUserData(USER_DATA, { customerUrl });

        showNotification({
          type: 'success',
          text: 'Login is successful',
        });

        return Promise.resolve(response.data);
      })
      .catch(commonMethods.actionDefaultErrorResponse);
  };
}

export function logout() {
  return (dispatch) =>
    authService
      .logout()
      .then((response) => {
        dispatch({
          type: LOG_OUT,
          data: response.data,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Some error occurred',
        });
      });
}

export function otpVerification(data) {
  return (dispatch) =>
    authService
      .otpVerify(data)
      .then((response) => {
        dispatch({
          type: OTP_VERIFICATION,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'OTP verification is successful.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function otpResend(data) {
  return () =>
    authService
      .otpResend(data)
      .then(() => {
        showNotification({
          type: 'success',
          text: 'Verification Code resend is successful',
        });

        return Promise.resolve();
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: PLEASE_TRY_AGAIN,
        });
      });
}

export function otpValidityCheck(query) {
  return (dispatch) =>
    authService
      .otpValidityCheck(query)
      .then((response) => {
        dispatch({
          type: OTP_VERIFICATION,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Verification Code is valid',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function userTokenVerification() {
  return (dispatch) =>
    authService
      .tokenVerify()
      .then((response) => {
        const { data } = response;

        dispatch({
          type: USER_TOKEN_VERIFICATION,
          data,
        });

        return Promise.resolve(data);
      })
      .catch((error) => Promise.reject(error));
}

export function forgotPassword(data, query, currentValues) {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_INIT,
      data: currentValues,
    });

    return authService
      .forgotPassword(data, query)
      .then((response) => {
        dispatch({
          type: FORGOT_PASSWORD,
          data: response.data,
        });

        return Promise.resolve(data);
      })
      .catch(commonMethods.actionDefaultErrorResponse);
  };
}

export function changePassword(data, query) {
  return () =>
    authService
      .changePassword(data, query)
      .then(() => {
        showNotification({
          type: 'success',
          text: 'Password changed successfully',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}
