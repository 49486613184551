import { callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const subscriptionService = {
  subscribe: async (data, params) => {
    const { method, endpoint } = endpoints.subscribeToCategory;

    const url = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url, data });

    return response;
  },

  unsubscribe: async (params) => {
    const { method, endpoint } = endpoints.unsubscribeToCategory;

    const url = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url });

    return response;
  },
};

export default subscriptionService;
