import { Col, Row } from 'react-bootstrap';
import { singlePackageProps } from '../customer-package-common-props';

export default function PackageInfo(props) {
  const { packageItem } = props;

  return (
    <>
      <Row className="mx-sm-2  py-2 py-sm-3 mb-4 mb-sm-0 bg-white rounded-lg">
        <Col sm={3} xs={12} className="d-flex">
          <p className="my-auto">
            <span className="d-sm-none">Package Name: </span> {packageItem.name}
          </p>
        </Col>

        <Col sm={3} xs={12} className="d-flex justify-content-start justify-content-sm-end">
          <p className="my-auto">
            <span className="d-sm-none">Tokens: </span> {packageItem.numberOfTokens} tokens
          </p>
        </Col>

        <Col sm={3} xs={12} className="d-flex justify-content-start justify-content-sm-end">
          <p className="my-auto">
            <span className="d-sm-none">Amount: </span>${packageItem.price}
          </p>
        </Col>
        <Col sm={3} xs={12} className="d-flex justify-content-start justify-content-sm-end">
          <p className="my-auto text-right">
            <span className="d-sm-none">Auto-Purchase Amount: </span>
            {packageItem.isAutoRenewable
              ? `$${packageItem.autoRenewPrice}`
              : 'Not auto purchasable'}
          </p>
        </Col>
      </Row>
    </>
  );
}

PackageInfo.propTypes = {
  packageItem: singlePackageProps.isRequired,
};

PackageInfo.defaultProps = {};
