import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import getCustomerInformation from '../../../../redux/actions/consumer-landing-action';
import { consumerCategoryProps } from '../../../login-registration/login-registration-common-props';

function CategoryDropDown(props) {
  const { consumerCategory, getCustomerInfo } = props;

  const { customerUrl } = useParams();

  useEffect(() => {
    getCustomerInfo({ customerUrl });
  }, []);

  return (
    <>
      <option value="">Select Astrological Sign</option>
      {consumerCategory.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </>
  );
}

CategoryDropDown.propTypes = {
  consumerCategory: consumerCategoryProps.isRequired,
  getCustomerInfo: PropTypes.func.isRequired,
};

CategoryDropDown.defaultProps = {};

const mapStateToProps = (state) => ({
  consumerCategory: state.consumerLanding.customerDetails.subscriptionCategory,
});

const mapDispatchToProps = {
  getCustomerInfo: getCustomerInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDropDown);
