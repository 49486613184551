import { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { grossSalesArrayProps } from '../revenue-common-props';
import SalesTableItem from './sales-table-item';

export default function SalesTableMobile({ salesData }) {
  const [clickedIndex, setClickedIndex] = useState(0);

  const onHandleCollapse = (propIndex) => {
    if (clickedIndex === propIndex) setClickedIndex(0);
    else setClickedIndex(propIndex);
  };

  return (
    <Accordion defaultActiveKey="0">
      {salesData.map((item, index) => (
        <SalesTableItem
          key={item.id}
          item={item}
          clickedIndex={clickedIndex}
          dataIndex={index + 1}
          onHandleCollapse={onHandleCollapse}
        />
      ))}
    </Accordion>
  );
}

SalesTableMobile.propTypes = {
  salesData: grossSalesArrayProps.isRequired,
};

SalesTableMobile.defaultProps = {};
