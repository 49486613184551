import commonMethods from '../../helper/common-methods';
import {
  ADD_CONSUMER_PLAYED_AUDIO,
  AUDIO_COPY_BY_CUSTOMER,
  AUDIO_DELETE_BY_CUSTOMER,
  AUDIO_FOR_CONSUMER,
  AUDIO_FOR_CUSTOMER,
  AUDIO_UPDATE_BY_CUSTOMER,
  INIT_CONSUMER_PLAYED_AUDIO,
  // eslint-disable-next-line prettier/prettier
  REMOVE_CONSUMER_PLAYED_AUDIO
} from '../types';

const initialState = {
  audiosForConsumer: {
    totalItems: 0,
    data: [],
    totalPages: 1,
    currentPage: 1,
  },
  audiosForCustomer: {
    totalItems: 0,
    data: [],
    totalPages: 1,
    currentPage: 1,
  },
  consumerPlayedAudio: [],
};

const audioReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUDIO_FOR_CONSUMER: {
      return commonMethods.paginationStateUpdate(state, action, 'audiosForConsumer');
    }

    case AUDIO_FOR_CUSTOMER: {
      return commonMethods.paginationStateUpdate(state, action, 'audiosForCustomer');
    }

    case ADD_CONSUMER_PLAYED_AUDIO:
      return {
        ...state,
        consumerPlayedAudio: [...state.consumerPlayedAudio, action.data],
      };

    case REMOVE_CONSUMER_PLAYED_AUDIO:
      return {
        ...state,
        consumerPlayedAudio: state.consumerPlayedAudio.filter((item) => item !== action.data),
      };

    case INIT_CONSUMER_PLAYED_AUDIO:
      return {
        ...state,
        consumerPlayedAudio: [],
      };

    case AUDIO_DELETE_BY_CUSTOMER: {
      return commonMethods.deletePaginationState(state, action, 'audiosForCustomer');
    }

    case AUDIO_UPDATE_BY_CUSTOMER: {
      return commonMethods.updateOnPaginationState(state, action, 'audiosForCustomer');
    }

    case AUDIO_COPY_BY_CUSTOMER: {
      return commonMethods.copyPaginationState(state, action, 'audiosForCustomer');
    }

    default:
      return state;
  }
};

export default audioReducer;
