import { callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const revenueReportService = {
  getCustomerRevenue: async (params, query) => {
    const { method, endpoint } = endpoints.getCustomerRevenue;

    const url = utils.buildUrl(baseUrl + endpoint, params, query);

    const response = await callAxiosWithAuth({ method, url });
    return response;
  },
  getCustomerUsage: async (params, query) => {
    const { method, endpoint } = endpoints.getCustomerUsage;

    const url = utils.buildUrl(baseUrl + endpoint, params, query);

    const response = await callAxiosWithAuth({ method, url });
    return response;
  },
  getCustomerGrossSales: async (params, query) => {
    const { method, endpoint } = endpoints.getCustomerGrossSales;

    const url = utils.buildUrl(baseUrl + endpoint, params, query);

    const response = await callAxiosWithAuth({ method, url });
    return response;
  },
};

export default revenueReportService;
