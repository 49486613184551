import { FieldArray, Formik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import commonMethods from '../../../../../helper/common-methods';
import {
  DEFAULT_DATE_TIME_WITHOUT_SECOND_FORMAT,
  // eslint-disable-next-line prettier/prettier
  TABLE_DATE_TIME_FORMAT
} from '../../../../../helper/constant-values';
import { AddIcon, BackIcon } from '../../../../../helper/icons/common-icons';
import routes from '../../../../../helper/route-details';
import { shortTextAddValidationSchema } from '../../../../../helper/schema-objects';
import { addShortTextByCustomer } from '../../../../../redux/actions/short-text-action';
import { userInformationProps } from '../../../../common/common-props';
import DataSavingSpinner from '../../../../common/loader-spinner/data-saving-spinner';
import WarningModal from '../../../../common/modals/warning-success-modal';
import ShortTextAddRow from './short-text-add-row';

function AddShortText(props) {
  const { userInfoState, addShortTextForCustomer } = props;

  const [dataSaving, setDataSaving] = useState(false);
  const [showBackButtonModal, setShowBackButtonModal] = useState(false);

  const { customerUrl, categoryId } = useParams();
  const history = useHistory();

  const initialValues = {
    shortTextList: [
      {
        id: 1,
        messageName: '',
        textContent: '',
        disburseDateTime: '',
      },
    ],
  };

  const backButtonModalConfirm = () => {
    history.push(routes.customer.category.selectedCategory(customerUrl, categoryId), {
      viewShortTextPage: true,
    });
  };

  const onHandleBack = () => {
    setShowBackButtonModal(true);
  };

  const getDateTimeForApi = (dateTime) =>
    commonMethods.getDateFromDateTime(
      dateTime,
      DEFAULT_DATE_TIME_WITHOUT_SECOND_FORMAT,
      TABLE_DATE_TIME_FORMAT
    );

  const onSubmit = (values) => {
    const shortTextList = _.map(values.shortTextList, (item) => {
      _.unset(item, 'id');

      return {
        ...item,
        disburseDateTime: getDateTimeForApi(item.disburseDateTime),
      };
    });

    const data = {
      shortTextList,
      consumerCategoryId: categoryId,
    };

    const params = {
      customerUrl,
    };

    setDataSaving(true);

    addShortTextForCustomer(data, params)
      .then(() => {
        setDataSaving(false);
        backButtonModalConfirm();
      })
      .catch(() => {
        setDataSaving(false);
      });
  };

  return (
    <>
      <WarningModal
        heading="Are you sure?"
        descriptionList={[
          'Are you sure, you want to move from the current page?',
          'All the unsaved changes will be lost.',
        ]}
        leftBtnText="No"
        rightBtnText="Yes"
        showModal={showBackButtonModal}
        onHideModal={() => {
          setShowBackButtonModal(false);
        }}
        onModalSubmit={backButtonModalConfirm}
      />

      <Container className="container-uxl">
        <Row>
          <Col>
            <BackIcon
              className="d-inline mb-2 mr-2 back-arrow-button cursor-pointer"
              width="2.3em"
              height="2.3em"
              onClick={onHandleBack}
            />
            <h4 className="pt-2 pl-1 d-inline">Add New Short Text</h4>
          </Col>
        </Row>

        <Container className="container-uxl rounded-corner bg-white">
          <Row className="mt-4 pt-3 pb-3">
            <Col className="border-bottom">
              <h5>Write Short Text</h5>
            </Col>
          </Row>

          <Formik
            initialValues={initialValues}
            validationSchema={shortTextAddValidationSchema(userInfoState.timeZone)}
            onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
            enableReinitialize
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <FieldArray name="shortTextList">
                  {(fieldArray) => (
                    <>
                      {formik.values.shortTextList.map((item, index) => (
                        <div key={item.id} className="border-bottom pb-3">
                          <ShortTextAddRow
                            formik={formik}
                            fieldArray={fieldArray}
                            index={index}
                            timeZone={userInfoState.timeZone}
                          />
                        </div>
                      ))}

                      <Row className="justify-content-end mb-2 pt-4 pb-4">
                        <Col sm={6} md={5} lg={3}>
                          <Button
                            size="lg"
                            variant="primary"
                            block
                            onClick={() => {
                              fieldArray.push({
                                id: uuidv4(),
                                messageName: '',
                                textContent: '',
                                disburseDateTime: '',
                              });
                            }}
                          >
                            Add new
                            <AddIcon width="1.8rem" height="1.8rem" className="mt-n1 ml-2" />
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </FieldArray>

                <Row className="justify-content-end mb-2 pt-4 pb-4">
                  <Col sm={6} md={5} lg={3}>
                    <Button
                      size="lg"
                      variant="outline-primary"
                      block
                      disabled={dataSaving}
                      onClick={onHandleBack}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col sm={6} md={5} lg={3} className="pt-3 pt-sm-0">
                    <Button
                      size="lg"
                      onClick={formik.handleSubmit}
                      type="submit"
                      block
                      disabled={
                        dataSaving ||
                        formik.errors.shortTextList !== undefined ||
                        formik.values.shortTextList.length === 0
                      }
                    >
                      <DataSavingSpinner
                        savingText="Processing"
                        defaultText="Save all"
                        dataSaving={dataSaving}
                      />
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Container>
    </>
  );
}

AddShortText.propTypes = {
  addShortTextForCustomer: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
};

AddShortText.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  addShortTextForCustomer: addShortTextByCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShortText);
