import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import commonMethods from '../../../helper/common-methods';
import { getPackagesForCustomer } from '../../../redux/actions/package';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import MenuBarWrapper from '../../common/menu-bar-wrapper/menu-bar-wrapper';
import CustomPagination from '../../common/pagination/custom-pagination';
import { browserViewProps, packageProps } from './customer-package-common-props';
import PackageInfoTitle from './packageinfo/package-info-title';
import PackageInfo from './packageinfo/packageInfo';
import './packages.css';

function CustomerPackages(props) {
  const { packageData, browserState, getPackages } = props;
  const { packagesForCustomer } = packageData;

  const { customerUrl } = useParams();

  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [dataLoading, setDataLoading] = useState(false);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);

  const onLoadMoreClick = () => {
    setDataLoading(true);
    if (packagesForCustomer.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  const getPaginationQuery = (pageChanged = false) => {
    const params = { customerUrl };
    const query = { page, itemPerPage };

    return {
      isMobileView: browserState.isMobile,
      params,
      query,
      page,
      itemPerPage,
      getMethod: getPackages,
      pageChanged,
      data: packagesForCustomer.data,
    };
  };

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery(true));
  }, [page]);

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery());
  }, [itemPerPage, mobileLastPageLoaded]);

  useEffect(() => {
    if (!browserState.isMobile) {
      commonMethods.updatePaginationData(getPaginationQuery());
    }
  }, [browserState.isMobile]);

  useEffect(() => {
    setDataLoading(false);
  }, [packageData.packagesForCustomer]);

  return (
    <MenuBarWrapper navBarIndex={2} headerTitle="Packages">
      <div className="px-2">
        <div className="mt-0 mt-sm-n4 py-2 py-sm-4">
          <span className="h4">All Packages</span>
        </div>
        <Container className="customer-package p-2" fluid>
          <PackageInfoTitle />
          {packagesForCustomer.data.map((item) => (
            <PackageInfo key={item.id} packageItem={item} />
          ))}

          {packagesForCustomer.totalItems >= 10 && (
            <CustomPagination
              itemPerPage={itemPerPage}
              page={page}
              setPage={setPage}
              setItemPerPage={setItemPerPage}
              totalPages={packagesForCustomer.totalPages}
              totalItems={packagesForCustomer.totalItems}
            />
          )}

          {packagesForCustomer.totalItems > packagesForCustomer.data.length && (
            <Row className="justify-content-center justify-content-lg-start pt-4 d-sm-none">
              <Col sm={11}>
                <Button size="lg" block disabled={dataLoading} onClick={onLoadMoreClick}>
                  <DataSavingSpinner
                    savingText="Loding"
                    defaultText="Load More"
                    dataSaving={dataLoading}
                  />
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </MenuBarWrapper>
  );
}

CustomerPackages.propTypes = {
  packageData: packageProps.isRequired,
  getPackages: PropTypes.func.isRequired,
  browserState: browserViewProps.isRequired,
};

CustomerPackages.defaultProps = {};

const mapStateToProps = (state) => ({
  packageData: state.packages,
  browserState: state.browserData.view,
});

const mapDispatchToProps = {
  getPackages: getPackagesForCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPackages);
