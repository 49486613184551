import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router';
import commonMethods from '../../../../helper/common-methods';
import {
  DEFAULT_DATE_TIME_WITHOUT_SECOND_FORMAT,
  // eslint-disable-next-line prettier/prettier
  TABLE_DATE_TIME_FORMAT
} from '../../../../helper/constant-values';
import { SmsCircleIcon } from '../../../../helper/icons/common-icons';
import WarningModal from '../../../common/modals/warning-success-modal';
import ActionButtons from '../category-common-components/action-buttons';
import ActionButtonsMobile from '../category-common-components/action-buttons-mobile';
import { singleShortTextFileProps } from '../category-common-props';
import ShortTextModifyModal from './short-text-modify-modal';
import ShortTextViewModal from './short-text-view-modal';

export default function ShortTextRow(props) {
  const { shortTextItem, timeZone, onDeleteShortText, onEditShortText, onCopyShortText } = props;

  const [showShortTextViewModal, setShowShortTextViewModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);

  const { customerUrl } = useParams();

  const onClickEditButton = () => {
    setShowEditModal(true);
  };

  const onClickCopyButton = () => {
    setShowCopyModal(true);
  };

  const onClickDeleteButton = () => {
    setShowDeleteModal(true);
  };

  const onShowShortTextViewModal = () => {
    setShowShortTextViewModal(true);
  };

  const onCloseShortTextViewModal = () => {
    setShowShortTextViewModal(false);
  };

  const onEditShortTextModalClose = () => {
    setShowEditModal(false);
  };

  const onCopyShortTextModalClose = () => {
    setShowCopyModal(false);
  };

  const onDeleteShortTextModalClose = () => {
    setShowDeleteModal(false);
  };

  const getDateTimeForApi = (dateTime) =>
    commonMethods.getDateFromDateTime(
      dateTime,
      DEFAULT_DATE_TIME_WITHOUT_SECOND_FORMAT,
      TABLE_DATE_TIME_FORMAT
    );

  const onConfirmDelete = () => {
    setDataLoading(true);

    onDeleteShortText(shortTextItem.id)
      .then(() => {
        setDataLoading(false);
        setShowDeleteModal(false);
      })
      .catch(() => {
        setDataLoading(false);
      });
  };

  const onEditShortTextSubmit = (values) => {
    const payload = {
      ...values,
      disburseDateTime: getDateTimeForApi(values.disburseDateTime),
    };

    const params = {
      customerUrl,
      shortTextId: +shortTextItem.id,
    };

    setDataLoading(true);

    return onEditShortText(payload, params)
      .then(() => {
        setDataLoading(false);
        onEditShortTextModalClose();
      })
      .catch(() => {
        setDataLoading(false);
        onEditShortTextModalClose();
      });
  };

  const onCopyShortTextSubmit = (values) => {
    const payload = {
      ..._.omit(values, ['textContent']),
      disburseDateTime: getDateTimeForApi(values.disburseDateTime),
      shortTextId: +shortTextItem.id,
    };

    const params = {
      customerUrl,
    };

    setDataLoading(true);

    return onCopyShortText(payload, params)
      .then(() => {
        setDataLoading(false);
        onCopyShortTextModalClose();
      })
      .catch(() => {
        setDataLoading(false);
        onCopyShortTextModalClose();
      });
  };

  const getLocalDateTime = (utcDateTime) =>
    commonMethods.getDateTimeFromUtc(timeZone, TABLE_DATE_TIME_FORMAT, utcDateTime);

  return (
    <>
      <ShortTextViewModal
        headerTitle={shortTextItem.messageName}
        textContent={shortTextItem.textContent}
        scheduledDateTime={getLocalDateTime(shortTextItem.disburseDateTime)}
        showModal={showShortTextViewModal}
        onCloseModal={onCloseShortTextViewModal}
        onEdit={onClickEditButton}
        onCopy={onClickCopyButton}
        onDelete={onClickDeleteButton}
      />

      <WarningModal
        heading="Are you sure?"
        description={`Are you sure, you want to delete the short text named ${shortTextItem.messageName}?`}
        leftBtnText="No"
        rightBtnText="Yes"
        showModal={showDeleteModal}
        onHideModal={onDeleteShortTextModalClose}
        processing={dataLoading}
        onModalSubmit={onConfirmDelete}
      />

      <ShortTextModifyModal
        headerTitle="Edit Short Text"
        timeZone={timeZone}
        submitButonText="Save Changes"
        showModal={showEditModal}
        onCloseModal={onEditShortTextModalClose}
        shortTextItem={shortTextItem}
        dataSaving={dataLoading}
        onSubmit={onEditShortTextSubmit}
      />

      <ShortTextModifyModal
        headerTitle="Copy Short Text"
        timeZone={timeZone}
        submitButonText="Copy Text File"
        showModal={showCopyModal}
        onCloseModal={onCopyShortTextModalClose}
        shortTextItem={shortTextItem}
        dataSaving={dataLoading}
        onSubmit={onCopyShortTextSubmit}
        copyShortText
      />

      <Col key={shortTextItem.id} sm={12} className="py-4 px-2 px-sm-3 border-top text-info">
        <Row className="d-none d-lg-flex">
          <Col lg={3} xl={3} className="text-truncate">
            <SmsCircleIcon
              width="2.5em"
              height="2.5em"
              className="text-primary cursor-pointer mr-2 custom-primary-btn"
              onClick={onShowShortTextViewModal}
            />
            <OverlayTrigger
              transition={false}
              placement="bottom-start"
              overlay={<Tooltip>{shortTextItem.messageName}</Tooltip>}
            >
              <span className="pt-2">{shortTextItem.messageName}</span>
            </OverlayTrigger>
          </Col>
          <Col lg={4} xl={4} className="d-flex justify-content-start pt-2">
            <OverlayTrigger
              transition={false}
              placement="bottom"
              overlay={<Tooltip>{shortTextItem.textContent}</Tooltip>}
            >
              <span className="flex-child-short-text text-truncate">
                {shortTextItem.textContent}
              </span>
            </OverlayTrigger>
          </Col>
          <Col lg={2} xl={3} className="pt-2">
            <span>{getLocalDateTime(shortTextItem.disburseDateTime)}</span>
          </Col>

          <Col lg={3} xl={2} className="d-none d-lg-flex justify-content-end mb-auto">
            <ActionButtons
              onHandleEdit={onClickEditButton}
              onHandleCopy={onClickCopyButton}
              onHandleDelete={onClickDeleteButton}
            />
          </Col>
        </Row>

        <Row className="d-none d-sm-flex d-lg-none">
          <Col sm={4} className="text-truncate">
            <SmsCircleIcon
              width="2.5em"
              height="2.5em"
              className="text-primary cursor-pointer mr-2 custom-primary-btn"
              onClick={onShowShortTextViewModal}
            />
            <OverlayTrigger
              transition={false}
              placement="bottom"
              overlay={<Tooltip>{shortTextItem.messageName}</Tooltip>}
            >
              <span className="pt-2">{shortTextItem.messageName}</span>
            </OverlayTrigger>
          </Col>
          <Col sm={3} className="pl-0 pt-2 text-truncate">
            <OverlayTrigger
              transition={false}
              placement="bottom"
              overlay={<Tooltip>{shortTextItem.textContent}</Tooltip>}
            >
              <span>{shortTextItem.textContent}</span>
            </OverlayTrigger>
          </Col>
          <Col sm={3} className="pt-2 px-4">
            <span>{getLocalDateTime(shortTextItem.disburseDateTime)}</span>
          </Col>

          <Col sm={2} className="d-lg-none d-flex justify-content-end pt-2">
            <ActionButtonsMobile
              onHandleEdit={onClickEditButton}
              onHandleCopy={onClickCopyButton}
              onHandleDelete={onClickDeleteButton}
            />
          </Col>
        </Row>

        <Row className="d-flex d-sm-none mobile-view-font">
          <Col xs={2}>
            <SmsCircleIcon
              width="2.5em"
              height="2.5em"
              className="text-primary cursor-pointer mr-2 custom-primary-btn"
              onClick={onShowShortTextViewModal}
            />
          </Col>

          <Col className="px-0" xs={8}>
            <Row>
              <Col xs={6} className="text-truncate">
                <OverlayTrigger
                  transition={false}
                  placement="bottom"
                  overlay={<Tooltip>{shortTextItem.messageName}</Tooltip>}
                >
                  <span className="font-weight-bold">{shortTextItem.messageName}</span>
                </OverlayTrigger>
              </Col>

              <Col className="px-4">
                <span>{getLocalDateTime(shortTextItem.disburseDateTime)}</span>
              </Col>
            </Row>
            <Row>
              <Col className="pl-3 text-truncate">
                <OverlayTrigger
                  transition={false}
                  placement="bottom"
                  overlay={<Tooltip>{shortTextItem.textContent}</Tooltip>}
                >
                  <span>{shortTextItem.textContent}</span>
                </OverlayTrigger>
              </Col>
            </Row>
          </Col>

          <Col xs={2}>
            <ActionButtonsMobile
              onHandleEdit={onClickEditButton}
              onHandleCopy={onClickCopyButton}
              onHandleDelete={onClickDeleteButton}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
}

ShortTextRow.propTypes = {
  shortTextItem: singleShortTextFileProps.isRequired,
  timeZone: PropTypes.string.isRequired,
  onDeleteShortText: PropTypes.func.isRequired,
  onEditShortText: PropTypes.func.isRequired,
  onCopyShortText: PropTypes.func.isRequired,
};

ShortTextRow.defaultProps = {};
