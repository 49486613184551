import PropTypes from 'prop-types';

export const singleCustomerProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const singleCategoryWithDeliverMediumProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  logoSvg: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  sortOrder: PropTypes.number.isRequired,
  customerId: PropTypes.number.isRequired,
  deliveryMedium: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isDisable: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      numberOfTokens: PropTypes.number.isRequired,
    })
  ),
});

export const customerListProps = PropTypes.shape({
  totalItems: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(singleCustomerProps.isRequired).isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
});

export const categoryWithDeliverMediumListProps = PropTypes.shape({
  totalItems: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(singleCategoryWithDeliverMediumProps.isRequired).isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
});

export const customerDetailsProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  thresholdToken: PropTypes.number.isRequired,
  details: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
});

export const singlePackageProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  numberOfTokens: PropTypes.number.isRequired,
  imagePath: PropTypes.string.isRequired,
  isAutoRenewable: PropTypes.bool.isRequired,
  autoRenewPrice: PropTypes.number.isRequired,
});

export const packageListProps = PropTypes.shape({
  totalItems: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(singlePackageProps.isRequired).isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
});
