import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'react-pro-sidebar/dist/css/styles.css';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { AUDIO } from '../../../helper/constant-values';
import {
  clearConsumerPlayedAudio,
  // eslint-disable-next-line prettier/prettier
  getAudiosForConsumer
} from '../../../redux/actions/audio-action';
import { getConsumerCategory } from '../../../redux/actions/consumer-category-action';
import { browserViewProps, userInformationProps } from '../../common/common-props';
import MenuBarWrapper from '../../common/menu-bar-wrapper/menu-bar-wrapper';
import WarningModal from '../../common/modals/warning-success-modal';
import Audio from './audio/audio';
import CategoryComponent from './category-group/category-group';
import { audioForConsumerProps } from './consumer-dashborad-common-props';
import './dashboard-container.css';

function Dashboard(props) {
  const {
    userInfoState,
    browserState,
    consumerPlayedAudioState,
    audiosForConsumerState,
    getAudios,
    clearPlayedAudio,
    getSubscriptionCategory,
  } = props;

  const [selectedCategory, setSelectedCategory] = useState(userInfoState.defaultCategoryId);
  const [latestSelectedCategory, setLatestSelectedCategory] = useState(0);
  const [showCategoryDropDown, setShowCategoryDropDown] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [audioSession, setAudioSession] = useState('');
  const [showTabChageModal, setTabChageModal] = useState(false);
  const [audioTokenCharge, setAudioTokenCharge] = useState(0);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);

  const { customerUrl } = useParams();

  const onToggleCategoryDropDown = () => {
    setShowCategoryDropDown(!showCategoryDropDown);
  };

  const onLoadMore = () => {
    if (audiosForConsumerState.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  const onTabChageModalCancel = () => {
    setTabChageModal(false);
  };

  const onTabChageModalProceed = (e, index) => {
    setSelectedCategory(index || latestSelectedCategory);
    setShowCategoryDropDown(false);
    setPage(1);
    setItemPerPage(20);
    setTabChageModal(false);
  };

  const onCategoryChange = (index) => {
    if (consumerPlayedAudioState.length !== 0 && index !== selectedCategory) {
      setTabChageModal(true);
      setLatestSelectedCategory(+index);
    } else {
      onTabChageModalProceed(0, +index);
    }
  };

  const updateAudioList = (lastPageLoad = false) => {
    const params = { consumerId: userInfoState.id, customerUrl };
    const query = { page, itemPerPage, consumerCategoryId: selectedCategory };
    const actionData = {};
    let currentNumberOfItems = (page - 1) * itemPerPage; // page - 1 because, we are updating page + 1 in each page change / load more button click

    if (lastPageLoad) {
      currentNumberOfItems = page * itemPerPage; // page because, we are not updating page + 1 (when page change / load more button clicked), because this is the last page
    }

    if (
      browserState.isMobile &&
      (currentNumberOfItems === 0 || audiosForConsumerState.data.length < currentNumberOfItems)
    ) {
      query.noPagination = true;
    } else {
      actionData.noPagination = false;
      actionData.isMobile = browserState.isMobile;
    }

    getAudios(params, query, actionData);
  };

  useEffect(() => {
    updateAudioList();
  }, [page, itemPerPage, selectedCategory]);

  useEffect(() => {
    updateAudioList(true);
  }, [mobileLastPageLoaded]);

  useEffect(() => {
    setAudioSession(uuidv4());
    clearPlayedAudio();

    const params = {
      customerUrl,
    };

    const query = {
      mediums: [AUDIO.toLocaleLowerCase()],
    };

    getSubscriptionCategory(params, query).then((subscriptionCategory) => {
      if (subscriptionCategory.length > 0) {
        const category = subscriptionCategory.find((item) => item.id === selectedCategory);
        const audioCharge = category.deliveryMediums[0].numberOfTokens;

        setAudioTokenCharge(audioCharge);
      }
    });
  }, [selectedCategory]);

  return (
    <>
      <WarningModal
        heading="Are you sure?"
        descriptionList={[
          'Do you want to exit current audio session?',
          'Next time you will be charged to play the same audio.',
        ]}
        leftBtnText="Cancel"
        rightBtnText="Proceed"
        showModal={showTabChageModal}
        onHideModal={onTabChageModalCancel}
        onModalSubmit={onTabChageModalProceed}
      />

      <MenuBarWrapper navBarIndex={0} headerTitle="Expanded Audio Readings">
        <Container className="container-uxl">
          <Row>
            <Col xs={12} sm={12} lg={12} className="pl-0">
              <h4 className="py-2">Select Astrological Sign</h4>
              <p>
                Click on your sign to access Rob Brezsny’s Audio Readings. These audio horoscopes
                provide thought-provoking advice based on your sign’s current alignment.
              </p>
            </Col>
          </Row>
          <Row className="rounded-corner bg-white px-0 p-sm-2">
            <CategoryComponent
              onCategoryChange={onCategoryChange}
              selectedCategory={selectedCategory}
              onToggleCategoryDropDown={onToggleCategoryDropDown}
              showCategoryDropDown={showCategoryDropDown}
            />
          </Row>
          <Row className="mt-4 mb-5 pb-5">
            <Col className="px-0">
              <Audio
                setItemPerPage={setItemPerPage}
                setPage={setPage}
                itemPerPage={itemPerPage}
                page={page}
                selectedCategory={selectedCategory}
                onLoadMore={onLoadMore}
                audioSession={audioSession}
                audioTokenCharge={audioTokenCharge}
              />
            </Col>
          </Row>
        </Container>
      </MenuBarWrapper>
    </>
  );
}

Dashboard.propTypes = {
  getAudios: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
  browserState: browserViewProps.isRequired,
  clearPlayedAudio: PropTypes.func.isRequired,
  consumerPlayedAudioState: PropTypes.arrayOf(PropTypes.number).isRequired,
  getSubscriptionCategory: PropTypes.func.isRequired,
  audiosForConsumerState: audioForConsumerProps.isRequired,
};

Dashboard.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
  browserState: state.browserData.view,
  consumerPlayedAudioState: state.audio.consumerPlayedAudio,
  audiosForConsumerState: state.audio.audiosForConsumer,
});

const mapDispatchToProps = {
  getAudios: getAudiosForConsumer,
  clearPlayedAudio: clearConsumerPlayedAudio,
  getSubscriptionCategory: getConsumerCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
