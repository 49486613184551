import PropTypes from 'prop-types';
import { Button, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import colors from '../../../../helper/colors';
import { EyeIcon, SmsIcon } from '../../../../helper/icons/common-icons';

export default function ActionButtons(props) {
  const { onHandleView, onHandleSmsUpload, uploadProcessing, uploadPercentage } = props;

  return (
    <>
      <Col md={6} className="px-0 pb-1">
        {!uploadProcessing && (
          <OverlayTrigger
            transition={false}
            rootClose
            placement="bottom-start"
            overlay={<Tooltip>Upload Short Text</Tooltip>}
          >
            <Button
              tabIndex={0}
              className="border rounded-circle mr-2 py-2 float-right"
              variant="outline-secondary"
              size="sm"
              onClick={onHandleSmsUpload}
            >
              <SmsIcon width="1.2rem" height="1.2rem" />
            </Button>
          </OverlayTrigger>
        )}

        {uploadProcessing && (
          <div className="progress-bar-short-text float-right mr-2">
            <CircularProgressbar
              value={uploadPercentage}
              text={`${uploadPercentage}%`}
              styles={buildStyles({
                pathColor: colors.secondary,
                textColor: colors.secondary,
                trailColor: `${colors.secondary}1A`,
              })}
            />
          </div>
        )}
      </Col>

      <Col md={6} className="px-0 pb-1">
        <OverlayTrigger
          transition={false}
          rootClose
          placement="bottom-start"
          overlay={<Tooltip>View</Tooltip>}
        >
          <Button
            tabIndex={0}
            className="border rounded-circle mr-2 py-2 float-right"
            variant="outline-secondary"
            size="sm"
            onClick={onHandleView}
          >
            <EyeIcon width="1.2rem" height="1.2rem" />
          </Button>
        </OverlayTrigger>
      </Col>
    </>
  );
}

ActionButtons.propTypes = {
  onHandleView: PropTypes.func,
  onHandleSmsUpload: PropTypes.func.isRequired,
  uploadProcessing: PropTypes.bool,
  uploadPercentage: PropTypes.number,
};

ActionButtons.defaultProps = {
  onHandleView: () => {},
  uploadProcessing: true,
  uploadPercentage: 0,
};
