import config from '../../../config/default';
import './footer.css';

export default function Footer() {
  const { copyRights, companyName, poweredBy } = config.customerFooter;

  return (
    <div className="d-none d-sm-flex flex-column flex-sm-row justify-content-between text-center">
      <footer className="pl-4">
        <span className="text-muted">
          <span>&#169;</span> {copyRights}
        </span>
      </footer>
      <footer className="pr-4">
        <span className="text-muted">
          {poweredBy} <span className="text-secondary font-weight-bold">{companyName}</span>
        </span>
      </footer>
    </div>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};
