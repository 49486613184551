import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './loader-spinner-style.css';

export default function DataSavingSpinner(props) {
  const { savingText, defaultText, dataSaving } = props;

  return (
    <>
      {dataSaving ? savingText : defaultText}
      {dataSaving && <FontAwesomeIcon icon={faSpinner} size="lg" spin className="ml-2" />}
    </>
  );
}

DataSavingSpinner.propTypes = {
  savingText: PropTypes.string,
  defaultText: PropTypes.string,
  dataSaving: PropTypes.bool,
};

DataSavingSpinner.defaultProps = {
  savingText: '',
  defaultText: '',
  dataSaving: false,
};
