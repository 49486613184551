import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {
  FirstCircleIcon,
  LastCircleIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  SkipLeftCircleIcon,
  // eslint-disable-next-line prettier/prettier
  SkipRightCircleIcon
} from '../../../helper/icons/common-icons';
import './audio-player-style.css';

export default function CustomAudioPlayer(props) {
  const { name, description, audioSrc, pauseAudio } = props;

  const audioRef = useRef(null);

  const [play, setPlay] = useState(true);
  const [beginningOfAudio, setBeginningOfAudio] = useState(false);
  const [endOfAudio, setEndOfAudio] = useState(false);

  const getBeginningAudioButtonClass = () =>
    beginningOfAudio ? 'disbale-audio-player-button' : '';

  const getEndAudioButtonClass = () => (endOfAudio ? 'disbale-audio-player-button' : '');

  const goFirst = () => {
    if (!play) {
      setBeginningOfAudio(true);
      setEndOfAudio(false);
    }

    audioRef.current.audio.current.currentTime = 0;
    if (play) audioRef.current.audio.current.play();
  };

  const goLast = () => {
    setEndOfAudio(true);
    setBeginningOfAudio(false);
    audioRef.current.audio.current.currentTime = audioRef.current.audio.current.duration;
  };

  useEffect(() => {
    if (play) {
      setBeginningOfAudio(false);
      setEndOfAudio(false);
    }
  }, [play]);

  useEffect(() => {
    audioRef.current.audio.current.pause();
  }, [pauseAudio]);

  useEffect(() => {
    const currentRef = audioRef.current.audio.current;

    currentRef.play();

    const timeUpdate = (e) => {
      if (e.target.paused) {
        if (parseInt(e.target.currentTime, 10) === 0) {
          setBeginningOfAudio(true);
        } else {
          setBeginningOfAudio(false);
        }

        if (e.target.currentTime === e.target.duration) {
          setEndOfAudio(true);
        } else {
          setEndOfAudio(false);
        }
      }
    };

    currentRef.addEventListener('timeupdate', timeUpdate);

    return () => currentRef.removeEventListener('timeupdate', timeUpdate);
  }, []);

  return (
    <Row className="bg-light rounded-lg">
      <Container className="container-uxl-player">
        <Row className="text-left pt-2">
          <Col>
            <span className="h6">{name}</span>
          </Col>
        </Row>
        <Row className="text-left pt-2">
          <Col>
            <span className="text-muted">{description}</span>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <AudioPlayer
              ref={audioRef}
              src={audioSrc}
              className="bg-light"
              customVolumeControls={[]}
              customAdditionalControls={[]}
              showSkipControls
              onPlay={() => setPlay(true)}
              onPause={() => setPlay(false)}
              onEnded={() => setEndOfAudio(true)}
              autoPlay
              customIcons={{
                previous: (
                  <FirstCircleIcon
                    width="1em"
                    height="1em"
                    className={`player-button ${getBeginningAudioButtonClass()}`}
                    onClick={goFirst}
                  />
                ),
                rewind: <SkipLeftCircleIcon width="1em" height="1em" className="player-button" />,
                play: (
                  <PlayCircleIcon
                    width="1em"
                    height="1em"
                    className="text-primary custom-primary-btn"
                  />
                ),
                pause: (
                  <PauseCircleIcon
                    width="1em"
                    height="1em"
                    className="text-primary custom-primary-btn"
                  />
                ),
                forward: (
                  <SkipRightCircleIcon width="1em" height="1em" className=" player-button" />
                ),
                next: (
                  <LastCircleIcon
                    width="1em"
                    height="1em"
                    className={`player-button ${getEndAudioButtonClass()}`}
                    onClick={goLast}
                  />
                ),
              }}
            />
          </Col>
        </Row>
      </Container>
    </Row>
  );
}

CustomAudioPlayer.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  audioSrc: PropTypes.string.isRequired,
  pauseAudio: PropTypes.bool,
};

CustomAudioPlayer.defaultProps = {
  description: '',
  pauseAudio: false,
};
