import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BiFilterAlt } from 'react-icons/bi';
import commonMethods from '../../../../../helper/common-methods';
import CustomMenu from './custom-menu';
import './date-filter-style.css';

export default function DateFilter(props) {
  const { onHandelFilterData, startDate, endDate } = props;
  const [showCategoryDropDown, setShowCategoryDropDown] = useState(false);

  const onToggleDropDown = () => {
    setShowCategoryDropDown(!showCategoryDropDown);
  };

  return (
    <>
      <Dropdown show={showCategoryDropDown} onToggle={onToggleDropDown}>
        <Dropdown.Toggle variant="secondary" className="dropdown-toggle-filter-date">
          <span>Filter by Date&nbsp; </span>
          <BiFilterAlt size="1.2rem" />
        </Dropdown.Toggle>
        {showCategoryDropDown && (
          <Dropdown.Menu
            as={CustomMenu}
            onToggleDropDown={onToggleDropDown}
            handelFilterdata={onHandelFilterData}
            startDate={startDate || commonMethods.getTodayDate()}
            endDate={endDate || commonMethods.getTodayDate()}
            className="custom-menu-filter-date"
          />
        )}
      </Dropdown>
    </>
  );
}

DateFilter.propTypes = {
  onHandelFilterData: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

DateFilter.defaultProps = {
  startDate: commonMethods.getYesterdayDate(true),
  endDate: commonMethods.getYesterdayDate(true),
};
