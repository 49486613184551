/* eslint-disable no-underscore-dangle */
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducers from './reducers';

const middleware = [thunk];
const initialState = {};
let reduxCompose;

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  reduxCompose = compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  reduxCompose = compose(applyMiddleware(...middleware));
}

const store = createStore(rootReducers, initialState, reduxCompose);

export default store;
