import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import { GoCalendar } from 'react-icons/go';
import { DATE_MDY_FORMAT, TABLE_DATE_FORMAT } from '../../../../../helper/constant-values';

const CustomDatePicker = React.forwardRef(({ value, onClick }, ref) => (
  <div className="d-flex flex-row justify-content-end" ref={ref}>
    <Form.Control
      value={moment(value, DATE_MDY_FORMAT).format(TABLE_DATE_FORMAT)}
      disabled
      className="filter-input"
    />
    <GoCalendar
      size="1.4em"
      onClick={onClick}
      className="position-absolute align-self-center cursor-pointer pr-2"
    />
  </div>
));

CustomDatePicker.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

CustomDatePicker.defaultProps = {
  value: '',
  onClick: () => {},
};

export default CustomDatePicker;
