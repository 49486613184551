import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';
import config from '../../../config/default';
import { OTP_LENGTH, OTP_TYPE_PASSWORD } from '../../../helper/constant-values';
import { otpResend, otpValidityCheck, otpVerification } from '../../../redux/actions/auth-action';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import { consumerSignupProps, forgotPasswordProps } from '../login-registration-common-props';
import './otp-verification.css';

function OtpVerification(props) {
  const {
    consumerSignupState,
    forgotPasswordState,
    verifyOtp,
    resendOtp,
    otpValidity,
    loginEmail,
    loginUsername,
    loginSmsPhoneNumber,
    onVerification,
    otpType,
    verifySmsPhoneNumber,
    isRegistration,
  } = props;

  const {
    username: signupUsername,
    tempEmail: signupEmail,
    tempSmsPhoneNumber: signupSmsPhoneNumber,
  } = consumerSignupState;

  const { username: forgotPasswordUsername, email: forgotPasswordEmail } = forgotPasswordState;

  const [otp, setOtp] = useState('');
  const [resendCount, setResendCount] = useState(false);
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [hideResendButton, setHideResendButton] = useState(false);
  const [invalidOTPError, setInvalidOTPError] = useState(false);
  const [invalidOTPErrorText, setInvalidOTPErrorText] = useState('');
  const [otpVerificationContimue, setOtpVerificationContimue] = useState(false);

  const email = loginEmail || signupEmail || forgotPasswordEmail;
  const username = loginUsername || signupUsername || forgotPasswordUsername;
  const smsPhoneNumber = loginSmsPhoneNumber || signupSmsPhoneNumber;

  const otpFieldBorderColor = isRegistration
    ? 'otp-box border-primary ml-n2'
    : 'otp-box border-secondary ml-n2';

  const onOtpChange = (otpValue) => {
    setOtp(otpValue);
    setInvalidOTPError(false);
    setInvalidOTPErrorText('');

    const regexForDigit = /^\d+$/;

    if (otpValue.length > 0 && !regexForDigit.test(otpValue)) {
      setInvalidOTPError(true);
      setInvalidOTPErrorText('Verification Code must be a numeric value');
      setDisableContinueButton(true);
    } else if (otpValue.length < OTP_LENGTH) {
      setDisableContinueButton(true);
    } else {
      setDisableContinueButton(false);
    }
  };

  const onResendOtp = () => {
    const otpData = {
      username,
      [otpType]: true,
    };

    setHideResendButton(true);

    resendOtp(otpData)
      .then(() => {
        setInvalidOTPError(false);
        setHideResendButton(false);
        setOtp('');
        setResendCount(resendCount + 1);
      })
      .catch(() => {
        setHideResendButton(false);
      });
  };

  const onOtpSubmit = () => {
    const otpData = {
      username,
    };

    if (otpType === OTP_TYPE_PASSWORD) {
      otpData.passwordToken = otp;
    } else if (verifySmsPhoneNumber) {
      otpData.smsPhoneNumberToken = otp;
    } else {
      otpData.emailToken = otp;
    }

    const otpAction = otpType === OTP_TYPE_PASSWORD ? otpValidity : verifyOtp;

    setOtpVerificationContimue(true);

    otpAction(otpData)
      .then(() => {
        onVerification();
        setOtpVerificationContimue(false);
      })
      .catch((error) => {
        if (error.response.data.isInvalidShortCode) {
          setInvalidOTPError(true);
          setInvalidOTPErrorText(error.response.data.message);
          setDisableContinueButton(true);
        }

        setOtpVerificationContimue(false);
      });
  };

  return (
    <>
      <Form onSubmit={(evt) => evt.preventDefault()} className="px-2 pl-lg-0">
        <Row className="justify-content-center justify-content-lg-start">
          <Col sm={11} md={7} lg={12}>
            <p className="text-center text-lg-left">
              We just sent you a verification code to{' '}
              <span className={isRegistration ? 'text-primary' : 'text-secondary'}>
                {verifySmsPhoneNumber ? smsPhoneNumber : email}
              </span>
            </p>

            {otpType !== OTP_TYPE_PASSWORD && verifySmsPhoneNumber && (
              <p>
                Please note that this message has been sent to your mobile device, not your email.
                Please enter the code below.
              </p>
            )}

            {otpType !== OTP_TYPE_PASSWORD && !verifySmsPhoneNumber && (
              <p>
                Please note that this message has been sent to your email, not your mobile phone. If
                you do not see it in your email, please check your spam folder. If this email is not
                correct, please{' '}
                <a className="text-secondary" href={`mailto:${config.email.supportEmail}`}>
                  contact support
                </a>
                .
              </p>
            )}
          </Col>
        </Row>

        <Row className="justify-content-center justify-content-lg-start pt-4 pb-2">
          <Col sm={11} lg={7} xl={6}>
            <OtpInput
              key={resendCount}
              onChange={onOtpChange}
              numInputs={6}
              value={otp}
              containerStyle="ml-md-2 ml-3"
              inputStyle={invalidOTPError ? otpFieldBorderColor : 'otp-box ml-n2'}
              shouldAutoFocus
              isInputNum
            />
          </Col>
        </Row>

        {invalidOTPError ? (
          <Row className="justify-content-center justify-content-lg-start pl-2 pl-lg-0">
            <Col sm={11} className="text-primary">
              {invalidOTPErrorText}
            </Col>
          </Row>
        ) : (
          <br />
        )}

        <Row className="justify-content-center justify-content-lg-start pt-4">
          <Col sm={11} lg={7} xl={6}>
            <Button
              size="lg"
              variant={isRegistration ? 'primary' : 'secondary'}
              block
              type="submit"
              onClick={onOtpSubmit}
              disabled={disableContinueButton || otpVerificationContimue}
            >
              <DataSavingSpinner
                savingText="Verifying"
                defaultText="Continue"
                dataSaving={otpVerificationContimue}
              />
            </Button>
          </Col>
        </Row>

        <Row className="justify-content-center justify-content-lg-start mt-4">
          <Col sm={11} lg={7} xl={6}>
            {!hideResendButton ? (
              <div
                aria-hidden="true"
                className={
                  isRegistration
                    ? 'text-primary font-weight-bold cursor-pointer text-center text-lg-left'
                    : 'text-secondary font-weight-bold cursor-pointer text-center text-lg-left'
                }
                onClick={onResendOtp}
              >
                Resend Code
              </div>
            ) : (
              <FontAwesomeIcon icon={faSpinner} size="lg" spin />
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

OtpVerification.propTypes = {
  verifyOtp: PropTypes.func.isRequired,
  resendOtp: PropTypes.func.isRequired,
  otpValidity: PropTypes.func.isRequired,
  onVerification: PropTypes.func.isRequired,
  loginEmail: PropTypes.string,
  loginUsername: PropTypes.string,
  loginSmsPhoneNumber: PropTypes.string,
  consumerSignupState: consumerSignupProps.isRequired,
  otpType: PropTypes.string.isRequired,
  verifySmsPhoneNumber: PropTypes.bool,
  forgotPasswordState: forgotPasswordProps.isRequired,
  isRegistration: PropTypes.bool,
};

OtpVerification.defaultProps = {
  loginEmail: '',
  loginUsername: '',
  loginSmsPhoneNumber: '',
  verifySmsPhoneNumber: false,
  isRegistration: false,
};

const mapStateToProps = (state) => ({
  consumerSignupState: state.auth.consumerSignup,
  forgotPasswordState: state.auth.forgotPassword,
});

const mapDispatchToProps = {
  verifyOtp: otpVerification,
  resendOtp: otpResend,
  otpValidity: otpValidityCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpVerification);
