import commonMethods from '../../helper/common-methods';
import { TOKEN_TRANSACTION_FOR_ADMIN } from '../types';

const initialState = {
  tokenTransactionForAdmin: {
    totalItems: 0,
    data: [],
    totalPages: 1,
    currentPage: 1,
  },
};

const tokenTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOKEN_TRANSACTION_FOR_ADMIN: {
      return commonMethods.paginationStateUpdate(state, action, 'tokenTransactionForAdmin');
    }

    default:
      return state;
  }
};

export default tokenTransactionReducer;
