import MenuBarWrapper from '../../common/menu-bar-wrapper/menu-bar-wrapper';
import RevenueChartItems from './revenue-chart-items';
import './revenue-chart.css';

export default function RevenueChart() {
  return (
    <MenuBarWrapper navBarIndex={0} headerTitle="Revenue & Usage">
      <RevenueChartItems />
    </MenuBarWrapper>
  );
}

RevenueChart.propTypes = {};

RevenueChart.defaultProps = {};
