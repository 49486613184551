import commonMethods from '../../helper/common-methods';
import {
  CUSTOMER_DELETE_BY_ADMIN,
  CUSTOMER_DETAILS_BY_ADMIN,
  // eslint-disable-next-line prettier/prettier
  CUSTOMER_LIST_BY_ADMIN
} from '../types';

const initialState = {
  customerListForAdmin: {
    totalItems: 0,
    data: [],
    totalPages: 1,
    currentPage: 1,
  },
  customerDetailsForAdmin: {
    id: 0,
    username: '',
    email: '',
    fullName: '',
    contactNumber: '',
    timeZone: '',
    url: '',
    thresholdToken: 0,
    companyName: '',
    details: '',
    logoUrl: '',
  },
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_LIST_BY_ADMIN: {
      return commonMethods.paginationStateUpdate(state, action, 'customerListForAdmin');
    }

    case CUSTOMER_DETAILS_BY_ADMIN: {
      return {
        ...state,
        customerDetailsForAdmin: action.data,
      };
    }

    case CUSTOMER_DELETE_BY_ADMIN: {
      return commonMethods.deletePaginationState(state, action, 'customerListForAdmin');
    }

    default:
      return state;
  }
};

export default customerReducer;
