import { combineReducers } from 'redux';
import audioReducer from './audio';
import authReducer from './auth';
import browserDataReducer from './browser-data';
import consumerCategoryReducer from './consumer-category';
import consumerLandingReducer from './consumer-landing';
import consumerReducer from './consumers';
import customerReducer from './customers';
import packageReducer from './package';
import paymentReducer from './payments';
import profileReducer from './profile';
import revenueReportReducer from './revenue-report';
import ShortTextReducer from './short-text';
import supportTicketReducer from './support-ticket';
import tokenTransactionReducer from './token-transactions';

export default combineReducers({
  consumerCategory: consumerCategoryReducer,
  supportTicket: supportTicketReducer,
  consumerLanding: consumerLandingReducer,
  packages: packageReducer,
  auth: authReducer,
  browserData: browserDataReducer,
  profile: profileReducer,
  audio: audioReducer,
  revenue: revenueReportReducer,
  shortText: ShortTextReducer,
  consumers: consumerReducer,
  customers: customerReducer,
  payments: paymentReducer,
  tokenTransactions: tokenTransactionReducer,
});
