/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
function handleArrayAsQueryParamter(key, arr) {
  arr = arr.map((item) => `${key}=${item}`);
  return arr.join('&');
}

function encode(val) {
  // ;,/?:@&=+$# these characters are not encoded by default.
  return encodeURI(val).replace('+', '%2B');
}

const utils = {
  generateQueryString: (query = {}) => {
    let queryString = '';
    // flag for first key
    let first = true;
    let queryParameter;
    for (const queryKey in query) {
      // if first key use '?' as delimiter, '&' otherwise
      const delimiter = first ? '?' : '&';
      first = false;
      // append the query key-value pairs with original url
      const isObjectKey = typeof query[queryKey] === 'object';
      const isArrayKey = Array.isArray(query[queryKey]);
      if (isArrayKey) {
        queryParameter = handleArrayAsQueryParamter(queryKey, query[queryKey]);
      } else if (isObjectKey) {
        queryParameter = `${queryKey}=${JSON.stringify(query[queryKey])}`;
      } else {
        queryParameter = `${queryKey}=${query[queryKey]}`;
      }
      queryString += delimiter + queryParameter;
    }
    return queryString;
  },

  buildUrl: (urlTemplate, params, query = {}) => {
    let paramNames;
    const paramRegex = new RegExp('{(.+?)}', 'm');
    do {
      // Get the matching params
      paramNames = paramRegex.exec(urlTemplate);
      // If there is a match and has a param value for the match
      // Using hasOwnProperty because param might also have null or 0 values which might result in false
      if (paramNames && params.hasOwnProperty(paramNames[1])) {
        // Replace and update the urlTemplate
        urlTemplate = urlTemplate.replace(paramNames[0], params[paramNames[1]]);
      }
    } while (paramNames);
    urlTemplate += query ? utils.generateQueryString(query) : '';
    return encode(urlTemplate);
  },
};

module.exports = utils;
