export default [
  {
    question: 'Welcome to our new website!',
    answer: `<p>Hello! Welcome to our new modernized website for the Free Will Astrology Expanded Audio Readings and Sunburst text messages.</p>
    <p>Yes, there are some changes...</p>
    <p>Tokens are now used for both weekly audio readings and daily Sunbursts. This means that Sunbursts now receive the same price discounts as voice readings when buying tokens in larger packages.</p>
    <p>Instead of paying up-front for a month of Sunbursts, you will be charged using tokens for the Sunbursts as they are delivered each day.</p>
    <p>Instead of subscribing for automatic monthly Sunburst renewal, you will be able to set up an Auto-Purchase option, to automatically purchase tokens when they fall below a threshold, to get the Sunbursts continuously. The Auto-Purchase option can be started or stopped at any time. See the FAQ on purchasing tokens for more information.</p>
    Our new website platform has other capabilities that are still in the works to provide additional services. Please be patient as we work out the kinks, and feel free to send us any comments or suggestions you may have.`,
  },
];
