import { Formik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import commonMethods from '../../../../../helper/common-methods';
import {
  addCustomerDetailsValidationSchema,
  // eslint-disable-next-line prettier/prettier
  updateCustomerDetailsValidationSchema
} from '../../../../../helper/schema-objects';
import {
  createCustomerByAdmin,
  getCustomerDetailsForAdmin,
  // eslint-disable-next-line prettier/prettier
  updateCustomerByAdmin
} from '../../../../../redux/actions/customer-actions';
import TimeZoneDropDown from '../../../../common/dropdown/timezone-dropdown';
import DoubleFormComponent from '../../../../common/form-component/double-form-component';
import FormComponent from '../../../../common/form-component/form-component';
import DataSavingSpinner from '../../../../common/loader-spinner/data-saving-spinner';
import { customerDetailsProps } from '../../customer-common-props';

function ConsumeDetailsForm(props) {
  const {
    customerDetailState,
    backFromCategoryView,
    customerDetailPreviousState,
    customerLogo,
    customerLogoError,
    isAddForm,
    onSavingData,
    updateCustomer,
    createCustomer,
    getCustomer,
  } = props;

  const [dataSaving, setDataSaving] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState('password');
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState('password');

  const { customerId } = useParams();

  let initialValues = {
    username: isAddForm ? '' : customerDetailState.username,
    fullName: isAddForm ? '' : customerDetailState.fullName,
    email: isAddForm ? '' : customerDetailState.email,
    contactNumber: isAddForm ? '' : customerDetailState.contactNumber,
    timeZone: isAddForm ? '' : customerDetailState.timeZone,
    url: isAddForm ? '' : customerDetailState.url,
    thresholdToken: isAddForm ? '' : customerDetailState.thresholdToken,
    companyName: isAddForm ? '' : customerDetailState.companyName,
    details: isAddForm ? '' : customerDetailState.details,
  };

  if (isAddForm) {
    initialValues.password = '';
    initialValues.confirmPassword = '';
  }

  if (backFromCategoryView) {
    initialValues = {
      ...initialValues,
      ...customerDetailPreviousState,
    };
  }

  const showHidePassword = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text');
    } else {
      setPasswordInputType('password');
    }
  };

  const showHideConfirmPassword = () => {
    if (confirmPasswordInputType === 'password') {
      setConfirmPasswordInputType('text');
    } else {
      setConfirmPasswordInputType('password');
    }
  };

  const onSubmit = (values, { setErrors }) => {
    const customerData = {
      ...values,
      fullName: values.fullName.trim(),
    };

    const userData = isAddForm
      ? _.omit(customerData, ['id', 'logoUrl'])
      : _.omit(customerData, ['username', 'id', 'url']);

    const formData = commonMethods.getFormData(userData, customerLogo, 'companyLogo');

    setDataSaving(true);

    const customerApi = backFromCategoryView || !isAddForm ? updateCustomer : createCustomer;

    const params = {};

    if (backFromCategoryView) params.customerId = customerData.id;
    if (!isAddForm) params.customerId = customerId;

    customerApi(formData, params)
      .then(() => {
        setDataSaving(false);
        onSavingData();
      })
      .catch((error) => {
        if (!error.response) {
          setDataSaving(false);
          return;
        }

        setDataSaving(false);

        const { isExistingEmail, isExistingUsername, isExistingUrl, message } = error.response.data;

        if (isExistingEmail) {
          setErrors({
            email: message,
          });
        }

        if (isExistingUsername) {
          setErrors({
            username: message,
          });
        }

        if (isExistingUrl) {
          setErrors({
            url: message,
          });
        }
      });
  };

  useEffect(() => {
    if (!isAddForm) {
      getCustomer({ customerId }).catch(() => {});
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={
          isAddForm ? addCustomerDetailsValidationSchema : updateCustomerDetailsValidationSchema
        }
        onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
        enableReinitialize
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <DoubleFormComponent
              formik={formik}
              firstColumnName="username"
              secondColumnName="fullName"
              firstColumnPlaceHolder="Username"
              secondColumnPlaceHolder="Name"
              firstColumnDisable={!isAddForm || backFromCategoryView}
              firstColumnAutoFocus
              firstColumnShowLabel
              secondColumnShowLabel
            />

            <DoubleFormComponent
              formik={formik}
              firstColumnName="contactNumber"
              secondColumnName="email"
              firstColumnPlaceHolder="Contact Phone Number"
              secondColumnPlaceHolder="Email"
              firstColumnShowLabel
              secondColumnShowLabel
            />

            <Row className="mt-1 justify-content-center">
              <FormComponent
                type="select"
                name="timeZone"
                formik={formik}
                inputColumnClass="col-sm-12 col-lg-8"
                errorColumnClass="d-none"
                placeholder="Time Zone"
                showLabel
              >
                <TimeZoneDropDown />
              </FormComponent>

              <FormComponent
                name="timeZone"
                inputColumnClass="d-none"
                errorColumnClass="col-sm-12 col-lg-8"
                formik={formik}
              />
            </Row>

            {isAddForm && (
              <>
                <Row className="justify-content-center">
                  <Col sm={10} md={6} lg={4}>
                    <Form.Label>Password</Form.Label>
                  </Col>
                  <Col sm={10} md={6} lg={4} className="d-none d-sm-flex">
                    <Form.Label>Confirm Password</Form.Label>
                  </Col>
                </Row>

                <DoubleFormComponent
                  formik={formik}
                  firstColumnName="password"
                  secondColumnName="confirmPassword"
                  firstColumnPlaceHolder="Password"
                  secondColumnPlaceHolder="Confirm Password"
                  firstColumnIconToolTip="Show/hide password"
                  secondColumnIconToolTip="Show/hide password"
                  firstColumnType={passwordInputType}
                  secondColumnType={confirmPasswordInputType}
                  firstColumnOnIconClick={showHidePassword}
                  secondColumnOnIconClick={showHideConfirmPassword}
                  firstColumnIsPassword
                  secondColumnIsPassword
                />
              </>
            )}

            <DoubleFormComponent
              formik={formik}
              firstColumnName="url"
              secondColumnName="companyName"
              firstColumnPlaceHolder="Company Url"
              secondColumnPlaceHolder="Company Name"
              firstColumnDisable={!isAddForm}
              firstColumnShowLabel
              secondColumnShowLabel
            />

            <DoubleFormComponent
              formik={formik}
              firstColumnName="details"
              secondColumnName="thresholdToken"
              firstColumnPlaceHolder="Company Details"
              secondColumnPlaceHolder="Threshold Token"
              secondColumnType="number"
              firstColumnShowLabel
              secondColumnShowLabel
            />

            <Row className="justify-content-center mt-1 mb-5">
              <Col lg={8} className="pt-3 pt-sm-0">
                <Button
                  size="lg"
                  block
                  variant="secondary"
                  onClick={formik.handleSubmit}
                  type="submit"
                  disabled={
                    !!(
                      formik.errors.fullName ||
                      formik.errors.email ||
                      formik.errors.contactNumber ||
                      formik.errors.timeZone ||
                      formik.errors.username ||
                      formik.errors.url ||
                      formik.errors.thresholdToken ||
                      formik.errors.companyName ||
                      formik.errors.details ||
                      (isAddForm && formik.errors.password) ||
                      (isAddForm && formik.errors.confirmPassword) ||
                      dataSaving ||
                      customerLogoError
                    )
                  }
                >
                  <DataSavingSpinner
                    savingText="Saving Changes"
                    defaultText="Save Changes"
                    dataSaving={dataSaving}
                  />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}

ConsumeDetailsForm.propTypes = {
  createCustomer: PropTypes.func.isRequired,
  customerDetailState: customerDetailsProps.isRequired,
  backFromCategoryView: PropTypes.bool,
  customerDetailPreviousState: customerDetailsProps.isRequired,
  customerLogo: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)])
    .isRequired,
  customerLogoError: PropTypes.string.isRequired,
  isAddForm: PropTypes.bool,
  onSavingData: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  getCustomer: PropTypes.func.isRequired,
};

ConsumeDetailsForm.defaultProps = {
  isAddForm: false,
  backFromCategoryView: false,
};

const mapStateToProps = (state) => ({
  customerDetailState: state.customers.customerDetailsForAdmin,
  customerDetailPreviousState: state.customers.customerDetailsForAdmin,
});

const mapDispatchToProps = {
  createCustomer: createCustomerByAdmin,
  updateCustomer: updateCustomerByAdmin,
  getCustomer: getCustomerDetailsForAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumeDetailsForm);
