import { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PaypalButton from './paypal-button';
import CustomCheckbox from '../../../../../common/buttons/custom-checkbox';

function PaypalPayment(props) {
  const { packageId, goToPackagePage, paymentProcessing, fundingSource } = props;

  const initialOptions = {
    'client-id': `${process.env.REACT_APP_PAYPAL_CLIENT_ID}&enable-funding=venmo`,
    components: 'buttons',
    currency: 'USD',
  };

  const [checkedSavePaymentInformation, setCheckedSavePaymentInformation] = useState(false);

  const onCheckSaveCardInfo = () => {
    setCheckedSavePaymentInformation(!checkedSavePaymentInformation);
  };

  return (
    <Row className="border rounded-lg py-3 mt-3">
      <Col>
        <PayPalScriptProvider options={initialOptions}>
          <PaypalButton
            packageId={packageId}
            goToPackagePage={goToPackagePage}
            paymentProcessing={paymentProcessing}
            fundingSource={fundingSource}
            checkedSavePaymentInformation={checkedSavePaymentInformation}
          />
        </PayPalScriptProvider>
        {fundingSource === 'paypal' && (
          <div className="d-inline">
            <CustomCheckbox checked={checkedSavePaymentInformation} onClick={onCheckSaveCardInfo} />
            <span className="pl-1 checkbox-label-subscription">
              Save this payment information as default payment.
            </span>
          </div>
        )}
      </Col>
    </Row>
  );
}

PaypalPayment.propTypes = {
  packageId: PropTypes.number.isRequired,
  goToPackagePage: PropTypes.func.isRequired,
  paymentProcessing: PropTypes.bool.isRequired,
  fundingSource: PropTypes.string,
};

PaypalPayment.defaultProps = {
  fundingSource: 'paypal',
};

export default PaypalPayment;
