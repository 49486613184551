import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { RingLoader } from 'react-spinners';
import colors from '../../../../../helper/colors';
import commonMethods from '../../../../../helper/common-methods';
import { ICON_SIZE } from '../../../../../helper/constant-values';
import { AddIcon } from '../../../../../helper/icons/common-icons';
import {
  addConsumerCategoryByAdmin,
  deleteConsumerCategoryByAdmin,
  getConsumerCategoryListForAdmin,
  // eslint-disable-next-line prettier/prettier
  updateConsumerCategoryByAdmin
} from '../../../../../redux/actions/consumer-category-action';
import { browserViewProps } from '../../../../common/common-props';
import DataSavingSpinner from '../../../../common/loader-spinner/data-saving-spinner';
import CustomPagination from '../../../../common/pagination/custom-pagination';
import { categoryWithDeliverMediumListProps } from '../../customer-common-props';
import CustomerCategoryModifyModal from '../../modify-customer/customer-category/customer-category-modify-modal';
import CustomerCategory from './customer-category';

function CustomerCategoryContainer(props) {
  const {
    categoryListState,
    browserState,
    newCustomerId,
    gotoCustomerPackageAddPage,
    isAddCustomerPage,
    getConsumerCategoryList,
    addConsumerCategory,
    updateConsumerCategory,
    deleteConsumerCategory,
  } = props;

  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [dataLoadCont, setDataLoadCount] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [mobileLoadMoreLoading, setMobileLoadMoreLoading] = useState(false);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);

  const { customerId } = useParams();

  const onAddCategoryClick = () => {
    setShowAddCategoryModal(true);
  };

  const onCloseCategoryModifyModal = () => {
    setShowAddCategoryModal(false);
  };

  const getPaginationQuery = ({
    pageChanged = false,
    paramsData = {},
    customMethod = null,
    queryData = {},
    payload = null,
  }) => {
    const params = { ...paramsData };
    const query = {
      page,
      itemPerPage,
      customerId: customerId || newCustomerId,
      ...queryData,
    };

    return {
      isMobileView: browserState.isMobile,
      params,
      query,
      page,
      payload,
      itemPerPage,
      getMethod: customMethod || getConsumerCategoryList,
      pageChanged,
      data: categoryListState.data,
    };
  };

  const onLoadMoreClick = () => {
    setMobileLoadMoreLoading(true);

    if (categoryListState.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  const onAddCategorySubmit = (payload) =>
    commonMethods.updatePaginationData(
      getPaginationQuery({
        payload,
        queryData: { sendList: !browserState.isMobile },
        customMethod: addConsumerCategory,
      })
    );

  const onUpdateCategorySubmit = (payload, params) =>
    commonMethods.updatePaginationData(
      getPaginationQuery({
        payload,
        paramsData: params,
        customMethod: updateConsumerCategory,
      })
    );

  const onDeleteCategorySubmit = (params) =>
    commonMethods.updatePaginationData(
      getPaginationQuery({
        paramsData: params,
        queryData: { sendList: !browserState.isMobile },
        customMethod: deleteConsumerCategory,
      })
    );

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery({ pageChanged: true }));
  }, [page]);

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery({}));
  }, [itemPerPage, mobileLastPageLoaded]);

  useEffect(() => {
    if (!browserState.isMobile) {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    }
  }, [browserState.isMobile]);

  useEffect(() => {
    if (dataLoadCont !== 0) {
      setDataLoading(false);
    }
    setMobileLoadMoreLoading(false);
    setDataLoadCount(dataLoadCont + 1);
  }, [categoryListState]);

  return (
    <>
      {showAddCategoryModal && (
        <CustomerCategoryModifyModal
          showModal={showAddCategoryModal}
          headerTitle="Add Category"
          onCloseModal={onCloseCategoryModifyModal}
          onSubmit={onAddCategorySubmit}
          customerId={customerId || newCustomerId}
          isAddCustomerCateory
        />
      )}

      {dataLoading && (
        <Col xs={12} className="d-flex justify-content-center">
          <RingLoader loading size={100} color={colors.secondary} />
        </Col>
      )}

      {!dataLoading && (
        <>
          <Col xs={12}>
            <Button
              variant="outline-secondary"
              className="float-right mb-3"
              size="lg"
              onClick={onAddCategoryClick}
            >
              <AddIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
              Add Category
            </Button>
          </Col>

          <CustomerCategory
            categoryList={categoryListState}
            onUpdateCategorySubmit={onUpdateCategorySubmit}
            onDeleteCategorySubmit={onDeleteCategorySubmit}
            customerId={customerId || newCustomerId}
          />

          {categoryListState.totalItems > 10 && (
            <Col xs={12} className="px-0 d-none d-md-block border-top bg-white">
              <CustomPagination
                itemPerPage={itemPerPage}
                page={page}
                setPage={setPage}
                setItemPerPage={setItemPerPage}
                totalPages={categoryListState.totalPages}
                totalItems={categoryListState.totalItems}
              />
            </Col>
          )}

          {categoryListState.totalItems > categoryListState.data.length && (
            <Col
              sm={11}
              className="bg-light justify-content-center justify-content-lg-start pt-4 d-sm-none"
            >
              <Button
                size="lg"
                variant="secondary"
                disabled={dataLoading}
                onClick={onLoadMoreClick}
                block
              >
                <DataSavingSpinner
                  savingText="Loding"
                  defaultText="Load More"
                  dataSaving={mobileLoadMoreLoading}
                />
              </Button>
            </Col>
          )}

          {isAddCustomerPage && (
            <>
              <Col xs={12} md={6} className="pt-3 pt-sm-0 d-flex justify-content-center" />
              <Col
                xs={12}
                md={{ span: 6, offset: 3 }}
                className="pt-3 pt-sm-0 justify-content-center"
              >
                <Button size="lg" variant="secondary" block onClick={gotoCustomerPackageAddPage}>
                  Continue
                </Button>
              </Col>
            </>
          )}
        </>
      )}
    </>
  );
}

CustomerCategoryContainer.propTypes = {
  categoryListState: categoryWithDeliverMediumListProps.isRequired,
  browserState: browserViewProps.isRequired,
  getConsumerCategoryList: PropTypes.func.isRequired,
  addConsumerCategory: PropTypes.func.isRequired,
  newCustomerId: PropTypes.number,
  gotoCustomerPackageAddPage: PropTypes.func,
  isAddCustomerPage: PropTypes.bool,
  updateConsumerCategory: PropTypes.func.isRequired,
  deleteConsumerCategory: PropTypes.func.isRequired,
};

CustomerCategoryContainer.defaultProps = {
  newCustomerId: 0,
  gotoCustomerPackageAddPage: () => {},
  isAddCustomerPage: false,
};

const mapStateToProps = (state) => ({
  categoryListState: state.consumerCategory.categoryForAdmin,
  browserState: state.browserData.view,
});

const mapDispatchToProps = {
  getConsumerCategoryList: getConsumerCategoryListForAdmin,
  addConsumerCategory: addConsumerCategoryByAdmin,
  updateConsumerCategory: updateConsumerCategoryByAdmin,
  deleteConsumerCategory: deleteConsumerCategoryByAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCategoryContainer);
