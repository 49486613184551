import { Col, Row } from 'react-bootstrap';

export default function ConsumerListTitle() {
  return (
    <Row className="d-none d-lg-flex header mx-2 mt-4">
      <Col lg={10}>
        <Row>
          <Col sm={3}>
            <p>Customer Url</p>
          </Col>
          <Col sm={3}>
            <p>Name</p>
          </Col>
          <Col sm={3}>
            <p>Username</p>
          </Col>
          <Col sm={3} className="d-flex">
            <p>Email</p>
          </Col>
        </Row>
      </Col>
      <Col lg={2} className="d-flex justify-content-end">
        <p>Actions</p>
      </Col>
    </Row>
  );
}

ConsumerListTitle.propTypes = {};

ConsumerListTitle.defaultProps = {};
