/* eslint-disable import/prefer-default-export */
import commonMethods from '../../helper/common-methods';
import { showNotification } from '../../helper/notifications';
import shortTextService from '../../services/short-text';
import {
  SHORT_TEXT_COPY_BY_CUSTOMER,
  SHORT_TEXT_DELETE_BY_CUSTOMER,
  SHORT_TEXT_EDIT_BY_CUSTOMER,
  // eslint-disable-next-line prettier/prettier
  SHORT_TEXT_FOR_CUSTOMER
} from '../types';

export function getShortTextForCustomer(params, query, actionData) {
  return (dispatch) =>
    shortTextService
      .getShortTextForCustomer(params, query)
      .then((response) => {
        dispatch({
          type: SHORT_TEXT_FOR_CUSTOMER,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function copyShortTextByCustomer(data, params, query, actionData) {
  return (dispatch) =>
    shortTextService
      .copyShortTextByCustomer(data, params, query)
      .then((response) => {
        dispatch({
          type: SHORT_TEXT_COPY_BY_CUSTOMER,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });

        showNotification({
          type: 'success',
          text: 'Short text copied successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function editShortTextByCustomer(data, params) {
  return (dispatch) =>
    shortTextService
      .editShortTextByCustomer(data, params)
      .then((response) => {
        dispatch({
          type: SHORT_TEXT_EDIT_BY_CUSTOMER,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Short text updated successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function addShortTextByCustomer(data, params) {
  return () =>
    shortTextService
      .addShortTextByCustomer(data, params)
      .then(() => {
        showNotification({
          type: 'success',
          text: 'Short text added successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function deleteShortTextByCustomer(params, query, actionData) {
  return (dispatch) =>
    shortTextService
      .deleteShortTextByCustomer(params, query)
      .then((response) => {
        dispatch({
          type: SHORT_TEXT_DELETE_BY_CUSTOMER,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });

        showNotification({
          type: 'success',
          text: 'Short text deleted successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function bulkShortTextUploadByAdmin(data, params, onUploadProgress) {
  return () =>
    shortTextService
      .bulkShortTextUploadByAdmin(data, params, onUploadProgress)
      .then(() => {
        showNotification({
          type: 'success',
          text: 'Short text uploaded successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}
