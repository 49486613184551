import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { subscriptionListProps } from '../../common/common-props';
import CategoryCard from './category-card';

export default function CategoryList(props) {
  const { categoriesList, cardOnClick } = props;

  return (
    <Container fluid className="px-lg-4">
      <Row className="mb-4 ">
        <Col md={12} className="d-flex justify-content-start">
          <span className="ml-n3 header-title ">Categories</span>
        </Col>
      </Row>
      <Row className="bg-white rounded-lg py-2 px-2">
        {categoriesList.map((item) => (
          <Col lg={4} md={6} className="py-2 px-2" key={item.id}>
            <CategoryCard subscription={item} cardOnClick={cardOnClick} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

CategoryList.propTypes = {
  categoriesList: subscriptionListProps.isRequired,
  cardOnClick: PropTypes.func.isRequired,
};

CategoryList.defaultProps = {};
