import PropTypes from 'prop-types';
import { Col, Container, Modal } from 'react-bootstrap';
import { ICON_SIZE } from '../../../../helper/constant-values';
import useModalAndToolTip from '../../../../helper/hooks/useModalAndToolTip';
import { CloseCircleIcon } from '../../../../helper/icons/common-icons';
import { singleConsumerProps } from '../consumer-common-props';
import Profile from './profile';

export default function ConsumerDetailsModal(props) {
  const { showModal, onCloseModal, consumerItem, dataLoading } = props;

  useModalAndToolTip(showModal);

  return (
    <Modal
      show={showModal}
      onHide={onCloseModal}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
      scrollable
    >
      <Modal.Header>
        <Col xs={10} className="text-truncate">
          <span className="h5">{dataLoading ? 'Loading...' : consumerItem.url}</span>
        </Col>
        <Col xs={2} className="mt-2 mt-sm-0 text-right">
          <CloseCircleIcon
            className="text-muted cursor-pointer"
            width={ICON_SIZE}
            height={ICON_SIZE}
            onClick={onCloseModal}
          />
        </Col>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          {dataLoading && (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}

          {!dataLoading && <Profile consumerDetails={consumerItem.consumerDetails} />}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

ConsumerDetailsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  consumerItem: singleConsumerProps,
  dataLoading: PropTypes.bool,
};

ConsumerDetailsModal.defaultProps = {
  dataLoading: false,
  consumerItem: {
    id: 0,
    email: '',
    fullName: '',
    url: '',
    username: '',
    consumerDetails: {},
  },
};
