import { callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const audioService = {
  getShortTextForCustomer: async (params, query) => {
    const { method, endpoint } = endpoints.getShortTextForCustomer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  copyShortTextByCustomer: async (data, params, query) => {
    const { method, endpoint } = endpoints.copyShortTextByCustomer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, data, url: requestUrl });

    return response;
  },

  editShortTextByCustomer: async (data, params) => {
    const { method, endpoint } = endpoints.editShortTextByCustomer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, data, url: requestUrl });

    return response;
  },

  addShortTextByCustomer: async (data, params) => {
    const { method, endpoint } = endpoints.addShortTextByCustomer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, data, url: requestUrl });

    return response;
  },

  deleteShortTextByCustomer: async (params, query) => {
    const { method, endpoint } = endpoints.deleteShortTextByCustomer;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  bulkShortTextUploadByAdmin: async (data, params, onUploadProgress) => {
    const { method, endpoint } = endpoints.bulkShortTextUploadByAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data, onUploadProgress });

    return response;
  },
};

export default audioService;
