import PropTypes from 'prop-types';

export const singleTokenTransactionProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  tokenDebit: PropTypes.number.isRequired,
  tokenCredit: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  newTokenBalance: PropTypes.number.isRequired,
});

export const tokenTransactionListProps = PropTypes.shape({
  totalItems: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(singleTokenTransactionProps.isRequired).isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
});
