import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { RingLoader } from 'react-spinners';
import moment from 'moment';
import colors from '../../../../helper/colors';
import { getCustomerUsage } from '../../../../redux/actions/revenue-report-action';
import { usageReportProps } from '../revenue-common-props';
import LineChart from '../chart/line-chart';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../helper/constant-values';

function UsageLineChart(props) {
  const { startDate, endDate, usageCustomer, getUsage } = props;

  const [isLoading, setIsLoading] = useState(false);

  const { customerUrl } = useParams();

  useEffect(() => {
    setIsLoading(true);

    const params = { customerUrl };
    const query = {
      startDate: moment(startDate).format(DEFAULT_DATE_TIME_FORMAT),
      endDate: moment(endDate).format(DEFAULT_DATE_TIME_FORMAT),
    };

    getUsage(params, query)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [startDate, endDate]);

  return (
    <Container className="mt-4 bg-white rounded-lg container-uxl">
      <Row className="pt-4">
        <Col>
          <span className="h4 ml-lg-4">Usage Summary</span>
        </Col>
      </Row>
      {isLoading && (
        <div className="revenue-loder ml-5">
          <RingLoader loading size={100} color={colors.secondary} />
        </div>
      )}
      {!isLoading && (
        <Row className="mt-4 mt-lg-0">
          <Col md={3} className="d-flex flex-column text-center justify-content-center">
            <Row className="d-flex flex-column text-center justify-content-center pb-5">
              <small>Total Sunbursts</small>
              <span className="h1 pt-2" style={{ color: 'rgb(55,112,246)' }}>
                {usageCustomer.totalSmsSunbrusts.toLocaleString()}
              </span>
            </Row>
            <Row className="d-flex flex-column text-center justify-content-center">
              <small>Total Readings</small>
              <span className="h1 pt-2" style={{ color: 'rgb(255, 99, 132)' }}>
                {usageCustomer.totalAudioPlays.toLocaleString()}
              </span>
            </Row>
          </Col>
          <Col md={9} className="p-lg-5">
            <LineChart
              title="Daily Usage"
              labels={usageCustomer.usageData.map((data) => data.createdAt)}
              datasets={[
                {
                  label: 'Sunbursts',
                  data: usageCustomer.usageData.map((data) => data.smsSunbrusts),
                  borderColor: 'rgb(55,112,246)',
                  backgroundColor: 'rgba(0,58,250,0.5)',
                  pointRadius: 4,
                },
                {
                  label: 'Readings',
                  data: usageCustomer.usageData.map((data) => data.audioPlays),
                  borderColor: 'rgb(255, 99, 132)',
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  pointRadius: 4,
                },
              ]}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}

UsageLineChart.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  usageCustomer: usageReportProps.isRequired,
  getUsage: PropTypes.func.isRequired,
};

UsageLineChart.defaultProps = {};

const mapStateToProps = (state) => ({
  usageCustomer: state.revenue.usageCustomer,
});

const mapDispatchToProps = {
  getUsage: getCustomerUsage,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsageLineChart);
