module.exports = {
  BASE_URL: process.env.REACT_APP_API_URL,
  getCustomerDetailsByUrl: {
    method: 'get',
    endpoint: '/v1/customer-details-by-url/{url}',
  },
  getCustomerPackages: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/packages',
  },
  getConsumerPackages: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/packages',
  },
  toggleAutoPaymentButton: {
    method: 'put',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/package/toggleAutoRenewable',
  },
  changePassword: {
    method: 'put',
    endpoint: '/v1/customers/{customerUrl}/password/change',
  },
  forgotPassword: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/password/forgot',
  },
  getConsumerCategories: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/consumer-category',
  },
  packagePurchaseByStripe: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/package/purchase/stripe',
  },
  packageCreateOrderByPaypal: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/package/paypal/order',
  },
  packageCheckoutOrderByPaypal: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/package/paypal/checkout',
  },
  packagePurchaseWithStripeDefaultPayment: {
    method: 'post',
    endpoint:
      '/v1/customers/{customerUrl}/consumers/{consumerId}/package/purchase/default-payment/stripe',
  },
  packagePurchaseWithPaypalDefaultPayment: {
    method: 'post',
    endpoint:
      '/v1/customers/{customerUrl}/consumers/{consumerId}/package/purchase/default-payment/paypal',
  },
  getSupportTicketsForConsumer: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/tickets',
  },
  createSupportTicket: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/tickets',
  },
  customerLogin: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/login',
  },
  adminLogin: {
    method: 'post',
    endpoint: '/v1/admin/login',
  },
  consumerSignUp: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/consumers/signup',
  },
  otpVerify: {
    method: 'post',
    endpoint: '/v1/user/shortcode/verify',
  },
  otpResend: {
    method: 'post',
    endpoint: '/v1/user/shortcode/resend',
  },
  otpValidityCheck: {
    method: 'get',
    endpoint: '/v1/user/shortcode/validity',
  },
  tokenVerify: {
    method: 'get',
    endpoint: '/v1/token-validity',
  },
  logout: {
    method: 'get',
    endpoint: '/v1/user/logout',
  },
  consumerLanding: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/landingpage',
  },
  getConsumerProfile: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/profile',
  },
  getConsumerProfileDefaultCardInfo: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/default-payment',
  },
  updateConsumerProfile: {
    method: 'put',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/profile',
  },
  getCustomerProfile: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/profile',
  },
  updateCustomerProfile: {
    method: 'put',
    endpoint: '/v1/customers/{customerUrl}/profile',
  },
  profilePasswordChange: {
    method: 'put',
    endpoint: '/v1/customers/{customerUrl}/profile/password',
  },
  getAudiosForConsumer: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/audio',
  },
  chargeAudioForConsumer: {
    method: 'put',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/audio/{audioId}/charge',
  },
  getAudiosForCustomer: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/audio',
  },
  deleteAudioByCustomer: {
    method: 'delete',
    endpoint: '/v1/customers/{customerUrl}/audio/{audioId}',
  },
  updateAudioByCustomer: {
    method: 'put',
    endpoint: '/v1/customers/{customerUrl}/audio/{audioId}',
  },
  copyAudioByCustomer: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/audio/copy',
  },
  addAudioByCustomer: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/audio',
  },
  getCustomerRevenue: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/revenue',
  },
  getCustomerUsage: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/usage',
  },
  getCustomerGrossSales: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/gross-sales',
  },
  getAdminProfile: {
    method: 'get',
    endpoint: '/v1/admin/profile',
  },
  updateAdminProfile: {
    method: 'put',
    endpoint: '/v1/admin/profile',
  },
  adminProfilePasswordChange: {
    method: 'put',
    endpoint: '/v1/admin/profile/password',
  },
  subscribeToCategory: {
    method: 'put',
    endpoint:
      '/v1/customers/{customerUrl}/consumers/{consumerId}/subscription/{consumerCategoryId}/subscribe',
  },
  unsubscribeToCategory: {
    method: 'put',
    endpoint:
      '/v1/customers/{customerUrl}/consumers/{consumerId}/subscription/{consumerCategoryId}/unsubscribe',
  },
  getShortTextForCustomer: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/short-text',
  },
  addShortTextByCustomer: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/short-text',
  },
  copyShortTextByCustomer: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/short-text/copy',
  },
  editShortTextByCustomer: {
    method: 'put',
    endpoint: '/v1/customers/{customerUrl}/short-text/{shortTextId}',
  },
  deleteShortTextByCustomer: {
    method: 'delete',
    endpoint: '/v1/customers/{customerUrl}/short-text/{shortTextId}',
  },
  bulkShortTextUploadByAdmin: {
    method: 'post',
    endpoint: '/v1/admin/bulk-short-text/{customerId}',
  },
  getConsumerListForAdmin: {
    method: 'get',
    endpoint: '/v1/admin/consumers',
  },
  getCustomerListForAdmin: {
    method: 'get',
    endpoint: '/v1/admin/customers',
  },
  getCustomerDetailsForAdmin: {
    method: 'get',
    endpoint: '/v1/admin/customer/{customerId}',
  },
  getConsumerDetailsForAdmin: {
    method: 'get',
    endpoint: '/v1/admin/consumer-details/{customerUrl}/{consumerId}',
  },
  updateConsumerTokenByAdmin: {
    method: 'put',
    endpoint: '/v1/admin/token-balance/{customerUrl}/{consumerId}',
  },
  updateConsumerByAdmin: {
    method: 'put',
    endpoint: '/v1/admin/consumer/{customerUrl}/{consumerId}',
  },
  getPaymentHistoriesForAdmin: {
    method: 'get',
    endpoint: '/v1/admin/payment-histories',
  },
  paymentRefundByAdmin: {
    method: 'post',
    endpoint: '/v1/admin/payment-history/refund',
  },
  createCustomerByAdmin: {
    method: 'post',
    endpoint: '/v1/admin/customer',
  },
  updateCustomerByAdmin: {
    method: 'put',
    endpoint: '/v1/admin/customer/{customerId}',
  },
  deleteCustomerByAdmin: {
    method: 'delete',
    endpoint: '/v1/admin/delete-customer/{customerId}',
  },
  getConsumerCategoryListForAdmin: {
    method: 'get',
    endpoint: '/v1/admin/consumer-categories',
  },
  addConsumerCategoryByAdmin: {
    method: 'post',
    endpoint: '/v1/admin/consumer-category',
  },
  updateConsumerCategoryByAdmin: {
    method: 'put',
    endpoint: '/v1/admin/consumer-category/{consumerCategoryId}',
  },
  deleteConsumerCategoryByAdmin: {
    method: 'delete',
    endpoint: '/v1/admin/consumer-category/{consumerCategoryId}',
  },
  getPackageListForAdmin: {
    method: 'get',
    endpoint: '/v1/admin/packages',
  },
  addPackageByAdmin: {
    method: 'post',
    endpoint: '/v1/admin/package',
  },
  updatePackageByAdmin: {
    method: 'put',
    endpoint: '/v1/admin/package/{packageId}',
  },
  deletePackageByAdmin: {
    method: 'delete',
    endpoint: '/v1/admin/package/{packageId}',
  },
  getTokenTransactionByAdmin: {
    method: 'get',
    endpoint: '/v1/admin/token-transactions',
  },
  getDefaultPaymentInfoByConsumer: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/default-payment',
  },
  addStripeDefaultPaymentInfoByConsumer: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/default-payment/stripe',
  },
  updateStripeDefaultPaymentInfoByConsumer: {
    method: 'put',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/default-payment/stripe',
  },
  deleteDefaultPaymentInfoByConsumer: {
    method: 'delete',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/default-payment',
  },
  getPaypalDefaultPaymentInfoPage: {
    method: 'get',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/default-payment/paypal',
  },
  confirmPaypalDefaultPaymentInfo: {
    method: 'post',
    endpoint: '/v1/customers/{customerUrl}/consumers/{consumerId}/default-payment/paypal/confirm',
  },
};
