import commonMethods from '../../helper/common-methods';
import {
  CONSUMER_DETAILS_FOR_ADMIN,
  CONSUMER_LIST_BY_ADMIN,
  // eslint-disable-next-line prettier/prettier
  CONSUMER_UPDATE_BY_ADMIN
} from '../types';

const initialState = {
  consumerListForAdmin: {
    totalItems: 0,
    data: [],
    totalPages: 1,
    currentPage: 1,
  },
  consumerDetailsForAdmin: {
    username: '',
    fullName: '',
    email: '',
    contactNumber: '',
    smsPhoneNumber: '',
    stripeId: '',
    profilePic: '',
    unverifiedEmail: '',
    unverifiedSmsPhoneNumber: '',
    tokenBalance: 0,
    defaultCategoryName: '',
    subscriptions: [],
  },
};

const consumerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSUMER_LIST_BY_ADMIN: {
      return commonMethods.paginationStateUpdate(state, action, 'consumerListForAdmin');
    }

    case CONSUMER_DETAILS_FOR_ADMIN: {
      return {
        ...state,
        consumerDetailsForAdmin: {
          ...state.consumerDetailsForAdmin,
          ...action.data,
        },
      };
    }

    case CONSUMER_UPDATE_BY_ADMIN: {
      const updatedData = state.consumerListForAdmin.data.map((item) => {
        if (item.id === action.data.id) return action.data;
        return item;
      });

      return {
        ...state,
        consumerListForAdmin: {
          ...state.consumerListForAdmin,
          data: updatedData,
        },
      };
    }

    default:
      return state;
  }
};

export default consumerReducer;
