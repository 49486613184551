import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Figure, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import defaultProfilePic from '../../../assets/image/defaultProfilePic.png';
import commomMethods from '../../../helper/common-methods';
import { ADMIN, CONSUMER, CUSTOMER, ICON_SIZE } from '../../../helper/constant-values';
import { EditIcon, LogoutIcon } from '../../../helper/icons/common-icons';
import { logout } from '../../../redux/actions/auth-action';
import {
  getAdminProfile,
  getConsumerProfile,
  // eslint-disable-next-line prettier/prettier
  getCustomerProfile
} from '../../../redux/actions/profile-action';
import {
  adminProfileProps,
  consumerProfileProps,
  customerProfileProps,
  // eslint-disable-next-line prettier/prettier
  userInformationProps
} from '../profile-common-props';
import DefaultPaymentInfo from './default-payment/default-payment-info';
import ProfileInfo from './profile-info';
import './profile.css';
import TokenSubscriptionInfo from './token-subscription-info';

function Profile(props) {
  const {
    goToEditUserProfile,
    goToUserChangePassword,
    goToUpdateDefaultPayment,
    userLogout,
    userInfoState,
    consumerProfileState,
    customerProfileState,
    adminProfileState,
    customerProfile,
    consumerProfile,
    adminProfile,
  } = props;

  const { type: userType } = userInfoState;

  const userBtnColor = userType === ADMIN ? 'outline-secondary' : 'outline-primary';

  const [passwordLastChangeText, setPasswordLastChangeText] = useState(
    'Password changed 0 day ago'
  );

  const history = useHistory();
  const { customerUrl } = useParams();

  const logOut = () => {
    userLogout().then(() => {
      if (userType === ADMIN) {
        history.push('/admin/login');
      } else {
        history.push(`/${customerUrl}/login`);
      }
    });
  };

  useEffect(() => {
    if (userInfoState.type === CUSTOMER) {
      customerProfile({ customerUrl });
    } else if (userInfoState.type === CONSUMER) {
      consumerProfile({ customerUrl, consumerId: userInfoState.id });
    } else {
      adminProfile();
    }
  }, []);

  useEffect(() => {
    let passwordLastUpdated = '';
    if (userInfoState.type === ADMIN) {
      passwordLastUpdated = adminProfileState.passwordLastUpdatedAt;
    } else if (userInfoState.type === CONSUMER) {
      passwordLastUpdated = consumerProfileState.passwordLastUpdatedAt;
    } else {
      passwordLastUpdated = customerProfileState.passwordLastUpdatedAt;
    }

    const [day, month, year] =
      commomMethods.getDayMonthYearDifferenceWithCurrentDate(passwordLastUpdated);

    let lastPasswordText = '';

    if (year) {
      if (!month) lastPasswordText = `Password changed ${year} year ago`;
      else lastPasswordText = `Password changed ${year} year & ${month} month ago`;
    } else if (month) {
      lastPasswordText = `Password changed ${month} month ago`;
    } else {
      lastPasswordText = `Password changed ${day} days ago`;
    }

    setPasswordLastChangeText(lastPasswordText);
  }, [
    consumerProfileState.passwordLastUpdatedAt,
    customerProfileState.passwordLastUpdatedAt,
    adminProfileState.passwordLastUpdatedAt,
  ]);

  return (
    <>
      <Row>
        <Col xs={6} className="pl-0">
          <h4 className="pt-2 pl-1">My Profile</h4>
        </Col>
        <Col className="pr-0">
          <Button variant={userBtnColor} className="float-right" size="lg" onClick={logOut}>
            <LogoutIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-2" />
            Logout
          </Button>
        </Col>
      </Row>
      <Row className="mt-4 mb-4 mb-sm-0 pt-5 rounded-corner bg-white">
        <Col lg={3} className="d-flex justify-content-center pt-lg-3">
          <Figure>
            <Figure.Image
              width={134}
              height={134}
              src={commomMethods.getImageApi(userInfoState.profilePic) || defaultProfilePic}
              fluid={false}
              roundedCircle
            />
            <h5 className="text-center pt-4">{userInfoState.fullName}</h5>
          </Figure>
        </Col>
        <Col lg={1} className="d-none d-lg-block border-left vertical-divider" />
        <Col lg={1} className="d-block d-lg-none border-top ml-4 mr-4 mb-2 mb-sm-4" />
        <Col lg={8} className="mr-2 ml-2 ml-lg-5">
          {userType === CONSUMER && (
            <>
              <DefaultPaymentInfo
                userBtnColor={userBtnColor}
                goToUpdateDefaultPayment={goToUpdateDefaultPayment}
              />
              <TokenSubscriptionInfo />
            </>
          )}

          <ProfileInfo userBtnColor={userBtnColor} goToEditUserProfile={goToEditUserProfile} />

          <Row className="d-block d-sm-none">
            <Col>
              <Button
                variant={userBtnColor}
                className="float-right button-style"
                size="lg"
                block
                onClick={goToEditUserProfile}
              >
                <EditIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
                Edit Profile
              </Button>
            </Col>
          </Row>
          <Row className="pt-2 mb-5 mb-sm-0">
            <Col sm={6} className="mb-sm-2">
              <h4 className="pb-1 pt-5 pb-sm-3 pt-sm-3">Password</h4>
              <p className="text-info">{passwordLastChangeText}</p>
            </Col>
            <Col className="d-none d-sm-block pl-0" sm={6}>
              <Button
                variant={userBtnColor}
                className="float-sm-right"
                size="lg"
                onClick={goToUserChangePassword}
              >
                <EditIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
                Change Password
              </Button>
            </Col>

            <Col className="d-block d-sm-none">
              <Button
                variant={userBtnColor}
                className="float-sm-right"
                size="lg"
                onClick={goToUserChangePassword}
                block
              >
                <EditIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
                Change Password
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

Profile.propTypes = {
  goToUserChangePassword: PropTypes.func.isRequired,
  consumerProfile: PropTypes.func.isRequired,
  goToUpdateDefaultPayment: PropTypes.func.isRequired,
  adminProfile: PropTypes.func.isRequired,
  customerProfile: PropTypes.func.isRequired,
  goToEditUserProfile: PropTypes.func.isRequired,
  userLogout: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
  consumerProfileState: consumerProfileProps.isRequired,
  customerProfileState: customerProfileProps.isRequired,
  adminProfileState: adminProfileProps.isRequired,
};

Profile.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
  consumerProfileState: state.profile.consumerProfile,
  customerProfileState: state.profile.customerProfile,
  adminProfileState: state.profile.adminProfile,
});

const mapDispatchToProps = {
  userLogout: logout,
  consumerProfile: getConsumerProfile,
  customerProfile: getCustomerProfile,
  adminProfile: getAdminProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
