import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  // eslint-disable-next-line prettier/prettier
  useStripe
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import stripeLogo from '../../../../assets/image/stripe.png';
import { showNotification } from '../../../../helper/notifications';
import {
  addStripeDefaultCardInfoByConsumer,
  getDefaultCardInfoByConsumer,
  // eslint-disable-next-line prettier/prettier
  updateStripeDefaultPaymentInfoByConsumer
} from '../../../../redux/actions/payments-action';
import { userInformationProps } from '../../../common/common-props';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import { defaultPaymentInfoProps } from '../../profile-common-props';

function StripeDefaultPayment(props) {
  const stripe = useStripe();
  const elements = useElements();

  const {
    userInfoState,
    goToUserProfile,
    getDefaultCardInfo,
    addDefaultCardInfo,
    updateDefaultCardInfo,
    defaultPaymentInfo,
    paymentProcessing,
    setPaymentProcessing,
  } = props;

  const [name, setName] = useState('');
  const { customerUrl } = useParams();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [cardProcessing, setCardProcessing] = useState(false);
  const [isCardNumberError, setIsCardNumberError] = useState(false);
  const [isCardCVVError, setIsCardCVVError] = useState(false);
  const [isCardExpiryError, setIsCardExpiryError] = useState(false);
  const [isCardNameError, setIsCardNameError] = useState(false);
  const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState('');
  const [cardNameErrorMessage, setCardNameErrorMessage] = useState('');
  const [cardCVVErrorMessage, setCardCVVeErrorMessage] = useState('');
  const [cardExpiryErrorMessage, setCardExpiryErrorMessage] = useState('');

  const { id: consumerId } = userInfoState;

  const onChangeCardNumber = (event) => {
    if (event.error) {
      setIsCardNumberError(true);
      setCardNumberErrorMessage(event.error.message);
    } else setIsCardNumberError(false);
  };

  const onChangeCardCVV = (event) => {
    if (event.error) {
      setIsCardCVVError(true);
      setCardCVVeErrorMessage(event.error.message);
    } else setIsCardCVVError(false);
  };

  const onChangeCardExpaireDate = (event) => {
    if (event.error) {
      setIsCardExpiryError(true);
      setCardExpiryErrorMessage(event.error.message);
    } else setIsCardExpiryError(false);
  };

  const onChangeName = (event) => {
    const { value } = event.target;

    const regexForAnyDigit = /^[a-zA-Z ]*$/;

    if (!regexForAnyDigit.test(value)) {
      setIsCardNameError(true);
      setCardNameErrorMessage('Please enter a valid Name');
    } else {
      setIsCardNameError(false);
      setName(value);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return;
    }

    setCardProcessing(true);
    setPaymentProcessing(true);

    stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name,
        },
      })
      .then((payload) => {
        const paymentData = {
          paymentId: payload.paymentMethod.id,
        };

        const query = {
          customerUrl,
          consumerId,
        };

        const defaultPaymentMethodApi = defaultPaymentInfo.isStripePayment
          ? updateDefaultCardInfo
          : addDefaultCardInfo;

        defaultPaymentMethodApi(paymentData, query)
          .then(() => {
            setCardProcessing(false);
            setPaymentProcessing(false);
            goToUserProfile();
          })
          .catch(() => {
            setCardProcessing(false);
            setPaymentProcessing(false);
          });
      })
      .catch(() => {
        setCardProcessing(false);

        showNotification({
          type: 'error',
          text: 'Error while adding default payment.',
        });
      });
  };

  const inputStyle = {
    base: {
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  };

  useEffect(() => {
    if (!stripe || !elements) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
  }, [stripe, elements]);

  useEffect(() => {
    if (isCardNumberError || isCardCVVError || isCardExpiryError || isCardNameError)
      setDisableSubmitButton(true);
    else setDisableSubmitButton(false);
  }, [isCardNumberError, isCardCVVError, isCardExpiryError, isCardNameError]);

  useEffect(() => {
    getDefaultCardInfo({ customerUrl, consumerId: userInfoState.id });
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Container>
        <Row className="border rounded-lg pb-4">
          <Col>
            <Row className="py-3">
              <Col className="px-lg-5 pl-md-5">
                <Form.Label>Credit or Debit Card</Form.Label>
                <CardNumberElement
                  options={{
                    style: inputStyle,
                  }}
                  onChange={onChangeCardNumber}
                />
                {isCardNumberError ? (
                  <div className="error-label">
                    <span className="small">* {cardNumberErrorMessage}</span>
                  </div>
                ) : (
                  <div className="empty-label" />
                )}

                <Form.Label>Name</Form.Label>
                <input
                  type="text"
                  className="card-input-name"
                  placeholder="Name"
                  width="500px"
                  onChange={onChangeName}
                />
                {isCardNameError ? (
                  <div className="error-label">
                    <span className="small">* {cardNameErrorMessage}</span>
                  </div>
                ) : (
                  <div className="empty-label" />
                )}

                <Form.Label>CVV</Form.Label>
                <CardCvcElement
                  options={{
                    style: inputStyle,
                    placeholder: 'CVV',
                  }}
                  onChange={onChangeCardCVV}
                />
                {isCardCVVError ? (
                  <div className="error-label">
                    <span className="small">* {cardCVVErrorMessage}</span>
                  </div>
                ) : (
                  <div className="empty-label" />
                )}

                <Form.Label>Expire Date</Form.Label>
                <CardExpiryElement
                  options={{
                    style: inputStyle,
                  }}
                  onChange={onChangeCardExpaireDate}
                />
                {isCardExpiryError ? (
                  <div className="error-label">
                    <span className="small">* {cardExpiryErrorMessage}</span>
                  </div>
                ) : (
                  <div className="empty-label" />
                )}
              </Col>
            </Row>

            <Row className="pt-2">
              <Col className="d-flex justify-content-sm-end justify-content-between">
                <Button
                  type="submit"
                  variant="warning"
                  className="px-4"
                  disabled={disableSubmitButton || cardProcessing || paymentProcessing}
                >
                  <DataSavingSpinner
                    savingText="Processing"
                    defaultText={
                      defaultPaymentInfo.isStripePayment || defaultPaymentInfo.isPaypalPayment
                        ? 'Update Default Card'
                        : 'Add Default Card'
                    }
                    dataSaving={cardProcessing || paymentProcessing}
                  />
                </Button>
              </Col>
            </Row>

            <Row className="pt-3">
              <Col xs={12}>
                <img src={stripeLogo} alt="stripeLogo" />{' '}
                <span> &nbsp; Credit card processing by Stripe</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </form>
  );
}

StripeDefaultPayment.propTypes = {
  goToUserProfile: PropTypes.func.isRequired,
  getDefaultCardInfo: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
  addDefaultCardInfo: PropTypes.func.isRequired,
  updateDefaultCardInfo: PropTypes.func.isRequired,
  defaultPaymentInfo: defaultPaymentInfoProps.isRequired,
  paymentProcessing: PropTypes.func.isRequired,
  setPaymentProcessing: PropTypes.bool.isRequired,
};

StripeDefaultPayment.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
  defaultPaymentInfo: state.payments.consumerDefaultPaymentInfo,
});

const mapDispatchToProps = {
  addDefaultCardInfo: addStripeDefaultCardInfoByConsumer,
  updateDefaultCardInfo: updateStripeDefaultPaymentInfoByConsumer,
  getDefaultCardInfo: getDefaultCardInfoByConsumer,
};

export default connect(mapStateToProps, mapDispatchToProps)(StripeDefaultPayment);
