import commonMethods from '../../helper/common-methods';
import {
  CONSUMER_CATEGORY_ADD_BY_ADMIN,
  CONSUMER_CATEGORY_DELETE_BY_ADMIN,
  CONSUMER_CATEGORY_UPDATE_BY_ADMIN,
  GET_CONSUMER_CATEGORY_LIST_FOR_ADMIN,
  GET_CONSUMER_CATEGORY_WITH_MEDIUM,
  SUBSCRIBE_TO_CATEGORY,
  // eslint-disable-next-line prettier/prettier
  UNSUBSCRIBE_TO_CATEGORY
} from '../types';

const initialState = {
  category: [],
  categoryForAdmin: {
    totalItems: 0,
    data: [],
    totalPages: 1,
    currentPage: 1,
  },
};

const updateSubscription = (state, action) =>
  state.category.map((item) => {
    if (item.id === action.data.consumerCategoryId) {
      return {
        ...item,
        subscribedMediums: action.data.subscribedMediums,
        isSubscribed: action.data.isSubscribed,
      };
    }

    return item;
  });

const consumerCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONSUMER_CATEGORY_WITH_MEDIUM:
      return {
        ...state,
        category: action.data,
      };

    case SUBSCRIBE_TO_CATEGORY:
      return {
        ...state,
        category: updateSubscription(state, action),
      };

    case UNSUBSCRIBE_TO_CATEGORY:
      return {
        ...state,
        category: updateSubscription(state, action),
      };

    case GET_CONSUMER_CATEGORY_LIST_FOR_ADMIN:
      return commonMethods.paginationStateUpdate(state, action, 'categoryForAdmin');

    case CONSUMER_CATEGORY_DELETE_BY_ADMIN: {
      return commonMethods.deletePaginationState(state, action, 'categoryForAdmin');
    }

    case CONSUMER_CATEGORY_UPDATE_BY_ADMIN: {
      return commonMethods.updateOnPaginationState(state, action, 'categoryForAdmin');
    }

    case CONSUMER_CATEGORY_ADD_BY_ADMIN: {
      return commonMethods.addPaginationState(state, action, 'categoryForAdmin');
    }

    default:
      return state;
  }
};

export default consumerCategoryReducer;
