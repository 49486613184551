import PropTypes from 'prop-types';

export const userInformationProps = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  customerUrl: PropTypes.string.isRequired,
  customerLogo: PropTypes.string.isRequired,
  tokenBalance: PropTypes.number.isRequired,
  profilePic: PropTypes.string.isRequired,
  smsPhoneNumber: PropTypes.string,
  timeZone: PropTypes.string,
});

export const singleSubscriptionProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  deliveryMediums: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      numberOfTokens: PropTypes.number.isRequired,
      isDisable: PropTypes.bool.isRequired,
    })
  ),
});

export const deliveryMediumListProps = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    numberOfTokens: PropTypes.number.isRequired,
  })
);

export const subscriptionListProps = PropTypes.arrayOf(singleSubscriptionProps.isRequired);

export const subscriptionProps = PropTypes.shape({
  packages: PropTypes.arrayOf(singleSubscriptionProps.isRequired).isRequired,
});
