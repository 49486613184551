import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getCustomerProfile } from '../../../redux/actions/profile-action';
import { customerProfileProps } from '../../profile/profile-common-props';
import RevenueLineChart from './revenue-line-chart/revenue-line-chart';
import GrossSales from './gross-sales/gross-sales-container';
import UsageLineChart from './usage-line-chart/usage-line-chart';

const DateToggleButtons = {
  Today: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
  'This Week': [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
  'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
};

function RevenueChartItems(props) {
  const { customerProfileState, getProfile } = props;

  const { customerUrl } = useParams();

  const [dateRange, setDateRange] = useState(DateToggleButtons.Today);
  const [startDate, setStartDate] = useState(DateToggleButtons.Today[0]);
  const [endDate, setEndDate] = useState(DateToggleButtons.Today[1]);

  useEffect(() => {
    getProfile({ customerUrl });
  }, []);

  useEffect(() => {
    if (moment(dateRange[0]).isValid() && moment(dateRange[1]).isValid()) {
      setStartDate(moment(dateRange[0]).startOf('day').toDate());
      setEndDate(moment(dateRange[1]).endOf('day').toDate());
    }
  }, [dateRange]);

  return (
    <Container className="container-uxl">
      <Row>
        <Col className="px-0">
          <small className="float-right">Time Zone: {customerProfileState.timeZone}</small>
          <Container className="mt-4 bg-white rounded-lg container-uxl">
            <Row className="pt-3 pb-3 align-items-center">
              <Col md="9">
                <ToggleButtonGroup name="tbg-dates" onChange={setDateRange}>
                  {Object.keys(DateToggleButtons).map((key, index) => (
                    <ToggleButton id={`tbg-${index}`} key={key} value={DateToggleButtons[key]}>
                      {key}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Col>
              <Col md="3">
                <DatePicker
                  selectsRange
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  onChange={setDateRange}
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col className="px-0">
          <RevenueLineChart startDate={startDate} endDate={endDate} />
        </Col>
      </Row>
      <Row>
        <Col className="px-0">
          <UsageLineChart startDate={startDate} endDate={endDate} />
        </Col>
      </Row>
      <Row>
        <Col className="px-0">
          <GrossSales startDate={startDate} endDate={endDate} />
        </Col>
      </Row>
    </Container>
  );
}

RevenueChartItems.propTypes = {
  customerProfileState: customerProfileProps.isRequired,
  getProfile: PropTypes.func.isRequired,
};

RevenueChartItems.defaultProps = {};

const mapStateToProps = (state) => ({
  customerProfileState: state.profile.customerProfile,
});

const mapDispatchToProps = {
  getProfile: getCustomerProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(RevenueChartItems);
