import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { grossSalesArrayProps } from '../revenue-common-props';

export default function SalesTableDesktop({ salesData }) {
  const renderSalesDateList = () =>
    salesData.map((item) => (
      <Row key={item.id} className="pb-4">
        <OverlayTrigger
          transition={false}
          rootClose
          placement="bottom-start"
          overlay={<Tooltip>{item.name}</Tooltip>}
        >
          <Col md="6" className="text-truncate">
            {item.name}
          </Col>
        </OverlayTrigger>
        <Col md="2" className="text-right">
          {item.packageSoldQuantity.toLocaleString()}
        </Col>
        <Col md="2" className="text-right">
          {item.tokenCount.toLocaleString()}
        </Col>
        <Col md="2" className="text-right">
          ${item.totalAmount.toLocaleString()}
        </Col>
      </Row>
    ));

  const renderSalesDataTotalRow = () => (
    <Row className="font-weight-bold pb-4">
      <Col md="6">Total</Col>
      <Col md="2" className="text-right">
        {salesData.reduce((acc, item) => acc + (item.packageSoldQuantity || 0), 0).toLocaleString()}
      </Col>
      <Col md="2" className="text-right">
        {salesData.reduce((acc, item) => acc + (item.tokenCount || 0), 0).toLocaleString()}
      </Col>
      <Col md="2" className="text-right">
        ${salesData.reduce((acc, item) => acc + (item.totalAmount || 0), 0).toLocaleString()}
      </Col>
    </Row>
  );

  return (
    <>
      <Row className="font-weight-bold pt-4">
        <Col md="6">Package Name</Col>
        <Col md="2" className="text-right">
          Packages Sold
        </Col>
        <Col md="2" className="text-right">
          Token Count
        </Col>
        <Col md="2" className="text-right">
          Dollar Amount
        </Col>
      </Row>
      <Row>
        <Col>
          <hr className="bg-info" />
        </Col>
      </Row>
      {renderSalesDateList()}
      {renderSalesDataTotalRow()}
    </>
  );
}

SalesTableDesktop.propTypes = {
  salesData: grossSalesArrayProps.isRequired,
};

SalesTableDesktop.defaultProps = {};
