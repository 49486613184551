import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import 'react-pro-sidebar/dist/css/styles.css';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { ADMIN, CONSUMER, CUSTOMER, ICON_SIZE } from '../../../helper/constant-values';
import useClickOutside from '../../../helper/hooks/useClickOutside';
import useIsMounted from '../../../helper/hooks/useIsMounted';
import { LogoutIcon, SettingIcon } from '../../../helper/icons/common-icons';
import { clearConsumerPlayedAudio } from '../../../redux/actions/audio-action';
import { logout } from '../../../redux/actions/auth-action';
import { browserViewProps, userInformationProps } from '../common-props';
import Footer from '../footer/footer';
import Header from '../header/header';
import WarningModal from '../modals/warning-success-modal';
import NavigationBar from '../navbar/navigation-bar';
import './menu-bar-wrapper.css';

function MenuBarWrapper(props) {
  const {
    children,
    navBarIndex,
    headerTitle,
    userInfoState,
    browserState,
    consumerPlayedAudioState,
    clearPlayedAudio,
    logOutUser,
  } = props;

  const history = useHistory();
  const { customerUrl } = useParams();
  const profileRef = useRef(null);
  const location = useLocation();
  const isMounted = useIsMounted();

  const [showSidebar, setShowSidebar] = useState(false);
  const [showProfileDropDown, setShowProfileDropDown] = useState(false);
  const [viewArrowIcon, setViewArrowIcon] = useState(false);
  const [profileDropDownClickCount, setProfileDropDownClickCount] = useState(0);
  const [showTabChageModal, setShowTabChageModal] = useState(false);
  const [changeAudioTab, setChangeAudioTab] = useState(false);
  const [moveToProfilePage, setMoveToProfilePage] = useState(false);
  const [moveToPackagePage, setMoveToPackagePage] = useState(false);
  const [moveToLogoutPage, setMoveToLogoutPage] = useState(false);

  const goToProfilePage = () => {
    if (userInfoState.type === CONSUMER) {
      history.push(`/${customerUrl}/profile`);
    } else if (userInfoState.type === CUSTOMER) {
      history.push(`/${customerUrl}/customer/profile`);
    } else {
      history.push('/admin/profile');
    }
  };

  const goToPackagepage = () => {
    history.push(`/${customerUrl}/packages`);
  };

  const logOut = () => {
    logOutUser().then(() => {
      if (userInfoState.type === ADMIN) history.push('/admin/login');
      else history.push(`/${customerUrl}/login`);
    });
  };

  const onTabChageModalCancel = () => {
    setShowTabChageModal(false);
    setMoveToProfilePage(false);
    setMoveToPackagePage(false);
    setMoveToLogoutPage(false);
  };

  const onTabChageModalProceed = () => {
    setShowTabChageModal(false);
    setChangeAudioTab(true);
    clearPlayedAudio();

    if (moveToProfilePage) {
      setMoveToProfilePage(false);
      goToProfilePage();
    }

    if (moveToPackagePage) {
      setMoveToPackagePage(false);
      goToPackagepage();
    }

    if (moveToLogoutPage) {
      setMoveToLogoutPage(false);
      logOut();
    }
  };

  const onConsumerAudioFilesTabChage = () => {
    if (consumerPlayedAudioState.length !== 0) {
      setShowTabChageModal(true);
    } else {
      setChangeAudioTab(true);
    }
  };

  const customMenuClass =
    userInfoState.type === ADMIN ? 'admin-custom-menu' : 'custom-menu-menubar';
  const profileDropdownClass = `${customMenuClass} d-flex justify-content-between justify-content-sm-center justify-content-lg-between`;
  const backArrowClass =
    userInfoState.type !== ADMIN
      ? 'arrow-button text-primary d-block d-sm-none'
      : 'arrow-button text-secondary d-block d-sm-none';

  const onToggleSidebar = (value) => {
    if (isMounted.current) {
      setShowSidebar(value);
      if (value === false) {
        setShowProfileDropDown(false);
      }
    }
  };

  const onToggleProfileDropDown = () => {
    if (!browserState.isMobile) {
      setShowSidebar(!showSidebar);
      setViewArrowIcon(!showSidebar);
    }

    setShowProfileDropDown(!showProfileDropDown);
  };

  const onLogoutClick = () => {
    if (consumerPlayedAudioState.length !== 0 && userInfoState.type === CONSUMER) {
      setShowTabChageModal(true);
      setMoveToLogoutPage(true);
    } else {
      logOut();
    }
  };

  const onChangeProfilePage = () => {
    if (consumerPlayedAudioState.length !== 0 && userInfoState.type === CONSUMER) {
      setShowTabChageModal(true);
      setMoveToProfilePage(true);
    } else {
      goToProfilePage();
    }
  };

  const onPurchasingToken = () => {
    if (consumerPlayedAudioState.length !== 0) {
      setShowTabChageModal(true);
      setMoveToPackagePage(true);
    } else {
      goToPackagepage();
    }
  };

  useClickOutside(profileRef, () => {
    if (profileDropDownClickCount > 0) {
      setShowProfileDropDown(false);
    }

    setProfileDropDownClickCount((preValue) => preValue + 1);
  });

  useEffect(() => {
    let timeout;
    if (showSidebar) {
      timeout = setTimeout(() => {
        setViewArrowIcon(showSidebar);
      }, 200);
    } else {
      setViewArrowIcon(showSidebar);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [showSidebar]);

  useEffect(() => {
    if (!showProfileDropDown) {
      setProfileDropDownClickCount(0);
    }
  }, [showProfileDropDown]);

  useEffect(() => {
    setShowProfileDropDown(false);
  }, [location]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <WarningModal
        heading="Are you sure?"
        descriptionList={[
          'Do you want to exit current audio session?',
          'Next time you will be charged to play the same audio.',
        ]}
        leftBtnText="Cancel"
        rightBtnText="Proceed"
        showModal={showTabChageModal}
        onHideModal={onTabChageModalCancel}
        onModalSubmit={onTabChageModalProceed}
      />

      <Container fluid className="p-0 container-uxl-menubar">
        {showSidebar && viewArrowIcon && (
          <BsArrowLeftCircleFill
            size="2.8em"
            className={backArrowClass}
            onClick={() => onToggleSidebar(false)}
          />
        )}

        {showProfileDropDown && (
          <div className="custom-dropdown-menu shadow px-3 pt-2 mb-5 bg-white" ref={profileRef}>
            <p role="presentation" className={profileDropdownClass} onClick={onChangeProfilePage}>
              <span className="d-block d-sm-none d-lg-block">My Profile</span>
              <SettingIcon width={ICON_SIZE} height={ICON_SIZE} className="ml-0 ml-lg-5" />
            </p>
            <p role="presentation" className={profileDropdownClass} onClick={onLogoutClick}>
              <span className="d-block d-sm-none d-lg-block">Logout</span>
              <LogoutIcon width={ICON_SIZE} height={ICON_SIZE} className="ml-0 ml-lg-5" />
            </p>
          </div>
        )}

        <Row className="no-gutters">
          <Col className="col-sm-2 col-lg-navbar d-none d-sm-block">
            <NavigationBar
              navBarIndex={navBarIndex}
              onToggleProfileDropDown={onToggleProfileDropDown}
              showProfileDropDown={showProfileDropDown}
              setChangeAudioTab={setChangeAudioTab}
              onConsumerAudioFilesTabChage={onConsumerAudioFilesTabChage}
              changeAudioTab={changeAudioTab}
            />
          </Col>

          <Col className="col-sm-2 col-lg-navbar d-block d-sm-none mobile-flex">
            <NavigationBar
              forMobile
              toggled={showSidebar}
              onToggleProfileDropDown={onToggleProfileDropDown}
              showProfileDropDown={showProfileDropDown}
              onToggleSidebar={onToggleSidebar}
              navBarIndex={navBarIndex}
              setChangeAudioTab={setChangeAudioTab}
              onConsumerAudioFilesTabChage={onConsumerAudioFilesTabChage}
              changeAudioTab={changeAudioTab}
            />
          </Col>

          <Col className="d-flex flex-column justify-content-between min-vh-100 col-sm-10 col-lg-body">
            <Header
              headerTitle={headerTitle}
              onToggleSidebar={onToggleSidebar}
              onPurchasingToken={onPurchasingToken}
            />
            <div className="d-flex flex-column flex-grow-1 p-3 p-sm-4 bg-light">{children}</div>
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
}

MenuBarWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  navBarIndex: PropTypes.number,
  headerTitle: PropTypes.string.isRequired,
  logOutUser: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
  browserState: browserViewProps.isRequired,
  consumerPlayedAudioState: PropTypes.arrayOf(PropTypes.number).isRequired,
  clearPlayedAudio: PropTypes.func.isRequired,
};

MenuBarWrapper.defaultProps = {
  navBarIndex: -1,
};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
  browserState: state.browserData.view,
  consumerPlayedAudioState: state.audio.consumerPlayedAudio,
});

const mapDispatchToProps = {
  logOutUser: logout,
  clearPlayedAudio: clearConsumerPlayedAudio,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuBarWrapper);
