import PropTypes from 'prop-types';
import { useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { ICON_SIZE } from '../../../../helper/constant-values';
import useModalAndToolTip from '../../../../helper/hooks/useModalAndToolTip';
import { CloseCircleIcon } from '../../../../helper/icons/common-icons';
import { singleConsumerProps } from '../consumer-common-props';
import ConsumerProfileEdit from './consumer-profile-edit';
import TokenEdit from './token-edit';

export default function ConsumerEditModal(props) {
  const {
    showModal,
    onCloseModal,
    consumerItem,
    dataLoading,
    onTokenEditModalSubmit,
    dataSaving,
    onProfileEditModalSubmit,
  } = props;

  useModalAndToolTip(showModal);

  const [showConsumerEdit, setShowConsumerEdit] = useState(true);
  const [showTokenEdit, setShowTokenEdit] = useState(false);

  const onConsumerEditRadioClick = () => {
    setShowConsumerEdit(true);
    setShowTokenEdit(false);
  };

  const onTokenEditRadioClick = () => {
    setShowConsumerEdit(false);
    setShowTokenEdit(true);
  };

  return (
    <Modal
      show={showModal}
      onHide={onCloseModal}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header>
        <Col xs={10} className="text-truncate">
          <span className="h5">
            {dataLoading ? 'Loading...' : `${consumerItem.fullName} (${consumerItem.username})`}
          </span>
        </Col>
        <Col xs={2} className="mt-2 mt-sm-0 text-right">
          <CloseCircleIcon
            className="text-muted cursor-pointer"
            width={ICON_SIZE}
            height={ICON_SIZE}
            onClick={onCloseModal}
          />
        </Col>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          {dataLoading && (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}

          {!dataLoading && (
            <Row className="pb-4">
              <Col>
                <Form.Check type="radio" id="consumer-edit-modal-for-admin-radio">
                  <Form.Check.Input
                    type="radio"
                    className="consumer-ckeck-input"
                    checked={showConsumerEdit}
                    onChange={onConsumerEditRadioClick}
                  />
                  <Form.Check.Label className="ml-2 consumer-ckeck-lebel">
                    Edit Profile
                  </Form.Check.Label>
                </Form.Check>
              </Col>

              <Col>
                <Form.Check type="radio" id="consumer-edit-modal-for-admin-radio">
                  <Form.Check.Input
                    type="radio"
                    className="consumer-ckeck-input"
                    checked={showTokenEdit}
                    onChange={onTokenEditRadioClick}
                  />
                  <Form.Check.Label className="ml-2 consumer-ckeck-lebel">
                    Edit Token Balance
                  </Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
          )}

          {!dataLoading && showConsumerEdit && (
            <ConsumerProfileEdit
              dataSaving={dataSaving}
              onCloseModal={onCloseModal}
              onSubmitModal={onProfileEditModalSubmit}
            />
          )}

          {!dataLoading && showTokenEdit && (
            <TokenEdit
              consumerDetails={consumerItem.consumerDetails}
              dataSaving={dataSaving}
              onCloseModal={onCloseModal}
              onSubmitModal={onTokenEditModalSubmit}
            />
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

ConsumerEditModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  dataLoading: PropTypes.bool,
  consumerItem: singleConsumerProps,
  onTokenEditModalSubmit: PropTypes.func.isRequired,
  onProfileEditModalSubmit: PropTypes.func.isRequired,
  dataSaving: PropTypes.bool,
};

ConsumerEditModal.defaultProps = {
  dataLoading: false,
  dataSaving: false,
  consumerItem: {
    id: 0,
    email: '',
    fullName: '',
    url: '',
    username: '',
    tempEmail: '',
    consumerDetails: {},
  },
};
