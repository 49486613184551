import PropTypes from 'prop-types';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { ICON_SIZE } from '../../../helper/constant-values';
import useModalAndToolTip from '../../../helper/hooks/useModalAndToolTip';
import {
  CheckCircleIcon,
  CloseCircleIcon,
  InfoIcon,
  // eslint-disable-next-line prettier/prettier
  WarningIcon
} from '../../../helper/icons/common-icons';
import TextBody from './body-text';
import FooterButtons from './footer-buttons';
import './modal-style.css';

export default function WarningModal(props) {
  const {
    heading,
    description,
    descriptionList,
    leftBtnText,
    rightBtnText,
    showModal,
    onHideModal,
    onModalSubmit,
    isSuccessModal,
    disabledModal,
    processing,
    savingRightText,
    showInfoIcon,
    topHeaderText,
  } = props;

  useModalAndToolTip(showModal);

  return (
    <Modal
      show={showModal}
      onHide={onHideModal}
      size="lg"
      className="px-2"
      animation={false}
      centered
    >
      <Modal.Body className="text-center">
        <Container>
          <Row className="mb-3 py-3 border-bottom">
            <Col xs={7} className="d-flex justify-content-start">
              {!isSuccessModal && !showInfoIcon && (
                <>
                  <WarningIcon className="text-primary" width="1.8em" height="1.8em" />
                  <span className="h4 pl-2">{topHeaderText || 'Warning'}</span>
                </>
              )}

              {!isSuccessModal && showInfoIcon && (
                <>
                  <InfoIcon className="text-primary" width="1.8em" height="1.8em" />
                  <span className="h4 pl-2">{topHeaderText || 'Information'}</span>
                </>
              )}

              {isSuccessModal && (
                <>
                  <CheckCircleIcon className="success-icon" width="1.8em" height="1.8em" />
                  <span className="h4 pl-2">{topHeaderText || 'Success!'}</span>
                </>
              )}
            </Col>

            <Col className="mr-0 pr-0">
              <CloseCircleIcon
                className={`offset-10 text-muted ${
                  processing || disabledModal ? '' : 'cursor-pointer'
                }`}
                width={ICON_SIZE}
                height={ICON_SIZE}
                onClick={() => {
                  if (!processing) {
                    onHideModal();
                  }
                }}
              />
            </Col>
          </Row>

          <TextBody heading={heading} description={description} descriptionList={descriptionList} />

          <FooterButtons
            leftText={leftBtnText}
            rightText={rightBtnText}
            leftVariant="outline-info"
            rightVariant="primary"
            onHandleLeft={onHideModal}
            onHandleRight={onModalSubmit}
            disabledButtons={disabledModal}
            processing={processing}
            savingRightText={savingRightText}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}

WarningModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func.isRequired,
  onModalSubmit: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  leftBtnText: PropTypes.string.isRequired,
  rightBtnText: PropTypes.string.isRequired,
  isSuccessModal: PropTypes.bool,
  descriptionList: PropTypes.arrayOf(PropTypes.string),
  disabledModal: PropTypes.bool,
  processing: PropTypes.bool,
  savingRightText: PropTypes.string,
  showInfoIcon: PropTypes.bool,
  topHeaderText: PropTypes.string,
};

WarningModal.defaultProps = {
  isSuccessModal: false,
  descriptionList: [],
  description: '',
  disabledModal: false,
  processing: false,
  savingRightText: 'Processing',
  showInfoIcon: false,
  topHeaderText: '',
};
