import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import LoadingPage from '../components/common/loader-spinner/loader-spinner';
import { userTokenVerification } from '../redux/actions/auth-action';
import browserStorage from './browser-storage';
import { ADMIN, CONSUMER, CUSTOMER, USER_DATA } from './constant-values';

function RestrictedOnLoginRoutes(props) {
  const { Component, verifyToken } = props;
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [homePageUrl, setHomePageUrl] = useState(null);

  const updateHomeUrl = (userType) => {
    const userData = browserStorage.getUserData(USER_DATA);

    if (userData && userData.customerUrl && userType === CUSTOMER) {
      setHomePageUrl(`/${userData.customerUrl}/customer/revenue-report`);
    } else if (userData && userData.customerUrl && userType === CONSUMER) {
      setHomePageUrl(`/${userData.customerUrl}/dashboard`);
    } else if (userType === ADMIN) {
      setHomePageUrl('/admin/customers');
    }
  };

  useEffect(() => {
    verifyToken()
      .then(({ type }) => {
        updateHomeUrl(type);
        setLoading(false);
        setRedirect(true);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading && <LoadingPage />}
      {redirect && homePageUrl && <Redirect to={homePageUrl} />}
      {!loading && !redirect && <Component />}
    </>
  );
}

RestrictedOnLoginRoutes.propTypes = {
  Component: PropTypes.elementType.isRequired,
  verifyToken: PropTypes.func.isRequired,
};

RestrictedOnLoginRoutes.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  verifyToken: userTokenVerification,
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedOnLoginRoutes);
