import { Button, Col, Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import './landing-page-header-style.css';

export default function LandingPageHeader() {
  const history = useHistory();
  const { customerUrl } = useParams();

  const onLogin = () => {
    history.push({
      pathname: `/${customerUrl}/login`,
      state: {
        clickedRegistration: false,
      },
    });
  };

  const onRegistration = () => {
    history.push({
      pathname: `/${customerUrl}/login`,
      state: {
        clickedRegistration: true,
      },
    });
  };

  return (
    <>
      <Col className="d-flex justify-content-center px-0">
        <Container className="d-flex justify-content-end login-reg-button-background-landing-page py-2">
          <Button variant="danger" onClick={onLogin} className="mr-2 font-weight-bolder">
            Login
          </Button>
          <Button
            variant="secondary"
            onClick={onRegistration}
            className="font-weight-bolder mr-2 mr-sm-0"
          >
            Register
          </Button>
        </Container>
      </Col>
    </>
  );
}

LandingPageHeader.propTypes = {};

LandingPageHeader.defaultProps = {};
