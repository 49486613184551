import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { singleSubscriptionProps } from '../../common/common-props';

export default function CategoryCard(props) {
  const { subscription, cardOnClick } = props;

  const onHandleCard = () => {
    cardOnClick(subscription.id);
  };

  return (
    <Card className="bg-light cursor-pointer rounded-lg h-100" onClick={onHandleCard}>
      <Card.Header className="bg-light border-0 text-center d-flex justify-content-center">
        <div
          className="card-img-top mb-n3 w-50 text-muted"
          dangerouslySetInnerHTML={{ __html: subscription.logoSvg }}
        />
      </Card.Header>
      <Card.Body className="text-center d-flex flex-column justify-content-between">
        <Card.Text className="pt-2">
          <span className="h4">{subscription.name}</span>
        </Card.Text>

        <Card.Text className="pb-2 text-muted customer-category-card-mediums">
          Audio, SMS, Email
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

CategoryCard.propTypes = {
  subscription: singleSubscriptionProps.isRequired,
  cardOnClick: PropTypes.func.isRequired,
};

CategoryCard.defaultProps = {};
