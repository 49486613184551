import { Col, Row } from 'react-bootstrap';
import { CheckboxOffIcon, CheckboxOnIcon } from '../../../../../helper/icons/common-icons';
import { singleCategoryWithDeliverMediumProps } from '../../customer-common-props';

export default function CategoryRowBody({ categoryItem }) {
  return (
    <>
      <Row>
        <Col xs={12} sm={6}>
          <Row>
            <Col className="d-flex justify-content-center justify-content-sm-start">
              <div
                className="text-muted category-icons-list-admin"
                dangerouslySetInnerHTML={{ __html: categoryItem.logoSvg }}
              />
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <p className="article-body">
                <span className="font-weight-bold">Sort Order: </span> {categoryItem.sortOrder}
              </p>
            </Col>
          </Row>
        </Col>

        <Col xs={12} sm={6}>
          <Row>
            <Col xs={7}>
              <p className="font-weight-bold">Delivery Medium</p>
            </Col>
            <Col sx={5}>
              <p className="font-weight-bold">Token Cost</p>
            </Col>
          </Row>

          {categoryItem.deliveryMedium.map((item) => (
            <Row className="pb-1" key={item.id}>
              <Col xs={7}>
                {!item.isDisable && <CheckboxOnIcon width="1.2rem" height="1.2rem" />}
                {item.isDisable && <CheckboxOffIcon width="1.2rem" height="1.2rem" />}

                <p className="d-inline pl-3 checkbox-label-delivery-medium">{item.name}</p>
              </Col>

              <Col xs={5}>
                <p className="d-inline checkbox-label-delivery-medium">{item.numberOfTokens}</p>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
}

CategoryRowBody.propTypes = {
  categoryItem: singleCategoryWithDeliverMediumProps.isRequired,
};

CategoryRowBody.defaultProps = {};
