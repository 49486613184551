import { CREATE_SUPPORT_TICKET, GET_SUPPORT_TICKET_FOR_CONSUMER } from '../types';

const initialState = {
  tickets: {
    totalItems: 0,
    data: [],
    totalPages: 0,
    currentPage: 1,
  },
  createdTicket: {},
};

const supportTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPORT_TICKET_FOR_CONSUMER:
      return {
        ...state,
        tickets: action.data,
      };
    case CREATE_SUPPORT_TICKET:
      return {
        ...state,
        createdTicket: action.data,
      };

    default:
      return state;
  }
};

export default supportTicketReducer;
