import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import commonMethods from '../../../../helper/common-methods';
import { TABLE_DATE_TIME_FORMAT } from '../../../../helper/constant-values';
import { CalenderIcon } from '../../../../helper/icons/common-icons';
import { formikProps } from '../../../common/common-props';
import FormComponent from '../../../common/form-component/form-component';

export default function DateTimePicker(props) {
  const {
    formik,
    timeZone,
    initialDateTimeUtc,
    fieldName,
    iconToolTip,
    placeholder,
    inputColumnClass,
  } = props;

  const onChangeDateTime = (dateTime) => {
    if (!dateTime) return;

    const { setFieldValue, setFieldTouched } = formik;

    setFieldTouched(fieldName, true);
    setFieldValue(fieldName, commonMethods.getDateFromDateTime(dateTime, TABLE_DATE_TIME_FORMAT));
  };

  return (
    <Datetime
      onChange={onChangeDateTime}
      initialValue={
        initialDateTimeUtc
          ? commonMethods.getUtcDateTime({
              timeZone,
              dateTime: initialDateTimeUtc,
            })
          : initialDateTimeUtc
      }
      timeFormat="HH:mm"
      displayTimeZone={timeZone}
      renderInput={(prop, openCalendar) => (
        <FormComponent
          type="text"
          placeholder={placeholder}
          name={fieldName}
          icon={CalenderIcon}
          inputColumnClass={inputColumnClass}
          errorColumnClass="d-none"
          showIcon
          iconToolTip={iconToolTip}
          onIconClick={openCalendar}
          formik={formik}
          disabled
          isDarkDisableInput
        />
      )}
    />
  );
}

DateTimePicker.propTypes = {
  formik: formikProps.isRequired,
  timeZone: PropTypes.string.isRequired,
  initialDateTimeUtc: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  fieldName: PropTypes.string.isRequired,
  iconToolTip: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputColumnClass: PropTypes.string,
};

DateTimePicker.defaultProps = {
  initialDateTimeUtc: '',
  inputColumnClass: 'col-12',
};
