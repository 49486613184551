import { showNotification } from '../../helper/notifications';
import revenueService from '../../services/revenue-report';
import {
  GET_REVENUE_REPORT_FOR_CUSTOMER,
  GET_USAGE_REPORT_FOR_CUSTOMER,
  GET_GROSS_SALES_FOR_CUSTOMER,
} from '../types';

export function getCustomerRevenue(params, query) {
  return (dispatch) =>
    revenueService
      .getCustomerRevenue(params, query)
      .then((response) => {
        dispatch({
          type: GET_REVENUE_REPORT_FOR_CUSTOMER,
          data: response.data,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error Retrieving Revenue Summary',
        });

        return Promise.reject();
      });
}

export function getCustomerUsage(params, query) {
  return (dispatch) =>
    revenueService
      .getCustomerUsage(params, query)
      .then((response) => {
        dispatch({
          type: GET_USAGE_REPORT_FOR_CUSTOMER,
          data: response.data,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error Retrieving Usage Summary',
        });

        return Promise.reject();
      });
}

export function getCustomerGrossSales(params, query, actionData) {
  return (dispatch) =>
    revenueService
      .getCustomerGrossSales(params, query)
      .then((response) => {
        dispatch({
          type: GET_GROSS_SALES_FOR_CUSTOMER,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error Retrieving Gross Sales',
        });

        return Promise.reject();
      });
}
