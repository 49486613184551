import commonMethods from '../../helper/common-methods';
import { showNotification } from '../../helper/notifications';
import consumerService from '../../services/consumers';
import {
  CONSUMER_DETAILS_FOR_ADMIN,
  CONSUMER_LIST_BY_ADMIN,
  // eslint-disable-next-line prettier/prettier
  CONSUMER_UPDATE_BY_ADMIN
} from '../types';

export function getConsumerListForAdmin(params, query, actionData) {
  return (dispatch) =>
    consumerService
      .getConsumerListForAdmin(query)
      .then((response) => {
        dispatch({
          type: CONSUMER_LIST_BY_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function getConsumerDetailsForAdmin(params) {
  return (dispatch) =>
    consumerService
      .getConsumerDetailsForAdmin(params)
      .then((response) => {
        dispatch({
          type: CONSUMER_DETAILS_FOR_ADMIN,
          data: response.data,
        });

        return response.data;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function updateConsumerTokenByAdmin(data, params) {
  return () =>
    consumerService
      .updateConsumerTokenByAdmin(data, params)
      .then(() => {
        showNotification({
          type: 'success',
          text: 'Token updated successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function updateConsumerByAdmin(data, params) {
  return (dispatch) =>
    consumerService
      .updateConsumerByAdmin(data, params)
      .then((response) => {
        dispatch({
          type: CONSUMER_UPDATE_BY_ADMIN,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Consumer updated successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}
