import { useEffect } from 'react';

export default function useModalAndToolTip(showModal) {
  useEffect(() => {
    if (!showModal) {
      // https://stackoverflow.com/a/6976583/12091915
      window.focus();

      if (document.activeElement) {
        document.activeElement.blur();
      }
    }
  }, [showModal]);
}
