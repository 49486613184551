import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { Col, Container, Figure, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import defaultPic from '../../../../../assets/image/gray.jpg';
import config from '../../../../../config/default';
import commonMethods from '../../../../../helper/common-methods';
import { ICON_SIZE } from '../../../../../helper/constant-values';
import useModalAndToolTip from '../../../../../helper/hooks/useModalAndToolTip';
import { CameraIcon, CloseCircleIcon } from '../../../../../helper/icons/common-icons';
import { singleCategoryWithDeliverMediumProps } from '../../customer-common-props';
import CustomerCategoryForm from './customer-category-form';

function CustomerCategoryModifyModal(props) {
  const {
    showModal,
    onCloseModal,
    headerTitle,
    categoryItem,
    onSubmit,
    isAddCustomerCateory,
    customerId,
  } = props;

  const [categoryLogoFile, setCategoryLogoFile] = useState(null);
  const [categoryLogoUrl, setCategoryLogoUrl] = useState('');
  const [categoryLogoError, setCategoryLogoError] = useState(
    isAddCustomerCateory ? '* Logo is required.' : ''
  );

  const categoryPicRef = useRef(null);

  useModalAndToolTip(showModal);

  const maximumImageSize = config.files.maximumSvgUploadSize;

  const onCameraIconClick = () => {
    categoryPicRef.current.click();
  };

  const onCategoryPicUpload = async (event) => {
    const imageFile = event.target.files[0];

    if (!imageFile) return;

    if (!commonMethods.isSvgFile(imageFile.name)) {
      setCategoryLogoError('* Selected file format is not supported.');
    } else if (imageFile.size > maximumImageSize) {
      setCategoryLogoError('* Maximum 40KB svg file is allowed.');
      setCategoryLogoFile(imageFile);
    } else {
      setCategoryLogoError('');
      setCategoryLogoFile(imageFile);
    }

    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setCategoryLogoUrl(reader.result);
      }
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={onCloseModal}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        animation={false}
        className="service-modal-dialog"
        centered
        scrollable
      >
        <Modal.Header>
          <Col xs={10} className="text-truncate">
            <span className="h5">{headerTitle}</span>
          </Col>
          <Col xs={2} className="mt-2 mt-sm-0 text-right">
            <CloseCircleIcon
              className="text-muted cursor-pointer"
              width={ICON_SIZE}
              height={ICON_SIZE}
              onClick={onCloseModal}
            />
          </Col>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row className="rounded-corner bg-white">
              <Col>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <div>
                      <Figure className="mb-0">
                        <Figure.Image
                          width={134}
                          height={134}
                          src={
                            categoryLogoUrl ||
                            commonMethods.getBase64UrlFromSvgtext(categoryItem.logoSvg) ||
                            defaultPic
                          }
                          fluid={false}
                          roundedCircle
                          className="bg-light"
                        />
                      </Figure>

                      <CameraIcon
                        width="2.8em"
                        height="2.8em"
                        className="upload-logo cursor-pointer"
                        onClick={onCameraIconClick}
                      />

                      <input
                        className="d-none"
                        type="file"
                        accept=".svg"
                        onChange={onCategoryPicUpload}
                        ref={categoryPicRef}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col sm={8} className="text-center">
                    <span className="text-info">
                      <small>* Supported file format: .svg</small>
                    </span>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col sm={8} className="text-center">
                    <span className="text-info">
                      <small> * Recommended width & height ratio of image file: 1:1</small>
                    </span>
                  </Col>
                </Row>

                <Row className="justify-content-center pb-3">
                  <Col sm={8} className="text-center">
                    <span className="text-primary">{categoryLogoError}</span>
                    {!categoryLogoError && <br />}
                  </Col>
                </Row>
              </Col>
            </Row>

            <CustomerCategoryForm
              onCategorySubmit={onSubmit}
              categoryItem={categoryItem}
              isAddForm={isAddCustomerCateory}
              categoryLogo={categoryLogoFile}
              onCloseModal={onCloseModal}
              categoryLogoError={categoryLogoError}
              customerId={customerId}
            />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

CustomerCategoryModifyModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  categoryItem: singleCategoryWithDeliverMediumProps,
  onSubmit: PropTypes.func.isRequired,
  isAddCustomerCateory: PropTypes.bool,
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

CustomerCategoryModifyModal.defaultProps = {
  categoryItem: {
    id: 0,
    name: '',
    logoSvg: '',
    imageUrl: '',
    customerId: 0,
    sortOrder: 0,
    deliveryMedium: [
      {
        id: 0,
        isDisable: false,
        name: '',
        numberOfTokens: 0,
      },
    ],
  },
  isAddCustomerCateory: false,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCategoryModifyModal);
