import { axios } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const authService = {
  adminLogin: async (loginData) => {
    const { method, endpoint } = endpoints.adminLogin;
    const url = utils.buildUrl(baseUrl + endpoint);

    const response = await axios({ method, url, data: loginData });
    return response;
  },

  consumerCustomerLogin: async (loginData, params) => {
    const { method, endpoint } = endpoints.customerLogin;

    const url = utils.buildUrl(baseUrl + endpoint, params);

    const response = await axios({ method, url, data: loginData });
    return response;
  },

  logout: async () => {
    const { method, endpoint } = endpoints.logout;

    const url = utils.buildUrl(baseUrl + endpoint);

    const response = await axios({ method, url });
    return response;
  },

  consumerSignUp: async (data, params) => {
    const { method, endpoint } = endpoints.consumerSignUp;

    const url = utils.buildUrl(baseUrl + endpoint, params);

    const response = await axios({ method, url, data });
    return response;
  },

  otpVerify: async (data) => {
    const { method, endpoint } = endpoints.otpVerify;

    const url = utils.buildUrl(baseUrl + endpoint);

    const response = await axios({ method, url, data });
    return response;
  },

  otpResend: async (data) => {
    const { method, endpoint } = endpoints.otpResend;

    const url = utils.buildUrl(baseUrl + endpoint);

    const response = await axios({ method, url, data });
    return response;
  },

  otpValidityCheck: async (query) => {
    const { method, endpoint } = endpoints.otpValidityCheck;

    const url = utils.buildUrl(baseUrl + endpoint, {}, query);

    const response = await axios({ method, url });
    return response;
  },

  tokenVerify: async () => {
    const { method, endpoint } = endpoints.tokenVerify;

    const url = utils.buildUrl(baseUrl + endpoint);

    const response = await axios({ method, url });
    return response;
  },

  forgotPassword: async (data, params) => {
    const { method, endpoint } = endpoints.forgotPassword;

    const url = utils.buildUrl(baseUrl + endpoint, params);

    const response = await axios({ method, url, data });
    return response;
  },

  changePassword: async (data, params) => {
    const { method, endpoint } = endpoints.changePassword;

    const url = utils.buildUrl(baseUrl + endpoint, params);

    const response = await axios({ method, url, data });
    return response;
  },
};

export default authService;
