/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import ConsumerForAdmin from './components/admin-menubar/consumers/consumer-container';
import CreditRefundForAdmin from './components/admin-menubar/credit-refund/credit-refund-container';
import CustomerForAdmin from './components/admin-menubar/customers/customer-container';
import TokenTransctionForAdmin from './components/admin-menubar/token-transaction/token-transaction-container';
import PageNotFound from './components/common/page-not-found';
import ConsumerPackages from './components/consumer-menubar/consumer-package/consumer-package-container';
import PackagePurchase from './components/consumer-menubar/consumer-package/package-purchase/package-purchase-container';
import ConsumerSubscriptions from './components/consumer-menubar/consumer-subscription/consumer-subscription-container';
import ConsumerDashboard from './components/consumer-menubar/dashboard/dashboard-container';
import SupportEmailMessage from './components/consumer-menubar/support-email-message/support-email-message-container';
import ConsumerLandingPage from './components/consumer/landing-page/landing-page-container';
import CustomerCategory from './components/customer-menubar/category-and-service/category-and-service-container';
import CustomerPackages from './components/customer-menubar/packages/customer-packages-container';
import RevenueReport from './components/customer-menubar/revenue-usage/revenue-chart-container';
import Faq from './components/faq/faq-container';
import AdminLoginPage from './components/login-registration/admin-login/admin-login-container';
import CustomerConsumerLogin from './components/login-registration/login-registration-contaier';
import Profile from './components/profile/profile-container';
import PaypalDefaultPaymentAddSuccess from './components/profile/profile/default-payment/paypal-default-payment-add-success';
import './custom-bootstrap.scss';
import { ADMIN, CONSUMER, CUSTOMER } from './helper/constant-values';
import { CustomToastContainer } from './helper/notifications';
import PrivateRoutes from './helper/private-routes';
import RestrictedOnLoginRoutes from './helper/restricted-on-login-routes';
import './index.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

window.__react_toast_provider = React.createRef();

ReactDOM.render(
  <ToastProvider
    placement="bottom-left"
    ref={window.__react_toast_provider}
    components={{ ToastContainer: CustomToastContainer }}
  >
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <div>
            <Switch>
              <Route exact path="/admin">
                <Redirect to="/admin/login" />
              </Route>
              <Route
                path="/admin/login"
                render={() => <RestrictedOnLoginRoutes Component={AdminLoginPage} />}
                exact
              />
              <Route
                path="/admin/profile"
                render={() => <PrivateRoutes Component={Profile} userType={ADMIN} />}
                exact
              />
              <Route
                path="/admin/profile/edit"
                render={() => <PrivateRoutes Component={Profile} userType={ADMIN} />}
                exact
              />
              <Route
                path="/admin/profile/edit/verification"
                render={() => <PrivateRoutes Component={Profile} userType={ADMIN} />}
                exact
              />
              <Route
                path="/admin/profile/change-password"
                render={() => <PrivateRoutes Component={Profile} userType={ADMIN} />}
                exact
              />
              <Route
                path="/admin/consumers"
                render={() => <PrivateRoutes Component={ConsumerForAdmin} userType={ADMIN} />}
                exact
              />
              <Route
                path="/admin/credit-refund"
                render={() => <PrivateRoutes Component={CreditRefundForAdmin} userType={ADMIN} />}
                exact
              />
              <Route
                path="/admin/token-transction"
                render={() => (
                  <PrivateRoutes Component={TokenTransctionForAdmin} userType={ADMIN} />
                )}
                exact
              />
              <Route
                path="/admin/customers"
                render={() => <PrivateRoutes Component={CustomerForAdmin} userType={ADMIN} />}
                exact
              />
              <Route
                path="/admin/customers/add"
                render={() => <PrivateRoutes Component={CustomerForAdmin} userType={ADMIN} />}
                exact
              />
              <Route
                path="/admin/customers/:customerId"
                render={() => <PrivateRoutes Component={CustomerForAdmin} userType={ADMIN} />}
                exact
              />
              <Route
                path="/admin/customers/:customerId/edit"
                render={() => <PrivateRoutes Component={CustomerForAdmin} userType={ADMIN} />}
                exact
              />

              {/*
               *     Add admin related url before this line
               *     Add customer and consumer related url below this line
               */}

              <Route
                path="/:customerUrl/profile"
                render={() => <PrivateRoutes Component={Profile} userType={CONSUMER} />}
                exact
              />
              <Route
                path="/:customerUrl/profile/edit"
                render={() => <PrivateRoutes Component={Profile} userType={CONSUMER} />}
                exact
              />
              <Route
                path="/:customerUrl/profile/edit/verification"
                render={() => <PrivateRoutes Component={Profile} userType={CONSUMER} />}
                exact
              />
              <Route
                path="/:customerUrl/profile/change-password"
                render={() => <PrivateRoutes Component={Profile} userType={CONSUMER} />}
                exact
              />
              <Route
                path="/:customerUrl/profile/default-payment/update"
                render={() => <PrivateRoutes Component={Profile} userType={CONSUMER} />}
                exact
              />
              <Route
                path="/:customerUrl/paypal/default-payment/success"
                render={() => (
                  <PrivateRoutes Component={PaypalDefaultPaymentAddSuccess} userType={CONSUMER} />
                )}
                exact
              />
              <Route
                path="/:customerUrl/customer/profile"
                render={() => <PrivateRoutes Component={Profile} userType={CUSTOMER} />}
                exact
              />
              <Route
                path="/:customerUrl/customer/profile/edit"
                render={() => <PrivateRoutes Component={Profile} userType={CUSTOMER} />}
                exact
              />
              <Route
                path="/:customerUrl/customer/profile/edit/verification"
                render={() => <PrivateRoutes Component={Profile} userType={CUSTOMER} />}
                exact
              />
              <Route
                path="/:customerUrl/customer/profile/change-password"
                render={() => <PrivateRoutes Component={Profile} userType={CUSTOMER} />}
                exact
              />
              <Route
                path="/:customerUrl/dashboard"
                render={() => <PrivateRoutes Component={ConsumerDashboard} userType={CONSUMER} />}
                exact
              />
              <Route
                path="/:customerUrl/customer/revenue-report"
                render={() => <PrivateRoutes Component={RevenueReport} userType={CUSTOMER} />}
                exact
              />
              <Route
                path="/:customerUrl/customer/packages"
                render={() => <PrivateRoutes Component={CustomerPackages} userType={CUSTOMER} />}
                exact
              />
              <Route
                path="/:customerUrl/customer/faq"
                render={() => <PrivateRoutes Component={Faq} userType={CUSTOMER} />}
                exact
              />
              <Route
                path="/:customerUrl/customer/categories"
                render={() => <PrivateRoutes Component={CustomerCategory} userType={CUSTOMER} />}
                exact
              />
              <Route
                path="/:customerUrl/customer/categories/:categoryId"
                render={() => <PrivateRoutes Component={CustomerCategory} userType={CUSTOMER} />}
                exact
              />
              <Route
                path="/:customerUrl/customer/categories/:categoryId/short-text/upload"
                render={() => <PrivateRoutes Component={CustomerCategory} userType={CUSTOMER} />}
                exact
              />
              <Route
                path="/:customerUrl/login"
                render={() => <RestrictedOnLoginRoutes Component={CustomerConsumerLogin} />}
                exact
              />
              <Route
                path="/:customerUrl/contact-support"
                render={() => <PrivateRoutes Component={SupportEmailMessage} userType={CONSUMER} />}
                exact
              />
              <Route
                path="/:customerUrl/packages"
                render={() => <PrivateRoutes Component={ConsumerPackages} userType={CONSUMER} />}
                exact
              />
              <Route
                path="/:customerUrl/subscriptions"
                render={() => (
                  <PrivateRoutes Component={ConsumerSubscriptions} userType={CONSUMER} />
                )}
                exact
              />
              <Route
                path="/:customerUrl/faq"
                render={() => <PrivateRoutes Component={Faq} userType={CONSUMER} />}
                exact
              />
              <Route
                path="/:customerUrl/packages/purchase"
                render={() => <PrivateRoutes Component={PackagePurchase} userType={CONSUMER} />}
                exact
              />
              <Route exact path="/:customerUrl">
                <Redirect to="/freewillastrology/login" />
              </Route>
              <Route exact path="/">
                <Redirect to="/freewillastrology/login" />
              </Route>
              <Route component={PageNotFound} />
            </Switch>
          </div>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </ToastProvider>,
  document.getElementById('root')
);

reportWebVitals();
