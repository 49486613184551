import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { RingLoader } from 'react-spinners';
import colors from '../../../../helper/colors';
import commonMethods from '../../../../helper/common-methods';
import { ICON_SIZE } from '../../../../helper/constant-values';
import { AddIcon, DeleteIcon, EditIcon } from '../../../../helper/icons/common-icons';
import {
  deleteDefaultCardInfoByConsumer,
  // eslint-disable-next-line prettier/prettier
  getDefaultCardInfoByConsumer
} from '../../../../redux/actions/payments-action';
import WarningModal from '../../../common/modals/warning-success-modal';
import {
  defaultPaymentInfoProps,
  // eslint-disable-next-line prettier/prettier
  userInformationProps
} from '../../profile-common-props';
import DefaultPaymentInfoRow from './default-payment-info-row';

function DefaultPaymentInfo(props) {
  const {
    getDefaultCardInfo,
    userInfoState,
    defaultPaymentInfo,
    userBtnColor,
    deleteDefaultCardInfo,
    goToUpdateDefaultPayment,
  } = props;

  const [showDeleteDefaultPaymentModal, setShowDeleteDefaultPaymentModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const { customerUrl } = useParams();

  const onConfirmDelete = () => {
    setDataLoading(true);

    const params = { customerUrl, consumerId: userInfoState.id };

    return deleteDefaultCardInfo(params)
      .then(() => {
        setShowDeleteDefaultPaymentModal(false);
        setDataLoading(false);
      })
      .catch(() => {
        setShowDeleteDefaultPaymentModal(false);
        setDataLoading(false);
      });
  };

  useEffect(() => {
    if (defaultPaymentInfo.noDefaultPayment) {
      setDataLoading(true);
    }

    getDefaultCardInfo({ customerUrl, consumerId: userInfoState.id })
      .then(() => {
        setDataLoading(false);
      })
      .catch(() => {
        setDataLoading(false);
      });
  }, []);

  return (
    <>
      <WarningModal
        heading="Delete Default Payment Information"
        descriptionList={[
          'Are you sure, you want to delete your default payment?',
          'Your existing package subscription will be cancelled.',
        ]}
        leftBtnText="No"
        rightBtnText="Yes"
        showModal={showDeleteDefaultPaymentModal}
        onHideModal={() => setShowDeleteDefaultPaymentModal(false)}
        processing={dataLoading}
        onModalSubmit={onConfirmDelete}
      />

      <Row className="pb-3">
        <Col>
          <h4 className="pt-3 pb-2">Default Payment Info</h4>
        </Col>

        {!dataLoading && (
          <Col xs={12} xl={7}>
            <Button
              variant={userBtnColor}
              className="float-right"
              size="lg"
              onClick={goToUpdateDefaultPayment}
            >
              {!defaultPaymentInfo.noDefaultPayment && (
                <>
                  <EditIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
                  Update Default Payment
                </>
              )}

              {defaultPaymentInfo.noDefaultPayment && (
                <>
                  <AddIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
                  Add Default Payment
                </>
              )}
            </Button>
          </Col>
        )}
      </Row>

      {!defaultPaymentInfo.noDefaultPayment && (
        <>
          {defaultPaymentInfo.isStripePayment && (
            <>
              <DefaultPaymentInfoRow title="Payment Method Name:" value="Card" />

              <DefaultPaymentInfoRow
                title="Card Type:"
                value={commonMethods.capitalizeFirstLetter(defaultPaymentInfo.cardBrand)}
              />

              <DefaultPaymentInfoRow
                title="Card Number:"
                value={`**** **** **** ${defaultPaymentInfo.lastFourDigit}`}
              />

              <DefaultPaymentInfoRow
                title="Card Expire Date:"
                value={`${defaultPaymentInfo.expMonth} / ${defaultPaymentInfo.expYear}`}
              />
            </>
          )}

          {defaultPaymentInfo.isPaypalPayment && (
            <>
              <DefaultPaymentInfoRow title="Payment Method Name:" value="PayPal" />

              <DefaultPaymentInfoRow title="Name on PayPal:" value={defaultPaymentInfo.fullName} />

              <DefaultPaymentInfoRow title="Email on PayPal:" value={defaultPaymentInfo.email} />
            </>
          )}

          <Row className="pb-3">
            <Col>
              <Button
                variant={userBtnColor}
                className="float-right mt-3"
                size="lg"
                onClick={() => {
                  setShowDeleteDefaultPaymentModal(true);
                }}
              >
                <DeleteIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
                Delete Default Payment
              </Button>
            </Col>
          </Row>
        </>
      )}

      {dataLoading && (
        <>
          <Row className="pb-5 mb-5">
            <Col>
              <RingLoader loading size={100} color={colors.secondary} />
            </Col>
          </Row>
        </>
      )}

      {!dataLoading && defaultPaymentInfo.noDefaultPayment && (
        <Row>
          <Col>No Default Payment Method.</Col>
        </Row>
      )}
    </>
  );
}

DefaultPaymentInfo.propTypes = {
  getDefaultCardInfo: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
  defaultPaymentInfo: defaultPaymentInfoProps.isRequired,
  userBtnColor: PropTypes.string.isRequired,
  deleteDefaultCardInfo: PropTypes.func.isRequired,
  goToUpdateDefaultPayment: PropTypes.func.isRequired,
};

DefaultPaymentInfo.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
  defaultPaymentInfo: state.payments.consumerDefaultPaymentInfo,
});

const mapDispatchToProps = {
  getDefaultCardInfo: getDefaultCardInfoByConsumer,
  deleteDefaultCardInfo: deleteDefaultCardInfoByConsumer,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPaymentInfo);
