import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

export default function TextBody(props) {
  const { heading, description, descriptionList } = props;

  return (
    <Row>
      <Col sm={12} className="pb-3">
        <span className="h3">{heading}</span>
      </Col>
      <Col sm={12}>
        {description && <span>{description}</span>}
        {descriptionList &&
          descriptionList.map((item) => (
            <span key={item}>
              <span>{item}</span>
              <br />
            </span>
          ))}
      </Col>
    </Row>
  );
}

TextBody.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  descriptionList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TextBody.defaultProps = {};
