import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { Col, Figure, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import defaultPic from '../../../../../assets/image/gray.jpg';
import config from '../../../../../config/default';
import commonMethods from '../../../../../helper/common-methods';
import { BackIcon, CameraIcon } from '../../../../../helper/icons/common-icons';
import { customerDetailsProps } from '../../customer-common-props';
import CustomerDetailForm from './customer-details-form';

function CustomerDetailsModify(props) {
  const {
    backFromCategoryView,
    onBackArrowButtonClick,
    onSavingData,
    customerDetailState,
    isAddForm,
  } = props;

  const [customerLogoFile, setCustomerLogoFile] = useState('');
  const [customerLogoUrl, setCustomerLogoUrl] = useState('');

  const [customerLogoError, setCustomerLogoError] = useState('');

  const customerLogoRef = useRef(null);

  const maximumImageSize = config.files.maximumImageUploadSize;

  const onCameraIconClick = () => {
    customerLogoRef.current.click();
  };

  const onCustomerLogoUpload = async (event) => {
    const imageFile = event.target.files[0];

    if (!imageFile) return;

    if (!commonMethods.isImageFile(imageFile.name)) {
      setCustomerLogoError('* Selected file format is not supported.');
    } else if (imageFile.size > maximumImageSize) {
      setCustomerLogoError('* Maximum 4MB image file is allowed.');
      setCustomerLogoFile(imageFile);
    } else {
      setCustomerLogoError('');
      const file = await commonMethods.imageResize(imageFile, 200, 100);
      setCustomerLogoFile(file);
    }

    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setCustomerLogoUrl(reader.result);
      }
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <Row>
        <Col className="pl-0">
          <BackIcon
            className="d-inline mb-2 mr-2 cursor-pointer arrow-button-admin"
            width="2.3em"
            height="2.3em"
            onClick={onBackArrowButtonClick}
          />
          <h4 className="pt-2 pl-1 d-inline">
            {isAddForm ? 'Add Customer Details' : 'Update Customer Details'}
          </h4>
        </Col>
      </Row>

      <Row className="mt-4 mb-4 mb-sm-0 pt-4 rounded-corner bg-white">
        <Col>
          <Row>
            <Col className="d-flex justify-content-center">
              <div>
                <Figure className="mb-0">
                  <Figure.Image
                    width={200}
                    height={100}
                    src={
                      customerLogoUrl ||
                      (backFromCategoryView || !isAddForm
                        ? commonMethods.getImageApi(customerDetailState.logoUrl)
                        : '') ||
                      defaultPic
                    }
                    fluid={false}
                  />
                </Figure>

                <CameraIcon
                  width="2.8em"
                  height="2.8em"
                  className="upload-logo-customer cursor-pointer"
                  onClick={onCameraIconClick}
                />

                <input
                  className="d-none"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={onCustomerLogoUpload}
                  ref={customerLogoRef}
                />
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={8} className="text-center">
              <span className="text-info">
                <small>* Supported file formats: .jpg, .jpeg, .png</small>
              </span>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={8} className="text-center">
              <span className="text-info">
                <small> * Recommended width & height ratio of image file: 2:1</small>
              </span>
            </Col>
          </Row>

          <Row className="justify-content-center pb-3">
            <Col sm={8} className="text-center">
              <span className="text-primary">{customerLogoError}</span>
              {!customerLogoError && <br />}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col sm={8} className="mb-3 text-center">
              <h5 className="mt-1">Customer Information</h5>
            </Col>
          </Row>

          <CustomerDetailForm
            backFromCategoryView={backFromCategoryView}
            customerLogo={customerLogoFile}
            customerLogoError={customerLogoError}
            onSavingData={onSavingData}
            isAddForm={isAddForm}
          />
        </Col>
      </Row>
    </>
  );
}

CustomerDetailsModify.propTypes = {
  backFromCategoryView: PropTypes.bool,
  onBackArrowButtonClick: PropTypes.func.isRequired,
  onSavingData: PropTypes.func.isRequired,
  customerDetailState: customerDetailsProps.isRequired,
  isAddForm: PropTypes.bool,
};

CustomerDetailsModify.defaultProps = {
  backFromCategoryView: false,
  isAddForm: false,
};

const mapStateToProps = (state) => ({
  customerDetailState: state.customers.customerDetailsForAdmin,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsModify);
