import React from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

export default function LineChart(props) {
  const { title, labels, datasets } = props;

  return (
    <Line
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            position: 'top',
            text: title,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      }}
      data={{
        labels,
        datasets,
      }}
    />
  );
}

LineChart.propTypes = {
  title: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  datasets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      borderColor: PropTypes.string,
      backgroundColor: PropTypes.string,
    })
  ).isRequired,
};
