import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import { useParams } from 'react-router';
import config from '../../../../config/default';
import commonMethods from '../../../../helper/common-methods';
import { ICON_SIZE, TABLE_DATE_TIME_FORMAT } from '../../../../helper/constant-values';
import useModalAndToolTip from '../../../../helper/hooks/useModalAndToolTip';
import { CloseCircleIcon } from '../../../../helper/icons/common-icons';
import { audioModifyValidationSchema } from '../../../../helper/schema-objects';
import CustomAudioPlayer from '../../../common/audio-player/custom-audio-player';
import Dropzone from '../../../common/file-upload/dropzone';
import FormComponent from '../../../common/form-component/form-component';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import DateTimePicker from '../category-common-components/date-time-picker';
import { singleAudioFileProps } from '../category-common-props';

export default function AudioModifyModal(props) {
  const {
    showModal,
    onCloseModal,
    headerTitle,
    submitButonText,
    setSelectedAudioFile,
    timeZone,
    audioItem,
    dataSaving,
    onSubmit,
    showAudio,
    addAudio,
  } = props;

  const [audioFileError, setAudioFileError] = useState('');
  const [audioFileSelected, setAudioFileSelected] = useState(false);

  const { customerUrl } = useParams();

  const maximumAudioFileSize = config.files.maximumAudioUploadSize;
  const initialValues = {
    audioName: audioItem.audioName,
    startDateTime: audioItem.startDateTime
      ? commonMethods.getDateTimeFromUtc(timeZone, TABLE_DATE_TIME_FORMAT, audioItem.startDateTime)
      : '',
    endDateTime: audioItem.endDateTime
      ? commonMethods.getDateTimeFromUtc(timeZone, TABLE_DATE_TIME_FORMAT, audioItem.endDateTime)
      : '',
  };

  const onAudioFileSelect = (file) => {
    if (file === null) {
      setAudioFileError('');
      setAudioFileSelected(false);
    } else if (file.size > maximumAudioFileSize) {
      setAudioFileError('* Maximum 15MB audio file is allowed.');
      setAudioFileSelected(false);
    } else {
      setSelectedAudioFile(file);
      setAudioFileSelected(true);
    }
  };

  useModalAndToolTip(showModal);

  return (
    <Modal
      show={showModal}
      onHide={onCloseModal}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      className="service-modal-dialog"
      centered
    >
      <Modal.Header>
        <Col xs={10} className="text-truncate">
          <span className="h5">{headerTitle}</span>
        </Col>
        <Col xs={2} className="mt-2 mt-sm-0 text-right">
          <CloseCircleIcon
            className="text-muted cursor-pointer"
            width={ICON_SIZE}
            height={ICON_SIZE}
            onClick={onCloseModal}
          />
        </Col>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Formik
            initialValues={initialValues}
            validationSchema={audioModifyValidationSchema}
            onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
            enableReinitialize
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Row className="justify-content-center">
                  <FormComponent
                    type="text"
                    placeholder="Audio Name"
                    name="audioName"
                    inputColumnClass="col-12"
                    errorColumnClass="col-12"
                    formik={formik}
                    autoFocusInput
                    showLabel
                  />
                </Row>

                <Row className="justify-content-center d-none d-sm-flex">
                  <Col sm={6} className="mt-2 date-time-picker-audio-parent">
                    <Form.Label>Start Date & Time</Form.Label>
                  </Col>
                  <Col sm={6} className="mt-2 date-time-picker-audio-parent">
                    <Form.Label>End Date & Time</Form.Label>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col sm={6} className="date-time-picker-audio-parent d-sm-none">
                    <Form.Label>Start Date & Time</Form.Label>
                  </Col>

                  <Col sm={6} className="px-0 date-time-picker-audio-parent">
                    <DateTimePicker
                      formik={formik}
                      timeZone={timeZone}
                      initialDateTimeUtc={audioItem.startDateTime}
                      fieldName="startDateTime"
                      iconToolTip="Select Start Date & Time"
                      placeholder="Start Date & Time"
                    />

                    <FormComponent
                      type="text"
                      name="startDateTime"
                      inputColumnClass="d-none"
                      errorColumnClass="col-12"
                      formik={formik}
                    />
                  </Col>

                  <Col sm={6} className="date-time-picker-audio-parent d-sm-none">
                    <Form.Label>End Date & Time</Form.Label>
                  </Col>

                  <Col sm={6} className="px-0 date-time-picker-audio-parent">
                    <DateTimePicker
                      formik={formik}
                      timeZone={timeZone}
                      initialDateTimeUtc={audioItem.endDateTime}
                      fieldName="endDateTime"
                      iconToolTip="Select End Date & Time"
                      placeholder="End Date & Time"
                    />

                    <FormComponent
                      type="text"
                      name="endDateTime"
                      inputColumnClass="d-none"
                      errorColumnClass="col-12"
                      formik={formik}
                    />
                  </Col>
                </Row>

                {!showAudio && (
                  <Row className="justify-content-center">
                    <Dropzone
                      supportedFiles="mp3"
                      fileMaxSize="15MB"
                      onAudioFileSelect={onAudioFileSelect}
                    />
                  </Row>
                )}

                {showAudio && (
                  <Col className="justify-content-center">
                    <CustomAudioPlayer
                      name={audioItem.audioName}
                      audioSrc={commonMethods.getAudioAPI({
                        customerUrl,
                        audioId: audioItem.id,
                        audioFileName: audioItem.audioFileName,
                      })}
                    />
                  </Col>
                )}

                <Row className="justify-content-center pb-4">
                  <Col>
                    <span className="text-primary">{audioFileError}</span>
                    {!audioFileError && <br />}
                  </Col>
                </Row>

                <Row className="justify-content-end mb-2">
                  <Col sm={6} md={5} lg={4}>
                    <Button
                      size="lg"
                      variant="outline-primary"
                      block
                      disabled={dataSaving}
                      onClick={onCloseModal}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col sm={6} md={5} lg={4} className="pt-3 pt-sm-0">
                    <Button
                      size="lg"
                      onClick={formik.handleSubmit}
                      type="submit"
                      block
                      disabled={
                        !!(
                          formik.errors.audioName ||
                          formik.errors.startDateTime ||
                          formik.errors.endDateTime ||
                          dataSaving ||
                          (!audioFileSelected && addAudio)
                        )
                      }
                    >
                      <DataSavingSpinner
                        savingText="Processing"
                        defaultText={submitButonText}
                        dataSaving={dataSaving}
                      />
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

AudioModifyModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  submitButonText: PropTypes.string.isRequired,
  setSelectedAudioFile: PropTypes.func,
  timeZone: PropTypes.string.isRequired,
  audioItem: singleAudioFileProps,
  dataSaving: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  showAudio: PropTypes.bool,
  addAudio: PropTypes.bool,
};

AudioModifyModal.defaultProps = {
  setSelectedAudioFile: () => {},
  audioItem: {
    id: 0,
    audioName: '',
    startDateTime: '',
    endDateTime: '',
    audioFileName: '',
    duration: '',
  },
  dataSaving: false,
  showAudio: false,
  addAudio: false,
};
