import { callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const consumerService = {
  getConsumerListForAdmin: async (query) => {
    const { method, endpoint } = endpoints.getConsumerListForAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, {}, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  getConsumerDetailsForAdmin: async (params) => {
    const { method, endpoint } = endpoints.getConsumerDetailsForAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  updateConsumerTokenByAdmin: async (data, params) => {
    const { method, endpoint } = endpoints.updateConsumerTokenByAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data });

    return response;
  },

  updateConsumerByAdmin: async (data, params) => {
    const { method, endpoint } = endpoints.updateConsumerByAdmin;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data });

    return response;
  },
};

export default consumerService;
