import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { consumerProfileEidtByAdminValidationSchema } from '../../../../helper/schema-objects';
import { updateConsumerByAdmin } from '../../../../redux/actions/consumer-actions';
import FormComponent from '../../../common/form-component/form-component';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import { consumerDetailsProps } from '../consumer-common-props';

function ConsumerProfileEdit(props) {
  const { consumerState, onSubmitModal, dataSaving, onCloseModal } = props;

  const initialValues = {
    email: consumerState.email || consumerState.unverifiedEmail,
    smsPhoneNumber: consumerState.smsPhoneNumber || consumerState.unverifiedSmsPhoneNumber,
  };

  const onSubmit = (values, { setErrors }) => {
    onSubmitModal(values)
      .then(() => {})
      .catch((error) => {
        if (!error.response) {
          return;
        }

        const { isExistingEmail, message } = error.response.data;

        if (isExistingEmail) {
          setErrors({
            email: message,
          });
        } else {
          onCloseModal();
        }
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={consumerProfileEidtByAdminValidationSchema}
        onSubmit={(values, actions) => onSubmit(values, actions)}
        enableReinitialize
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mt-1 justify-content-center">
              <FormComponent
                placeholder="Email"
                name="email"
                inputColumnClass="col-12"
                errorColumnClass="d-none"
                formik={formik}
                showLabel
              />
            </Row>
            <Row className="justify-content-center">
              <FormComponent
                name="email"
                inputColumnClass="d-none"
                errorColumnClass="col-12"
                formik={formik}
              />
            </Row>

            <Row className="mt-1 justify-content-center">
              <FormComponent
                placeholder="SMS Phone Number"
                name="smsPhoneNumber"
                inputColumnClass="col-12"
                errorColumnClass="d-none"
                formik={formik}
                showLabel
              />
            </Row>
            <Row className="justify-content-center">
              <FormComponent
                name="smsPhoneNumber"
                inputColumnClass="d-none"
                errorColumnClass="col-12"
                formik={formik}
              />
            </Row>

            <Row className="justify-content-end mb-2">
              <Col sm={6} md={5} lg={4}>
                <Button
                  size="lg"
                  variant="outline-secondary"
                  block
                  disabled={dataSaving}
                  onClick={onCloseModal}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm={6} md={5} lg={4} className="pt-3 pt-sm-0">
                <Button
                  size="lg"
                  onClick={formik.handleSubmit}
                  type="submit"
                  variant="secondary"
                  block
                  disabled={!!(formik.errors.email || formik.errors.smsPhoneNumber || dataSaving)}
                >
                  <DataSavingSpinner
                    savingText="Processing"
                    defaultText="Save"
                    dataSaving={dataSaving}
                  />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}

ConsumerProfileEdit.propTypes = {
  onSubmitModal: PropTypes.func.isRequired,
  consumerState: consumerDetailsProps.isRequired,
  dataSaving: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

ConsumerProfileEdit.defaultProps = {};

const mapStateToProps = (state) => ({
  consumerState: state.consumers.consumerDetailsForAdmin,
});

const mapDispatchToProps = {
  updateProfile: updateConsumerByAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerProfileEdit);
