import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { ICON_SIZE } from '../../../../helper/constant-values';
import { CloseCircleIcon } from '../../../../helper/icons/common-icons';
import { singleSubscriptionProps } from '../consumer-subscription-common-props';
import FooterButtons from './footer-buttons';
import './modals-style.css';
import TokenInfo from './token-info';

export default function SubscriptionInfoModal(props) {
  const { showModal, hideModal, category, onConfirmSubscription, processingSubscription } = props;

  const [lowTokenError, setLowTokenError] = useState(false);
  const [rightButtonText, setRightButtonText] = useState('');

  useEffect(() => {
    if (!showModal) {
      setLowTokenError(false);
    }
  }, [showModal]);

  useEffect(() => {
    if (category.isSubscribed) {
      setRightButtonText('Unsubscribe');
    } else {
      setRightButtonText('Subscribe');
    }
  }, [category.isSubscribed]);

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      size="lg"
      className="px-2 py-5 py-sm-0"
      animation={false}
      centered
    >
      <Modal.Body className="text-center">
        <Container>
          <Row className="mb-4 pb-4 border-bottom">
            <Col xs={7} className="pl-0 pl-md-3 d-flex justify-content-start">
              <span className="h4">{category.name}</span>
            </Col>
            <Col className="pt-2 mr-0 pr-0">
              <CloseCircleIcon
                className={`offset-10 text-muted ${processingSubscription ? '' : 'cursor-pointer'}`}
                width={ICON_SIZE}
                height={ICON_SIZE}
                onClick={hideModal}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12} className="pb-3">
              <span className="h3">
                {category.isSubscribed ? 'You Are Subscribed' : 'You Are Not Subscribed'}
              </span>
            </Col>

            <Col sm={12} className="pb-3">
              <a href="/freewillastrology/faq">
                See the FAQ on Daily Sunbursts and purchasing tokens
              </a>{' '}
              for more information.
            </Col>
          </Row>

          <TokenInfo
            isError={lowTokenError}
            setError={setLowTokenError}
            deliveryMediums={category.deliveryMediums}
            checkedSms={category.isSubscribed}
          />

          <FooterButtons
            leftText="Cancel"
            rightText={rightButtonText}
            leftVariant="outline-info"
            rightVariant="primary"
            onHandleLeft={hideModal}
            onHandleRight={() => onConfirmSubscription(!category.isSubscribed)}
            isDisable={lowTokenError}
            processing={processingSubscription}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}

SubscriptionInfoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onConfirmSubscription: PropTypes.func.isRequired,
  category: singleSubscriptionProps.isRequired,
  processingSubscription: PropTypes.bool.isRequired,
};

SubscriptionInfoModal.defaultProps = {};
