import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import colors from '../../../../helper/colors';
import commonMethods from '../../../../helper/common-methods';
import {
  DEFAULT_DATE_TIME_WITHOUT_SECOND_FORMAT,
  // eslint-disable-next-line prettier/prettier
  TABLE_DATE_TIME_FORMAT
} from '../../../../helper/constant-values';
import { PlayCircleIcon } from '../../../../helper/icons/common-icons';
import { addAudioByCustomer } from '../../../../redux/actions/audio-action';
import AudioPlayerModal from '../../../common/audio-player/audio-player-modal';
import WarningModal from '../../../common/modals/warning-success-modal';
import ActionButtons from '../category-common-components/action-buttons';
import ActionButtonsMobile from '../category-common-components/action-buttons-mobile';
import { singleAudioFileProps } from '../category-common-props';
import AudioModifyModal from './audio-modify-modal';

function AudioRow(props) {
  const {
    audioItem,
    timeZone,
    onDeleteAudio,
    onEditAudio,
    onCopyAudio,
    updateUploadedAudioData,
    selectedHeaderButtonId,
    addAudio,
  } = props;

  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditModalWarning, setShowEditModalWarning] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedAudioFile, setSelectedAudioFile] = useState(null);
  const [audioDuration, setAudioDuration] = useState(
    audioItem.copyAudio ? audioItem.duration : '0:00'
  );

  const { uploadPercentage, audioUploadProcessing } = audioItem;

  const { customerUrl, categoryId } = useParams();

  const handleEditButton = () => {
    if (selectedHeaderButtonId === 2) {
      setShowEditModalWarning(true);
    } else {
      setShowEditModal(true);
    }
  };

  const handleCopyButton = () => {
    setShowCopyModal(true);
  };

  const handleDeleteButton = () => {
    setShowDeleteModal(true);
  };

  const getDateTimeForApi = (dateTime) =>
    commonMethods.getDateFromDateTime(
      dateTime,
      DEFAULT_DATE_TIME_WITHOUT_SECOND_FORMAT,
      TABLE_DATE_TIME_FORMAT
    );

  const onConfirmEdit = () => {
    setShowEditModal(true);
    setShowEditModalWarning(false);
  };

  const onEditAudioModalClose = () => {
    setShowEditModal(false);
    setAudioDuration(audioItem.duration);
    setSelectedAudioFile(null);
  };

  const onDeleteAudioModalClose = () => {
    setShowDeleteModal(false);
  };

  const onCopyAudioModalClose = () => {
    setShowCopyModal(false);
    setAudioDuration(audioItem.duration);
    setSelectedAudioFile(null);
  };

  const onPlayAudio = () => {
    setShowAudioPlayer(true);
  };

  const getLocalDateTime = (dateTime) => {
    if (selectedHeaderButtonId === 4)
      return commonMethods.getDateFromDateTime(
        dateTime,
        TABLE_DATE_TIME_FORMAT,
        DEFAULT_DATE_TIME_WITHOUT_SECOND_FORMAT
      );
    return commonMethods.getDateTimeFromUtc(timeZone, TABLE_DATE_TIME_FORMAT, dateTime);
  };

  const audioApi = commonMethods.getAudioAPI({
    customerUrl,
    audioId: audioItem.id,
    audioFileName: audioItem.audioFileName,
  });

  const onConfirmDelete = () => {
    setDataLoading(true);

    onDeleteAudio(audioItem.id)
      .then(() => {
        setDataLoading(false);
        setShowDeleteModal(false);
      })
      .catch(() => {
        setDataLoading(false);
      });
  };

  const onEditAudioSubmit = (values) => {
    const payload = {
      ...values,
      startDateTime: getDateTimeForApi(values.startDateTime),
      endDateTime: getDateTimeForApi(values.endDateTime),
      duration: audioDuration,
    };

    const params = {
      customerUrl,
      audioId: +audioItem.id,
    };

    const formData = commonMethods.getFormData(payload, selectedAudioFile, 'audio');

    setDataLoading(true);

    return onEditAudio(formData, params)
      .then((response) => {
        updateUploadedAudioData(audioItem.id, response.data);
        setDataLoading(false);
        onEditAudioModalClose();
      })
      .catch(() => {
        setDataLoading(false);
        onEditAudioModalClose();
      });
  };

  const onCopyAudioSubmit = (values) => {
    const payload = {
      ...values,
      startDateTime: getDateTimeForApi(values.startDateTime),
      endDateTime: getDateTimeForApi(values.endDateTime),
      audioId: +audioItem.id,
    };

    const params = {
      customerUrl,
    };

    setDataLoading(true);

    return onCopyAudio(payload, params)
      .then(() => {
        setDataLoading(false);
        onCopyAudioModalClose();
      })
      .catch(() => {
        setDataLoading(false);
        onCopyAudioModalClose();
      });
  };

  useEffect(() => {
    if (selectedAudioFile === null) return;
    commonMethods.getAudioDurationOfUploadedFile(setAudioDuration, selectedAudioFile);
  }, [selectedAudioFile]);

  useEffect(() => {
    if (audioItem.uploadPercentage > 0) return;
    if (audioItem.copyAudio || selectedHeaderButtonId !== 4) return;

    const payload = {
      ..._.pick(audioItem, ['duration', 'audioName']),
      startDateTime: audioItem.startDateTime,
      endDateTime: audioItem.endDateTime,
      consumerCategoryId: categoryId,
    };

    const params = {
      customerUrl,
    };

    const formData = commonMethods.getFormData(payload, audioItem.selectedAudioFile, 'audio');

    const onUploadProgress = (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor((loaded * 99) / total);

      updateUploadedAudioData(audioItem.id, { ...audioItem, uploadPercentage: percentage });
    };

    // eslint-disable-next-line consistent-return
    return addAudio(formData, params, onUploadProgress)
      .then((response) => {
        setTimeout(() => {
          updateUploadedAudioData(audioItem.id, {
            ...audioItem,
            audioFileName: response.data.audioFileName,
            id: response.data.id,
            audioUploadProcessing: false,
            uploadPercentage: 100,
          });
        }, [300]);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <AudioPlayerModal
        showModal={showAudioPlayer}
        modalShowHideToggle={() => {
          setShowAudioPlayer(!showAudioPlayer);
        }}
        headerTitle="Audio Player"
        name={audioItem.audioName}
        audioSrc={audioApi}
      />

      <AudioModifyModal
        headerTitle="Edit Audio"
        timeZone={timeZone}
        submitButonText="Save Changes"
        showModal={showEditModal}
        onCloseModal={onEditAudioModalClose}
        setSelectedAudioFile={setSelectedAudioFile}
        audioItem={audioItem}
        dataSaving={dataLoading}
        onSubmit={onEditAudioSubmit}
      />

      <AudioModifyModal
        headerTitle="Copy Audio"
        timeZone={timeZone}
        submitButonText="Copy Audio File"
        showModal={showCopyModal}
        onCloseModal={onCopyAudioModalClose}
        setSelectedAudioFile={setSelectedAudioFile}
        audioItem={audioItem}
        dataSaving={dataLoading}
        onSubmit={onCopyAudioSubmit}
        showAudio
      />

      <WarningModal
        heading="Are you sure?"
        description={
          selectedHeaderButtonId !== 2
            ? `Are you sure, you want to delete the audio named ${audioItem.audioName}?`
            : ''
        }
        descriptionList={
          selectedHeaderButtonId === 2
            ? [
                `Are you sure, you want to delete the audio named ${audioItem.audioName}?`,
                `You are trying to delete an active audio which is already available to your consumers. If you make any changes, it will also reflect to the consumer's dashboard.`,
              ]
            : []
        }
        leftBtnText="No"
        rightBtnText="Yes"
        showModal={showDeleteModal}
        onHideModal={onDeleteAudioModalClose}
        processing={dataLoading}
        onModalSubmit={onConfirmDelete}
      />

      <WarningModal
        heading="Are you sure?"
        description={`You are trying to edit an active audio which is already available to your consumers. If you make any changes, it will also reflect to the consumer's dashboard.`}
        leftBtnText="Cancel"
        rightBtnText="Proceed"
        showModal={showEditModalWarning}
        onHideModal={() => {
          setShowEditModalWarning(false);
        }}
        onModalSubmit={onConfirmEdit}
      />

      <Col key={audioItem.id} sm={12} className="py-4 px-2 px-sm-3 border-top text-info">
        <Row className="d-none d-lg-flex">
          <Col className="d-flex justify-content-start">
            {!audioUploadProcessing && (
              <PlayCircleIcon
                width="2.5em"
                height="2.5em"
                className="text-primary cursor-pointer mr-2 custom-primary-btn"
                onClick={onPlayAudio}
              />
            )}

            {audioUploadProcessing && (
              <div className="progress-bar-audio mr-2">
                <CircularProgressbar
                  value={uploadPercentage}
                  text={`${uploadPercentage}%`}
                  styles={buildStyles({
                    pathColor: colors.primary,
                    textColor: colors.primary,
                    trailColor: `${colors.primary}1A`,
                  })}
                />
              </div>
            )}

            <OverlayTrigger
              transition={false}
              placement="bottom"
              overlay={<Tooltip>{audioItem.audioName}</Tooltip>}
            >
              <span className="flex-child-audio text-truncate pt-2">{audioItem.audioName}</span>
            </OverlayTrigger>
          </Col>
          <Col className="pt-2">
            <span>{audioItem.duration}</span>
          </Col>
          <Col className="pt-2">
            <span>{getLocalDateTime(audioItem.startDateTime)}</span>
          </Col>
          <Col className="pt-2">
            <span>{getLocalDateTime(audioItem.endDateTime)}</span>
          </Col>

          <Col className="d-none d-lg-flex justify-content-end mb-auto">
            <ActionButtons
              onHandleEdit={handleEditButton}
              onHandleCopy={handleCopyButton}
              onHandleDelete={handleDeleteButton}
              disabled={audioUploadProcessing}
            />
          </Col>
        </Row>

        <Row className="d-none d-sm-flex d-lg-none">
          <Col sm={3} md={4} className="pr-0 text-truncate">
            <PlayCircleIcon
              width="2.5em"
              height="2.5em"
              className="text-primary cursor-pointer mr-2 custom-primary-btn"
              onClick={onPlayAudio}
            />
            <OverlayTrigger
              transition={false}
              placement="bottom"
              overlay={<Tooltip>{audioItem.audioName}</Tooltip>}
            >
              <span className="pt-2">{audioItem.audioName}</span>
            </OverlayTrigger>
          </Col>
          <Col sm={1} className="pl-0 pt-2">
            <span>{audioItem.duration}</span>
          </Col>
          <Col sm={3} className="pt-2 px-4">
            <span>{getLocalDateTime(audioItem.startDateTime)}</span>
          </Col>
          <Col sm={3} className="pt-2 px-4">
            <span>{getLocalDateTime(audioItem.endDateTime)}</span>
          </Col>

          <Col sm={2} md={1} className="d-lg-none d-flex justify-content-end pt-2">
            <ActionButtonsMobile
              onHandleEdit={handleEditButton}
              onHandleCopy={handleCopyButton}
              onHandleDelete={handleDeleteButton}
              disabled={audioUploadProcessing}
            />
          </Col>
        </Row>

        <Row className="d-flex d-sm-none mobile-view-font">
          <Col xs={2}>
            <PlayCircleIcon
              width="2.5em"
              height="2.5em"
              className="text-primary cursor-pointer mr-2 custom-primary-btn"
              onClick={onPlayAudio}
            />
          </Col>

          <Col className="px-0" xs={8}>
            <Row>
              <Col xs={7} className="text-truncate">
                <OverlayTrigger
                  transition={false}
                  placement="bottom"
                  overlay={<Tooltip>{audioItem.audioName}</Tooltip>}
                >
                  <span className="font-weight-bold">{audioItem.audioName}</span>
                </OverlayTrigger>
              </Col>
              <Col className="pl-3">
                <span>{audioItem.duration}</span>
              </Col>
            </Row>
            <Row>
              <Col xs={5} className="pr-4">
                <span className="small">{getLocalDateTime(audioItem.startDateTime)}</span>
              </Col>
              <Col xs={1} className="pr-4">
                <span className="small">To</span>
              </Col>
              <Col xs={5} className="pl-4">
                <span className="small">{getLocalDateTime(audioItem.endDateTime)}</span>
              </Col>
            </Row>
          </Col>

          <Col xs={2}>
            <ActionButtonsMobile
              onHandleEdit={handleEditButton}
              onHandleCopy={handleCopyButton}
              onHandleDelete={handleDeleteButton}
              disabled={audioUploadProcessing}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
}

AudioRow.propTypes = {
  audioItem: singleAudioFileProps.isRequired,
  timeZone: PropTypes.string.isRequired,
  onDeleteAudio: PropTypes.func.isRequired,
  onEditAudio: PropTypes.func.isRequired,
  onCopyAudio: PropTypes.func.isRequired,
  updateUploadedAudioData: PropTypes.func.isRequired,
  addAudio: PropTypes.func.isRequired,
  selectedHeaderButtonId: PropTypes.number.isRequired,
};

AudioRow.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  addAudio: addAudioByCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioRow);
