import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { WarningIcon } from '../../../../helper/icons/common-icons';
import {
  deliveryMediumListProps,
  // eslint-disable-next-line prettier/prettier
  userInformationProps
} from '../consumer-subscription-common-props';

function TokenInfo(props) {
  const { deliveryMediums, checkedSms, userInfoState, isError, setError } = props;

  const [smsToken, setSmsToken] = useState(0);

  const history = useHistory();
  const { customerUrl } = useParams();

  const goToPackages = () => {
    history.push(`/${customerUrl}/packages`);
  };

  useEffect(() => {
    const smsNumberOfTokens = deliveryMediums.find(
      (item) => item.name.toLowerCase() === 'sms'
    )?.numberOfTokens;

    setSmsToken(smsNumberOfTokens);
  }, [deliveryMediums]);

  useEffect(() => {
    let sumOfTokens = 0;

    if (checkedSms) sumOfTokens += smsToken;

    if (sumOfTokens > userInfoState.tokenBalance) {
      setError(true);
    } else {
      setError(false);
    }
  }, [checkedSms]);

  return (
    <>
      {isError && (
        <Row className="rounded-lg my-4 buy-tokens-warning py-3 text-primary">
          <Col md={1} xs={1}>
            <WarningIcon
              className="text-primary ml-lg-3 ml-sm-1 mt-sm-2 mt-2"
              width="1.6em"
              height="1.6em"
            />
          </Col>
          <Col lg={8} md={7} sm={7} xs={9}>
            <small>
              You do not have enough tokens to avail of the Sunburst services. Buy more tokens to
              avail of these services.
            </small>
          </Col>
          <Col lg={3} md={4} sm={4} className="mt-md-3 mt-lg-0">
            <Button
              variant="outline-primary"
              className="mt-1 py-2"
              size="sm"
              onClick={goToPackages}
              block
            >
              Buy Tokens
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}

TokenInfo.propTypes = {
  deliveryMediums: deliveryMediumListProps.isRequired,
  userInfoState: userInformationProps.isRequired,
  checkedSms: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
};

TokenInfo.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TokenInfo);
