import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { ICON_SIZE } from '../../../../helper/constant-values';
import { AddIcon, EditIcon } from '../../../../helper/icons/common-icons';
import { getPaypalDefaultPaymentInfoPage } from '../../../../redux/actions/payments-action';
import { defaultPaymentInfoProps, userInformationProps } from '../../profile-common-props';

function PaypalDefaultPayment(props) {
  const {
    defaultPaymentInfo,
    getDefaultPaymentInfoPage,
    userInfoState,
    paymentProcessing,
    setPaymentProcessing,
  } = props;

  const [processing, setProcessing] = useState(false);

  const { customerUrl } = useParams();

  const onClickPaypalAddButton = () => {
    setProcessing(true);
    setPaymentProcessing(true);

    const params = {
      customerUrl,
      consumerId: userInfoState.id,
    };

    return getDefaultPaymentInfoPage(params)
      .then(({ redrictUrl }) => {
        window.location.href = redrictUrl;
      })
      .catch(() => {
        setPaymentProcessing(false);
        setProcessing(false);
      });
  };

  return (
    <Button
      variant="warning"
      size="lg"
      onClick={onClickPaypalAddButton}
      disabled={processing || paymentProcessing}
    >
      {defaultPaymentInfo.isPaypalPayment && (
        <>
          {!(processing || paymentProcessing) && (
            <EditIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
          )}
          {(processing || paymentProcessing) && (
            <FontAwesomeIcon icon={faSpinner} size="lg" spin className="mr-1" />
          )}
          Update PayPal Payment
        </>
      )}

      {!defaultPaymentInfo.isPaypalPayment && (
        <>
          {!(processing || paymentProcessing) && (
            <AddIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
          )}
          {(processing || paymentProcessing) && (
            <FontAwesomeIcon icon={faSpinner} size="lg" spin className="mr-1" />
          )}
          Add PayPal Payment
        </>
      )}
    </Button>
  );
}

PaypalDefaultPayment.propTypes = {
  getDefaultPaymentInfoPage: PropTypes.func.isRequired,
  defaultPaymentInfo: defaultPaymentInfoProps.isRequired,
  userInfoState: userInformationProps.isRequired,
  paymentProcessing: PropTypes.func.isRequired,
  setPaymentProcessing: PropTypes.bool.isRequired,
};

PaypalDefaultPayment.defaultProps = {};

const mapStateToProps = (state) => ({
  defaultPaymentInfo: state.payments.consumerDefaultPaymentInfo,
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  getDefaultPaymentInfoPage: getPaypalDefaultPaymentInfoPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaypalDefaultPayment);
