module.exports = {
  consumer: {
    profile: {
      default: (customerUrl = ':customerUrl') => `/${customerUrl}/profile`,
      edit: (customerUrl = ':customerUrl') => `/${customerUrl}/profile/edit`,
      verification: (customerUrl = ':customerUrl') => `/${customerUrl}/profile/edit/verification`,
      changePassword: (customerUrl = ':customerUrl') => `/${customerUrl}/profile/change-password`,
      updateDefaultPayment: (customerUrl = ':customerUrl') =>
        `/${customerUrl}/profile/default-payment/update`,
    },
  },
  customer: {
    profile: {
      default: (customerUrl = ':customerUrl') => `/${customerUrl}/customer/profile`,
      edit: (customerUrl = ':customerUrl') => `/${customerUrl}/customer/profile/edit`,
      verification: (customerUrl = ':customerUrl') =>
        `/${customerUrl}/customer/profile/edit/verification`,
      changePassword: (customerUrl = ':customerUrl') =>
        `/${customerUrl}/customer/profile/change-password`,
    },
    category: {
      default: (customerUrl = ':customerUrl') => `/${customerUrl}/customer/categories`,
      selectedCategory: (customerUrl = ':customerUrl', categoryId = ':categoryId') =>
        `/${customerUrl}/customer/categories/${categoryId}`,
      shortText: {
        upload: (customerUrl = ':customerUrl', categoryId = ':categoryId') =>
          `/${customerUrl}/customer/categories/${categoryId}/short-text/upload`,
      },
    },
  },
  admin: {
    profile: {
      default: () => '/admin/profile',
      edit: () => '/admin/profile/edit',
      verification: () => 'edit/verification',
      changePassword: () => '/admin/profile/change-password',
    },
    customers: {
      default: () => '/admin/customers',
      view: (id) => `/admin/customers/${id}`,
      add: () => '/admin/customers/add',
      update: (id) => `/admin/customers/${id}/edit`,
    },
  },
};
