import { showNotification } from '../../helper/notifications';
import supportTicketService from '../../services/support-ticket';
import { CREATE_SUPPORT_TICKET, GET_SUPPORT_TICKET_FOR_CONSUMER } from '../types';

export function getSupportTicketsForConsumer(query) {
  return (dispatch) => {
    supportTicketService
      .getSupportTicketsForConsumer(query)
      .then((response) => {
        dispatch({
          type: GET_SUPPORT_TICKET_FOR_CONSUMER,
          data: response.data,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error while getting support tickets',
        });
      });
  };
}

export function createSupportTicket(data, query) {
  return (dispatch) =>
    supportTicketService
      .create(data, query)
      .then((response) => {
        dispatch({
          type: CREATE_SUPPORT_TICKET,
          data: response.data,
        });
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          text: 'Error while sending support email',
        });

        return Promise.reject(error);
      });
}
