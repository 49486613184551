import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Button, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { getDefaultCardInfoByConsumer } from '../../../../redux/actions/payments-action';
import DropdownAndButtons from '../../../common/dropdown-and-buttons/dropdown-and-buttons';
import { userInformationProps } from '../../profile-common-props';
import PaypalDefaultPayment from './paypal-default-payment';
import StripeDefaultPayment from './stripe-default-payment';

function UpdateDefaultPayment(props) {
  const { goToUserProfile, getDefaultCardInfo, userInfoState } = props;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);
  const paymentTypes = [
    { id: 1, name: 'Card' },
    { id: 2, name: 'PayPal' },
  ];

  const [selectedPaymentType, setSelectedPaymentType] = useState(1);
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const { customerUrl } = useParams();
  const location = useLocation();
  const history = useHistory();

  const onPaymentTypeChange = (typeId) => {
    setSelectedPaymentType(parseInt(typeId, 10));
  };

  useEffect(() => {
    history.replace(location.pathname, {
      search: '',
    });

    getDefaultCardInfo({ customerUrl, consumerId: userInfoState.id });
  }, []);

  return (
    <>
      <Row className="pb-3">
        <Col>
          <Button
            variant="link"
            className="cursor-pointer button-shadow-none text-dark pl-0"
            onClick={goToUserProfile}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />{' '}
            <span className="d-inline">Back</span>
          </Button>
        </Col>
      </Row>

      <Container>
        <DropdownAndButtons
          types={paymentTypes}
          selectedType={selectedPaymentType}
          onTypeChange={onPaymentTypeChange}
        />

        {selectedPaymentType === 1 && (
          <Container>
            <Elements stripe={stripePromise}>
              <StripeDefaultPayment
                goToUserProfile={goToUserProfile}
                paymentProcessing={paymentProcessing}
                setPaymentProcessing={setPaymentProcessing}
              />
            </Elements>
          </Container>
        )}

        {selectedPaymentType === 2 && (
          <Container>
            <PaypalDefaultPayment
              paymentProcessing={paymentProcessing}
              setPaymentProcessing={setPaymentProcessing}
            />
          </Container>
        )}
      </Container>
    </>
  );
}

UpdateDefaultPayment.propTypes = {
  goToUserProfile: PropTypes.func.isRequired,
  getDefaultCardInfo: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
};

UpdateDefaultPayment.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  getDefaultCardInfo: getDefaultCardInfoByConsumer,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDefaultPayment);
