/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

export default class CustomAlert extends Component {
  constructor(props) {
    super(props);
    this.timeOutRef = null;
  }

  componentDidMount() {
    const { hasTimeout, timeOut } = this.props;
    if (hasTimeout) {
      this.timeOutRef = setTimeout(() => {
        this.handleDismiss();
      }, timeOut);
    }
  }

  handleDismiss = () => {
    const { closeAlert } = this.props;

    clearTimeout(this.timeOutRef);
    this.timeOutRef = null;

    if (closeAlert) closeAlert();
  };

  render() {
    const { bsStyle, message, closeAlert, className, style } = this.props;

    return (
      <Alert
        onDismiss={closeAlert && this.handleDismiss}
        bsStyle={bsStyle}
        style={{ ...style }}
        className={className}
      >
        {message}
      </Alert>
    );
  }
}
