import PropTypes from 'prop-types';

export const customerInformationProps = PropTypes.shape({
  customerDetails: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    logoUrl: PropTypes.string.isRequired,
    subscriptionCategory: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
});

export const consumerCategoryProps = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
  })
);

export const consumerSignupProps = PropTypes.shape({
  username: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  tempEmail: PropTypes.string.isRequired,
  tempSmsPhoneNumber: PropTypes.string.isRequired,
});

export const forgotPasswordProps = PropTypes.shape({
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
});

export const consumerSignupInitProps = PropTypes.shape({
  username: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired,
  defaultCategoryId: PropTypes.string.isRequired,
});
