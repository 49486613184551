import { useEffect, useState } from 'react';
import { Button, Col, Container, FormControl, InputGroup, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import consumerFaqData from '../../config/faq/consumer-faq-data';
import customerFaqData from '../../config/faq/customer-faq-data';
import commonMethods from '../../helper/common-methods';
import { CONSUMER, CUSTOMER, ICON_SIZE } from '../../helper/constant-values';
import { SearchIcon } from '../../helper/icons/common-icons';
import { browserViewProps, userInformationProps } from '../common/common-props';
import MenuBarWrapper from '../common/menu-bar-wrapper/menu-bar-wrapper';
import CustomPagination from '../common/pagination/custom-pagination';
import Faq from './faq';
import './faq.css';

function FaqContainer(props) {
  const { browserState, userInfoState } = props;

  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [faqData, setFaqData] = useState({
    data: [],
    totalItems: 0,
    totalPages: 1,
  });
  const [faqList, setFaqList] = useState([]);

  const addIdToFaqData = (faq) => ({
    ...faq,
    data: faq.data.map((item) => ({
      ...item,
      id: uuidv4(),
    })),
  });

  const updatePaginationData = () => {
    const dataList =
      searchText === ''
        ? faqList
        : faqList.filter(
            (item) => item.question.toLowerCase().search(searchText.toLocaleLowerCase()) !== -1
          );

    const data = commonMethods.getPagingData(dataList, {
      page,
      itemPerPage,
      isMobileView: browserState.isMobile,
    });

    setFaqData(addIdToFaqData(data));
  };

  const onLoadMoreClick = () => {
    if (faqData.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  const onChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    updatePaginationData();
  }, [page, itemPerPage, mobileLastPageLoaded, browserState.isMobile]);

  useEffect(() => {
    setPage(1);
    updatePaginationData();
  }, [searchText]);

  useEffect(() => {
    if (userInfoState.type === CONSUMER) {
      const data = commonMethods.getPagingData(consumerFaqData, {
        page,
        itemPerPage,
        isMobileView: browserState.isMobile,
      });

      setFaqData(addIdToFaqData(data));
      setFaqList(consumerFaqData);
    } else if (userInfoState.type === CUSTOMER) {
      const data = commonMethods.getPagingData(customerFaqData, {
        page,
        itemPerPage,
        isMobileView: browserState.isMobile,
      });

      setFaqData(addIdToFaqData(data));
      setFaqList(customerFaqData);
    }
  }, []);

  useEffect(() => {
    setSearchText('');
  }, [showSearchBox]);

  return (
    <MenuBarWrapper navBarIndex={3} headerTitle="Support">
      <Container className="container-uxl p-0">
        <Row>
          <Col xs={9} md={7} lg={8}>
            <h4>Frequently Asked Questions</h4>
          </Col>
          <Col xs={3} md={5} lg={4}>
            <InputGroup className="justify-content-end">
              <InputGroup.Prepend>
                <InputGroup.Text className="bg-white border-right-0 rounded-corner-left faq-search-icon border-0">
                  <SearchIcon width={ICON_SIZE} height={ICON_SIZE} className="d-none d-sm-block" />
                  <SearchIcon
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                    onClick={() => {
                      setShowSearchBox(!showSearchBox);
                    }}
                    className="d-block d-sm-none"
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>

              <FormControl
                placeholder="Search"
                className="border-0 faq-search-box rounded-corner-right d-none d-sm-block"
                onChange={onChangeSearch}
              />
            </InputGroup>
          </Col>
        </Row>

        {showSearchBox && (
          <Row className="d-flxex d-sm-none mt-3">
            <Col>
              <FormControl
                placeholder="Search"
                className="faq-search-box rounded-corner"
                onChange={onChangeSearch}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Faq faqList={faqData.data} />
        </Row>

        {faqData.totalItems > 10 && (
          <Row>
            <Col xs={12} className="d-none d-md-block">
              <CustomPagination
                itemPerPage={itemPerPage}
                page={page}
                setPage={setPage}
                setItemPerPage={setItemPerPage}
                totalPages={faqData.totalPages}
                totalItems={faqData.totalItems}
                customClass="bg-white rounded-corner border-0"
              />
            </Col>
          </Row>
        )}

        {faqData.totalItems > faqData.data.length && (
          <Row>
            <Col
              sm={11}
              className="bg-light justify-content-center justify-content-lg-start pt-4 d-sm-none"
            >
              <Button size="lg" variant="secondary" onClick={onLoadMoreClick} block>
                Load More
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </MenuBarWrapper>
  );
}

FaqContainer.propTypes = {
  browserState: browserViewProps.isRequired,
  userInfoState: userInformationProps.isRequired,
};

FaqContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  browserState: state.browserData.view,
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FaqContainer);
