import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import routes from '../../../../helper/route-details';
import CustomerDetailsModify from './customer-details/customer-details-modify';

export default function UpdateCustomerContainer() {
  const { customerId } = useParams();

  const history = useHistory();

  const gotoCustomerDetailsViewPage = () => {
    history.push(routes.admin.customers.view(customerId));
  };

  return (
    <>
      <Container className="container-uxl">
        <CustomerDetailsModify
          onBackArrowButtonClick={gotoCustomerDetailsViewPage}
          onSavingData={gotoCustomerDetailsViewPage}
        />
      </Container>
    </>
  );
}

UpdateCustomerContainer.propTypes = {};

UpdateCustomerContainer.defaultProps = {};
