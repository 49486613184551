import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { BsCreditCard, BsPeople } from 'react-icons/bs';
import { MdOutlineGeneratingTokens } from 'react-icons/md';
import { TiThListOutline } from 'react-icons/ti';
import { MenuItem } from 'react-pro-sidebar';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ADMIN, CONSUMER, CUSTOMER } from '../../../helper/constant-values';
import {
  CategoryIcon,
  ContactSupportIcon,
  FaqIcon,
  MusicIcon,
  PackageIcon,
  RevenueIcon,
  // eslint-disable-next-line prettier/prettier
  SubscriptionIcon
} from '../../../helper/icons/common-icons';
import { userInformationProps } from '../common-props';
import './navigation-bar.scss';

function UserMenuItems(props) {
  const {
    userInfoState,
    menuItemClass,
    activeNavIndex,
    onToggleSidebar,
    onConsumerAudioFilesTabChage,
    changeAudioTab,
    setChangeAudioTab,
  } = props;

  const { type: userType, customerUrl } = userInfoState;

  const [currentPageTitle, setCurrentPageTitle] = useState('');
  const [lastestPageTitle, setLastestPageTitle] = useState('');
  const [lastestPageIndex, setLastestPageIndex] = useState(-1);
  const history = useHistory();

  const LARGE_ICON_SIZE = '2.3em';
  const SMALL_ICON_SIZE = '1.6em';

  const activeMenuItemClass = userType === ADMIN ? 'admin-active-menu-item' : 'active-menu-item';
  const hoverMenuItemClass = userType === ADMIN ? 'hover-color-item-admin' : 'hover-color-item';
  const showOnlyIconClass = `d-none d-sm-block d-lg-none ${hoverMenuItemClass}`;
  const showIconWithTextClass = `d-inline d-sm-none d-lg-inline ${hoverMenuItemClass}`;

  const customerItemTitles = ['Revenue & Usage', 'Categories & Services', 'Packages', 'FAQ'];

  const consumerItemTitles = [
    'Audio Readings',
    'Purchase Tokens',
    'Daily Sunbursts',
    'Support / FAQ',
    'Contact Us',
  ];

  const adminItemTitles = ['Customers', 'Consumers', 'Payments', 'Token Transaction'];

  const customerItemIconsSm = [
    <RevenueIcon width={SMALL_ICON_SIZE} height={SMALL_ICON_SIZE} />,
    <CategoryIcon width={SMALL_ICON_SIZE} height={SMALL_ICON_SIZE} />,
    <PackageIcon width={SMALL_ICON_SIZE} height={SMALL_ICON_SIZE} />,
    <FaqIcon width={SMALL_ICON_SIZE} height={SMALL_ICON_SIZE} />,
  ];

  const customerItemIconsLg = [
    <RevenueIcon width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} />,
    <CategoryIcon width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} />,
    <PackageIcon width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} />,
    <FaqIcon width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} />,
  ];

  const consumerItemIconsSm = [
    <MusicIcon width={SMALL_ICON_SIZE} height={SMALL_ICON_SIZE} />,
    <PackageIcon width={SMALL_ICON_SIZE} height={SMALL_ICON_SIZE} />,
    <SubscriptionIcon width={SMALL_ICON_SIZE} height={SMALL_ICON_SIZE} />,
    <FaqIcon width={SMALL_ICON_SIZE} height={SMALL_ICON_SIZE} />,
    <ContactSupportIcon width={SMALL_ICON_SIZE} height={SMALL_ICON_SIZE} />,
  ];

  const consumerItemIconsLg = [
    <MusicIcon width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} />,
    <PackageIcon width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} />,
    <SubscriptionIcon width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} />,
    <FaqIcon width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} />,
    <ContactSupportIcon width={LARGE_ICON_SIZE} height={LARGE_ICON_SIZE} />,
  ];

  const adminItemIconsSm = [
    <BsPeople size="1.6em" />,
    <TiThListOutline size="1.6em" />,
    <BsCreditCard size="1.6em" />,
    <MdOutlineGeneratingTokens size="1.6em" />,
  ];

  const adminItemIconsLg = [
    <BsPeople size="2.3em" />,
    <TiThListOutline size="2.3em" />,
    <BsCreditCard size="2.3em" />,
    <MdOutlineGeneratingTokens size="2.3em" />,
  ];

  const customerItemLinks = [
    `/${customerUrl}/customer/revenue-report`,
    `/${customerUrl}/customer/categories`,
    `/${customerUrl}/customer/packages`,
    `/${customerUrl}/customer/faq`,
  ];

  const consumerItemLinks = [
    `/${customerUrl}/dashboard`,
    `/${customerUrl}/packages`,
    `/${customerUrl}/subscriptions`,
    `/${customerUrl}/faq`,
    `/${customerUrl}/contact-support`,
  ];

  const adminItemLinks = [
    '/admin/customers',
    '/admin/consumers',
    '/admin/credit-refund',
    '/admin/token-transction',
  ];

  let itemTitles = [];
  let itemLinks = [];

  let itemIconsSm = [];
  let itemIconsLg = [];

  if (userType === ADMIN) {
    itemTitles = adminItemTitles;
    itemLinks = adminItemLinks;
    itemIconsSm = adminItemIconsSm;
    itemIconsLg = adminItemIconsLg;
  } else if (userType === CUSTOMER) {
    itemTitles = customerItemTitles;
    itemLinks = customerItemLinks;
    itemIconsSm = customerItemIconsSm;
    itemIconsLg = customerItemIconsLg;
  } else {
    itemTitles = consumerItemTitles;
    itemLinks = consumerItemLinks;
    itemIconsSm = consumerItemIconsSm;
    itemIconsLg = consumerItemIconsLg;
  }

  const changeCurrentPage = (title, index) => {
    setCurrentPageTitle(title);
    setChangeAudioTab(false);

    setTimeout(() => {
      onToggleSidebar(false);
    }, 150);
    history.push(itemLinks[index]);
  };

  const onMenuItemClick = (title, index) => {
    if (userType === CONSUMER && currentPageTitle === consumerItemTitles[0]) {
      onConsumerAudioFilesTabChage();
      setLastestPageTitle(title);
      setLastestPageIndex(index);
    } else {
      changeCurrentPage(title, index);
    }
  };

  useEffect(() => {
    if (changeAudioTab) {
      changeCurrentPage(lastestPageTitle, lastestPageIndex);
    }
  }, [changeAudioTab]);

  useEffect(() => {
    if (activeNavIndex !== -1) {
      setCurrentPageTitle(itemTitles[activeNavIndex]);
    }
  }, []);

  return (
    <>
      {itemTitles.map((title, index) => (
        <div key={title}>
          <MenuItem
            className={
              title === currentPageTitle
                ? `${menuItemClass} ${activeMenuItemClass} admin-list`
                : `${menuItemClass} admin-list`
            }
            onClick={() => onMenuItemClick(title, index)}
          >
            <span className={showIconWithTextClass}>{itemIconsSm[index]}</span>
            &ensp;
            <span className={`${showIconWithTextClass} h6`}>{title}</span>
            <span className={showOnlyIconClass}>{itemIconsLg[index]}</span>
          </MenuItem>
          <br />
        </div>
      ))}
    </>
  );
}

UserMenuItems.propTypes = {
  activeNavIndex: PropTypes.number,
  menuItemClass: PropTypes.string.isRequired,
  onToggleSidebar: PropTypes.func.isRequired,
  onConsumerAudioFilesTabChage: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
  changeAudioTab: PropTypes.bool.isRequired,
  setChangeAudioTab: PropTypes.func.isRequired,
};

UserMenuItems.defaultProps = {
  activeNavIndex: -1,
};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenuItems);
