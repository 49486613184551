import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import CustomerInfoPanel from './customer-info-panel';
import NonCustomerInfoPanel from './non-customer-info-panel';

export default function InfoPanel(props) {
  const { isCustomerInfo, title, details, imageUrl, buttonText } = props;
  const formattedTitle = title.charAt(0).toUpperCase() + title.slice(1);
  return (
    <Container>
      {isCustomerInfo ? (
        <CustomerInfoPanel
          title={formattedTitle}
          details={details}
          imageUrl={imageUrl}
          buttonText={buttonText}
          isCustomerInfo
        />
      ) : (
        <NonCustomerInfoPanel
          title={formattedTitle}
          details={details}
          imageUrl={imageUrl}
          buttonText={buttonText}
          isCustomerInfo
        />
      )}
    </Container>
  );
}

InfoPanel.propTypes = {
  isCustomerInfo: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
};

InfoPanel.defaultProps = {
  buttonText: '',
};
