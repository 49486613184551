/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import Colors from '../../../helper/colors';
import './dropzone.css';

export default function Dropzone(props) {
  const {
    supportedFiles,
    fileMaxSize,
    backgroundColor,
    borderAndButtonVarient,
    onAudioFileSelect,
    acceptedFileTypes,
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);

  const [fileError, setFileError] = useState('');

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: acceptedFileTypes,
    onDrop: (files) => {
      if (files.length === 0) {
        setFileError('Invalid file is selected.');
      } else {
        setFileError('');
      }
    },
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
      onAudioFileSelect(acceptedFiles[0]);
    } else {
      setSelectedFile(null);
      onAudioFileSelect(null);
    }
  }, [acceptedFiles]);

  return (
    <Container>
      <Container
        {...getRootProps({ className: `dropzone text-${borderAndButtonVarient}` })}
        style={{ backgroundColor: `${backgroundColor}1A` }}
      >
        <input {...getInputProps()} />
        <Row className="justify-content-center text-dark">Drag and drop files here</Row>
        <Row className="justify-content-center pt-2 text-muted">OR</Row>
        <Row className="justify-content-center pt-2">
          <Col xs={8} sm={6} lg={4}>
            <Button size="lg" variant={`outline-${borderAndButtonVarient}`} onClick={open} block>
              Browse Files
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center pt-2 text-dark">
          <small>Supported File formats: {supportedFiles}</small>
        </Row>
        <Row className="justify-content-center pt-1 text-dark">
          <small>File Size: {fileMaxSize} (max)</small>
        </Row>

        {fileError && (
          <Row className="justify-content-center pt-1 text-primary">
            <small>* {fileError}</small>
          </Row>
        )}

        {selectedFile !== null && (
          <Row className="justify-content-center pt-1 text-dark">
            <small>
              <span className="d-inline-block text-truncate slected-file">
                <strong>Selected File:</strong> {selectedFile.name}
              </span>
            </small>
          </Row>
        )}

        {selectedFile === null && !fileError && (
          <Row className="justify-content-center pt-1 text-dark">
            <br />
          </Row>
        )}
      </Container>
    </Container>
  );
}

Dropzone.propTypes = {
  supportedFiles: PropTypes.string.isRequired,
  fileMaxSize: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  borderAndButtonVarient: PropTypes.string,
  onAudioFileSelect: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.string,
};

Dropzone.defaultProps = {
  borderAndButtonVarient: 'primary',
  backgroundColor: Colors.primary,
  acceptedFileTypes: '.mp3',
};
