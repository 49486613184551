import {
  CONSUMER_SIGN_UP,
  CONSUMER_SIGN_UP_INIT,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_INIT,
  LOGIN_INIT,
  LOG_OUT,
  OTP_VERIFICATION,
  PACKAGE_PAYMENT,
  UPDATE_TOKEN_BALANCE,
  // eslint-disable-next-line prettier/prettier
  USER_TOKEN_VERIFICATION
} from '../types';

const initialState = {
  forgotPassword: {
    username: '',
    email: '',
    message: '',
  },
  forgotPasswordPrevious: {
    emailOrUsername: '',
  },
  loginPrevious: {
    emailOrUsername: '',
  },
  consumerSignup: {
    email: '',
    fullName: '',
    username: '',
    tempEmail: '',
  },
  signupPrevious: {
    username: '',
    fullName: '',
    email: '',
    contactNumber: '',
    defaultCategoryId: '',
  },
  logout: {
    message: '',
  },
  otpVerification: {
    message: '',
    isInvalidShortCode: false,
    passwordToken: '',
  },
  userInfo: {
    fullName: '',
    id: 0,
    username: '',
    type: '',
    customerUrl: '',
    customerLogo: '',
    tokenBalance: 0,
    profilePic: '',
    defaultCategoryId: 0,
    email: '',
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: action.data,
      };

    case CONSUMER_SIGN_UP:
      return {
        ...state,
        consumerSignup: action.data,
      };

    case LOG_OUT:
      return {
        ...state,
        logout: action.data,
      };

    case OTP_VERIFICATION:
      return {
        ...state,
        otpVerification: action.data,
      };

    case USER_TOKEN_VERIFICATION:
      return {
        ...state,
        userInfo: action.data,
      };

    case CONSUMER_SIGN_UP_INIT:
      return {
        ...state,
        signupPrevious: action.data,
      };

    case FORGOT_PASSWORD_INIT:
      return {
        ...state,
        forgotPasswordPrevious: action.data,
      };

    case LOGIN_INIT:
      return {
        ...state,
        loginPrevious: action.data,
      };

    case PACKAGE_PAYMENT:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.data,
        },
      };

    case UPDATE_TOKEN_BALANCE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.data,
        },
      };

    default:
      return state;
  }
};

export default authReducer;
