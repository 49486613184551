import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import commonMethods from '../../../helper/common-methods';
import { adminProfileValidationSchema } from '../../../helper/schema-objects';
import { updateAdminProfile } from '../../../redux/actions/profile-action';
import FormComponent from '../../common/form-component/form-component';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import VerificationCodeConfirmModal from '../../common/modals/verification-code-send-confirmation-modal/verification-code-confirmation-modal';
import { adminProfilePreviousProps, adminProfileProps } from '../profile-common-props';

function AdminEditProfile(props) {
  const {
    onUpdatingProfile,
    profileState,
    updateProfile,
    backFromVerification,
    profilePreviousState,
    focusEmail,
    profilePicFile,
  } = props;

  const [dataSaving, setDataSaving] = useState(false);
  const [showVerificationConfirmModal, setShowVerificationConfirmModal] = useState(false);
  const [profileSubmitValues, setProfileSubmitValues] = useState({});
  const [profileSubmitMethods, setProfileSubmitMethods] = useState({});

  let initialValues = {
    fullName: profileState.fullName,
    email: profileState.email,
    contactNumber: profileState.contactNumber,
  };

  if (backFromVerification) {
    initialValues = {
      ...initialValues,
      ...profilePreviousState,
    };
  }

  const onVerificationModalCancel = () => {
    setShowVerificationConfirmModal(false);
    setProfileSubmitValues({});
    setProfileSubmitMethods({});
  };

  const onVerificationModalProceed = (values, formikFunctions) => {
    const profileData = {
      ...values,
      fullName: values.fullName.trim(),
    };

    setDataSaving(true);
    onVerificationModalCancel();

    const formData = commonMethods.getFormData(profileData, profilePicFile);

    updateProfile(formData, profileData)
      .then((data) => {
        setDataSaving(false);
        onUpdatingProfile({ ...data, ...profileData });
      })
      .catch((error) => {
        if (!error.response) {
          return;
        }

        setDataSaving(false);

        const { isExistingEmail, message } = error.response.data;

        if (isExistingEmail) {
          formikFunctions.setErrors({
            email: message,
          });
        }
      });
  };

  const onSubmit = (values, formikFunctions) => {
    if (values.email !== profileState.email) {
      setProfileSubmitValues(values);
      setProfileSubmitMethods(formikFunctions);
      setShowVerificationConfirmModal(true);
    } else {
      onVerificationModalProceed(values, formikFunctions);
    }
  };

  return (
    <>
      {showVerificationConfirmModal && (
        <VerificationCodeConfirmModal
          isEmailVerification
          isProfilePage
          verificationMedium={profileSubmitValues.email}
          showModal={showVerificationConfirmModal}
          onModalCancel={onVerificationModalCancel}
          onModalProceed={() =>
            onVerificationModalProceed(profileSubmitValues, profileSubmitMethods)
          }
        />
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={adminProfileValidationSchema}
        onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Row className="justify-content-center">
              <FormComponent
                placeholder="Name"
                name="fullName"
                inputColumnClass="col-lg-5 col-sm-10"
                errorColumnClass="d-none"
                formik={formik}
                autoFocusInput
                showLabel
              />
            </Row>
            <Row className="justify-content-center">
              <FormComponent
                name="fullName"
                inputColumnClass="d-none"
                errorColumnClass="col-lg-5 col-sm-10"
                formik={formik}
              />
            </Row>

            <Row className="mt-1 justify-content-center">
              <FormComponent
                placeholder="Email"
                name="email"
                inputColumnClass="col-lg-5 col-sm-10"
                errorColumnClass="d-none"
                formik={formik}
                autoFocusInput={focusEmail}
                showLabel
              />
            </Row>
            <Row className="justify-content-center">
              <FormComponent
                name="email"
                inputColumnClass="d-none"
                errorColumnClass="col-lg-5 col-sm-10"
                formik={formik}
              />
            </Row>

            <Row className="mt-1 justify-content-center">
              <FormComponent
                placeholder="Contact Phone Number"
                name="contactNumber"
                inputColumnClass="col-lg-5 col-sm-10"
                errorColumnClass="d-none"
                formik={formik}
                showLabel
              />
            </Row>
            <Row className="justify-content-center">
              <FormComponent
                name="contactNumber"
                inputColumnClass="d-none"
                errorColumnClass="col-lg-5 col-sm-10"
                formik={formik}
              />
            </Row>

            <Row className="justify-content-center mt-1 mb-5">
              <Col sm={10} lg={5}>
                <Button
                  variant="secondary"
                  size="lg"
                  block
                  onClick={formik.handleSubmit}
                  type="submit"
                  disabled={
                    !!(
                      formik.errors.fullName ||
                      formik.errors.email ||
                      formik.errors.contactNumber ||
                      dataSaving
                    )
                  }
                >
                  <DataSavingSpinner
                    savingText="Saving Changes"
                    defaultText="Save Changes"
                    dataSaving={dataSaving}
                  />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}

AdminEditProfile.propTypes = {
  onUpdatingProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  profileState: adminProfileProps.isRequired,
  backFromVerification: PropTypes.bool.isRequired,
  profilePreviousState: adminProfilePreviousProps.isRequired,
  focusEmail: PropTypes.bool.isRequired,
  profilePicFile: PropTypes.string.isRequired,
};

AdminEditProfile.defaultProps = {};

const mapStateToProps = (state) => ({
  profileState: state.profile.adminProfile,
  profilePreviousState: state.profile.adminProfilePrevious,
});

const mapDispatchToProps = {
  updateProfile: updateAdminProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditProfile);
