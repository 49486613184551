import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';
import { formikProps } from '../common-props';
import FormComponent from './form-component';

export default function DoubleFormComponent(props) {
  const {
    formik,
    firstColumnDisable,
    firstColumnName,
    firstColumnPlaceHolder,
    secondColumnName,
    secondColumnPlaceHolder,
    firstColumnType,
    secondColumnType,
    FirstColumnChildren,
    SecondColumnChildren,
    firstColumnAutoFocus,
    secondColumnAutoFocus,
    firstColumnIsPassword,
    secondColumnIsPassword,
    firstColumnOnIconClick,
    secondColumnOnIconClick,
    firstColumnShowLabel,
    secondColumnShowLabel,
    firstColumnIconToolTip,
    secondColumnIconToolTip,
  } = props;

  return (
    <>
      <Row className="justify-content-center">
        <FormComponent
          type={firstColumnType}
          placeholder={firstColumnPlaceHolder}
          name={firstColumnName}
          inputColumnClass="col-sm-10 col-md-6 col-lg-4"
          errorColumnClass="d-none"
          formik={formik}
          autoFocusInput={firstColumnAutoFocus}
          disabled={firstColumnDisable}
          isPassword={firstColumnIsPassword}
          onIconClick={firstColumnOnIconClick}
          showLabel={firstColumnShowLabel}
          iconToolTip={firstColumnIconToolTip}
        >
          <FirstColumnChildren />
        </FormComponent>

        <FormComponent
          name={firstColumnName}
          inputColumnClass="d-none"
          errorColumnClass="col-sm-10 col-md-6 col-lg-4 d-sm-none"
          formik={formik}
        />

        {secondColumnIsPassword && (
          <Col className="d-flex d-sm-none">
            <Form.Label>Confirm Password</Form.Label>
          </Col>
        )}

        <FormComponent
          type={secondColumnType}
          placeholder={secondColumnPlaceHolder}
          name={secondColumnName}
          inputColumnClass="col-sm-10 col-md-6 col-lg-4"
          errorColumnClass="d-none"
          formik={formik}
          autoFocusInput={secondColumnAutoFocus}
          isPassword={secondColumnIsPassword}
          onIconClick={secondColumnOnIconClick}
          showLabel={secondColumnShowLabel}
          iconToolTip={secondColumnIconToolTip}
        >
          <SecondColumnChildren />
        </FormComponent>

        <FormComponent
          name={secondColumnName}
          inputColumnClass="d-none"
          errorColumnClass="col-sm-10 col-md-6 col-lg-4 d-sm-none"
          formik={formik}
        />
      </Row>

      <Row className="justify-content-center d-none d-sm-flex">
        <FormComponent
          name={firstColumnName}
          inputColumnClass="d-none"
          errorColumnClass="col-sm-10 col-md-6 col-lg-4"
          formik={formik}
        />
        <FormComponent
          name={secondColumnName}
          inputColumnClass="d-none"
          errorColumnClass="col-sm-10 col-md-6 col-lg-4"
          formik={formik}
        />
      </Row>
    </>
  );
}

DoubleFormComponent.propTypes = {
  formik: formikProps.isRequired,
  firstColumnName: PropTypes.string.isRequired,
  firstColumnPlaceHolder: PropTypes.string,
  secondColumnName: PropTypes.string.isRequired,
  secondColumnPlaceHolder: PropTypes.string,
  firstColumnDisable: PropTypes.bool,
  firstColumnType: PropTypes.string,
  secondColumnType: PropTypes.string,
  FirstColumnChildren: PropTypes.elementType,
  SecondColumnChildren: PropTypes.elementType,
  firstColumnAutoFocus: PropTypes.bool,
  secondColumnAutoFocus: PropTypes.bool,
  firstColumnIsPassword: PropTypes.bool,
  secondColumnIsPassword: PropTypes.bool,
  firstColumnOnIconClick: PropTypes.func,
  secondColumnOnIconClick: PropTypes.func,
  firstColumnShowLabel: PropTypes.bool,
  secondColumnShowLabel: PropTypes.bool,
  firstColumnIconToolTip: PropTypes.string,
  secondColumnIconToolTip: PropTypes.string,
};

DoubleFormComponent.defaultProps = {
  firstColumnDisable: false,
  firstColumnType: 'text',
  secondColumnType: 'text',
  FirstColumnChildren: () => <p>ok</p>,
  SecondColumnChildren: () => <p>ok</p>,
  firstColumnPlaceHolder: '',
  secondColumnPlaceHolder: '',
  firstColumnAutoFocus: false,
  secondColumnAutoFocus: false,
  firstColumnIsPassword: false,
  secondColumnIsPassword: false,
  firstColumnOnIconClick: () => {},
  secondColumnOnIconClick: () => {},
  firstColumnShowLabel: false,
  secondColumnShowLabel: false,
  firstColumnIconToolTip: '',
  secondColumnIconToolTip: '',
};
