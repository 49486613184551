/* eslint-disable no-unused-vars */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import info9000Logo from '../../../assets/image/info9000Logo.png';
import { OTP_TYPE_PASSWORD } from '../../../helper/constant-values';
import AdminForgotPassword from './admin-forgot-password/admin-forgot-password';
import AdminNewPassword from './admin-forgot-password/admin-new-password';
import AdminLogin from './admin-login';
import AdminOtpVerification from './admin-otp-verfication/admin-otp-verification';

export default function AdminLoginContainer(props) {
  const [isLoginPage, setIsLoginPage] = useState(true);
  const [isForgotPasswordPage, setIsForgotPasswordPage] = useState(false);
  const [isNewPasswordPage, setIsNewPasswordPage] = useState(false);
  const [isVerificationPage, setIsVerificationPage] = useState(false);
  const [backFromVerification, setBackFromVerification] = useState(false);

  const goToLogin = () => {
    setIsLoginPage(true);
    setIsForgotPasswordPage(false);
    setIsNewPasswordPage(false);
    setIsVerificationPage(false);
    setBackFromVerification(false);
  };

  const goToForgotPassword = () => {
    setIsLoginPage(false);
    setIsForgotPasswordPage(true);
    setIsNewPasswordPage(false);
    setIsVerificationPage(false);
  };

  const goToVerification = () => {
    setIsLoginPage(false);
    setIsForgotPasswordPage(false);
    setIsNewPasswordPage(false);
    setIsVerificationPage(true);
  };

  const goToNewPasswordPage = () => {
    setIsLoginPage(false);
    setIsForgotPasswordPage(false);
    setIsNewPasswordPage(true);
    setIsVerificationPage(false);
  };

  const onBackButtonClick = () => {
    if (isVerificationPage || isNewPasswordPage) {
      goToForgotPassword();
      setBackFromVerification(true);
    } else if (isForgotPasswordPage) {
      goToLogin();
    }
  };

  return (
    <Container fluid>
      {!isLoginPage && (
        <Row className="border-bottom py-3">
          <Col sm={2} xs={4}>
            <Button
              variant="link"
              className="cursor-pointer button-shadow-none text-dark pl-0"
              onClick={onBackButtonClick}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Back
            </Button>
          </Col>
        </Row>
      )}

      <Container className="justify-content-center px-0 px-sm-auto">
        <Card className="mt-5 pb-4 bg-light">
          <Card.Body className="px-2 pl-lg-0">
            <Row className="justify-content-center pb-4">
              <Col sm={11} className="d-flex justify-content-center">
                <img src={info9000Logo} alt="info-9000 logo" />
              </Col>
            </Row>
            {isLoginPage && <AdminLogin goToForgotPassword={goToForgotPassword} />}

            {isForgotPasswordPage && (
              <AdminForgotPassword
                goToVerificationPage={goToVerification}
                backFromVerification={backFromVerification}
              />
            )}

            {isNewPasswordPage && <AdminNewPassword goToLoginPage={goToLogin} />}

            {isVerificationPage && (
              <AdminOtpVerification
                onVerification={goToNewPasswordPage}
                otpType={OTP_TYPE_PASSWORD}
                onEditIconClick={onBackButtonClick}
              />
            )}
          </Card.Body>
        </Card>
      </Container>
    </Container>
  );
}

AdminLoginContainer.propTypes = {};

AdminLoginContainer.defaultProps = {};
