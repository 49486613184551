import { Pagination } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { Col, Dropdown, Row } from 'react-bootstrap';
import './custom-pagination.css';

export default function CustomPagination(props) {
  const { setPage, setItemPerPage, itemPerPage, page, totalPages, totalItems, customClass } = props;

  const onPageChage = (e, pageNo) => {
    setPage(pageNo);
  };

  const onItemPerPageChage = (itemNumber) => {
    const itemCurrentPosistion = page === 1 ? 1 : (page - 1) * itemPerPage;
    const newPageNumber = +Math.ceil(itemCurrentPosistion / itemNumber);

    setItemPerPage(+itemNumber);
    setPage(newPageNumber);
  };

  return (
    <Row className={`pagination-row p-2 m-0 d-none d-sm-flex ${customClass}`}>
      <Col className="pt-2 pr-0">
        <span className="d-inline">
          <span className="text-info">Items per page:</span>{' '}
          <span className="text-dark font-weight-bold">{itemPerPage}</span>
        </span>
        <Dropdown className="d-inline" onSelect={onItemPerPageChage}>
          <Dropdown.Toggle
            split
            variant="loght"
            id="dropdown-split-basic"
            className="toggle-button"
          />

          <Dropdown.Menu className="pagination-dropdown">
            <Dropdown.Item eventKey={10}>10</Dropdown.Item>
            <Dropdown.Item eventKey={20}>20</Dropdown.Item>
            <Dropdown.Item eventKey={50}>50</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <span className="text-info"> of {totalItems} entries</span>
      </Col>
      <Col sm={6} className="d-flex justify-content-end pt-2 pr-0">
        <Pagination
          size="medium"
          className="d-lg-none"
          count={totalPages}
          color="primary"
          siblingCount={0}
          page={page}
          onChange={onPageChage}
        />
        <Pagination
          size="medium"
          className="d-none d-lg-block"
          count={totalPages}
          color="primary"
          page={page}
          onChange={onPageChage}
        />
      </Col>
    </Row>
  );
}

CustomPagination.propTypes = {
  setPage: PropTypes.func.isRequired,
  setItemPerPage: PropTypes.func.isRequired,
  itemPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  customClass: PropTypes.string,
};

CustomPagination.defaultProps = {
  customClass: '',
};
