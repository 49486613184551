import PropTypes from 'prop-types';
import React from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';

const ButtonToggle = React.forwardRef(({ onClick }, ref) => (
  <div
    ref={ref}
    role="button"
    tabIndex={0}
    className="cursor-pointer"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <BsThreeDotsVertical size="1.2rem" />
  </div>
));

ButtonToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
};

ButtonToggle.defaultProps = {};

export default ButtonToggle;
