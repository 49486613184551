import PropTypes from 'prop-types';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { BackIcon } from '../../../../helper/icons/common-icons';
import routes from '../../../../helper/route-details';
import FilesHeaderButton from './common-components/files-header-buttons';
import CustomerCategoryContainer from './customer-category/customer-category-container';
import CustomerInfoContainer from './customer-info/customer-info-container';
import CustomerPackageContainer from './customer-package/customer-package-container';

const headerButtons = [
  { name: 'Customer Info', id: 1 },
  { name: 'Category', id: 2 },
  { name: 'Package', id: 3 },
];

export default function ViewCustomerContainer(props) {
  const {
    isAddCustomerCategoryPage,
    isAddCustomerPackagePage,
    newCustomerId,
    onBackButtonClick,
    gotoCustomerPackageAddPage,
    gotoCustomerListViewPage,
  } = props;

  const getHeaderTitle = () => {
    if (isAddCustomerCategoryPage) {
      return 'Customer Category';
    }
    if (isAddCustomerPackagePage) {
      return 'Customer Package';
    }
    return 'Customer Information';
  };

  const [infoView, setInfoView] = useState(!isAddCustomerCategoryPage && !isAddCustomerPackagePage);
  const [categoryView, setCategoryView] = useState(isAddCustomerCategoryPage);
  const [packageView, setPackageView] = useState(isAddCustomerPackagePage);
  const [selectedTabId, setSelectedTabId] = useState(1);
  const [showDropDown, setShowDropDown] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(getHeaderTitle());

  const history = useHistory();

  const onBackArrowButtonClick = () => {
    history.push(routes.admin.customers.default());
  };

  const onToggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const gotoCategoryTab = () => {
    setInfoView(false);
    setCategoryView(true);
    setPackageView(false);
    setHeaderTitle('Customer Category');
  };

  const gotoCustomerInfoTab = () => {
    setInfoView(true);
    setCategoryView(false);
    setPackageView(false);
    setHeaderTitle('Customer Information');
  };

  const gotoPackageTab = () => {
    setInfoView(false);
    setCategoryView(false);
    setPackageView(true);
    setHeaderTitle('Customer Package');
  };

  const onTabChange = (id) => {
    if (selectedTabId !== +id) {
      setSelectedTabId(+id);

      if (+id === 1) {
        gotoCustomerInfoTab();
      } else if (+id === 2) {
        gotoCategoryTab();
      } else {
        gotoPackageTab();
      }
    }
  };

  return (
    <Container className="container-uxl">
      <Row>
        <Col className="pl-0">
          <BackIcon
            className="d-inline mb-2 mr-2 cursor-pointer arrow-button-admin"
            width="2.3em"
            height="2.3em"
            onClick={
              isAddCustomerCategoryPage || isAddCustomerPackagePage
                ? onBackButtonClick
                : onBackArrowButtonClick
            }
          />
          <h4 className="pt-2 pl-1 d-inline">{headerTitle}</h4>
        </Col>
      </Row>

      <Row className="mt-4 mb-4 pb-4 mb-sm-0 rounded-corner bg-white custom-row">
        {!isAddCustomerCategoryPage && !isAddCustomerPackagePage && (
          <FilesHeaderButton
            onTabChange={onTabChange}
            selectedTabId={selectedTabId}
            headerButtons={headerButtons}
            onToggleDropDown={onToggleDropDown}
            showDropDown={showDropDown}
          />
        )}

        {infoView && <CustomerInfoContainer />}

        {categoryView && (
          <CustomerCategoryContainer
            newCustomerId={newCustomerId}
            gotoCustomerPackageAddPage={gotoCustomerPackageAddPage}
            isAddCustomerPage={isAddCustomerCategoryPage}
          />
        )}

        {packageView && (
          <CustomerPackageContainer
            newCustomerId={newCustomerId}
            gotoCustomerListViewPage={gotoCustomerListViewPage}
            isAddCustomerPage={isAddCustomerPackagePage}
          />
        )}
      </Row>
    </Container>
  );
}

ViewCustomerContainer.propTypes = {
  isAddCustomerCategoryPage: PropTypes.bool,
  isAddCustomerPackagePage: PropTypes.bool,
  newCustomerId: PropTypes.number,
  onBackButtonClick: PropTypes.func,
  gotoCustomerPackageAddPage: PropTypes.func,
  gotoCustomerListViewPage: PropTypes.func,
};

ViewCustomerContainer.defaultProps = {
  isAddCustomerCategoryPage: false,
  isAddCustomerPackagePage: false,
  newCustomerId: 0,
  onBackButtonClick: () => {},
  gotoCustomerPackageAddPage: () => {},
  gotoCustomerListViewPage: () => {},
};
