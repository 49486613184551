import PropTypes from 'prop-types';
import './info-panel-style.css';

export default function ImageComponent(props) {
  const { imageUrl, header, isCusomerPanel } = props;
  const panelClass = isCusomerPanel ? 'fig-img' : 'admin-img';

  return (
    <div className="pb-5 pt-4">
      {header ? (
        <div className="banner-img">
          <img src={imageUrl} alt="generic" />
        </div>
      ) : (
        <div className={panelClass}>
          <img src={imageUrl} alt="generic" />
        </div>
      )}
    </div>
  );
}

ImageComponent.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  header: PropTypes.bool,
  isCusomerPanel: PropTypes.bool,
};

ImageComponent.defaultProps = {
  header: false,
  isCusomerPanel: true,
};
