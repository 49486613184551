import { Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { consumerProfileProps } from '../profile-common-props';

function TokenSubscriptionInfo(props) {
  const { consumerProfileState } = props;

  const consumerTokenTitles = [
    'Token Balance:',
    'Possible Readings:',
    'Auto-Purchase Package:',
    'Auto-Purchase Token Threshold:',
  ];

  const consumerTokenValues = [
    consumerProfileState.tokenBalance,
    Math.floor(consumerProfileState.tokenBalance / 7) || 0,
    consumerProfileState.autoRenewPackage ? consumerProfileState.autoRenewPackage : 'none',
    consumerProfileState.thresholdToken,
  ];

  return (
    <>
      <Row className="pb-3">
        <Col>
          <h4 className="pt-5 pb-2">Tokens & Subscriptions</h4>
        </Col>
      </Row>

      {consumerTokenTitles.map((title, index) => (
        <Row className="pb-2" key={title}>
          <Col xs={6} md={5} lg={4}>
            <p className="text-dark font-weight-bold">{title}</p>
          </Col>
          <Col xs={6} md={7} lg={8} className="d-flex justify-content-end justify-content-sm-start">
            <OverlayTrigger
              transition={false}
              placement="bottom"
              overlay={<Tooltip>{consumerTokenValues[index]}</Tooltip>}
            >
              <span className="flex-child text-info text-truncate">
                {consumerTokenValues[index]}
              </span>
            </OverlayTrigger>
          </Col>
        </Row>
      ))}

      <Row className="pb-2">
        <Col xs={8} md={5} lg={4}>
          <p className="text-dark font-weight-bold">Sunbursts Subscriptions:</p>
        </Col>

        {consumerProfileState.subscriptions && consumerProfileState.subscriptions.length === 0 && (
          <Col xs={4} md={7} lg={8} className="d-flex justify-content-end justify-content-sm-start">
            No active subscriptions.
          </Col>
        )}
      </Row>

      {consumerProfileState.subscriptions && consumerProfileState.subscriptions.length > 0 && (
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th />
              <th>Category</th>
              <th>Delivery Medium</th>
            </tr>
          </thead>
          <tbody>
            {consumerProfileState.subscriptions &&
              consumerProfileState.subscriptions.map((item, index) => (
                <tr key={item.categoryName}>
                  <td>{index + 1}</td>
                  <td>{item.categoryName}</td>
                  <td>{item.deliveryMediums.join(' , ')}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

TokenSubscriptionInfo.propTypes = {
  consumerProfileState: consumerProfileProps.isRequired,
};

TokenSubscriptionInfo.defaultProps = {};

const mapStateToProps = (state) => ({
  consumerProfileState: state.profile.consumerProfile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TokenSubscriptionInfo);
