import momentTimeZone from 'moment-timezone';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import commonMethods from '../../../../helper/common-methods';
import { TABLE_DATE_TIME_FORMAT } from '../../../../helper/constant-values';
import { singleTokenTransactionProps } from '../token-transaction-container-common-props';

export default function TokenTransactionListInfo(props) {
  const { tokenTransactionItem } = props;

  const getDateForSingleAudio = (date) =>
    commonMethods.getDateTimeFromUtc(momentTimeZone.tz.guess(), TABLE_DATE_TIME_FORMAT, date);

  const overlay = (label, value) => (
    <OverlayTrigger transition={false} placement="bottom" overlay={<Tooltip>{value}</Tooltip>}>
      <span>
        <span className="font-weight-bold d-lg-none">{label}:</span> {value}
      </span>
    </OverlayTrigger>
  );

  return (
    <>
      <Row className="mx-sm-2 py-2 mb-4 mb-lg-0 bg-white rounded-corner">
        <Col>
          <Row>
            <Col md={6} lg={2} className="text-truncate">
              {overlay('Date', getDateForSingleAudio(tokenTransactionItem.createdAt))}
            </Col>
            <Col md={6} lg={2} className="text-truncate">
              {overlay('Username', tokenTransactionItem.username)}
            </Col>
            <Col md={12} lg={4} className="text-truncate">
              {overlay(
                'Description',
                commonMethods.replacePaymnetMethodsWithCasing(tokenTransactionItem.description)
              )}
            </Col>
            <Col md={6} lg={1} className="text-truncate d-lg-flex justify-content-end">
              {overlay('Token Debit', tokenTransactionItem.tokenDebit)}
            </Col>
            <Col md={6} lg={1} className="text-truncate d-lg-flex justify-content-end">
              {overlay('Token Credit', tokenTransactionItem.tokenCredit)}
            </Col>
            <Col md={6} lg={2} className="text-truncate d-lg-flex justify-content-end">
              {overlay('Token Balance', tokenTransactionItem.newTokenBalance)}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

TokenTransactionListInfo.propTypes = {
  tokenTransactionItem: singleTokenTransactionProps.isRequired,
};

TokenTransactionListInfo.defaultProps = {};
