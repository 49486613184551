import {
  PayPalButtons,
  // eslint-disable-next-line prettier/prettier
  usePayPalScriptReducer
} from '@paypal/react-paypal-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { RingLoader } from 'react-spinners';
import colors from '../../../../../../helper/colors';
import {
  packageCheckoutOrderByPaypal,
  // eslint-disable-next-line prettier/prettier
  packageCreateOrderByPaypal
} from '../../../../../../redux/actions/package';
import { userInformationProps } from '../../../../../common/common-props';

function PaypalButton(props) {
  const {
    packageId,
    goToPackagePage,
    paymentProcessing,
    createOrder,
    checkoutOrder,
    userInfoState,
    fundingSource,
    checkedSavePaymentInformation,
  } = props;

  const { id: consumerId } = userInfoState;

  const { customerUrl } = useParams();

  const [{ isPending }] = usePayPalScriptReducer();

  const onCreateOrder = () => {
    const paymentData = {
      packageId,
      saveCardAsDefault: checkedSavePaymentInformation,
    };

    const query = {
      customerUrl,
      consumerId,
    };

    return createOrder(paymentData, query)
      .then((data) => data.id)
      .catch(() => {});
  };

  const onApproveOrder = (data) => {
    const paymentData = {
      packageId,
      saveCardAsDefault: checkedSavePaymentInformation,
      orderId: data.orderID,
      paymentSource: data.paymentSource,
    };

    const query = {
      customerUrl,
      consumerId,
    };

    return checkoutOrder(paymentData, query)
      .then(() => {
        goToPackagePage();
      })
      .catch(() => {});
  };

  return (
    <>
      {isPending && (
        <div className="d-flex justify-content-center">
          <RingLoader loading size={100} color={colors.secondary} />
        </div>
      )}

      {!isPending && (
        <PayPalButtons
          style={{ layout: 'vertical' }}
          disabled={paymentProcessing}
          forceReRender={[checkedSavePaymentInformation]}
          fundingSource={fundingSource}
          createOrder={onCreateOrder}
          onApprove={onApproveOrder}
        />
      )}
    </>
  );
}

PaypalButton.propTypes = {
  packageId: PropTypes.number.isRequired,
  goToPackagePage: PropTypes.func.isRequired,
  paymentProcessing: PropTypes.bool.isRequired,
  fundingSource: PropTypes.string,
  userInfoState: userInformationProps.isRequired,
  createOrder: PropTypes.func.isRequired,
  checkoutOrder: PropTypes.func.isRequired,
  checkedSavePaymentInformation: PropTypes.bool.isRequired,
};

PaypalButton.defaultProps = {
  fundingSource: 'paypal',
};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  createOrder: packageCreateOrderByPaypal,
  checkoutOrder: packageCheckoutOrderByPaypal,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaypalButton);
