import { Col, Container, Row } from 'react-bootstrap';
import { packageListProps } from './consumer-package-common-props';
import SinglePackage from './single-package';

export default function PackageList({ packages }) {
  return (
    <Container className="container-uxl">
      <Row>
        <Col xs={12} sm={12} lg={12} className="pl-0">
          <h4 className="py-2">Select a Token Package</h4>
          <p>Tokens allow you to buy Readings and receive Daily Sunbursts.</p>
          <p>
            To receive Sunbursts continuously turn on Auto-Purchase for one of the packages.{' '}
            <a href="/freewillastrology/faq">See the FAQ for token purchases</a> for more
            information.
          </p>
        </Col>
      </Row>
      <Row className="rounded-lg bg-white px-lg-4 pt-sm-4 pb-4 pb-sm-5">
        {packages.map((item) => (
          <Col md={6} className="pt-4" key={item.id}>
            <SinglePackage singlePackage={item} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

PackageList.propTypes = {
  packages: packageListProps.isRequired,
};

PackageList.defaultProps = {};
