import PropTypes from 'prop-types';

export const consumerDetailsProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  tempEmail: PropTypes.string,
  contactNumber: PropTypes.string.isRequired,
  smsPhoneNumber: PropTypes.string.isRequired,
  stripeId: PropTypes.string.isRequired,
  profilePic: PropTypes.string.isRequired,
  unverifiedEmail: PropTypes.string.isRequired,
  unverifiedSmsPhoneNumber: PropTypes.string.isRequired,
  tokenBalance: PropTypes.number.isRequired,
  defaultCategoryName: PropTypes.string.isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string,
      deliveryMediums: PropTypes.arrayOf(PropTypes.string),
    })
  ),
});

export const singleConsumerProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  tempEmail: PropTypes.string,
  consumerDetails: consumerDetailsProps,
});

export const consumerListProps = PropTypes.shape({
  totalItems: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(singleConsumerProps.isRequired).isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
});
