import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { showNotification } from '../../../../../helper/notifications';
import DateInput from './date-input';

const CustomMenu = React.forwardRef((props, ref) => {
  const {
    className,
    'aria-labelledby': labeledBy,
    onToggleDropDown,
    handelFilterdata,
    startDate,
    endDate,
  } = props;

  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const handleApplyFilter = (event) => {
    event.preventDefault();

    onToggleDropDown();
    handelFilterdata(selectedStartDate, selectedEndDate);
  };

  const onStartDateChange = (date) => {
    if (!moment(date).isAfter(selectedEndDate)) {
      setSelectedStartDate(date);
    } else {
      showNotification({
        type: 'error',
        text: 'Start date can not be after end date',
      });
    }
  };

  const onEndDateChange = (date) => {
    if (!moment(date).isBefore(selectedStartDate)) {
      setSelectedEndDate(date);
    } else {
      showNotification({
        type: 'error',
        text: 'End date can not be before start date',
      });
    }
  };

  useEffect(() => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  }, [startDate, endDate]);

  return (
    <div ref={ref} className={className} aria-labelledby={labeledBy}>
      <Container>
        <Form onSubmit={handleApplyFilter}>
          <Row className="mb-3 py-3 border-bottom">
            <Col xs={8} className="d-flex justify-content-start">
              <span className="h5">Filter by date</span>
            </Col>
            <Col className="d-flex justify-content-end">
              <AiOutlineCloseCircle
                size="1.3em"
                onClick={onToggleDropDown}
                className="cursor-pointer"
              />
            </Col>
          </Row>
          <Row className="px-0">
            <Col sm={6} className="pr-sm-1 pb-3 pb-sm-0">
              <DateInput selectedDate={selectedStartDate} onDateChage={onStartDateChange} />
            </Col>
            <Col sm={6} className="pl-sm-1">
              <DateInput selectedDate={selectedEndDate} onDateChage={onEndDateChange} />
            </Col>
          </Row>
          <Row className="justify-content-end border-top  mt-3 py-2">
            <Col xs={5} sm={4}>
              <Button variant="outline-secondary" size="sm" onClick={onToggleDropDown} block>
                Cancel
              </Button>
            </Col>
            <Col xs={7} sm={5}>
              <Button type="submit" variant="secondary" size="sm" block className="filter-button">
                Apply Filter
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
});

CustomMenu.propTypes = {
  className: PropTypes.string.isRequired,
  'aria-labelledby': PropTypes.string.isRequired,
  onToggleDropDown: PropTypes.func.isRequired,
  handelFilterdata: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
};

CustomMenu.defaultProps = {};

export default CustomMenu;
