import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { supportEmailMessageValidationSchema } from '../../../helper/schema-objects';
import { createSupportTicket } from '../../../redux/actions/support-ticket-action';
import { userInformationProps } from '../../common/common-props';
import FormComponent from '../../common/form-component/form-component';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import WarningModal from '../../common/modals/warning-success-modal';

function EmailMessage(props) {
  const { userInfoState, createSupportTicketFunc } = props;
  const { id: consumerId } = userInfoState;

  const { customerUrl } = useParams();
  const history = useHistory();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [isEmailSubmited, setIsEmailSubmitted] = useState(false);

  const initialValues = {
    description: '',
    consumerEmail: userInfoState.email,
  };

  const query = { customerUrl, consumerId };

  const goToDashBoard = () => {
    history.push(`/${customerUrl}/dashboard`);
  };

  const closeSuccessModal = () => {
    setIsEmailSubmitted(false);
  };

  const onSubmitForm = (values, { resetForm }) => {
    const data = { description: values.description, email: values.consumerEmail };

    setDisableSubmitButton(true);

    createSupportTicketFunc(data, query)
      .then(() => {
        setIsEmailSubmitted(true);
        setDisableSubmitButton(false);
        resetForm();
      })
      .catch(() => {
        setDisableSubmitButton(false);
        resetForm();
      });
  };

  return (
    <>
      <Container className="container-uxl">
        <Row>
          <Col xs={12} sm={12} lg={12} className="pl-0">
            <p>
              Please review our <a href="/freewillastrology/faq">Frequently Asked Questions</a>{' '}
              before sending us a message.
            </p>
            <p>
              If you need assistance, please describe your problem as clearly as you can, and make
              sure to provide enough information so that we are able to assist you.
            </p>
          </Col>
        </Row>
        <Row className="bg-white rounded-lg">
          <Formik
            initialValues={initialValues}
            validationSchema={supportEmailMessageValidationSchema}
            onSubmit={(values, { resetForm }) => onSubmitForm(values, { resetForm })}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit} className="w-100">
                <div className="px-sm-4 px-3 pt-2">
                  <Row>
                    <FormComponent
                      type="textarea"
                      placeholder="Message"
                      name="description"
                      inputColumnClass="col-12"
                      errorColumnClass="d-none"
                      inputClass="bg-light"
                      formik={formik}
                      autoFocusInput
                      showLabel
                    />
                    <FormComponent
                      type="textarea"
                      name="description"
                      inputColumnClass="d-none"
                      errorColumnClass="col-12"
                      formik={formik}
                    />
                  </Row>
                  <Row>
                    <FormComponent
                      type="text"
                      placeholder="Enter your Email Address"
                      name="consumerEmail"
                      inputColumnClass="col-12"
                      errorColumnClass="d-none"
                      inputClass="bg-light"
                      formik={formik}
                      showLabel
                    />
                    <FormComponent
                      type="text"
                      name="consumerEmail"
                      inputColumnClass="d-none"
                      errorColumnClass="col-12"
                      formik={formik}
                    />
                  </Row>
                  <Row>
                    <Col className="pb-4 d-none d-sm-flex justify-content-end">
                      <Button
                        size="lg"
                        className="px-5"
                        variant="primary"
                        onClick={formik.handleSubmit}
                        type="submit"
                        disabled={
                          !!(
                            formik.errors.description ||
                            formik.errors.consumerEmail ||
                            disableSubmitButton
                          )
                        }
                      >
                        <DataSavingSpinner
                          savingText="Submitting"
                          defaultText="Submit"
                          dataSaving={disableSubmitButton}
                        />
                      </Button>
                    </Col>

                    <Col className="pb-4 d-block d-sm-none">
                      <Button
                        size="lg"
                        block
                        className="w-sm-25"
                        variant="primary"
                        onClick={formik.handleSubmit}
                        type="submit"
                        disabled={
                          !!(
                            formik.errors.description ||
                            formik.errors.consumerEmail ||
                            disableSubmitButton
                          )
                        }
                      >
                        <DataSavingSpinner
                          savingText="Submitting"
                          defaultText="Submit"
                          dataSaving={disableSubmitButton}
                        />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </Row>
      </Container>

      <WarningModal
        showModal={isEmailSubmited}
        isSuccessModal
        heading=""
        description="Your message has been sent to Free Will Astrology Customer Support."
        leftBtnText="Back"
        rightBtnText="Return to Website"
        onModalSubmit={goToDashBoard}
        onHideModal={closeSuccessModal}
        topHeaderText="Message Submitted"
      />
    </>
  );
}

EmailMessage.propTypes = {
  userInfoState: userInformationProps.isRequired,
  createSupportTicketFunc: PropTypes.func.isRequired,
};

EmailMessage.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  createSupportTicketFunc: createSupportTicket,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailMessage);
