import PropTypes from 'prop-types';

export const singleAudioFileProps = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  audioName: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  audioFileName: PropTypes.string.isRequired,
  startDateTime: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string])
    .isRequired,
  endDateTime: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string])
    .isRequired,
});

export const audioFileListProps = PropTypes.arrayOf(singleAudioFileProps.isRequired);

export const audioFilesProps = PropTypes.shape({
  totalItems: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(singleAudioFileProps.isRequired).isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
});

export const singleUploadedAudioFileProps = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  audioName: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  startDateTime: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string])
    .isRequired,
  endDateTime: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string])
    .isRequired,
  audioFileName: PropTypes.string.isRequired,
  uploadPercentage: PropTypes.number.isRequired,
  copyAudio: PropTypes.bool.isRequired,
  audioUploadProcessing: PropTypes.bool.isRequired,
});

export const uploadedFilesProps = PropTypes.shape({
  totalItems: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(singleUploadedAudioFileProps.isRequired).isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
});

export const singleShortTextFileProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  messageName: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  disburseDateTime: PropTypes.string.isRequired,
});

export const shortTextFilesProps = PropTypes.shape({
  totalItems: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(singleShortTextFileProps.isRequired).isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
});
