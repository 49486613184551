import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import LoadingPage from '../components/common/loader-spinner/loader-spinner';
import PageNotFoundPage from '../components/common/page-not-found';
import { userTokenVerification } from '../redux/actions/auth-action';
import toggleMobileView from '../redux/actions/browser-action';
import browserStorage from './browser-storage';
import {
  DESKTOP_VIEW_PORT_WIDTH,
  LAPTOP_VIEW_PORT_WIDTH,
  MOBILE_VIEW_PORT_WIDTH,
  TAB_VIEW_PORT_WIDTH,
  // eslint-disable-next-line prettier/prettier
  USER_DATA
} from './constant-values';
import { showNotification } from './notifications';

function PrivateRoutes(props) {
  const { Component, userType, verifyToken, updateBrowserView } = props;

  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [showPageNotFound, setShowPageNotFound] = useState(false);
  const [loginPageUrl, setLoginPageUrl] = useState(null);

  const { customerUrl } = useParams();

  const updateLoginUrl = () => {
    const userData = browserStorage.getUserData(USER_DATA);

    if (userData && userData.customerUrl) {
      setLoginPageUrl(`/${userData.customerUrl}/login`);
    } else if (customerUrl) {
      setLoginPageUrl(`/${customerUrl}/login`);
    } else {
      setLoginPageUrl('/');
    }
  };

  const showToastError = (message) => {
    const text = message || 'Access denied.';
    showNotification({
      type: 'error',
      text,
    });
  };

  useEffect(() => {
    verifyToken()
      .then(({ type, customerUrl: currentCustomerUrl }) => {
        if (currentCustomerUrl && currentCustomerUrl !== customerUrl) {
          setShowPageNotFound(true);
          setLoading(false);
        } else if (type === userType) {
          setLoading(false);
          setShowPageNotFound(false);
        } else {
          updateLoginUrl();
          setLoading(false);
          setShowPageNotFound(false);
          setRedirect(true);
          showToastError();
        }
      })
      .catch((error) => {
        const { message } = error.response?.data || { message: 'Internal server error' };
        showToastError(message);
        updateLoginUrl();
        setLoading(false);
        setRedirect(true);
      });
  }, [Component]);

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const updateWindowDimensions = () => {
      const width = window.innerWidth;
      const viewWidthData = {
        isMobile: false,
        isTab: false,
        isLaptop: false,
        isDesktop: false,
        isExtraLargeScreen: false,
      };

      if (width < MOBILE_VIEW_PORT_WIDTH) {
        viewWidthData.isMobile = true;
      } else if (width < TAB_VIEW_PORT_WIDTH) {
        viewWidthData.isTab = true;
      } else if (width < LAPTOP_VIEW_PORT_WIDTH) {
        viewWidthData.isLaptop = true;
      } else if (width < DESKTOP_VIEW_PORT_WIDTH) {
        viewWidthData.isDesktop = true;
      } else if (width >= DESKTOP_VIEW_PORT_WIDTH) {
        viewWidthData.isExtraLargeScreen = true;
      }

      updateBrowserView(viewWidthData);

      setTimeout(() => {
        const viewHeight = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${viewHeight}px`);
      }, [500]);
    };

    updateWindowDimensions();

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <>
      {loading && !showPageNotFound && <LoadingPage />}
      {redirect && loginPageUrl && !showPageNotFound && <Redirect to={loginPageUrl} />}
      {showPageNotFound && <PageNotFoundPage />}
      {!loading && !redirect && !showPageNotFound && <Component />}
    </>
  );
}

PrivateRoutes.propTypes = {
  Component: PropTypes.elementType.isRequired,
  userType: PropTypes.string.isRequired,
  verifyToken: PropTypes.func.isRequired,
  updateBrowserView: PropTypes.func.isRequired,
};

PrivateRoutes.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  verifyToken: userTokenVerification,
  updateBrowserView: toggleMobileView,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoutes);
