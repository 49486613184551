import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import commonMethods from '../../../../helper/common-methods';
import { AddIcon } from '../../../../helper/icons/common-icons';
import routes from '../../../../helper/route-details';
import { getCustomerListForAdmin } from '../../../../redux/actions/customer-actions';
import { browserViewProps } from '../../../common/common-props';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import CustomPagination from '../../../common/pagination/custom-pagination';
import { customerListProps } from '../customer-common-props';
import CustomerListInfo from './customer-info';
import CustomerListTitle from './customer-list-title';

function CustomerListContainer(props) {
  const { customerList, browserState, getCustomerList } = props;

  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [dataLoading, setDataLoading] = useState(false);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);

  const history = useHistory();

  const onAddCustomer = () => {
    history.push(routes.admin.customers.add());
  };

  const getPaginationQuery = ({
    pageChanged = false,
    paramsData = {},
    customMethod = null,
    queryData = {},
    payload = null,
  }) => {
    const params = { ...paramsData };
    const query = {
      page,
      itemPerPage,
      ...queryData,
    };

    return {
      isMobileView: browserState.isMobile,
      params,
      query,
      page,
      payload,
      itemPerPage,
      getMethod: customMethod || getCustomerList,
      pageChanged,
      data: customerList.data,
    };
  };

  const onLoadMoreClick = () => {
    setDataLoading(true);
    if (customerList.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery({ pageChanged: true }));
  }, [page]);

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery({}));
  }, [itemPerPage, mobileLastPageLoaded]);

  useEffect(() => {
    if (!browserState.isMobile) {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    }
  }, [browserState.isMobile]);

  useEffect(() => {
    setDataLoading(false);
  }, [customerList]);

  return (
    <>
      <Container className="container-uxl pb-3 px-1">
        <Row>
          <Col xs={6} className="mb-2">
            <h4 className="pt-2 pl-1">All Customers</h4>
          </Col>

          <Col xs={6} className="mb-2">
            <Button className="float-right" variant="outline-secondary" onClick={onAddCustomer}>
              <AddIcon width="1.2rem" height="1.2rem" className="mr-1" />
              Add Customer
            </Button>
          </Col>
        </Row>
      </Container>

      <Container className="container-uxl customer-list-for-admin px-sm-0">
        <CustomerListTitle />

        {customerList.data.map((item) => (
          <CustomerListInfo
            key={item.id}
            customerItem={item}
          />
        ))}

        {customerList.totalItems > 10 && (
          <Col className="px-0 d-none d-md-block border-top bg-white">
            <CustomPagination
              itemPerPage={itemPerPage}
              page={page}
              setPage={setPage}
              setItemPerPage={setItemPerPage}
              totalPages={customerList.totalPages}
              totalItems={customerList.totalItems}
            />
          </Col>
        )}

        {customerList.totalItems > customerList.data.length && (
          <Col
            sm={11}
            className="bg-light justify-content-center justify-content-lg-start pt-4 d-sm-none"
          >
            <Button
              size="lg"
              variant="secondary"
              disabled={dataLoading}
              onClick={onLoadMoreClick}
              block
            >
              <DataSavingSpinner
                savingText="Loding"
                defaultText="Load More"
                dataSaving={dataLoading}
              />
            </Button>
          </Col>
        )}
      </Container>
    </>
  );
}

CustomerListContainer.propTypes = {
  customerList: customerListProps.isRequired,
  browserState: browserViewProps.isRequired,
  getCustomerList: PropTypes.func.isRequired
};

CustomerListContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  customerList: state.customers.customerListForAdmin,
  browserState: state.browserData.view,
});

const mapDispatchToProps = {
  getCustomerList: getCustomerListForAdmin
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListContainer);
