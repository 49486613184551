import PropTypes from 'prop-types';
import { Button, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DeleteIcon, EditIcon } from '../../../../../helper/icons/common-icons';

export default function ActionButtons(props) {
  const { onHandleEdit, onHandleDelete } = props;

  return (
    <>
      <Col xs={4} className="px-0 pb-1">
        <OverlayTrigger
          transition={false}
          rootClose
          placement="bottom-start"
          overlay={<Tooltip>Edit</Tooltip>}
        >
          <Button
            tabIndex={0}
            className="border rounded-circle mr-2 py-2 float-right"
            variant="outline-secondary"
            size="sm"
            onClick={onHandleEdit}
          >
            <EditIcon width="1.2rem" height="1.2rem" />
          </Button>
        </OverlayTrigger>
      </Col>

      <Col xs={4} className="px-0 pb-1">
        <OverlayTrigger
          transition={false}
          rootClose
          placement="bottom-start"
          overlay={<Tooltip>Delete</Tooltip>}
        >
          <Button
            tabIndex={-1}
            className="border rounded-circle mr-2 py-2 float-right"
            variant="outline-secondary"
            size="sm"
            onClick={onHandleDelete}
          >
            <DeleteIcon width="1.2rem" height="1.2rem" />
          </Button>
        </OverlayTrigger>
      </Col>
    </>
  );
}

ActionButtons.propTypes = {
  onHandleEdit: PropTypes.func,
  onHandleDelete: PropTypes.func,
};

ActionButtons.defaultProps = {
  onHandleEdit: () => {},
  onHandleDelete: () => {},
};
