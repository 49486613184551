import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import CopyrightAndPrivacyFooter from './copyright-and-privacy-footer';
import './landing-page-footer-style.css';
import LogoAndSocialFooter from './logo-and-social-footer';

export default function LandingPageFooter(props) {
  const { logoUrl } = props;
  return (
    <Container className="footer pt-4">
      <LogoAndSocialFooter logoUrl={logoUrl} />

      <CopyrightAndPrivacyFooter />
    </Container>
  );
}

LandingPageFooter.propTypes = {
  logoUrl: PropTypes.string,
};

LandingPageFooter.defaultProps = {
  logoUrl: 'logo',
};
