import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import routes from '../../../helper/route-details';
import { getConsumerCategory } from '../../../redux/actions/consumer-category-action';
import { subscriptionListProps } from '../../common/common-props';
import MenuBarWrapper from '../../common/menu-bar-wrapper/menu-bar-wrapper';
import CategoryList from './category-list';
import './category-style.css';
import ServiceContainer from './service-container';

function CategoryAndServiceContainer(props) {
  const { subscriptionCategory, getCategory } = props;

  const [isCategoryListView, setIsCategoryListView] = useState(true);
  const [isServiceView, setIsServiceView] = useState(false);
  const [isShortTextPage, setIsShortTextPage] = useState(false);
  const [isShortTextUploadView, setIsShortTextUploadView] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [selectedCategoryName, setSelectedCategoryName] = useState('');

  const { customerUrl, categoryId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const gotoCategoryListViewPage = () => {
    setIsCategoryListView(true);
    setIsServiceView(false);
    setIsShortTextUploadView(false);
  };

  const gotoServiceViewPage = () => {
    setIsServiceView(true);
    setIsCategoryListView(false);
    setIsShortTextUploadView(false);
  };

  const gotoShortTextUploadPage = () => {
    setIsShortTextUploadView(true);
    setIsServiceView(true);
    setIsCategoryListView(false);
  };

  const categoryCardOnClick = (id) => {
    history.push(routes.customer.category.selectedCategory(customerUrl, id));
  };

  const backButtonClick = () => {
    history.push(routes.customer.category.default(customerUrl));
  };

  const updateSelectedCategoryName = (data = subscriptionCategory) => {
    const categoryItem = data.find((item) => item.id === selectedCategoryId);

    if (categoryItem) {
      setSelectedCategoryName(categoryItem.name);
    }
  };

  useEffect(() => {
    if (location.pathname === routes.customer.category.default(customerUrl)) {
      gotoCategoryListViewPage();
    } else if (
      categoryId !== undefined &&
      location.pathname === routes.customer.category.selectedCategory(customerUrl, categoryId)
    ) {
      gotoServiceViewPage();
      setSelectedCategoryId(+categoryId);

      if (location.state && location.state.viewShortTextPage) {
        setIsShortTextPage(true);
      } else {
        setIsShortTextPage(false);
      }
    } else if (
      categoryId !== undefined &&
      location.pathname === routes.customer.category.shortText.upload(customerUrl, categoryId)
    ) {
      gotoShortTextUploadPage();
    } else {
      gotoCategoryListViewPage();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (subscriptionCategory.length === 0) {
      getCategory({ customerUrl }).then((data) => {
        updateSelectedCategoryName(data);
      });
    } else {
      updateSelectedCategoryName();
    }
  }, [selectedCategoryId]);

  return (
    <MenuBarWrapper navBarIndex={1} headerTitle="Categories & Services">
      <>
        {isCategoryListView && (
          <CategoryList categoriesList={subscriptionCategory} cardOnClick={categoryCardOnClick} />
        )}

        {isServiceView && (
          <ServiceContainer
            categoryName={selectedCategoryName}
            isShortTextPage={isShortTextPage}
            onHandleBack={backButtonClick}
            isShortTextUploadView={isShortTextUploadView}
          />
        )}
      </>
    </MenuBarWrapper>
  );
}

CategoryAndServiceContainer.propTypes = {
  subscriptionCategory: subscriptionListProps.isRequired,
  getCategory: PropTypes.func.isRequired,
};

CategoryAndServiceContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  subscriptionCategory: state.consumerCategory.category,
});

const mapDispatchToProps = {
  getCategory: getConsumerCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryAndServiceContainer);
