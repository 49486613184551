import { Formik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { loginValidationSchema } from '../../../helper/schema-objects';
import { customerConsumerLogin } from '../../../redux/actions/auth-action';
import FormComponent from '../../common/form-component/form-component';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import './customer-consumer-login.css';

function CustomerConsumerLogin(props) {
  const {
    goToforgetPassword,
    login,
    onUnverifiedEmail,
    goToRegistrationPage,
    backFromVerification,
    loginPreviousState,
  } = props;

  const [showPassword, setShowPassword] = useState('password');
  const [rememberMe, setRememberMe] = useState(false);
  const [loginContinue, setLoginContinue] = useState(false);

  const { customerUrl } = useParams();
  const history = useHistory();

  const query = {
    customerUrl,
  };

  let initialValues = {
    emailOrUsername: '',
    password: '',
  };

  if (backFromVerification) {
    initialValues = {
      ...initialValues,
      ...loginPreviousState,
    };
  }

  const consumerData = {};

  const showHidePassword = () => {
    if (showPassword === 'password') {
      setShowPassword('text');
    } else {
      setShowPassword('password');
    }
  };

  const onSubmit = (values, { setErrors }) => {
    consumerData.usernameOrEmail = values.emailOrUsername.trim();
    consumerData.password = values.password;
    consumerData.rememberMe = rememberMe;

    setLoginContinue(true);

    const loginCurrentValues = _.omit(values, ['password']);

    login(consumerData, query, loginCurrentValues)
      .then(({ isCustomer }) => {
        setLoginContinue(false);

        if (isCustomer) {
          history.push(`/${customerUrl}/customer/revenue-report`);
        } else {
          history.push(`/${customerUrl}/dashboard`);
        }
      })
      .catch((error) => {
        setLoginContinue(false);

        if (!error.response) {
          return;
        }

        const { needEmailVerification, invalidUserNameOrPassword, username, tempEmail, message } =
          error.response.data;

        if (needEmailVerification) {
          onUnverifiedEmail({ username, email: tempEmail });
        } else if (invalidUserNameOrPassword) {
          setErrors({
            password: message,
            emailOrUsername: ' ',
          });
        }
      });
  };

  return (
    <>
      <div className="px-2 pl-lg-0">
        <Formik
          initialValues={initialValues}
          validationSchema={loginValidationSchema}
          onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Row className="justify-content-center justify-content-lg-start">
                <FormComponent
                  type="text"
                  placeholder="Email or Username"
                  name="emailOrUsername"
                  formik={formik}
                  autoFocusInput
                  showLabel
                />
              </Row>

              <Row className="justify-content-center justify-content-lg-start mt-2">
                <Col sm={11} lg={6}>
                  <Form.Label>Password</Form.Label>
                </Col>
              </Row>

              <Row className="justify-content-center justify-content-lg-start">
                <FormComponent
                  isPassword
                  type={showPassword}
                  placeholder="Password"
                  name="password"
                  fontAwesomeIconSize="lg"
                  iconToolTip="Show/hide password"
                  onIconClick={showHidePassword}
                  formik={formik}
                />
              </Row>
              <Row className="justify-content-center justify-content-lg-start mt-2">
                <Col sm={11} lg={6}>
                  <Row>
                    <Col xs={6} className="pr-0 pr-md-2">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="&ensp;Remember Me"
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={6} className="pl-0 pl-md-2">
                      <div className="text-secondary font-weight-bold d-flex justify-content-end">
                        <div
                          className="cursor-pointer"
                          onClick={goToforgetPassword}
                          role="presentation"
                        >
                          Forgot Password?
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center justify-content-lg-start mt-3 pt-lg-3">
                <Col sm={11} lg={6}>
                  <Button
                    size="lg"
                    variant="secondary"
                    block
                    onClick={formik.handleSubmit}
                    type="submit"
                    disabled={
                      !!(formik.errors.emailOrUsername || formik.errors.password || loginContinue)
                    }
                  >
                    <DataSavingSpinner defaultText="Login" dataSaving={loginContinue} />
                  </Button>
                </Col>
              </Row>

              <Row className="pt-3">
                <Col md={12} className="d-flex justify-content-center justify-content-lg-start">
                  Don&apos;t have an account yet?&nbsp;
                  <span
                    aria-hidden="true"
                    className="font-weight-bold text-secondary cursor-pointer"
                    onClick={goToRegistrationPage}
                  >
                    Register
                  </span>
                </Col>
              </Row>
              <Row className="pt-2">
                <Col md={12} className="d-flex justify-content-center justify-content-lg-start">
                  Questions?&nbsp;
                  <a
                    aria-hidden="true"
                    className="font-weight-bold text-secondary cursor-pointer"
                    href="mailto:freewillcs@gmail.com?subject=Question from login screen"
                  >
                    Contact Customer Support
                  </a>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

CustomerConsumerLogin.propTypes = {
  login: PropTypes.func.isRequired,
  goToforgetPassword: PropTypes.func.isRequired,
  onUnverifiedEmail: PropTypes.func.isRequired,
  goToRegistrationPage: PropTypes.func.isRequired,
  backFromVerification: PropTypes.bool,
  loginPreviousState: PropTypes.shape({
    emailOrUsername: PropTypes.string.isRequired,
  }).isRequired,
};

CustomerConsumerLogin.defaultProps = {
  backFromVerification: false,
};

const mapStateToProps = (state) => ({
  loginPreviousState: state.auth.loginPrevious,
});

const mapDispatchToProps = {
  login: customerConsumerLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerConsumerLogin);
