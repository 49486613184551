import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { newPasswordValidationSchema } from '../../../../helper/schema-objects';
import { changePassword } from '../../../../redux/actions/auth-action';
import FormComponent from '../../../common/form-component/form-component';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import { forgotPasswordProps } from '../../login-registration-common-props';

function AdminNewPassword(props) {
  const { otpVerificationState, userChangePassword, goToLoginPage, forgotPasswordState } = props;

  const [showNewPassword, setShowNewPassword] = useState('password');
  const [showConfirmPassword, setShowConfirmPassword] = useState('password');
  const [continueButtonLoading, setContinueButtonLoading] = useState(false);

  const { customerUrl } = useParams();

  const showHideNewPassword = () => {
    if (showNewPassword === 'text') setShowNewPassword('password');
    else setShowNewPassword('text');
  };

  const showHideConfirmPassword = () => {
    if (showConfirmPassword === 'text') setShowConfirmPassword('password');
    else setShowConfirmPassword('text');
  };

  const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
  };

  const onSubmit = (values, { setErrors }) => {
    const userData = {
      ...values,
      verificationToken: otpVerificationState.passwordToken,
      username: forgotPasswordState.username,
    };

    setContinueButtonLoading(true);

    userChangePassword(userData, { customerUrl })
      .then(() => {
        setContinueButtonLoading(false);
        goToLoginPage();
      })
      .catch((error) => {
        setContinueButtonLoading(false);

        if (!error.response) return;

        const { message, currentAndNewPasswordMatch } = error.response.data;

        if (currentAndNewPasswordMatch) {
          setErrors({
            newPassword: ' ',
            confirmNewPassword: message,
          });
        }
      });
  };

  return (
    <>
      <div className="px-2 pl-lg-0">
        <Row className="justify-content-center pb-4">
          <Col sm={11} md={7} lg={12}>
            <p className="text-center">Password must be 8 characters at least.</p>
          </Col>
        </Row>

        <Formik
          initialValues={initialValues}
          validationSchema={newPasswordValidationSchema}
          onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Row className="justify-content-center mt-2">
                <Col sm={11} lg={7}>
                  <Form.Label>New Password</Form.Label>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <FormComponent
                  isPassword
                  type={showNewPassword}
                  placeholder="New Password"
                  name="newPassword"
                  fontAwesomeIconSize="lg"
                  iconToolTip="Show/hide password"
                  onIconClick={showHideNewPassword}
                  formik={formik}
                  inputColumnClass="col-lg-7 col-sm-11"
                  errorColumnClass="col-lg-7 col-sm-11"
                  autoFocusInput
                />
              </Row>

              <Row className="justify-content-center mt-2">
                <Col sm={11} lg={7}>
                  <Form.Label>Confirm New Password</Form.Label>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <FormComponent
                  isPassword
                  type={showConfirmPassword}
                  placeholder="Confirm New Password"
                  name="confirmNewPassword"
                  fontAwesomeIconSize="lg"
                  iconToolTip="Show/hide password"
                  onIconClick={showHideConfirmPassword}
                  formik={formik}
                  inputColumnClass="col-lg-7 col-sm-11"
                  errorColumnClass="col-lg-7 col-sm-11"
                />
              </Row>
              <Row className="justify-content-center mt-3 pt-lg-3">
                <Col sm={11} lg={7}>
                  <Button
                    size="lg"
                    variant="secondary"
                    block
                    onClick={formik.handleSubmit}
                    type="submit"
                    disabled={
                      !!(
                        formik.errors.newPassword ||
                        formik.errors.confirmNewPassword ||
                        continueButtonLoading
                      )
                    }
                  >
                    <DataSavingSpinner defaultText="Continue" dataSaving={continueButtonLoading} />
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

AdminNewPassword.propTypes = {
  userChangePassword: PropTypes.func.isRequired,
  goToLoginPage: PropTypes.func.isRequired,
  otpVerificationState: PropTypes.shape({
    message: PropTypes.string.isRequired,
    passwordToken: PropTypes.string.isRequired,
  }).isRequired,
  forgotPasswordState: forgotPasswordProps.isRequired,
};

AdminNewPassword.defaultProps = {};

const mapStateToProps = (state) => ({
  otpVerificationState: state.auth.otpVerification,
  forgotPasswordState: state.auth.forgotPassword,
});

const mapDispatchToProps = {
  userChangePassword: changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNewPassword);
