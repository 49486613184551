import PropTypes from 'prop-types';
import { SwitchOffIcon, SwitchOnIcon } from '../../../helper/icons/common-icons';

export default function CustomSwitch(props) {
  const { checked, className, onClick } = props;

  return (
    <>
      {!checked && <SwitchOffIcon className={`text-muted ${className}`} onClick={onClick} />}
      {checked && <SwitchOnIcon className={`text-warning ${className}`} onClick={onClick} />}
    </>
  );
}

CustomSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

CustomSwitch.defaultProps = {};
