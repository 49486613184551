import PropTypes from 'prop-types';

export const singlePackageProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  numberOfTokens: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  autoRenewPrice: PropTypes.number.isRequired,
  isAutoRenewable: PropTypes.bool.isRequired,
});

export const packageListProps = PropTypes.arrayOf(singlePackageProps.isRequired);

export const packageProps = PropTypes.shape({
  packagesForCustomer: PropTypes.shape({
    totalItems: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(singlePackageProps.isRequired).isRequired,
  }),
});

export const browserViewProps = PropTypes.shape({
  isMobile: PropTypes.bool.isRequired,
  isTab: PropTypes.bool.isRequired,
  isLaptop: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isExtraLargeScreen: PropTypes.bool.isRequired,
});
