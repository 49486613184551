import commonMethods from '../../helper/common-methods';
import {
  SHORT_TEXT_COPY_BY_CUSTOMER,
  SHORT_TEXT_DELETE_BY_CUSTOMER,
  SHORT_TEXT_EDIT_BY_CUSTOMER,
  // eslint-disable-next-line prettier/prettier
  SHORT_TEXT_FOR_CUSTOMER
} from '../types';

const initialState = {
  shortTextForCustomer: {
    totalItems: 0,
    data: [],
    totalPages: 1,
    currentPage: 1,
  },
};

const shortTextReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHORT_TEXT_FOR_CUSTOMER: {
      return commonMethods.paginationStateUpdate(state, action, 'shortTextForCustomer');
    }

    case SHORT_TEXT_DELETE_BY_CUSTOMER: {
      return commonMethods.deletePaginationState(state, action, 'shortTextForCustomer');
    }

    case SHORT_TEXT_EDIT_BY_CUSTOMER: {
      return commonMethods.updateOnPaginationState(state, action, 'shortTextForCustomer');
    }

    case SHORT_TEXT_COPY_BY_CUSTOMER: {
      return commonMethods.copyPaginationState(state, action, 'shortTextForCustomer');
    }

    default:
      return state;
  }
};

export default shortTextReducer;
