import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Card, Container, Form, FormCheck } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import commonMethods from '../../../helper/common-methods';
import { toggleAutoPaymentButton } from '../../../redux/actions/package';
import CustomSwitch from '../../common/buttons/custom-switch';
import { userInformationProps } from '../../common/common-props';
import WarningModal from '../../common/modals/warning-success-modal';
import { consumerProfileProps, singlePackageProps } from './consumer-package-common-props';

function SinglePackage(props) {
  const history = useHistory();
  const { customerUrl } = useParams();
  const { singlePackage, isPayment, userInfoState, updateAutoPayment, consumerProfileState } =
    props;
  const { id: packageId } = singlePackage;
  const { id: consumerId } = userInfoState;

  const [isAutoPaymentChecked, setAutoPaymentChecked] = useState(
    singlePackage.consumerAutoRenewablePackages.length > 0
  );
  const [showDefaultPaymentWarning, setShowDefaultPaymentWarning] = useState(false);
  const [showAutoRenewNotification, setShowAutoRenewNotification] = useState(false);

  const onConfirmAutoRenewable = () => {
    setShowAutoRenewNotification(false);
    setAutoPaymentChecked((previousValue) => !previousValue);

    const query = { customerUrl, consumerId };

    updateAutoPayment({ isAutoPaymentChecked: !isAutoPaymentChecked, packageId }, query).catch(
      () => {
        setAutoPaymentChecked((previousValue) => !previousValue);
      }
    );
  };

  const onHandleTogglebtn = () => {
    if (isAutoPaymentChecked) {
      onConfirmAutoRenewable();
    } else if (
      commonMethods.isNullOrEmpty(userInfoState.stripeId) &&
      commonMethods.isNullOrEmpty(userInfoState.paypalPaymentToken)
    ) {
      setShowDefaultPaymentWarning(true);
    } else {
      setShowAutoRenewNotification(true);
    }
  };

  const onHandleBuyNow = () => {
    history.push(`/${customerUrl}/packages/purchase`, {
      packageId: singlePackage.id,
    });
  };

  const goToProfilePage = () => {
    history.push(`/${customerUrl}/profile`);
  };

  useEffect(() => {
    setAutoPaymentChecked(singlePackage.consumerAutoRenewablePackages.length > 0);
  }, [singlePackage.consumerAutoRenewablePackages.length]);

  return (
    <>
      <WarningModal
        heading="Add Default Payment"
        description="Your default payment method is not set. To subscribe a package, you need to set the default payment method."
        leftBtnText="Cancel"
        rightBtnText="Go to My Profile"
        showModal={showDefaultPaymentWarning}
        onHideModal={() => setShowDefaultPaymentWarning(false)}
        onModalSubmit={goToProfilePage}
      />

      <WarningModal
        heading=""
        description={`This Token Package will be purchased automatically when your token balance drops below ${consumerProfileState.thresholdToken} so you can receive Sunbursts continuously.`}
        leftBtnText="Cancel"
        rightBtnText="Proceed"
        showModal={showAutoRenewNotification}
        onHideModal={() => setShowAutoRenewNotification(false)}
        onModalSubmit={onConfirmAutoRenewable}
        showInfoIcon
      />

      <Card className="text-center rounded-10">
        <Card.Header className={isAutoPaymentChecked || isPayment ? 'bg-warning' : ''}>
          <h5>{singlePackage.name}</h5>
        </Card.Header>

        <Card.Body>
          <div className="package-image-wrapper">
            <Card.Img
              variant="top"
              fluid="true"
              alt="image"
              className="mb-n3 package-image-consumer"
              src={commonMethods.getImageApi(singlePackage.imagePath)}
            />
          </div>

          <Container className="card-contant d-flex flex-column justify-content-center">
            {!singlePackage.isAutoRenewable && !isPayment ? (
              <div className="pt-5" />
            ) : (
              <div className="pt-4" />
            )}

            <Card.Text className="pt-2">
              <span className="d-inline h1 package-font">{singlePackage.numberOfTokens}</span>
              <span className="d-inline h5 text-info">
                &nbsp;Token{singlePackage.numberOfTokens === 1 ? '' : 's'}
              </span>
            </Card.Text>

            <Card.Text className="text-dark">
              Single Purchase Price: ${parseFloat(singlePackage.price).toFixed(2)}
            </Card.Text>

            {singlePackage.isAutoRenewable && !isPayment && (
              <Card.Text className="text-dark">
                Auto-Purchase price: ${parseFloat(singlePackage.autoRenewPrice).toFixed(2)}
              </Card.Text>
            )}

            {!singlePackage.isAutoRenewable && (
              <span className="mb-3">
                {' '}
                <br />
              </span>
            )}

            {singlePackage.isAutoRenewable && !isPayment ? (
              <FormCheck className="pb-4">
                <Form.Label className="d-inline pr-2 text-muted">
                  Auto-Purchase
                </Form.Label>
                <CustomSwitch
                  className="d-inline cursor-pointer"
                  onClick={onHandleTogglebtn}
                  checked={isAutoPaymentChecked}
                />
                <span className="pl-2">{isAutoPaymentChecked ? 'ON' : 'OFF'}</span>
              </FormCheck>
            ) : (
              <br />
            )}
          </Container>

          {!isPayment && (
            <Button variant="outline-primary" size="lg" onClick={onHandleBuyNow} block>
              Purchase Package
            </Button>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

SinglePackage.propTypes = {
  singlePackage: singlePackageProps.isRequired,
  updateAutoPayment: PropTypes.func.isRequired,
  isPayment: PropTypes.bool,
  userInfoState: userInformationProps.isRequired,
  consumerProfileState: consumerProfileProps.isRequired,
};

SinglePackage.defaultProps = {
  isPayment: false,
};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
  consumerProfileState: state.profile.consumerProfile,
});

const mapDispatchToProps = {
  updateAutoPayment: toggleAutoPaymentButton,
};

export default connect(mapStateToProps, mapDispatchToProps)(SinglePackage);
