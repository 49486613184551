import { Col, Figure, Row } from 'react-bootstrap';
import defaultProfilePic from '../../../../assets/image/defaultProfilePic.png';
import commomMethods from '../../../../helper/common-methods';
import { consumerDetailsProps } from '../consumer-common-props';
import ProfileInfo from './profile-info';
import './profile.css';

export default function Profile(props) {
  const { consumerDetails } = props;

  return (
    <>
      {consumerDetails && (
        <Row className="mb-4 mb-sm-0 rounded-corner bg-white">
          <Col lg={3} className="d-flex justify-content-center pt-lg-3">
            <Figure>
              <Figure.Image
                width={134}
                height={134}
                src={commomMethods.getImageApi(consumerDetails.profilePic) || defaultProfilePic}
                fluid={false}
                roundedCircle
              />
              <h5 className="text-center pt-4">{consumerDetails.fullName}</h5>
            </Figure>
          </Col>
          <Col lg={1} className="d-none d-lg-block border-left vertical-divider-admin" />
          <Col lg={1} className="d-block d-lg-none border-top ml-4 mr-4 mb-2 mb-sm-4" />
          <Col lg={8} className="mr-2 ml-2 ml-lg-5">
            <Row className="pb-3">
              <Col>
                <h4 className="pt-3 pb-2">Personal Information</h4>
              </Col>
            </Row>
            <ProfileInfo consumerDetails={consumerDetails} />
          </Col>
        </Row>
      )}
    </>
  );
}

Profile.propTypes = {
  consumerDetails: consumerDetailsProps,
};

Profile.defaultProps = {
  consumerDetails: {
    id: 0,
    email: '',
    fullName: '',
    url: '',
    username: '',
    contactNumber: '',
    smsPhoneNumber: '',
    stripeId: '',
    profilePic: '',
    unverifiedEmail: '',
    unverifiedSmsPhoneNumber: '',
    tokenBalance: 0,
    defaultCategoryName: '',
  },
};
