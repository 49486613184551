/* eslint-disable import/prefer-default-export */
import commonMethods from '../../helper/common-methods';
import { showNotification } from '../../helper/notifications';
import consumerCategoryService from '../../services/consumer-category';
import subscriptionService from '../../services/subscription';
import {
  CONSUMER_CATEGORY_ADD_BY_ADMIN,
  CONSUMER_CATEGORY_DELETE_BY_ADMIN,
  CONSUMER_CATEGORY_UPDATE_BY_ADMIN,
  GET_CONSUMER_CATEGORY_LIST_FOR_ADMIN,
  GET_CONSUMER_CATEGORY_WITH_MEDIUM,
  SUBSCRIBE_TO_CATEGORY,
  // eslint-disable-next-line prettier/prettier
  UNSUBSCRIBE_TO_CATEGORY
} from '../types';

export function getConsumerCategory(params, query = {}) {
  return (dispatch) =>
    consumerCategoryService
      .get(params, query)
      .then((response) => {
        dispatch({
          type: GET_CONSUMER_CATEGORY_WITH_MEDIUM,
          data: response.data,
        });

        return response.data;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function getConsumerCategoryListForAdmin(params, query, actionData) {
  return (dispatch) =>
    consumerCategoryService
      .getConsumerCategoryListForAdmin(query)
      .then((response) => {
        dispatch({
          type: GET_CONSUMER_CATEGORY_LIST_FOR_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });

        return response.data;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function addConsumerCategoryByAdmin(data, params, query, actionData) {
  return (dispatch) =>
    consumerCategoryService
      .addConsumerCategoryByAdmin(data, query)
      .then((response) => {
        dispatch({
          type: CONSUMER_CATEGORY_ADD_BY_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });

        showNotification({
          type: 'success',
          text: 'Category added successfully.',
        });

        return response.data;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function updateConsumerCategoryByAdmin(data, params) {
  return (dispatch) =>
    consumerCategoryService
      .updateConsumerCategoryByAdmin(data, params)
      .then((response) => {
        dispatch({
          type: CONSUMER_CATEGORY_UPDATE_BY_ADMIN,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Category updated successfully.',
        });

        return response.data;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function deleteConsumerCategoryByAdmin(params, query, actionData) {
  return (dispatch) =>
    consumerCategoryService
      .deleteConsumerCategoryByAdmin(params, query)
      .then((response) => {
        dispatch({
          type: CONSUMER_CATEGORY_DELETE_BY_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });

        showNotification({
          type: 'success',
          text: 'Category deleted successfully.',
        });

        return response.data;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function subscribeToCategory(data, params) {
  return (dispatch) =>
    subscriptionService
      .subscribe(data, params)
      .then((response) => {
        dispatch({
          type: SUBSCRIBE_TO_CATEGORY,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Subscription updated successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function unsubscribeToCategory(params) {
  return (dispatch) =>
    subscriptionService
      .unsubscribe(params)
      .then((response) => {
        dispatch({
          type: UNSUBSCRIBE_TO_CATEGORY,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Unsubscribed successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}
