import PropTypes from 'prop-types';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { CloseIcon } from '../../../../../helper/icons/common-icons';
import { formikProps } from '../../../../common/common-props';
import FormComponent from '../../../../common/form-component/form-component';
import DateTimePicker from '../../category-common-components/date-time-picker';

export default function ShortTextAddRow(props) {
  const { fieldArray, formik, index, timeZone } = props;

  const [textareaContent, setTextareaContent] = useState('');

  const onInputChange = (e) => {
    formik.handleChange(e);

    if (e.target.name === `shortTextList[${index}].textContent`) {
      setTextareaContent(e.target.value);
      formik.setFieldTouched(`shortTextList[${index}].textContent`, true);
    }
  };

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-end pb-2 pt-3">
          <CloseIcon
            className="cursor-pointer"
            onClick={() => {
              fieldArray.remove(index);
            }}
          />
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col xs={12} lg={4}>
          <Row>
            <FormComponent
              type="text"
              placeholder="Message Name"
              name={`shortTextList[${index}].messageName`}
              inputColumnClass="col-12 col-sm-6 col-lg-12"
              errorColumnClass="col-12 d-sm-none d-lg-block pb-lg-3"
              formik={formik}
              autoFocusInput
              showLabel
            />

            <Col className="px-0 mt-2 mt-sm-0 date-time-picker-short-text-parent">
              <Form.Label className="pl-3">Disburse Date & Time</Form.Label>
              <DateTimePicker
                timeZone={timeZone}
                formik={formik}
                fieldName={`shortTextList[${index}].disburseDateTime`}
                iconToolTip="Select Disburse Date & Time"
                placeholder="Disburse Date & Time"
                inputColumnClass="col-12"
              />

              <FormComponent
                type="text"
                name={`shortTextList[${index}].disburseDateTime`}
                inputColumnClass="d-none"
                errorColumnClass="col-12 d-sm-none d-lg-block"
                formik={formik}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={12} className="d-none d-sm-block d-lg-none">
          <Row>
            <FormComponent
              name={`shortTextList[${index}].messageName`}
              inputColumnClass="d-none"
              errorColumnClass="col-6"
              formik={formik}
            />

            <Col className="px-0 mt-2 mt-sm-0 pb-sm-3 pb-lg-0 date-time-picker-short-text-parent">
              <FormComponent
                name={`shortTextList[${index}].disburseDateTime`}
                inputColumnClass="d-none"
                errorColumnClass="col-6"
                formik={formik}
              />
            </Col>
          </Row>
        </Col>

        <Col className="justify-content-center pb-4">
          <Row>
            <FormComponent
              type="textarea"
              placeholder="Message"
              name={`shortTextList[${index}].textContent`}
              inputColumnClass="col-12"
              errorColumnClass="d-none d-sm-flex col-sm-7 col-lg-8"
              formik={{
                ...formik,
                ...{ handleChange: onInputChange },
              }}
              showLabel
            />

            <Col className="text-right text-muted">
              <small>Word Counts: {textareaContent.length}/160 (Max)</small>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

ShortTextAddRow.propTypes = {
  fieldArray: PropTypes.objectOf(PropTypes.any).isRequired,
  formik: formikProps.isRequired,
  index: PropTypes.number.isRequired,
  timeZone: PropTypes.string.isRequired,
};

ShortTextAddRow.defaultProps = {};
