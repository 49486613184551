import { Col, Figure, Row } from 'react-bootstrap';
import defaultPic from '../../../../../assets/image/gray.jpg';
import commomMethods from '../../../../../helper/common-methods';
import { singlePackageProps } from '../../customer-common-props';

export default function PackageRowBody({ packageItem }) {
  return (
    <>
      <Row>
        <Col xs={12} sm={6}>
          <Row>
            <Col className="d-flex justify-content-center justify-content-sm-start">
              <Figure className="text-center text-sm-left package-image-consumer">
                <Figure.Image
                  className="package-image-consumer-wrapper"
                  src={commomMethods.getImageApi(packageItem.imagePath) || defaultPic}
                  fluid={false}
                />
                <h5 className="text-center pt-4">{packageItem.name} Logo</h5>
              </Figure>
            </Col>
          </Row>
        </Col>

        <Col xs={12} sm={6}>
          <p className="font-weight-bold">
            Number Of Tokens:{' '}
            <span className="font-weight-normal">{packageItem.numberOfTokens}</span>
          </p>

          <p className="font-weight-bold">
            Price: $<span className="font-weight-normal">{packageItem.price}</span>
          </p>

          {packageItem.isAutoRenewable && (
            <p className="font-weight-bold">
              Auto-Purchase Price:{' '}
              <span className="font-weight-normal">{packageItem.autoRenewPrice}</span>
            </p>
          )}

          <p className="font-weight-bold">
            Auto-Purchasable:{' '}
            <span className="font-weight-normal">{packageItem.isAutoRenewable ? 'on' : 'off'}</span>
          </p>
        </Col>
      </Row>
    </>
  );
}

PackageRowBody.propTypes = {
  packageItem: singlePackageProps.isRequired,
};

PackageRowBody.defaultProps = {};
