import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import ImageComponent from './image-component';
import InfoPanelDetails from './info-panel-details';

export default function CustomerInfoPanel(props) {
  const { title, details, imageUrl, buttonText, isCustomerInfo } = props;

  return (
    <Row className="justify-content-center py-5">
      <Col lg={6} sm={12} className="py-md-5 ml-4 ml-md-5 mr-lg-n5 ml-xs-n5">
        <ImageComponent imageUrl={imageUrl} isCusomerPanel />
      </Col>
      <Col lg={6} sm={12} className="py-md-5 pl-5">
        <InfoPanelDetails
          details={details}
          title={title}
          buttonText={buttonText}
          header={false}
          isCustomerInfo={isCustomerInfo}
        />
      </Col>
    </Row>
  );
}

CustomerInfoPanel.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  isCustomerInfo: PropTypes.bool.isRequired,
};

CustomerInfoPanel.defaultProps = {};
