import commonMethods from '../../../helper/common-methods';

export default function TimeZoneDropDown() {
  const allTimeZone = commonMethods.getTimeZoneUSAndCA();

  return (
    <>
      <option value="">Select Time Zone</option>
      {allTimeZone.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </>
  );
}

TimeZoneDropDown.propTypes = {};

TimeZoneDropDown.defaultProps = {};
