import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { RingLoader } from 'react-spinners';
import colors from '../../../../helper/colors';
import commonMethods from '../../../../helper/common-methods';
import {
  copyShortTextByCustomer,
  deleteShortTextByCustomer,
  editShortTextByCustomer,
  // eslint-disable-next-line prettier/prettier
  getShortTextForCustomer
} from '../../../../redux/actions/short-text-action';
import { browserViewProps, userInformationProps } from '../../../common/common-props';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import CustomPagination from '../../../common/pagination/custom-pagination';
import FilesHeaderButton from '../category-common-components/files-header-buttons';
import { shortTextFilesProps } from '../category-common-props';
import ShortTextHeading from './short-text-heading';
import ShortTextRow from './short-text-row';

const ShortTextHeaderButtons = [
  { name: 'Unsent', id: 1, isUnsent: true },
  { name: 'Sent', id: 2, isSent: true },
];

function ShortTextContainer(props) {
  const {
    customerShortText,
    browserState,
    userInfoState,
    onUploadShortText,
    getShortText,
    deleteShortText,
    editShortText,
    copyShortText,
  } = props;

  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [dataLoading, setDataLoading] = useState(false);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);
  const [showFileTypeDropDown, setShowFileTypeDropDown] = useState(false);
  const [selectedHeaderButtonId, setSelectedHeaderButtonId] = useState(1);
  const [fileTypeChanging, setFileTypeChanging] = useState(false);

  const { customerUrl, categoryId } = useParams();

  const onFileTypeChange = (index) => {
    if (selectedHeaderButtonId !== +index) {
      setSelectedHeaderButtonId(+index);
      setPage(1);
      setItemPerPage(20);
      setMobileLastPageLoaded(false);
      setFileTypeChanging(true);
    }
  };

  const onToggleFileTypeDropDown = () => {
    setShowFileTypeDropDown(!showFileTypeDropDown);
  };

  const onLoadMoreClick = () => {
    setDataLoading(true);

    if (customerShortText.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  const getPaginationQuery = ({
    pageChanged = false,
    paramsData = {},
    customMethod = null,
    queryData = {},
    payload = null,
  }) => {
    const selectedHeaderButton = ShortTextHeaderButtons.find(
      (item) => item.id === selectedHeaderButtonId
    );

    const params = { customerUrl, ...paramsData };
    const query = {
      page,
      itemPerPage,
      consumerCategoryId: +categoryId,
      ..._.omit(selectedHeaderButton, ['id', 'name']),
      ...queryData,
    };

    return {
      isMobileView: browserState.isMobile,
      params,
      query,
      page,
      payload,
      itemPerPage,
      getMethod: customMethod || getShortText,
      pageChanged,
      data: customerShortText.data,
    };
  };

  const onDeleteShortText = (shortTextId) =>
    commonMethods.updatePaginationData(
      getPaginationQuery({
        paramsData: { shortTextId },
        queryData: { sendList: !browserState.isMobile },
        customMethod: deleteShortText,
      })
    );

  const onEditShortText = (data, params) => editShortText(data, params);

  const onCopyShortText = (payload, params) =>
    commonMethods.updatePaginationData(
      getPaginationQuery({
        paramsData: params,
        payload,
        queryData: { sendList: !browserState.isMobile },
        customMethod: copyShortText,
      })
    );

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery({ pageChanged: true }));
  }, [page]);

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery({}));
  }, [itemPerPage, mobileLastPageLoaded, selectedHeaderButtonId]);

  useEffect(() => {
    if (!browserState.isMobile) {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    }
  }, [browserState.isMobile]);

  useEffect(() => {
    setDataLoading(false);
    setFileTypeChanging(false);
  }, [customerShortText]);

  return (
    <>
      <Row className="mt-4 mt-sm-0 bg-white rounded-lg">
        <FilesHeaderButton
          onHandleAddButton={onUploadShortText}
          onFileTypeChange={onFileTypeChange}
          onToggleDropDown={onToggleFileTypeDropDown}
          showFileTypeDropDown={showFileTypeDropDown}
          selectedFileType={selectedHeaderButtonId}
          headerButtons={ShortTextHeaderButtons}
        />

        {!fileTypeChanging && (
          <>
            <ShortTextHeading />

            {customerShortText.data.map((item) => (
              <ShortTextRow
                key={item.id}
                shortTextItem={item}
                timeZone={userInfoState.timeZone}
                onDeleteShortText={onDeleteShortText}
                onEditShortText={onEditShortText}
                onCopyShortText={onCopyShortText}
              />
            ))}

            <Col className="px-0 border-top">
              {customerShortText.totalItems > 10 && (
                <CustomPagination
                  itemPerPage={itemPerPage}
                  page={page}
                  setPage={setPage}
                  setItemPerPage={setItemPerPage}
                  totalPages={customerShortText.totalPages}
                  totalItems={customerShortText.totalItems}
                />
              )}
            </Col>

            {customerShortText.totalItems > customerShortText.data.length && (
              <Col
                sm={11}
                className="bg-light justify-content-center justify-content-lg-start pt-4 d-sm-none"
              >
                <Button size="lg" disabled={dataLoading} onClick={onLoadMoreClick} block>
                  <DataSavingSpinner
                    savingText="Loding"
                    defaultText="Load More"
                    dataSaving={dataLoading}
                  />
                </Button>
              </Col>
            )}
          </>
        )}
      </Row>

      <Col className="pb-5 mb-5 bg-light d-sm-none" />

      {fileTypeChanging && (
        <Row className="d-flex justify-content-center bg-white">
          <RingLoader loading size={100} color={colors.secondary} />
        </Row>
      )}
    </>
  );
}

ShortTextContainer.propTypes = {
  customerShortText: shortTextFilesProps.isRequired,
  browserState: browserViewProps.isRequired,
  getShortText: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
  deleteShortText: PropTypes.func.isRequired,
  editShortText: PropTypes.func.isRequired,
  copyShortText: PropTypes.func.isRequired,
  onUploadShortText: PropTypes.func.isRequired,
};

ShortTextContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  customerShortText: state.shortText.shortTextForCustomer,
  browserState: state.browserData.view,
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  getShortText: getShortTextForCustomer,
  deleteShortText: deleteShortTextByCustomer,
  editShortText: editShortTextByCustomer,
  copyShortText: copyShortTextByCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortTextContainer);
