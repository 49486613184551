/* eslint-disable import/prefer-default-export */
import _axios from 'axios';
import browserStorage from './browser-storage';
import { USER_DATA } from './constant-values';

export const axios = _axios.create({
  headers: { 'Content-Type': 'application/json' },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  withCredentials: true,
});

export const axiosWithFormData = _axios.create({
  headers: { 'Content-Type': 'multipart/form-data' },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  withCredentials: true,
});

export const callAxiosWithAuth = async (data, isFormData) => {
  const { customerUrl } = browserStorage.getUserData(USER_DATA);

  const axiosApi = isFormData ? axiosWithFormData : axios;

  try {
    const response = await axiosApi(data);
    return response;
  } catch (error) {
    if (error.response.status === 401) {
      if (!customerUrl) {
        browserStorage.removeUserData(USER_DATA);
        window.location.href = process.env.REACT_APP_UI_URL;
      } else {
        browserStorage.removeUserData(USER_DATA);
        window.location.href = `${process.env.REACT_APP_UI_URL}/${customerUrl}/login`;
      }
    }
    return Promise.reject(error);
  }
};
