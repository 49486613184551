import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPackagesForConsumer } from '../../../redux/actions/package';
import { getConsumerProfile } from '../../../redux/actions/profile-action';
import { userInformationProps } from '../../common/common-props';
import MenuBarWrapper from '../../common/menu-bar-wrapper/menu-bar-wrapper';
import { packageProps } from './consumer-package-common-props';
import './consumer-package-style.css';
import PackageList from './package-list';

function ConsumerPackage(props) {
  const { packageData, userInfoState, packagesForConsumer, consumerProfile } = props;
  const { id: consumerId } = userInfoState;
  const { customerUrl } = useParams();

  useEffect(() => {
    const params = { customerUrl, consumerId };

    packagesForConsumer(params);
    consumerProfile(params);
  }, []);

  return (
    <MenuBarWrapper navBarIndex={1} headerTitle="Purchase Tokens">
      <PackageList packages={packageData.packages} />
    </MenuBarWrapper>
  );
}

ConsumerPackage.propTypes = {
  packageData: packageProps.isRequired,
  packagesForConsumer: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
  consumerProfile: PropTypes.func.isRequired,
};

ConsumerPackage.defaultProps = {};

const mapStateToProps = (state) => ({
  packageData: state.packages,
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  packagesForConsumer: getPackagesForConsumer,
  consumerProfile: getConsumerProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerPackage);
