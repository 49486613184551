/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

export const userInformationProps = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  customerUrl: PropTypes.string,
  customerLogo: PropTypes.string,
  tokenBalance: PropTypes.number,
  profilePic: PropTypes.string,
  defaultCategoryId: PropTypes.number,
  email: PropTypes.string.isRequired,
  smsPhoneNumber: PropTypes.string,
  timeZone: PropTypes.string,
  isTestAudioPlayed: PropTypes.bool,
  stripeId: PropTypes.string,
  paypalPaymentToken: PropTypes.string,
});

export const browserViewProps = PropTypes.shape({
  isMobile: PropTypes.bool.isRequired,
  isTab: PropTypes.bool.isRequired,
  isLaptop: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isExtraLargeScreen: PropTypes.bool.isRequired,
});

export const consumerLandingProps = PropTypes.shape({
  customerDetails: PropTypes.shape({
    subscriptionCategory: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
});

export const singleSubscriptionProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  logoSvg: PropTypes.string.isRequired,

  deliveryMediums: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      numberOfTokens: PropTypes.number.isRequired,
    }).isRequired
  ),
});

export const subscriptionListProps = PropTypes.arrayOf(singleSubscriptionProps.isRequired);

export const formikProps = PropTypes.shape({
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
});
