import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import routes from '../../../../helper/route-details';
import { customerDetailsProps } from '../customer-common-props';
import CustomerViewPage from '../view-customer/view-customer-container';
import CustomerDetailsModify from './customer-details/customer-details-modify';

function AddCustomerContainer(props) {
  const { customerDetails } = props;

  const [showAddCustomerDetailsPage, setShowAddCustomerDetailsPage] = useState(true);
  const [showAddCustomerCategoryPage, setShowAddCustomerCategoryPage] = useState(false);
  const [showAddCustomerPackagePage, setShowAddCustomerPackagePage] = useState(false);
  const [backFromCategoryView, setBackFromCategoryView] = useState(false);

  const history = useHistory();

  const gotoCustomerListViewPage = () => {
    history.push(routes.admin.customers.default());
  };

  const gotoCustomerDetailsAddPage = () => {
    setBackFromCategoryView(true);
    setShowAddCustomerDetailsPage(true);
    setShowAddCustomerCategoryPage(false);
    setShowAddCustomerPackagePage(false);
  };

  const gotoCustomerCategoryAddPage = () => {
    setShowAddCustomerCategoryPage(true);
    setBackFromCategoryView(false);
    setShowAddCustomerDetailsPage(false);
    setShowAddCustomerPackagePage(false);
  };

  const gotoCustomerPackageAddPage = () => {
    setShowAddCustomerCategoryPage(false);
    setBackFromCategoryView(false);
    setShowAddCustomerDetailsPage(false);
    setShowAddCustomerPackagePage(true);
  };

  return (
    <>
      <Container className="container-uxl">
        {showAddCustomerDetailsPage && (
          <CustomerDetailsModify
            onBackArrowButtonClick={gotoCustomerListViewPage}
            backFromCategoryView={backFromCategoryView}
            onSavingData={gotoCustomerCategoryAddPage}
            isAddForm
          />
        )}

        {showAddCustomerCategoryPage && (
          <CustomerViewPage
            onBackButtonClick={gotoCustomerDetailsAddPage}
            newCustomerId={customerDetails.id}
            gotoCustomerPackageAddPage={gotoCustomerPackageAddPage}
            isAddCustomerCategoryPage
          />
        )}

        {showAddCustomerPackagePage && (
          <CustomerViewPage
            onBackButtonClick={gotoCustomerCategoryAddPage}
            newCustomerId={customerDetails.id}
            gotoCustomerListViewPage={gotoCustomerListViewPage}
            isAddCustomerPackagePage
          />
        )}
      </Container>
    </>
  );
}

AddCustomerContainer.propTypes = {
  customerDetails: customerDetailsProps.isRequired,
};

AddCustomerContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  customerDetails: state.customers.customerDetailsForAdmin,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerContainer);
