// Consumer Category
export const GET_CONSUMER_CATEGORY_WITH_MEDIUM = 'GET_CONSUMER_CATEGORY_WITH_MEDIUM';
export const GET_CONSUMER_CATEGORY_LIST_FOR_ADMIN = 'GET_CONSUMER_CATEGORY_LIST_FOR_ADMIN';
export const CONSUMER_CATEGORY_ADD_BY_ADMIN = 'CONSUMER_CATEGORY_ADD_BY_ADMIN';
export const CONSUMER_CATEGORY_UPDATE_BY_ADMIN = 'CONSUMER_CATEGORY_UPDATE_BY_ADMIN';
export const CONSUMER_CATEGORY_DELETE_BY_ADMIN = 'CONSUMER_CATEGORY_DELETE_BY_ADMIN';

// Support ticket
export const GET_SUPPORT_TICKET_FOR_CONSUMER = 'GET_SUPPORT_TICKET_FOR_CONSUMER';
export const CREATE_SUPPORT_TICKET = 'CREATE_SUPPORT_TICKET';

// Packages
export const GET_PACKAGES_FOR_CONSUMER = 'GET_PACKAGES_FOR_CONSUME';
export const TOGGLE_AUTO_PAYMENT_BUTTON = 'TOGGLE_AUTO_PAYMENT_BUTTON';
export const PACKAGE_PAYMENT = 'PACKAGE_PAYMENT';
export const GET_PACKAGES_FOR_CUSTOMER = 'GET_PACKAGES_FOR_CUSTOMER';
export const GET_PACKAGE_LIST_FOR_ADMIN = 'GET_PACKAGE_LIST_FOR_ADMIN';
export const PACKAGE_ADD_BY_ADMIN = 'PACKAGE_ADD_BY_ADMIN';
export const PACKAGE_UPDATE_BY_ADMIN = 'PACKAGE_UPDATE_BY_ADMIN';
export const PACKAGE_DELETE_BY_ADMIN = 'PACKAGE_DELETE_BY_ADMIN';

// Auth
export const LOG_OUT = 'LOG_OUT';
export const CONSUMER_SIGN_UP = 'CONSUMER_SIGN_UP';
export const CONSUMER_SIGN_UP_INIT = 'CONSUMER_SIGN_UP_INIT';
export const OTP_VERIFICATION = 'OTP_VERIFICATION';
export const USER_TOKEN_VERIFICATION = 'USER_TOKEN_VERIFICATION';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_INIT = 'FORGOT_PASSWORD_INIT';
export const LOGIN_INIT = 'LOGIN_INIT';
export const UPDATE_TOKEN_BALANCE = 'UPDATE_TOKEN_BALANCE';

// Profile
export const GET_CONSUMER_PROFILE = 'GET_CONSUMER_PROFILE';
export const GET_CUSTOMER_PROFILE = 'GET_CUSTOMER_PROFILE';
export const GET_ADMIN_PROFILE = 'GET_ADMIN_PROFILE';
export const CUSTOMER_PROFILE_INIT = 'LOGIN_INCUSTOMER_PROFILE_INITIT';
export const CONSUMER_PROFILE_INIT = 'CONSUMER_PROFILE_INIT';
export const PROFILE_PASSWORD_CHANGE = 'PROFILE_PASSWORD_CHANGE';
export const ADMIN_PROFILE_INIT = 'ADMIN_PROFILE_INIT';

// Subscription
export const SUBSCRIBE_TO_CATEGORY = 'SUBSCRIBE_TO_CATEGORY';
export const UNSUBSCRIBE_TO_CATEGORY = 'UNSUBSCRIBE_TO_CATEGORY';

// Consumer Landing
export const GET_USER_INFORMATION_AND_SUBSCRIPTION_CATEGORY =
  'GET_USER_INFORMATION_AND_SUBSCRIPTION_CATEGORY';

// Browser Data
export const INNER_WIDH_CHANGE = 'INNER_WIDH_CHANGE';

// Customer Audio
export const AUDIO_FOR_CONSUMER = 'AUDIO_FOR_CONSUMER';
export const AUDIO_FOR_CUSTOMER = 'AUDIO_FOR_CUSTOMER';
export const AUDIO_DELETE_BY_CUSTOMER = 'AUDIO_DELETE_BY_CUSTOMER';
export const AUDIO_UPDATE_BY_CUSTOMER = 'AUDIO_UPDATE_BY_CUSTOMER';
export const AUDIO_COPY_BY_CUSTOMER = 'AUDIO_COPY_BY_CUSTOMER';
export const ADD_CONSUMER_PLAYED_AUDIO = 'ADD_CONSUMER_PLAYED_AUDIO';
export const REMOVE_CONSUMER_PLAYED_AUDIO = 'REMOVE_CONSUMER_PLAYED_AUDIO';
export const INIT_CONSUMER_PLAYED_AUDIO = 'INIT_CONSUMER_PLAYED_AUDIO';

// Short Text
export const SHORT_TEXT_FOR_CUSTOMER = 'SHORT_TEXT_FOR_CUSTOMER';
export const SHORT_TEXT_EDIT_BY_CUSTOMER = 'SHORT_TEXT_EDIT_BY_CUSTOMER';
export const SHORT_TEXT_DELETE_BY_CUSTOMER = 'SHORT_TEXT_DELETE_BY_CUSTOMER';
export const SHORT_TEXT_COPY_BY_CUSTOMER = 'SHORT_TEXT_COPY_BY_CUSTOMER';

// Customer Revenue & Usage
export const GET_REVENUE_REPORT_FOR_CUSTOMER = 'GET_REVENUE_REPORT_FOR_CUSTOMER';
export const GET_USAGE_REPORT_FOR_CUSTOMER = 'GET_USAGE_REPORT_FOR_CUSTOMER';
export const GET_GROSS_SALES_FOR_CUSTOMER = 'GET_GROSS_SALES_FOR_CUSTOMER';

// consumers
export const CONSUMER_LIST_BY_ADMIN = 'CONSUMER_LIST_BY_ADMIN';
export const CONSUMER_UPDATE_BY_ADMIN = 'CONSUMER_UPDATE_BY_ADMIN';
export const CONSUMER_DETAILS_FOR_ADMIN = 'CONSUMER_DETAILS_FOR_ADMIN';

// customers
export const CUSTOMER_LIST_BY_ADMIN = 'CUSTOMER_LIST_BY_ADMIN';
export const CUSTOMER_DETAILS_BY_ADMIN = 'CUSTOMER_DETAILS_BY_ADMIN';
export const CUSTOMER_DELETE_BY_ADMIN = 'CUSTOMER_DELETE_BY_ADMIN';

// Payments history
export const CONSUMER_PAYMENT_HISTORIES_BY_ADMIN = 'CONSUMER_PAYMENT_HISTORIES_BY_ADMIN';
export const CONSUMER_PAYMENT_REFUND_BY_ADMIN = 'CONSUMER_PAYMENT_REFUND_BY_ADMIN';

// Token transactions
export const TOKEN_TRANSACTION_FOR_ADMIN = 'TOKEN_TRANSACTION_FOR_ADMIN';

// Payments
export const GET_CONSUMER_DEFAULT_PAYMENT_INFO = 'GET_CONSUMER_DEFAULT_PAYMENT_INFO';
export const DELETE_CONSUMER_DEFAULT_PAYMENT_INFO = 'DELETE_CONSUMER_DEFAULT_PAYMENT_INFO';
