/* eslint-disable prettier/prettier */
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { BiArrowBack } from 'react-icons/bi';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { changePasswordOfProfileValidationSchema } from '../../../helper/schema-objects';
import { adminProfilePasswordChange, profilePasswordChange } from '../../../redux/actions/profile-action';
import FormComponent from '../../common/form-component/form-component';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import './change-password.css';

function ChangePassword(props) {
  const { userChangePassword, goToUserProfile, onBackArrowButtonClick, isAdmin, adminChangePassword } = props;

  const [showCurrentPassword, setShowCurrentPassword] = useState('password');
  const [showNewPassword, setShowNewPassword] = useState('password');
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState('password');
  const [dataSaving, setDataSaving] = useState(false);

  const { customerUrl } = useParams();

  const backBtnColorClass = isAdmin ? 'profile-arrow-button-admin' : 'profile-arrow-button';

  const showHideCurrentPassword = () => {
    if (showCurrentPassword === 'password') setShowCurrentPassword('text');
    else setShowCurrentPassword('password');
  };

  const showHideNewPassword = () => {
    if (showNewPassword === 'password') setShowNewPassword('text');
    else setShowNewPassword('password');
  };

  const showHideConfirmPassword = () => {
    if (showConfirmNewPassword === 'password') setShowConfirmNewPassword('text');
    else setShowConfirmNewPassword('password');
  };

  const onSubmit = (values, { setErrors }) => {
    setDataSaving(true);
    
    const changePasswordAction = isAdmin ? adminChangePassword: userChangePassword;

    changePasswordAction(values, { customerUrl })
      .then(() => {
        setDataSaving(false);
        goToUserProfile();
      })
      .catch((error) => {
        setDataSaving(false);

        if (!error.response) {
          return;
        }

        const { currentAndNewPasswordMatch, invalidCurrentPassword, message } = error.response.data;

        if (currentAndNewPasswordMatch) {
          setErrors({
            currentPassword: ' ',
            newPassword: ' ',
            confirmNewPassword: message,
          });
        } else if (invalidCurrentPassword) {
          setErrors({
            currentPassword: message,
          });
        }
      });
  };

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  return (
    <>
      <Row>
        <Col className="pl-0">
          <BiArrowBack
            className={`${backBtnColorClass} d-inline mb-2 mr-2 cursor-pointer`}
            size="2.3em"
            onClick={onBackArrowButtonClick}
          />
          <h4 className="pt-2 pl-1 d-inline">My Profile</h4>
        </Col>
      </Row>

      <Row className="mt-4 mb-4 mb-sm-0 pt-5 rounded-corner bg-white">
        <Col>
          <Row>
            <Col className="text-center">
              <h5 className="pb-3">Change Your Password</h5>
            </Col>
          </Row>

          <Formik
            initialValues={initialValues}
            validationSchema={changePasswordOfProfileValidationSchema}
            onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Row className="justify-content-center">
                  <Col sm={10} lg={5}>
                    <Form.Label>Current Password</Form.Label> 
                  </Col>
                </Row>
                
                <Row className="justify-content-center">
                  <FormComponent
                    isPassword
                    type={showCurrentPassword}
                    placeholder="Current Password"
                    name="currentPassword"
                    iconToolTip="Show/hide password"
                    onIconClick={showHideCurrentPassword}
                    inputColumnClass="col-lg-5 col-sm-10"
                    errorColumnClass="d-none"
                    formik={formik}
                  />
                </Row>
                <Row className="justify-content-center">
                  <FormComponent
                    name="currentPassword"
                    inputColumnClass="d-none"
                    errorColumnClass="col-lg-5 col-sm-10"
                    formik={formik}
                  />
                </Row>

                <Row className="justify-content-center">
                  <Col sm={10} lg={5}>
                    <Form.Label>New Password</Form.Label> 
                  </Col>
                </Row>

                <Row className="mt-1 justify-content-center">
                  <FormComponent
                    isPassword
                    type={showNewPassword}
                    placeholder="New Password"
                    name="newPassword"
                    iconToolTip="Show/hide password"
                    onIconClick={showHideNewPassword}
                    inputColumnClass="col-lg-5 col-sm-10"
                    errorColumnClass="d-none"
                    formik={formik}
                  />
                </Row>
                <Row className="justify-content-center">
                  <FormComponent
                    name="newPassword"
                    inputColumnClass="d-none"
                    errorColumnClass="col-lg-5 col-sm-10"
                    formik={formik}
                  />
                </Row>

                <Row className="justify-content-center">
                  <Col sm={10} lg={5}>
                    <Form.Label>Confirm New Password</Form.Label> 
                  </Col>
                </Row>

                <Row className="mt-1 justify-content-center">
                  <FormComponent
                    isPassword
                    type={showConfirmNewPassword}
                    placeholder="Confirm New Password"
                    name="confirmNewPassword"
                    iconToolTip="Show/hide password"
                    onIconClick={showHideConfirmPassword}
                    inputColumnClass="col-lg-5 col-sm-10"
                    errorColumnClass="d-none"
                    formik={formik}
                  />
                </Row>
                <Row className="justify-content-center">
                  <FormComponent
                    name="confirmNewPassword"
                    inputColumnClass="d-none"
                    errorColumnClass="col-lg-5 col-sm-10"
                    formik={formik}
                  />
                </Row>

                <Row className="justify-content-center mt-3 pb-5 mb-5">
                  <Col sm={10} lg={5} className="pb-sm-5 mb-sm-5">
                    <Button
                      variant={isAdmin ? 'secondary' : 'primary'}
                      size="lg"
                      block
                      onClick={formik.handleSubmit}
                      type="submit"
                      disabled={
                        !!(
                          formik.errors.currentPassword ||
                          formik.errors.newPassword ||
                          formik.errors.confirmNewPassword ||
                          dataSaving
                        )
                      }
                    >
                      <DataSavingSpinner
                        savingText="Saving Changes"
                        defaultText="Save Changes"
                        dataSaving={dataSaving}
                      />
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}

ChangePassword.propTypes = {
  goToUserProfile: PropTypes.func.isRequired,
  userChangePassword: PropTypes.func.isRequired,
  adminChangePassword: PropTypes.func.isRequired,
  onBackArrowButtonClick: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

ChangePassword.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  userChangePassword: profilePasswordChange,
  adminChangePassword: adminProfilePasswordChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
