import PropTypes from 'prop-types';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { singleConsumerProps } from '../consumer-common-props';
import ActionButtons from './action-buttons';

export default function ConsumerListInfo(props) {
  const { onEditConsumer, onViewConsumer, consumerItem } = props;

  return (
    <>
      <Row className="mx-sm-2 py-2 mb-4 mb-lg-0 bg-white rounded-corner">
        <Col xs={10}>
          <Row>
            <Col md={6} lg={3} className="d-flex">
              <span className="font-weight-bold d-lg-none pr-1">Customer Url:</span>
              <OverlayTrigger
                transition={false}
                placement="bottom"
                overlay={<Tooltip> {consumerItem.url}</Tooltip>}
              >
                <span className="flex-child-admin text-info text-truncate">{consumerItem.url}</span>
              </OverlayTrigger>
            </Col>

            <Col md={6} lg={3} className="d-flex">
              <span className="font-weight-bold d-lg-none pr-1">Name:</span>
              <OverlayTrigger
                transition={false}
                placement="bottom"
                overlay={<Tooltip> {consumerItem.fullName}</Tooltip>}
              >
                <span className="flex-child-admin text-info text-truncate">
                  {consumerItem.fullName}
                </span>
              </OverlayTrigger>
            </Col>

            <Col md={6} lg={3} className="d-flex">
              <span className="font-weight-bold d-lg-none pr-1">Username:</span>
              <OverlayTrigger
                transition={false}
                placement="bottom"
                overlay={<Tooltip> {consumerItem.username}</Tooltip>}
              >
                <span className="flex-child-admin text-info text-truncate">
                  {consumerItem.username}
                </span>
              </OverlayTrigger>
            </Col>

            <Col md={6} lg={3} className="d-flex pr-0">
              <span className="font-weight-bold d-lg-none pr-1">Email:</span>
              <OverlayTrigger
                transition={false}
                placement="bottom"
                overlay={<Tooltip> {consumerItem.email || consumerItem.tempEmail}</Tooltip>}
              >
                <span className="flex-child-admin text-info text-truncate">
                  {consumerItem.email || consumerItem.tempEmail}
                </span>
              </OverlayTrigger>
            </Col>
          </Row>
        </Col>

        <Col xs={2} className="d-flex justify-content-end">
          <Row>
            <ActionButtons
              onHandleEdit={() => {
                onEditConsumer(consumerItem);
              }}
              onHandleView={() => {
                onViewConsumer(consumerItem);
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

ConsumerListInfo.propTypes = {
  onEditConsumer: PropTypes.func.isRequired,
  onViewConsumer: PropTypes.func.isRequired,
  consumerItem: singleConsumerProps.isRequired,
};

ConsumerListInfo.defaultProps = {};
