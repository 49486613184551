import commonMethods from '../../helper/common-methods';
import {
  CONSUMER_PAYMENT_HISTORIES_BY_ADMIN,
  CONSUMER_PAYMENT_REFUND_BY_ADMIN,
  DELETE_CONSUMER_DEFAULT_PAYMENT_INFO,
  // eslint-disable-next-line prettier/prettier
  GET_CONSUMER_DEFAULT_PAYMENT_INFO
} from '../types';

const initialState = {
  consumersPaymentHistoryForAdmin: {
    totalItems: 0,
    data: [],
    totalPages: 1,
    currentPage: 1,
  },
  consumerDefaultPaymentInfo: {
    expMonth: '',
    expYear: '',
    lastFourDigit: '',
    cardBrand: '',
    noDefaultPayment: true,
    isStripePayment: false,
    isPaypalPayment: false,
    fullName: '',
    email: '',
  },
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSUMER_PAYMENT_HISTORIES_BY_ADMIN: {
      return commonMethods.paginationStateUpdate(state, action, 'consumersPaymentHistoryForAdmin');
    }

    case CONSUMER_PAYMENT_REFUND_BY_ADMIN: {
      const updatedState = commonMethods.updateOnPaginationState(
        state,
        { data: action.data.updatedData },
        'consumersPaymentHistoryForAdmin'
      );

      return commonMethods.addPaginationState(
        updatedState,
        { data: action.data.newData, isMobile: action.isMobile },
        'consumersPaymentHistoryForAdmin'
      );
    }

    case GET_CONSUMER_DEFAULT_PAYMENT_INFO:
      return {
        ...state,
        consumerDefaultPaymentInfo: action.data,
      };

    case DELETE_CONSUMER_DEFAULT_PAYMENT_INFO:
      return {
        ...state,
        consumerDefaultPaymentInfo: {
          expMonth: '',
          expYear: '',
          lastFourDigit: '',
          cardBrand: '',
          noDefaultPayment: true,
          isStripePayment: false,
          isPaypalPayment: false,
          fullName: '',
          email: '',
        },
      };

    default:
      return state;
  }
};

export default paymentReducer;
