import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import commonMethods from '../../../../helper/common-methods';
import { PlayCircleIcon } from '../../../../helper/icons/common-icons';
import CustomAudioPlayer from '../../../common/audio-player/custom-audio-player';

export default function SingleAudio(props) {
  const {
    name,
    audioFileName,
    date,
    audioId,
    onPlay,
    audioIndex,
    showUseTokenModal,
    consumerId,
    showTestPlay,
    audioSession,
    consumerPlayedAudioState,
  } = props;

  const { customerUrl } = useParams();

  const [isPlayed, setIsPlayed] = useState(false);

  const audioApi = commonMethods.getAudioAPI({
    customerUrl,
    consumerId,
    audioId,
    audioSession,
    audioFileName,
  });

  useEffect(() => {
    const audioPlayedBefore = consumerPlayedAudioState.find((item) => item === audioId);
    setIsPlayed(audioPlayedBefore === audioId);
  }, [consumerPlayedAudioState]);

  return (
    <>
      <Container fluid>
        {(audioId !== audioIndex || showUseTokenModal) && (
          <Row className="border-top py-1 flex align-items-center">
            <PlayCircleIcon
              width="2.5em"
              height="2.5em"
              className="text-primary cursor-pointer ml-2 mr-2 my-2 custom-primary-btn"
              onClick={() => onPlay(audioId)}
            />
            <span className={`flex ${isPlayed ? 'text-primary' : ''}`}>{name}</span>
          </Row>
        )}

        {audioId === audioIndex && !showUseTokenModal && (
          <div className="p-3">
            <CustomAudioPlayer
              name={name}
              description={date}
              audioSrc={audioApi}
              pauseAudio={showTestPlay}
            />
          </div>
        )}
      </Container>
    </>
  );
}

SingleAudio.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  audioId: PropTypes.number.isRequired,
  audioIndex: PropTypes.number.isRequired,
  onPlay: PropTypes.func.isRequired,
  showUseTokenModal: PropTypes.bool.isRequired,
  consumerId: PropTypes.number.isRequired,
  showTestPlay: PropTypes.bool.isRequired,
  audioSession: PropTypes.string.isRequired,
  consumerPlayedAudioState: PropTypes.arrayOf(PropTypes.number).isRequired,
  audioFileName: PropTypes.string.isRequired,
};

SingleAudio.defaultProps = {};
