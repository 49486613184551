import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getPackagesForConsumer } from '../../../../redux/actions/package';
import { defaultPaymentInfoProps, packageProps } from '../consumer-package-common-props';
import SinglePackage from '../single-package';
import PaymentContainer from './payment/payment-container';

function PackagePurchase(props) {
  const { packageId, onBackButtonClick, packageData, defaultPaymentInfo, browserDataState } = props;
  const { packages } = packageData;

  const singlePackageData = packages.find((packageNode) => packageNode.id === packageId);

  const [isAutoRenewable, setIsAutoRenewable] = useState(
    singlePackageData.consumerAutoRenewablePackages.length > 0
  );
  const [paymentType, setPaymentType] = useState(1);

  return (
    <>
      <Container className="border mt-4 mx-4 pb-4 mx-sm-auto mx-3 mb-3">
        <Row className="border-bottom py-3">
          <Col className="text-center">
            <span className="h3">Purchase Tokens</span>
          </Col>
        </Row>

        <Row>
          <Col className="mx-3 my-3 bg-primary text-white d-flex justify-content-center">
            To get Sunbursts without interruption and for a lower price per day, set up an
            Auto-Purchase package when you subscribe to the daily sunbursts.
          </Col>
        </Row>

        <Row className="py-3 d-block d-sm-none">
          <Col>
            <span className="h5">Your Package:</span>
          </Col>
        </Row>
        <Row className="py-4 d-none d-sm-flex">
          <Col sm={4} className="px-lg-5">
            <span>Your Package:</span>
          </Col>
          <Col sm={8} className="px-lg-5">
            <span>Payment Options:</span>
          </Col>
        </Row>

        <Row>
          <Col
            sm={4}
            className={`border-right px-lg-5 text-center ${
              (defaultPaymentInfo.isStripePayment || defaultPaymentInfo.isPaypalPayment) &&
              !browserDataState.isMobile
                ? `${
                    paymentType === 1 ? 'payment-divider-large' : 'payment-divider-medium'
                  } tab-gap-for-border`
                : 'payment-divider tab-gap-for-border'
            }`}
          >
            <SinglePackage singlePackage={singlePackageData} isPayment />
            <Button
              variant="link"
              className="cursor-pointer button-shadow-none text-primary pl-0"
              onClick={onBackButtonClick}
            >
              Change Package <FontAwesomeIcon icon={faRedo} />
            </Button>
          </Col>

          <Col className="d-block d-sm-none my-5">
            <hr />
            <span className="h5">Payment Options:</span>
          </Col>

          <Col sm={8} className="px-lg-5">
            <PaymentContainer
              packageId={packageId}
              isAutoRenewable={isAutoRenewable}
              goToPackagePage={onBackButtonClick}
              setIsAutoRenewable={setIsAutoRenewable}
              setPaymentType={setPaymentType}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

PackagePurchase.propTypes = {
  packageData: packageProps.isRequired,
  packageId: PropTypes.number.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  defaultPaymentInfo: defaultPaymentInfoProps.isRequired,
  browserDataState: PropTypes.shape({
    isMobile: PropTypes.bool.isRequired,
    isTab: PropTypes.bool.isRequired,
    isLaptop: PropTypes.bool.isRequired,
    isDesktop: PropTypes.bool.isRequired,
    isExtraLargeScreen: PropTypes.bool.isRequired,
  }).isRequired,
};

PackagePurchase.defaultProps = {};

const mapStateToProps = (state) => ({
  packageData: state.packages,
  defaultPaymentInfo: state.payments.consumerDefaultPaymentInfo,
  browserDataState: state.browserData.view,
});

const mapDispatchToProps = {
  getPackagesForConsumer,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackagePurchase);
