import { Col, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { consumerDetailsProps } from '../consumer-common-props';

export default function ProfileInfo(props) {
  const { consumerDetails } = props;

  const profileTitles = [
    'Username:',
    'Name:',
    'Email:',
    'Contact Phone Number:',
    'SMS Phone Number:',
    'Astrological Sign:',
    'Stripe Id:',
    'PayPal Payment Token:',
    'Token Balance:',
  ];

  const profileValues = [
    consumerDetails.username,
    consumerDetails.fullName,
    consumerDetails.email || `${consumerDetails.unverifiedEmail} (Unverified)`,
    consumerDetails.contactNumber,
    consumerDetails.smsPhoneNumber ||
      `${
        consumerDetails.unverifiedSmsPhoneNumber
          ? `${consumerDetails.unverifiedSmsPhoneNumber} (Unverified)`
          : ''
      }`,
    consumerDetails.defaultCategoryName,
    consumerDetails.stripeId,
    consumerDetails.paypalPaymentToken,
    consumerDetails.tokenBalance,
  ];

  return (
    <>
      {profileTitles.map((title, index) => (
        <Row className="pb-2" key={title}>
          <Col xs={6} sm={5} lg={4}>
            <p className="text-dark font-weight-bold">{title}</p>
          </Col>
          <Col xs={6} sm={7} lg={8} className="d-flex justify-content-end justify-content-sm-start">
            <OverlayTrigger
              transition={false}
              placement="bottom"
              overlay={<Tooltip>{profileValues[index]}</Tooltip>}
            >
              <span className="flex-child-admin text-info text-truncate">
                {profileValues[index]}
              </span>
            </OverlayTrigger>
          </Col>
        </Row>
      ))}

      <Row className="pb-2">
        <Col>
          <p className="text-dark font-weight-bold">Sunbursts Subscriptions:</p>
        </Col>
      </Row>

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th />
            <th>Category</th>
            <th>Delivery Medium</th>
          </tr>
        </thead>
        <tbody>
          {consumerDetails.subscriptions &&
            consumerDetails.subscriptions.map((item, index) => (
              <tr key={item.categoryName}>
                <td>{index + 1}</td>
                <td>{item.categoryName}</td>
                <td>{item.deliveryMediums.join(' , ')}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

ProfileInfo.propTypes = {
  consumerDetails: consumerDetailsProps,
};

ProfileInfo.defaultProps = {
  consumerDetails: {},
};
