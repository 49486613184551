import PropTypes from 'prop-types';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import momentTimeZone from 'moment-timezone';
import commonMethods from '../../../../helper/common-methods';
import { singlePaymentHistoryProps } from '../credit-refund-common-props';
import ActionButtons from './action-buttons';
import { TABLE_DATE_TIME_FORMAT } from '../../../../helper/constant-values';

export default function PaymentHistoryListInfo(props) {
  const { onRefund, paymentHistoryItem } = props;

  const overlay = (label, value) => (
    <OverlayTrigger transition={false} placement="bottom" overlay={<Tooltip>{value}</Tooltip>}>
      <span>
        <span className="font-weight-bold d-lg-none">{label}:</span> {value}
      </span>
    </OverlayTrigger>
  );

  return (
    <>
      <Row className="mx-sm-2 py-2 mb-4 mb-lg-0 bg-white rounded-corner">
        <Col xs={10} md={11}>
          <Row>
            <Col md={6} lg={2} className="text-truncate">
              {overlay(
                'Date',
                momentTimeZone
                  .tz(paymentHistoryItem.createdat, momentTimeZone.tz.guess())
                  .format(TABLE_DATE_TIME_FORMAT)
              )}
            </Col>

            <Col md={6} lg={3} className="text-truncate">
              {overlay('Username', paymentHistoryItem.username)}
            </Col>

            <Col md={6} lg={2} className="text-truncate">
              {overlay(
                'Payment Method',
                commonMethods.replacePaymnetMethodsWithCasing(paymentHistoryItem.paymentMethod)
              )}
            </Col>

            <Col md={6} lg={3} className="text-truncate">
              {overlay(
                'Payment Id',
                paymentHistoryItem.refundId ||
                  paymentHistoryItem.paymentIntentId ||
                  paymentHistoryItem.captureId
              )}
            </Col>
            <Col md={6} lg={2} className="text-truncate">
              {overlay('Amount', paymentHistoryItem.amount.toFixed(2))}
            </Col>
          </Row>
        </Col>

        <Col xs={2} md={1} className="d-flex justify-content-end">
          <Row>
            <ActionButtons
              onHandleEdit={() => {
                onRefund(paymentHistoryItem);
              }}
              amount={+paymentHistoryItem.amount}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

PaymentHistoryListInfo.propTypes = {
  onRefund: PropTypes.func.isRequired,
  paymentHistoryItem: singlePaymentHistoryProps.isRequired,
};

PaymentHistoryListInfo.defaultProps = {};
