import { Container, Jumbotron } from 'react-bootstrap';
import style from './page-not-found-style.module.css';

export default function PageNotFound() {
  return (
    <body>
      <Container>
        <Jumbotron className={style.jumboClass}>404 Page Not Found!</Jumbotron>
      </Container>
    </body>
  );
}

PageNotFound.propTypes = {};

PageNotFound.defaultProps = {};
