import PropTypes from 'prop-types';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ADMIN, CUSTOMER, ICON_SIZE } from '../../../helper/constant-values';
import { EditIcon } from '../../../helper/icons/common-icons';
import {
  adminProfileProps,
  consumerProfileProps,
  customerProfileProps,
  // eslint-disable-next-line prettier/prettier
  userInformationProps
} from '../profile-common-props';

function ProfileInfo(props) {
  const {
    userInfoState,
    consumerProfileState,
    customerProfileState,
    adminProfileState,
    userBtnColor,
    goToEditUserProfile,
  } = props;
  const { type: userType } = userInfoState;

  const consumerProfileTitles = [
    'Username:',
    'Name:',
    'Email:',
    'Contact Phone Number:',
    'SMS Phone Number:',
    'Astrological Sign:',
  ];

  const customerProfileTitles = [
    'Username:',
    'Name:',
    'Email:',
    'Contact Phone Number:',
    'Time Zone:',
  ];

  const adminProfileTitles = ['Username:', 'Name:', 'Email:', 'Contact Phone Number:'];

  const consumerProfileValues = [
    consumerProfileState.username,
    consumerProfileState.fullName,
    consumerProfileState.email,
    consumerProfileState.contactNumber,
    consumerProfileState.smsPhoneNumber,
    consumerProfileState.defaultCategory,
  ];

  const customerProfileValues = [
    customerProfileState.username,
    customerProfileState.fullName,
    customerProfileState.email,
    customerProfileState.contactNumber,
    customerProfileState.timeZone,
  ];

  const adminProfileValues = [
    adminProfileState.username,
    adminProfileState.fullName,
    adminProfileState.email,
    adminProfileState.contactNumber,
  ];

  let profileTitles = [];
  let profileValues = [];

  if (userType === ADMIN) {
    profileTitles = adminProfileTitles;
    profileValues = adminProfileValues;
  } else if (userType === CUSTOMER) {
    profileTitles = customerProfileTitles;
    profileValues = customerProfileValues;
  } else {
    profileTitles = consumerProfileTitles;
    profileValues = consumerProfileValues;
  }

  return (
    <>
      <Row className="pb-3">
        <Col>
          <h4 className="pt-3 pb-2">Personal Information</h4>
        </Col>
        <Col>
          <Button
            variant={userBtnColor}
            className="d-none d-sm-block float-right"
            size="lg"
            onClick={goToEditUserProfile}
          >
            <EditIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
            Edit Profile
          </Button>
        </Col>
      </Row>

      {profileTitles.map((title, index) => (
        <Row className="pb-2" key={title}>
          <Col xs={6} md={5} lg={4}>
            <p className="text-dark font-weight-bold">{title}</p>
          </Col>
          <Col xs={6} md={7} lg={8} className="d-flex justify-content-end justify-content-sm-start">
            <OverlayTrigger
              transition={false}
              placement="bottom"
              overlay={<Tooltip>{profileValues[index]}</Tooltip>}
            >
              <span className="flex-child text-info text-truncate">{profileValues[index]}</span>
            </OverlayTrigger>
          </Col>
        </Row>
      ))}
    </>
  );
}

ProfileInfo.propTypes = {
  userInfoState: userInformationProps.isRequired,
  consumerProfileState: consumerProfileProps.isRequired,
  customerProfileState: customerProfileProps.isRequired,
  adminProfileState: adminProfileProps.isRequired,
  userBtnColor: PropTypes.string.isRequired,
  goToEditUserProfile: PropTypes.func.isRequired,
};

ProfileInfo.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
  consumerProfileState: state.profile.consumerProfile,
  customerProfileState: state.profile.customerProfile,
  adminProfileState: state.profile.adminProfile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
