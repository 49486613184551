import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { RingLoader } from 'react-spinners';
import colors from '../../../../../helper/colors';
import commonMethods from '../../../../../helper/common-methods';
import { ICON_SIZE } from '../../../../../helper/constant-values';
import { AddIcon } from '../../../../../helper/icons/common-icons';
import {
  addPackageByAdmin,
  deletePackageByAdmin,
  getPackageListForAdmin,
  // eslint-disable-next-line prettier/prettier
  updatePackageByAdmin
} from '../../../../../redux/actions/package';
import { browserViewProps } from '../../../../common/common-props';
import DataSavingSpinner from '../../../../common/loader-spinner/data-saving-spinner';
import CustomPagination from '../../../../common/pagination/custom-pagination';
import { packageListProps } from '../../customer-common-props';
import CustomerPackageModifyModal from '../../modify-customer/customer-package/customer-package-modify-modal';
import CustomerPackage from './customer-package';

function CustomerPackageContainer(props) {
  const {
    packageyListState,
    browserState,
    newCustomerId,
    gotoCustomerListViewPage,
    isAddCustomerPage,
    addPackage,
    deletePackage,
    getPackageList,
    updatePackage,
  } = props;

  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [dataLoadCont, setDataLoadCount] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [mobileLoadMoreLoading, setMobileLoadMoreLoading] = useState(false);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);
  const [showAddPackageModal, setShowAddPackageModal] = useState(false);

  const { customerId } = useParams();

  const onAddPackageClick = () => {
    setShowAddPackageModal(true);
  };

  const onClosePackageModifyModal = () => {
    setShowAddPackageModal(false);
  };

  const getPaginationQuery = ({
    pageChanged = false,
    paramsData = {},
    customMethod = null,
    queryData = {},
    payload = null,
  }) => {
    const params = { ...paramsData };
    const query = {
      page,
      itemPerPage,
      customerId: customerId || newCustomerId,
      ...queryData,
    };

    return {
      isMobileView: browserState.isMobile,
      params,
      query,
      page,
      payload,
      itemPerPage,
      getMethod: customMethod || getPackageList,
      pageChanged,
      data: packageyListState.data,
    };
  };

  const onLoadMoreClick = () => {
    setMobileLoadMoreLoading(true);

    if (packageyListState.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  const onAddPackageSubmit = (payload) =>
    commonMethods.updatePaginationData(
      getPaginationQuery({
        payload,
        queryData: { sendList: !browserState.isMobile },
        customMethod: addPackage,
      })
    );

  const onUpdatePackageSubmit = (payload, params) =>
    commonMethods.updatePaginationData(
      getPaginationQuery({
        payload,
        paramsData: params,
        customMethod: updatePackage,
      })
    );

  const onDeletePackageSubmit = (params) =>
    commonMethods.updatePaginationData(
      getPaginationQuery({
        paramsData: params,
        queryData: { sendList: !browserState.isMobile },
        customMethod: deletePackage,
      })
    );

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery({ pageChanged: true }));
  }, [page]);

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery({}));
  }, [itemPerPage, mobileLastPageLoaded]);

  useEffect(() => {
    if (!browserState.isMobile) {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    }
  }, [browserState.isMobile]);

  useEffect(() => {
    if (dataLoadCont !== 0) {
      setDataLoading(false);
    }
    setMobileLoadMoreLoading(false);
    setDataLoadCount(dataLoadCont + 1);
  }, [packageyListState]);

  return (
    <>
      {showAddPackageModal && (
        <CustomerPackageModifyModal
          showModal={showAddPackageModal}
          headerTitle="Add Package"
          onCloseModal={onClosePackageModifyModal}
          onSubmit={onAddPackageSubmit}
          customerId={customerId || newCustomerId}
          isAddCustomerPackage
        />
      )}

      {dataLoading && (
        <Col xs={12} className="d-flex justify-content-center">
          <RingLoader loading size={100} color={colors.secondary} />
        </Col>
      )}

      {!dataLoading && (
        <>
          <Col xs={12}>
            <Button
              variant="outline-secondary"
              className="float-right mb-3"
              size="lg"
              onClick={onAddPackageClick}
            >
              <AddIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-1" />
              Add Package
            </Button>
          </Col>

          <CustomerPackage
            packageList={packageyListState}
            onUpdatePackageSubmit={onUpdatePackageSubmit}
            onDeletePackageSubmit={onDeletePackageSubmit}
            customerId={customerId || newCustomerId}
          />

          {packageyListState.totalItems > 10 && (
            <Col xs={12} className="px-0 d-none d-md-block border-top bg-white">
              <CustomPagination
                itemPerPage={itemPerPage}
                page={page}
                setPage={setPage}
                setItemPerPage={setItemPerPage}
                totalPages={packageyListState.totalPages}
                totalItems={packageyListState.totalItems}
              />
            </Col>
          )}

          {packageyListState.totalItems > packageyListState.data.length && (
            <Col
              sm={11}
              className="bg-light justify-content-center justify-content-lg-start pt-4 d-sm-none"
            >
              <Button
                size="lg"
                variant="secondary"
                disabled={dataLoading}
                onClick={onLoadMoreClick}
                block
              >
                <DataSavingSpinner
                  savingText="Loding"
                  defaultText="Load More"
                  dataSaving={mobileLoadMoreLoading}
                />
              </Button>
            </Col>
          )}

          {isAddCustomerPage && (
            <>
              <Col xs={12} md={6} className="pt-3 pt-sm-0 d-flex justify-content-center" />
              <Col
                xs={12}
                md={{ span: 6, offset: 3 }}
                className="pt-3 pt-sm-0 justify-content-center"
              >
                <Button size="lg" variant="secondary" block onClick={gotoCustomerListViewPage}>
                  Continue
                </Button>
              </Col>
            </>
          )}
        </>
      )}
    </>
  );
}

CustomerPackageContainer.propTypes = {
  packageyListState: packageListProps.isRequired,
  browserState: browserViewProps.isRequired,
  getPackageList: PropTypes.func.isRequired,
  addPackage: PropTypes.func.isRequired,
  newCustomerId: PropTypes.number,
  gotoCustomerListViewPage: PropTypes.func,
  isAddCustomerPage: PropTypes.bool,
  updatePackage: PropTypes.func.isRequired,
  deletePackage: PropTypes.func.isRequired,
};

CustomerPackageContainer.defaultProps = {
  newCustomerId: 0,
  gotoCustomerListViewPage: () => {},
  isAddCustomerPage: false,
};

const mapStateToProps = (state) => ({
  packageyListState: state.packages.packagesForAdmin,
  browserState: state.browserData.view,
});

const mapDispatchToProps = {
  addPackage: addPackageByAdmin,
  deletePackage: deletePackageByAdmin,
  getPackageList: getPackageListForAdmin,
  updatePackage: updatePackageByAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPackageContainer);
