import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import WarningModal from '../warning-success-modal';

export default function VerificationCodeConfirmModal(props) {
  const {
    isEmailVerification,
    isSMSVerification,
    isEmailAndSMSVerification,
    isPassowrdEmailVerification,
    showModal,
    onModalCancel,
    onModalProceed,
    isRegistrationPage,
    isProfilePage,
    isForgotPasswordPage,
    verificationMedium,
    secondVerificationMedium,
  } = props;

  const [description, setDescription] = useState('');

  useEffect(() => {
    if (showModal) {
      let descriptionString = `We will now send a 6-digit verification code to your `;

      if (isEmailVerification || isPassowrdEmailVerification) {
        descriptionString += 'email address ';
      } else if (isSMSVerification) {
        descriptionString += 'SMS phone number ';
      } else if (isEmailAndSMSVerification) {
        descriptionString += 'email address and SMS phone number ';
      }

      descriptionString += 'that you will need to enter on the website to ';

      if (isEmailVerification) {
        descriptionString += 'verify the email. ';
      } else if (isSMSVerification) {
        descriptionString += 'verify the SMS phone number. ';
      } else if (isPassowrdEmailVerification) {
        descriptionString += 'reset your password. ';
      } else if (isEmailAndSMSVerification) {
        descriptionString += 'verify the email and SMS phone number. ';
      }

      descriptionString += 'PLEASE CHECK to confirm that your ';

      if (isEmailVerification) {
        descriptionString += `email address (${verificationMedium}) is correct `;
      } else if (isSMSVerification) {
        descriptionString += `SMS phone number (${verificationMedium}) is correct `;
      } else if (isPassowrdEmailVerification) {
        descriptionString += `email address or username (${verificationMedium}) is correct `;
      } else if (isEmailAndSMSVerification) {
        descriptionString += `email address (${verificationMedium}) and SMS phone number (${secondVerificationMedium}) are correct `;
      }

      descriptionString += '- if not, click CANCEL and update the information ';

      if (isRegistrationPage) {
        descriptionString += 'on the registration form.';
      } else if (isProfilePage) {
        descriptionString += 'in your profile.';
      } else if (isForgotPasswordPage) {
        descriptionString += 'in the email or username field.';
      }

      setDescription(descriptionString);
    }
  }, [showModal]);

  return (
    <WarningModal
      heading="Send Verification Code"
      description={description}
      leftBtnText="Cancel"
      rightBtnText="Send The Code"
      showModal={showModal}
      onHideModal={onModalCancel}
      onModalSubmit={onModalProceed}
      showInfoIcon
    />
  );
}

VerificationCodeConfirmModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  isEmailVerification: PropTypes.bool,
  isSMSVerification: PropTypes.bool,
  isEmailAndSMSVerification: PropTypes.bool,
  isPassowrdEmailVerification: PropTypes.bool,
  onModalCancel: PropTypes.func.isRequired,
  onModalProceed: PropTypes.func.isRequired,
  isRegistrationPage: PropTypes.bool,
  isProfilePage: PropTypes.bool,
  isForgotPasswordPage: PropTypes.bool,
  verificationMedium: PropTypes.string.isRequired,
  secondVerificationMedium: PropTypes.string,
};

VerificationCodeConfirmModal.defaultProps = {
  isEmailVerification: false,
  isSMSVerification: false,
  isEmailAndSMSVerification: false,
  isPassowrdEmailVerification: false,
  isRegistrationPage: false,
  isProfilePage: false,
  isForgotPasswordPage: false,
  secondVerificationMedium: '',
};
