import PropTypes from 'prop-types';
import { Col, Container, Modal } from 'react-bootstrap';
import { ICON_SIZE } from '../../../../helper/constant-values';
import useModalAndToolTip from '../../../../helper/hooks/useModalAndToolTip';
import { CloseCircleIcon } from '../../../../helper/icons/common-icons';
import { singlePaymentHistoryProps } from '../credit-refund-common-props';
import CreditRefund from './credit-refund';

export default function CreditRefundModal(props) {
  const { showModal, onCloseModal, paymentHistoryItem, onSubmitModal, dataSaving } = props;

  useModalAndToolTip(showModal);

  return (
    <Modal
      show={showModal}
      onHide={onCloseModal}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header>
        <Col xs={10} className="text-truncate">
          <span className="h5">{`Payment ID: ${
            paymentHistoryItem.refundId ||
            paymentHistoryItem.paymentIntentId ||
            paymentHistoryItem.captureId
          }`}</span>
        </Col>
        <Col xs={2} className="mt-2 mt-sm-0 text-right">
          <CloseCircleIcon
            className="text-muted cursor-pointer"
            width={ICON_SIZE}
            height={ICON_SIZE}
            onClick={onCloseModal}
          />
        </Col>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <CreditRefund
            paymentHistoryItem={paymentHistoryItem}
            dataSaving={dataSaving}
            onCloseModal={onCloseModal}
            onSubmitModal={onSubmitModal}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
}

CreditRefundModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  paymentHistoryItem: singlePaymentHistoryProps,
  onSubmitModal: PropTypes.func.isRequired,
  dataSaving: PropTypes.bool,
};

CreditRefundModal.defaultProps = {
  dataSaving: false,
  paymentHistoryItem: {},
};
