import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ADMIN, CUSTOMER } from '../../../helper/constant-values';
import { userInformationProps } from '../common-props';

function MoveToDashboard(props) {
  const { userInfoState, children } = props;

  const { type: userType, customerUrl } = userInfoState;

  const [dashboardUrl, setDashboardUrl] = useState('');

  useEffect(() => {
    if (userType === ADMIN) {
      setDashboardUrl('/admin/customers');
    } else if (userType === CUSTOMER) {
      setDashboardUrl(`/${customerUrl}/customer/revenue-report`);
    } else {
      setDashboardUrl(`/${customerUrl}/dashboard`);
    }
  }, []);

  return <Link to={dashboardUrl}>{children}</Link>;
}

MoveToDashboard.propTypes = {
  userInfoState: userInformationProps.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.any),
  ]).isRequired,
};

MoveToDashboard.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MoveToDashboard);
