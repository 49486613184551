import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DEFAULT_DATE_FORMAT } from '../../../../../helper/constant-values';
import CustomDatePicker from './custom-datepicker';

export default function DateInput({ selectedDate, onDateChage }) {
  const [date, setDate] = useState(moment(selectedDate).toDate());

  const onDateSelect = (currentDate) => {
    onDateChage(moment(currentDate).format(DEFAULT_DATE_FORMAT));
  };

  useEffect(() => {
    setDate(moment(selectedDate).toDate());
  }, [selectedDate]);

  return (
    <DatePicker
      selected={date}
      onChange={onDateSelect}
      customInput={<CustomDatePicker />}
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
    />
  );
}

DateInput.propTypes = {
  onDateChage: PropTypes.func.isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
};

DateInput.defaultProps = {};
