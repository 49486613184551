import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import commonMethods from '../../../../helper/common-methods';
import { getPackagesForConsumer } from '../../../../redux/actions/package';
import MoveToDashboard from '../../../common/navbar/move-to-dashboard';
import { userInformationProps } from '../../../profile/profile-common-props';
import '../consumer-package-style.css';
import PackagePurchase from './package-purchase';

function PackagePurchaseContainer(props) {
  const { userInfoState, packagesForConsumer } = props;

  const history = useHistory();
  const { customerUrl } = useParams();
  const location = useLocation();
  const [showPaymentContent, setShowPaymentContent] = useState(false);

  const { id: consumerId } = userInfoState;

  const onBackButtonClick = () => {
    history.push(`/${customerUrl}/packages`);
  };

  useEffect(() => {
    if (location.state === undefined) onBackButtonClick();
    else {
      packagesForConsumer({ customerUrl, consumerId }).then(() => {
        setShowPaymentContent(true);
      });
    }
  }, []);

  return (
    <Container fluid>
      <Row className="border-bottom py-3">
        <Col xs={2}>
          <Button
            variant="link"
            className="cursor-pointer button-shadow-none text-dark pl-0"
            onClick={onBackButtonClick}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />{' '}
            <span className="d-none d-sm-inline">Back</span>
          </Button>
        </Col>
        <Col xs={8} className="d-flex justify-content-center">
          <MoveToDashboard>
            <img
              className="heading-logo"
              src={commonMethods.getImageApi(userInfoState.customerLogo)}
              alt="logo"
            />
          </MoveToDashboard>
        </Col>
      </Row>
      <Row>
        {showPaymentContent && (
          <PackagePurchase
            packageId={location.state.packageId}
            onBackButtonClick={onBackButtonClick}
          />
        )}
      </Row>
    </Container>
  );
}

PackagePurchaseContainer.propTypes = {
  packagesForConsumer: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
};

PackagePurchaseContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  packagesForConsumer: getPackagesForConsumer,
};

export default connect(mapStateToProps, mapDispatchToProps)(PackagePurchaseContainer);
