import { callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const supportTicketService = {
  getSupportTicketsForConsumer: async ({
    customerUrl,
    consumerId,
    page = 1,
    itemPerPage = 100,
    noPagination = false,
    searchText = '',
  }) => {
    const { method, endpoint } = endpoints.getSupportTicketsForConsumer;
    const url = utils.buildUrl(
      baseUrl + endpoint,
      { customerUrl, consumerId },
      { page, itemPerPage, noPagination, searchText }
    );

    const resopnse = await callAxiosWithAuth({ method, url });
    return resopnse;
  },

  create: async (createData, { customerUrl, consumerId }) => {
    const { method, endpoint } = endpoints.createSupportTicket;
    const url = utils.buildUrl(baseUrl + endpoint, { customerUrl, consumerId });

    const resopnse = await callAxiosWithAuth({ method, url, data: createData });
    return resopnse;
  },
};

export default supportTicketService;
