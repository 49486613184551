import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useDebounce } from 'react-use';
import commonMethods from '../../../helper/common-methods';
import {
  getConsumerDetailsForAdmin,
  getConsumerListForAdmin,
  updateConsumerByAdmin,
  // eslint-disable-next-line prettier/prettier
  updateConsumerTokenByAdmin
} from '../../../redux/actions/consumer-actions';
import { browserViewProps } from '../../common/common-props';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import MenuBarWrapper from '../../common/menu-bar-wrapper/menu-bar-wrapper';
import CustomPagination from '../../common/pagination/custom-pagination';
import { consumerListProps } from './consumer-common-props';
import './consumer-container.css';
import ConsumerDetailsModal from './consumer-details/consumer-details-modal';
import ConsumerEditModal from './consumer-edit/consumer-edit-modal';
import ConsumerListInfo from './consumer-list/consumer-info';
import ConsumerListTitle from './consumer-list/consumer-list-title';

function ConsumerContainer(props) {
  const {
    consumerList,
    browserState,
    updateConsumerProfileByAdmin,
    getConsumerDetailsByAdmin,
    getConsumerList,
    updateConsumerToken,
  } = props;

  const [searchText, setSearchText] = useState('');
  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [dataLoading, setDataLoading] = useState(false);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);
  const [showConsumerDetailsViewModal, setShowConsumerDetailsViewModal] = useState(false);
  const [consumerDetailsDataLoading, setConsumerDetailsDataLoading] = useState(false);
  const [showConsumerTokenEditModal, setShowConsumerTokenEditModal] = useState(false);
  const [selectedConsumerItem, setSelectedConsumerItem] = useState({
    url: '',
    id: 0,
    email: '',
    fullName: '',
    username: '',
    tempEmail: '',
  });

  const onTokenEditModalSubmit = ({
    numberOfTokens = 0,
    addToken = false,
    subtractToken = false,
  }) => {
    setDataLoading(true);

    const params = {
      consumerId: selectedConsumerItem.id,
      customerUrl: selectedConsumerItem.url,
    };

    const data = {
      numberOfTokens,
      addToken,
      subtractToken,
    };

    return updateConsumerToken(data, params)
      .then(() => {
        setDataLoading(false);
        setShowConsumerTokenEditModal(false);
      })
      .catch(() => {
        setDataLoading(false);
        setShowConsumerTokenEditModal(false);
      });
  };

  const onProfileEditModalSubmit = ({ email = '', smsPhoneNumber = '' }) => {
    setDataLoading(true);

    const params = {
      consumerId: selectedConsumerItem.id,
      customerUrl: selectedConsumerItem.url,
    };

    const data = {
      email,
      smsPhoneNumber,
    };

    return updateConsumerProfileByAdmin(data, params)
      .then(() => {
        setDataLoading(false);
        setShowConsumerTokenEditModal(false);
      })
      .catch((error) => {
        setDataLoading(false);

        return Promise.reject(error);
      });
  };

  const getConsumerDetails = (selectedItem) => {
    setConsumerDetailsDataLoading(true);
    setSelectedConsumerItem(selectedItem);

    const params = {
      consumerId: selectedItem.id,
      customerUrl: selectedItem.url,
    };

    getConsumerDetailsByAdmin(params)
      .then((data) => {
        setSelectedConsumerItem({
          ..._.omit(selectedItem, ['consumerDetails']),
          consumerDetails: data,
        });
        setConsumerDetailsDataLoading(false);
      })
      .catch(() => {
        setConsumerDetailsDataLoading(false);
      });
  };

  const onViewConsumer = (selectedItem) => {
    setShowConsumerDetailsViewModal(true);
    getConsumerDetails(selectedItem);
  };

  const onEditConsumer = (selectedItem) => {
    setShowConsumerTokenEditModal(true);
    getConsumerDetails(selectedItem);
  };

  const getPaginationQuery = ({
    pageChanged = false,
    paramsData = {},
    customMethod = null,
    queryData = {},
    payload = null,
  }) => {
    const params = { ...paramsData };
    const query = {
      page,
      itemPerPage,
      searchText,
      ...queryData,
    };

    return {
      isMobileView: browserState.isMobile,
      params,
      query,
      page,
      payload,
      itemPerPage,
      getMethod: customMethod || getConsumerList,
      pageChanged,
      data: consumerList.data,
    };
  };

  const onLoadMoreClick = () => {
    setDataLoading(true);
    if (consumerList.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    commonMethods.updatePaginationData(getPaginationQuery({ pageChanged: true }));
  }, [page]);

  useDebounce(
    () => {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    },
    300,
    [itemPerPage, mobileLastPageLoaded, searchText]
  );

  useEffect(() => {
    if (!browserState.isMobile) {
      commonMethods.updatePaginationData(getPaginationQuery({}));
    }
  }, [browserState.isMobile]);

  useEffect(() => {
    setDataLoading(false);
  }, [consumerList]);

  return (
    <MenuBarWrapper navBarIndex={1} headerTitle="Consumers">
      <ConsumerDetailsModal
        showModal={showConsumerDetailsViewModal}
        consumerItem={selectedConsumerItem}
        dataLoading={consumerDetailsDataLoading}
        onCloseModal={() => {
          setShowConsumerDetailsViewModal(false);
        }}
      />

      <ConsumerEditModal
        showModal={showConsumerTokenEditModal}
        consumerItem={selectedConsumerItem}
        dataLoading={consumerDetailsDataLoading}
        dataSaving={dataLoading}
        onTokenEditModalSubmit={onTokenEditModalSubmit}
        onProfileEditModalSubmit={onProfileEditModalSubmit}
        onCloseModal={() => {
          setShowConsumerTokenEditModal(false);
        }}
      />

      <Container className="container-uxl pb-3 px-1">
        <Row>
          <Col xs={12} sm={6} lg={8} className="mb-2">
            <h4 className="pt-2 pl-1">All Consumers</h4>
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Search Consumers"
              value={searchText}
              size="lg"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container className="container-uxl consumer-list-for-admin px-sm-0">
        <ConsumerListTitle />
        {consumerList.data.map((item) => (
          <ConsumerListInfo
            key={item.id}
            consumerItem={item}
            onEditConsumer={onEditConsumer}
            onViewConsumer={onViewConsumer}
          />
        ))}

        {consumerList.totalItems > 10 && (
          <Col className="px-0 d-none d-md-block border-top bg-white">
            <CustomPagination
              itemPerPage={itemPerPage}
              page={page}
              setPage={setPage}
              setItemPerPage={setItemPerPage}
              totalPages={consumerList.totalPages}
              totalItems={consumerList.totalItems}
            />
          </Col>
        )}

        {consumerList.totalItems > consumerList.data.length && (
          <Col
            sm={11}
            className="bg-light justify-content-center justify-content-lg-start pt-4 d-sm-none"
          >
            <Button
              size="lg"
              variant="secondary"
              disabled={dataLoading}
              onClick={onLoadMoreClick}
              block
            >
              <DataSavingSpinner
                savingText="Loding"
                defaultText="Load More"
                dataSaving={dataLoading}
              />
            </Button>
          </Col>
        )}
      </Container>
    </MenuBarWrapper>
  );
}

ConsumerContainer.propTypes = {
  consumerList: consumerListProps.isRequired,
  browserState: browserViewProps.isRequired,
  getConsumerList: PropTypes.func.isRequired,
  getConsumerDetailsByAdmin: PropTypes.func.isRequired,
  updateConsumerToken: PropTypes.func.isRequired,
  updateConsumerProfileByAdmin: PropTypes.func.isRequired,
};

ConsumerContainer.defaultProps = {};

const mapStateToProps = (state) => ({
  consumerList: state.consumers.consumerListForAdmin,
  browserState: state.browserData.view,
});

const mapDispatchToProps = {
  getConsumerList: getConsumerListForAdmin,
  getConsumerDetailsByAdmin: getConsumerDetailsForAdmin,
  updateConsumerToken: updateConsumerTokenByAdmin,
  updateConsumerProfileByAdmin: updateConsumerByAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerContainer);
