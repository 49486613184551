import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { Col, Container, Figure, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import defaultPic from '../../../../../assets/image/gray.jpg';
import config from '../../../../../config/default';
import commonMethods from '../../../../../helper/common-methods';
import { ICON_SIZE } from '../../../../../helper/constant-values';
import useModalAndToolTip from '../../../../../helper/hooks/useModalAndToolTip';
import { CameraIcon, CloseCircleIcon } from '../../../../../helper/icons/common-icons';
import { singlePackageProps } from '../../customer-common-props';
import CustomerPackageForm from './customer-package-form';

function CustomerPackageModifyModal(props) {
  const {
    showModal,
    onCloseModal,
    headerTitle,
    packageItem,
    onSubmit,
    isAddCustomerPackage,
    customerId,
  } = props;

  const [packageLogoFile, setPackageLogoFile] = useState(null);
  const [packageLogoUrl, setPackageLogoUrl] = useState('');
  const [packageLogoError, setPackageLogoError] = useState(
    isAddCustomerPackage ? '* Logo is required.' : ''
  );

  const packageLogoRef = useRef(null);

  useModalAndToolTip(showModal);

  const maximumImageSize = config.files.maximumImageUploadSize;

  const onCameraIconClick = () => {
    packageLogoRef.current.click();
  };

  const onPackageLogoUpload = async (event) => {
    const imageFile = event.target.files[0];

    if (!imageFile) return;

    if (!commonMethods.isImageFile(imageFile.name)) {
      setPackageLogoError('* Selected file format is not supported.');
    } else if (imageFile.size > maximumImageSize) {
      setPackageLogoError('* Maximum 4MB image file is allowed.');
      setPackageLogoFile(imageFile);
    } else {
      setPackageLogoError('');
      const file = await commonMethods.imageResize(imageFile, 200, 100);
      setPackageLogoFile(file);
    }

    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setPackageLogoUrl(reader.result);
      }
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={onCloseModal}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        animation={false}
        className="service-modal-dialog"
        centered
        scrollable
      >
        <Modal.Header>
          <Col xs={10} className="text-truncate">
            <span className="h5">{headerTitle}</span>
          </Col>
          <Col xs={2} className="mt-2 mt-sm-0 text-right">
            <CloseCircleIcon
              className="text-muted cursor-pointer"
              width={ICON_SIZE}
              height={ICON_SIZE}
              onClick={onCloseModal}
            />
          </Col>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row className="rounded-corner bg-white">
              <Col>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <div>
                      <Figure className="package-modify-image-wrapper mb-0">
                        <Figure.Image
                          src={
                            packageLogoUrl ||
                            commonMethods.getImageApi(packageItem.imagePath) ||
                            defaultPic
                          }
                          fluid={false}
                          className="bg-light package-modify-image d-flex justify-content-center"
                        />
                      </Figure>

                      <CameraIcon
                        width="2.8em"
                        height="2.8em"
                        className="upload-logo-customer cursor-pointer"
                        onClick={onCameraIconClick}
                      />

                      <input
                        className="d-none"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={onPackageLogoUpload}
                        ref={packageLogoRef}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col sm={8} className="text-center">
                    <span className="text-info">
                      <small>* Supported file formats: .jpg, .jpeg, .png</small>
                    </span>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col sm={8} className="text-center">
                    <span className="text-info">
                      <small> * Recommended width & height ratio of image file: 2:1</small>
                    </span>
                  </Col>
                </Row>

                <Row className="justify-content-center pb-3">
                  <Col sm={8} className="text-center">
                    <span className="text-primary">{packageLogoError}</span>
                    {!packageLogoError && <br />}
                  </Col>
                </Row>
              </Col>
            </Row>

            <CustomerPackageForm
              onPackageSubmit={onSubmit}
              packageItem={packageItem}
              isAddForm={isAddCustomerPackage}
              packageLogo={packageLogoFile}
              onCloseModal={onCloseModal}
              packageLogoError={packageLogoError}
              customerId={customerId}
            />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

CustomerPackageModifyModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  packageItem: singlePackageProps,
  onSubmit: PropTypes.func.isRequired,
  isAddCustomerPackage: PropTypes.bool,
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

CustomerPackageModifyModal.defaultProps = {
  packageItem: {
    id: 0,
    name: '',
    price: 0,
    numberOfTokens: 0,
    imagePath: '',
    isAutoRenewable: false,
    autoRenewPrice: 0,
  },
  isAddCustomerPackage: false,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPackageModifyModal);
