/* eslint-disable prettier/prettier */
import { INNER_WIDH_CHANGE } from "../types";

const initialState = {
  view: {
    isMobile: false,
    isTab: false,
    isLaptop: false,
    isDesktop: false,
    isExtraLargeScreen: false,
  },
};

const browserDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case INNER_WIDH_CHANGE:
      return {
        ...state,
        view: action.data,
      };

    default:
      return state;
  }
};

export default browserDataReducer;
