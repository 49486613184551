import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import routes from '../../../helper/route-details';
import MenuBarWrapper from '../../common/menu-bar-wrapper/menu-bar-wrapper';
import './customer-container.css';
import CustomerListContainer from './customer-list/customer-list-container';
import AddCustomerContainer from './modify-customer/add-customer-container';
import UpdateCustomerContainer from './modify-customer/update-customer-container';
import ViewCustomerInfo from './view-customer/view-customer-container';

export default function CustomerContainer() {
  const [viewCustomerList, setViewCustomerList] = useState(true);
  const [addCustomerDetailsView, setAddCustomerDetailsView] = useState(false);
  const [updateCustomerDetailsView, setUpdateCustomerDetailsView] = useState(false);
  const [customerDetailsView, setCustomerDetailsView] = useState(false);

  const location = useLocation();
  const { customerId } = useParams();

  const gotoAddCustomerPage = () => {
    setAddCustomerDetailsView(true);
    setViewCustomerList(false);
    setCustomerDetailsView(false);
    setUpdateCustomerDetailsView(false);
  };

  const gotoUpdateCustomerPage = () => {
    setUpdateCustomerDetailsView(true);
    setAddCustomerDetailsView(false);
    setViewCustomerList(false);
    setCustomerDetailsView(false);
  };

  const gotoListViewCustomerPage = () => {
    setAddCustomerDetailsView(false);
    setViewCustomerList(true);
    setCustomerDetailsView(false);
    setUpdateCustomerDetailsView(false);
  };

  const gotoCustomerDetailsViewPage = () => {
    setAddCustomerDetailsView(false);
    setViewCustomerList(false);
    setCustomerDetailsView(true);
    setUpdateCustomerDetailsView(false);
  };

  useEffect(() => {
    if (location.pathname === routes.admin.customers.add()) {
      gotoAddCustomerPage();
    } else if (location.pathname === routes.admin.customers.view(customerId)) {
      gotoCustomerDetailsViewPage();
    } else if (location.pathname === routes.admin.customers.update(customerId)) {
      gotoUpdateCustomerPage();
    } else {
      gotoListViewCustomerPage();
    }
  }, [location.pathname]);

  return (
    <MenuBarWrapper navBarIndex={0} headerTitle="Customers">
      {viewCustomerList && <CustomerListContainer />}
      {addCustomerDetailsView && <AddCustomerContainer />}
      {updateCustomerDetailsView && <UpdateCustomerContainer />}

      {customerDetailsView && <ViewCustomerInfo />}
    </MenuBarWrapper>
  );
}

CustomerContainer.propTypes = {};

CustomerContainer.defaultProps = {};
