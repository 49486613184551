import PropTypes from 'prop-types';
import { useState } from 'react';
import { Accordion, Col } from 'react-bootstrap';
import FaqRow from './faq-row';

export default function Faq(props) {
  const { faqList } = props;

  const [clickedId, setClickedId] = useState('0');

  const onToggleFaqRow = (id) => {
    if (id === clickedId) {
      setClickedId('0');
    } else {
      setClickedId(id);
    }
  };

  return (
    <Col xs={12} className="pt-2 pb-4">
      <Accordion>
        {faqList.map((item) => (
          <FaqRow
            faqItem={item}
            eventKey={item.id}
            key={item.id}
            onToggle={onToggleFaqRow}
            clickedIndex={clickedId}
          />
        ))}
      </Accordion>
      {faqList.length === 0 && (
        <p className="bg-white d-flex justify-content-center mt-5">
          No FAQs match the searched term
        </p>
      )}
    </Col>
  );
}

Faq.propTypes = {
  faqList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
    })
  ).isRequired,
};

Faq.defaultProps = {};
