import commonMethods from '../../helper/common-methods';
import { showNotification } from '../../helper/notifications';
import packageService from '../../services/package';
import {
  GET_PACKAGES_FOR_CONSUMER,
  GET_PACKAGES_FOR_CUSTOMER,
  GET_PACKAGE_LIST_FOR_ADMIN,
  PACKAGE_ADD_BY_ADMIN,
  PACKAGE_DELETE_BY_ADMIN,
  PACKAGE_PAYMENT,
  PACKAGE_UPDATE_BY_ADMIN,
  // eslint-disable-next-line prettier/prettier
  TOGGLE_AUTO_PAYMENT_BUTTON
} from '../types';

export function getPackagesForConsumer(query) {
  return (dispatch) =>
    packageService
      .getConsumerPackages(query)
      .then((response) => {
        dispatch({
          type: GET_PACKAGES_FOR_CONSUMER,
          data: response.data,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error while getting packages',
        });
      });
}

export function toggleAutoPaymentButton(packageData, query) {
  return async (dispatch) =>
    packageService
      .toggleAutoPaymentButton(packageData, query)
      .then((response) => {
        dispatch({
          type: TOGGLE_AUTO_PAYMENT_BUTTON,
          data: response.data,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error while updating auto payment.',
        });

        return Promise.reject();
      });
}

export function packagePurchaseByStripe(paymentData, query) {
  return (dispatch) =>
    packageService
      .packagePurchaseByStripe(paymentData, query)
      .then((response) => {
        dispatch({
          type: PACKAGE_PAYMENT,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Payment is successful',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function packageCreateOrderByPaypal(paymentData, query) {
  return () =>
    packageService
      .packageCreateOrderByPaypal(paymentData, query)
      .then((response) => response.data)
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function packageCheckoutOrderByPaypal(paymentData, query) {
  return () =>
    packageService
      .packageCheckoutOrderByPaypal(paymentData, query)
      .then((response) => response.data)
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function packagePurchaseWithStripeDefaultPayment(paymentData, query) {
  return (dispatch) =>
    packageService
      .packagePurchaseWithStripeDefaultPayment(paymentData, query)
      .then((response) => {
        dispatch({
          type: PACKAGE_PAYMENT,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Payment is successful',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function packagePurchaseWithPaypalDefaultPayment(paymentData, query) {
  return (dispatch) =>
    packageService
      .packagePurchaseWithPaypalDefaultPayment(paymentData, query)
      .then((response) => {
        dispatch({
          type: PACKAGE_PAYMENT,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Payment is successful',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function getPackagesForCustomer(params, query, actionData) {
  return (dispatch) => {
    packageService
      .getCustomerPackages(params, query)
      .then((response) => {
        dispatch({
          type: GET_PACKAGES_FOR_CUSTOMER,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error while getting packages',
        });
      });
  };
}

export function getPackageListForAdmin(params, query, actionData) {
  return (dispatch) =>
    packageService
      .getPackageListForAdmin(query)
      .then((response) => {
        dispatch({
          type: GET_PACKAGE_LIST_FOR_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });

        return response.data;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function addPackageByAdmin(data, params, query, actionData) {
  return (dispatch) =>
    packageService
      .addPackageByAdmin(data, query)
      .then((response) => {
        dispatch({
          type: PACKAGE_ADD_BY_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });

        showNotification({
          type: 'success',
          text: 'Package added successfully.',
        });

        return response.data;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function updatePackageByAdmin(data, params) {
  return (dispatch) =>
    packageService
      .updatePackageByAdmin(data, params)
      .then((response) => {
        dispatch({
          type: PACKAGE_UPDATE_BY_ADMIN,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Package updated successfully.',
        });

        return response.data;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function deletePackageByAdmin(params, query, actionData) {
  return (dispatch) =>
    packageService
      .deletePackageByAdmin(params, query)
      .then((response) => {
        dispatch({
          type: PACKAGE_DELETE_BY_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });

        showNotification({
          type: 'success',
          text: 'Package deleted successfully.',
        });

        return response.data;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}
