/* eslint-disable import/prefer-default-export */
import commonMethods from '../../helper/common-methods';
import { showNotification } from '../../helper/notifications';
import audioService from '../../services/audio';
import {
  ADD_CONSUMER_PLAYED_AUDIO,
  AUDIO_COPY_BY_CUSTOMER,
  AUDIO_DELETE_BY_CUSTOMER,
  AUDIO_FOR_CONSUMER,
  AUDIO_FOR_CUSTOMER,
  AUDIO_UPDATE_BY_CUSTOMER,
  INIT_CONSUMER_PLAYED_AUDIO,
  // eslint-disable-next-line prettier/prettier
  REMOVE_CONSUMER_PLAYED_AUDIO, UPDATE_TOKEN_BALANCE
} from '../types';

export function getAudiosForConsumer(params, query, actionData) {
  return (dispatch) =>
    audioService
      .getAudiosForConsumer(params, query)
      .then((response) => {
        dispatch({
          type: AUDIO_FOR_CONSUMER,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function chargeAudioForConsumer(params, query) {
  return (dispatch) =>
    audioService
      .chargeAudioForConsumer(params, query)
      .then((response) => {
        dispatch({
          type: UPDATE_TOKEN_BALANCE,
          data: response.data,
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function addConsumerPlayedAudio(audioId) {
  return (dispatch) => {
    dispatch({
      type: ADD_CONSUMER_PLAYED_AUDIO,
      data: audioId,
    });
  };
}

export function removeConsumerPlayedAudio(audioId) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_CONSUMER_PLAYED_AUDIO,
      data: audioId,
    });
  };
}

export function clearConsumerPlayedAudio() {
  return (dispatch) => {
    dispatch({
      type: INIT_CONSUMER_PLAYED_AUDIO,
    });
  };
}

export function getAudiosForCustomer(params, query, actionData) {
  return (dispatch) =>
    audioService
      .getAudiosForCustomer(params, query)
      .then((response) => {
        dispatch({
          type: AUDIO_FOR_CUSTOMER,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function deleteAudioByCustomer(params, query, actionData) {
  return (dispatch) =>
    audioService
      .deleteAudioByCustomer(params, query)
      .then((response) => {
        if (actionData) {
          dispatch({
            type: AUDIO_DELETE_BY_CUSTOMER,
            data: response.data,
            noPagination: actionData.noPagination,
            isMobile: actionData.isMobile,
          });
        }

        showNotification({
          type: 'success',
          text: 'Audio deleted successfully.',
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function updateAudioByCustomer(params, query) {
  return (dispatch) =>
    audioService
      .updateAudioByCustomer(params, query)
      .then((response) => {
        dispatch({
          type: AUDIO_UPDATE_BY_CUSTOMER,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Audio updated successfully.',
        });

        return response;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function copyAudioByCustomer(data, params, query, actionData) {
  return (dispatch) =>
    audioService
      .copyAudioByCustomer(data, params, query)
      .then((response) => {
        if (actionData) {
          dispatch({
            type: AUDIO_COPY_BY_CUSTOMER,
            data: response.data,
            noPagination: actionData.noPagination,
            isMobile: actionData.isMobile,
          });
        }

        showNotification({
          type: 'success',
          text: 'Audio copied successfully.',
        });

        return response;
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function addAudioByCustomer(data, params, onUploadProgress) {
  return () =>
    audioService
      .addAudioByCustomer(data, params, onUploadProgress)
      .catch(commonMethods.actionDefaultErrorResponse);
}
