import PropTypes from 'prop-types';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import commonMethods from '../../../../helper/common-methods';
import routes from '../../../../helper/route-details';
import { bulkShortTextUploadByAdmin } from '../../../../redux/actions/short-text-action';
import { singleCustomerProps } from '../customer-common-props';
import ShortTextUploadModal from '../short-text-upload/short-text-upload-modal';
import ActionButtons from './action-buttons';

function CustomerListInfo(props) {
  const { customerItem, bulkShortTextUpload } = props;

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [shortTextUploadProcessing, setShortTextUploadProcessing] = useState(false);
  const [showShortTextUploadModal, setShowShortTextUploadModal] = useState(false);
  const [selectedShortTextFile, setSelectedShortTextFile] = useState(null);

  const history = useHistory();

  const onShortTextUpload = () => {
    setShowShortTextUploadModal(true);
  };

  const onShortTextUploadModalClose = () => {
    if (!shortTextUploadProcessing) setShowShortTextUploadModal(false);
  };

  const onViewCustomerDetails = () => {
    history.push(routes.admin.customers.view(customerItem.id));
  };

  const onShortTextSubmit = () => {
    setShortTextUploadProcessing(true);
    onShortTextUploadModalClose();

    const formData = commonMethods.getFormData({}, selectedShortTextFile, 'text');
    const params = { customerId: customerItem.id };

    const onUploadProgress = (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percentage = Math.floor((loaded * 100) / total);
      if (percentage === 100) percentage = 99;

      setUploadPercentage(percentage);
    };

    return bulkShortTextUpload(formData, params, onUploadProgress)
      .then(() => {
        setShortTextUploadProcessing(false);
      })
      .catch(() => {
        setShortTextUploadProcessing(false);
      });
  };

  return (
    <>
      <ShortTextUploadModal
        showModal={showShortTextUploadModal}
        setSelectedTextFile={setSelectedShortTextFile}
        onSubmit={onShortTextSubmit}
        onCloseModal={onShortTextUploadModalClose}
      />

      <Row className="mx-sm-2 py-2 py-sm-3 mb-4 mb-lg-0 bg-white rounded-corner">
        <Col xs={9}>
          <Row>
            <Col md={6}>
              <span className="font-weight-bold d-lg-none">Company Name:</span>{' '}
              {customerItem.companyName}
            </Col>
            <Col md={6}>
              <span className="font-weight-bold d-lg-none">Customer Url:</span> {customerItem.url}
            </Col>
          </Row>
        </Col>

        <Col xs={3} className="d-flex justify-content-end">
          <Row>
            <ActionButtons
              onHandleSmsUpload={() => {
                onShortTextUpload(
                  customerItem.id,
                  setShortTextUploadProcessing,
                  setUploadPercentage
                );
              }}
              uploadProcessing={shortTextUploadProcessing}
              uploadPercentage={uploadPercentage}
              onHandleView={onViewCustomerDetails}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

CustomerListInfo.propTypes = {
  customerItem: singleCustomerProps.isRequired,
  bulkShortTextUpload: PropTypes.func.isRequired
};

CustomerListInfo.defaultProps = {};

const mapStateToProps = (state) => ({
  customerList: state.customers.customerListForAdmin,
  browserState: state.browserData.view,
});

const mapDispatchToProps = {
  bulkShortTextUpload: bulkShortTextUploadByAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListInfo);
