import PropTypes from 'prop-types';
import { Button, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EditIcon, EyeIcon } from '../../../../helper/icons/common-icons';

export default function ActionButtons(props) {
  const { onHandleEdit, onHandleView } = props;

  return (
    <>
      <Col md={6} className="px-0">
        <OverlayTrigger
          transition={false}
          rootClose
          placement="bottom-start"
          overlay={<Tooltip>Edit</Tooltip>}
        >
          <Button
            tabIndex={0}
            className="border rounded-circle mr-2 py-2"
            variant="outline-secondary"
            size="sm"
            onClick={onHandleEdit}
          >
            <EditIcon width="1.2rem" height="1.2rem" />
          </Button>
        </OverlayTrigger>
      </Col>

      <Col md={6} className="px-0">
        <OverlayTrigger
          transition={false}
          rootClose
          placement="bottom-start"
          overlay={<Tooltip>View</Tooltip>}
        >
          <Button
            tabIndex={-1}
            className="border rounded-circle mr-2 py-2"
            variant="outline-secondary"
            size="sm"
            onClick={onHandleView}
          >
            <EyeIcon width="1.2rem" height="1.2rem" />
          </Button>
        </OverlayTrigger>
      </Col>
    </>
  );
}

ActionButtons.propTypes = {
  onHandleEdit: PropTypes.func.isRequired,
  onHandleView: PropTypes.func.isRequired,
};

ActionButtons.defaultProps = {};
