import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CopyIcon, DeleteIcon, EditIcon } from '../../../../helper/icons/common-icons';

const ButtonMenu = React.forwardRef(
  (
    {
      className,
      'aria-labelledby': labeledBy,
      onHandleEdit,
      onHandleCopy,
      onHandleDelete,
      onToggleDropDown,
    },
    ref
  ) => {
    const handleEdit = () => {
      onHandleEdit();
      onToggleDropDown();
    };

    const handleCopy = () => {
      onHandleCopy();
      onToggleDropDown();
    };

    const handleDelete = () => {
      onHandleDelete();
      onToggleDropDown();
    };

    return (
      <div ref={ref} className={className} aria-labelledby={labeledBy}>
        <Container>
          <Row>
            <Col xs={12} className="pt-2 d-flex" onClick={handleEdit}>
              <div
                role="button"
                tabIndex={0}
                id="edit"
                className="border action-buttons cursor-pointer ml-n1 mr-2 mt-n1"
              >
                <EditIcon width="1.2rem" height="1.2rem" />
              </div>
              <span>Edit</span>
            </Col>

            <Col>
              <hr />
            </Col>

            <Col xs={12} className="d-flex" onClick={handleCopy}>
              <div
                role="button"
                tabIndex={-1}
                id="copy"
                className="border action-buttons cursor-pointer ml-n1 mr-2 mt-n1"
              >
                <CopyIcon width="1.2rem" height="1.2rem" />
              </div>
              <span>Copy</span>
            </Col>

            <Col>
              <hr />
            </Col>

            <Col xs={12} className="pb-1 d-flex" onClick={handleDelete}>
              <div
                role="button"
                tabIndex={-2}
                id="delete"
                className="border action-buttons cursor-pointer ml-n1 mr-2 mt-n1"
              >
                <DeleteIcon width="1.2rem" height="1.2rem" />
              </div>
              <span>Delete</span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
);

ButtonMenu.propTypes = {
  className: PropTypes.string.isRequired,
  'aria-labelledby': PropTypes.string.isRequired,
  onToggleDropDown: PropTypes.func.isRequired,
  onHandleEdit: PropTypes.func.isRequired,
  onHandleCopy: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired,
};

ButtonMenu.defaultProps = {};

export default ButtonMenu;
