import { Formik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { registrationValidationSchema } from '../../../helper/schema-objects';
import { consumerSignUp } from '../../../redux/actions/auth-action';
import FormComponent from '../../common/form-component/form-component';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import VerificationCodeConfirmModal from '../../common/modals/verification-code-send-confirmation-modal/verification-code-confirmation-modal';
import { consumerCategoryProps, consumerSignupInitProps } from '../login-registration-common-props';
import './consumer-registration.css';

function Registration(props) {
  const { customerUrl } = useParams();

  const [passwordInputType, setPasswordInputType] = useState('password');
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState('password');
  const [registerContinue, setRegisterContinue] = useState(false);
  const [showVerificationConfirmModal, setShowVerificationConfirmModal] = useState(false);
  const [registerValues, setRegisterValues] = useState({});
  const [registerMethods, setRegisterMethods] = useState({});

  const {
    verificationAfterSignUp,
    signupPreviousState,
    signUp,
    goToLoginPage,
    consumerCategory,
    backFromVerification,
  } = props;

  const query = {
    customerUrl,
  };

  let initialValues = {
    username: '',
    fullName: '',
    email: '',
    contactNumber: '',
    defaultCategoryId: '',
    password: '',
    confirmPassword: '',
  };

  if (backFromVerification) {
    initialValues = {
      ...initialValues,
      ...signupPreviousState,
    };
  }

  const onVerificationModalCancel = () => {
    setShowVerificationConfirmModal(false);
    setRegisterValues({});
    setRegisterMethods({});
  };

  const onVerificationModalProceed = () => {
    const signupData = {
      ...registerValues,
      username: registerValues.username.trim(),
      fullName: registerValues.fullName.trim(),
      defaultCategoryId: +registerValues.defaultCategoryId,
    };

    const signupCurrentValues = _.omit(registerValues, ['password', 'confirmPassword']);

    setRegisterContinue(true);

    signUp(signupData, query, signupCurrentValues)
      .then(() => {
        setRegisterContinue(false);
        verificationAfterSignUp();
      })
      .catch((error) => {
        setRegisterContinue(false);

        if (!error.response) {
          return;
        }

        const { isExistingUsername, message, isExistingEmail } = error.response.data;

        if (isExistingUsername) {
          registerMethods.setErrors({
            username: message,
          });
        } else if (isExistingEmail) {
          registerMethods.setErrors({
            email: message,
          });
        }

        onVerificationModalCancel();
      });
  };

  const onRegister = (values, formikFunctions) => {
    setRegisterValues(values);
    setRegisterMethods(formikFunctions);
    setShowVerificationConfirmModal(true);
  };

  const showHidePassword = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text');
    } else {
      setPasswordInputType('password');
    }
  };

  const showHideConfirmPassword = () => {
    if (confirmPasswordInputType === 'password') {
      setConfirmPasswordInputType('text');
    } else {
      setConfirmPasswordInputType('password');
    }
  };

  return (
    <>
      <VerificationCodeConfirmModal
        isEmailVerification
        isRegistrationPage
        verificationMedium={registerValues.email}
        showModal={showVerificationConfirmModal}
        onModalCancel={onVerificationModalCancel}
        onModalProceed={onVerificationModalProceed}
      />

      <div className="px-2 pl-lg-0 mb-3">
        <Formik
          initialValues={initialValues}
          validationSchema={registrationValidationSchema}
          onSubmit={(values, { setErrors }) => onRegister(values, { setErrors })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Row className="justify-content-center justify-content-lg-start">
                <FormComponent
                  type="text"
                  placeholder="Username"
                  name="username"
                  formik={formik}
                  autoFocusInput
                  showLabel
                />
              </Row>
              <Row className="justify-content-center justify-content-lg-start mt-2">
                <FormComponent
                  type="text"
                  placeholder="Name"
                  name="fullName"
                  formik={formik}
                  showLabel
                />
              </Row>
              <Row className="justify-content-center justify-content-lg-start mt-2">
                <FormComponent
                  type="text"
                  placeholder="Email"
                  name="email"
                  formik={formik}
                  showLabel
                />
              </Row>
              <Row className="justify-content-center justify-content-lg-start mt-2">
                <FormComponent
                  type="text"
                  placeholder="Mobile Phone Number"
                  name="contactNumber"
                  formik={formik}
                  showLabel
                />
              </Row>

              <Row className="justify-content-center justify-content-lg-start mt-2">
                <FormComponent
                  type="select"
                  name="defaultCategoryId"
                  formik={formik}
                  showLabel
                  placeholder="Astrological Sign"
                >
                  <option value="">Select Astrological Sign</option>
                  {consumerCategory.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </FormComponent>
              </Row>

              <Row className="justify-content-center justify-content-lg-start mt-2">
                <Col sm={11} lg={6}>
                  <Form.Label>Password</Form.Label>
                </Col>
              </Row>

              <Row className="justify-content-center justify-content-lg-start">
                <FormComponent
                  isPassword
                  type={passwordInputType}
                  placeholder="Password"
                  name="password"
                  iconToolTip="Show/hide password"
                  onIconClick={showHidePassword}
                  formik={formik}
                />
              </Row>

              <Row className="justify-content-center justify-content-lg-start mt-2">
                <Col sm={11} lg={6}>
                  <Form.Label>Confirm Password</Form.Label>
                </Col>
              </Row>

              <Row className="justify-content-center justify-content-lg-start">
                <FormComponent
                  isPassword
                  type={confirmPasswordInputType}
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  iconToolTip="show/hide confirm password"
                  onIconClick={showHideConfirmPassword}
                  formik={formik}
                />
              </Row>

              <Row className="justify-content-center justify-content-lg-start mt-2">
                <Col sm={11} lg={6}>
                  <Button
                    size="lg"
                    onClick={formik.handleSubmit}
                    type="submit"
                    block
                    disabled={
                      !!(
                        formik.errors.username ||
                        formik.errors.fullname ||
                        formik.errors.contactNumber ||
                        formik.errors.confirmPassword ||
                        formik.errors.password ||
                        formik.errors.email ||
                        formik.errors.defaultCategoryId ||
                        registerContinue
                      )
                    }
                  >
                    <DataSavingSpinner
                      savingText="Registering"
                      defaultText="Register"
                      dataSaving={registerContinue}
                    />
                  </Button>
                </Col>
              </Row>

              <Row className="pt-3 pb-1">
                <Col
                  sm={12}
                  lg={6}
                  className="d-flex justify-content-center justify-content-lg-start"
                >
                  Already have an account?&nbsp;
                  <span
                    aria-hidden="true"
                    className="font-weight-bold text-primary cursor-pointer"
                    onClick={goToLoginPage}
                  >
                    Login
                  </span>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

Registration.propTypes = {
  signUp: PropTypes.func.isRequired,
  consumerCategory: consumerCategoryProps.isRequired,
  verificationAfterSignUp: PropTypes.func.isRequired,
  goToLoginPage: PropTypes.func.isRequired,
  signupPreviousState: consumerSignupInitProps.isRequired,
  backFromVerification: PropTypes.bool,
};

Registration.defaultProps = {
  backFromVerification: false,
};

const mapStateToProps = (state) => ({
  signupPreviousState: state.auth.signupPrevious,
});

const mapDispatchToProps = {
  signUp: consumerSignUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
