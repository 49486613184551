import PropTypes from 'prop-types';

export const singlePaymentHistoryProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  packageId: PropTypes.number.isRequired,
  paymentIntentId: PropTypes.string,
  refundedAmount: PropTypes.number.isRequired,
  numberOfTokens: PropTypes.number.isRequired,
  refundId: PropTypes.string,
  captureId: PropTypes.string,
  paymentMethod: PropTypes.string,
  createdat: PropTypes.string,
});

export const paymentHistoryListProps = PropTypes.shape({
  totalItems: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(singlePaymentHistoryProps.isRequired).isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
});
