import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { forgotPasswordValidationSchema } from '../../../../helper/schema-objects';
import { forgotPassword } from '../../../../redux/actions/auth-action';
import FormComponent from '../../../common/form-component/form-component';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';

function AdminForgotPassword(props) {
  const {
    goToVerificationPage,
    userForgotPassword,
    backFromVerification,
    forgotPasswordPreviousState,
  } = props;

  const [continueButtonLoading, setContinueButtonLoading] = useState(false);

  const { customerUrl } = useParams();

  let initialValues = {
    emailOrUsername: '',
  };

  if (backFromVerification) {
    initialValues = {
      ...initialValues,
      ...forgotPasswordPreviousState,
    };
  }

  const userData = {};

  const onSubmit = (values, { setErrors }) => {
    userData.emailOrUsername = values.emailOrUsername.trim();
    userData.isAdmin = true;

    setContinueButtonLoading(true);

    userForgotPassword(userData, { customerUrl }, values)
      .then(() => {
        setContinueButtonLoading(false);
        goToVerificationPage();
      })
      .catch((error) => {
        setContinueButtonLoading(false);

        if (!error.response) {
          return;
        }

        const { invalidUser, message } = error.response.data;

        if (invalidUser) {
          setErrors({
            emailOrUsername: message,
          });
        }
      });
  };

  return (
    <>
      <div className="px-2 pl-lg-0">
        <Row className="justify-content-center pb-4">
          <Col sm={11} md={7} lg={12} className="text-center">
            <span className="h4">Please enter your email or username.</span>
          </Col>
        </Row>

        <Formik
          initialValues={initialValues}
          validationSchema={forgotPasswordValidationSchema}
          onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Row className="justify-content-center">
                <FormComponent
                  type="text"
                  placeholder="Email or Username"
                  name="emailOrUsername"
                  formik={formik}
                  inputColumnClass="col-lg-7 col-sm-11"
                  errorColumnClass="col-lg-7 col-sm-11"
                  autoFocusInput
                  showLabel
                />
              </Row>
              <Row className="justify-content-center mt-3 pt-lg-3">
                <Col sm={11} lg={7}>
                  <Button
                    size="lg"
                    variant="secondary"
                    block
                    onClick={formik.handleSubmit}
                    type="submit"
                    disabled={!!(formik.errors.emailOrUsername || continueButtonLoading)}
                  >
                    <DataSavingSpinner defaultText="Continue" dataSaving={continueButtonLoading} />
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

AdminForgotPassword.propTypes = {
  goToVerificationPage: PropTypes.func.isRequired,
  userForgotPassword: PropTypes.func.isRequired,
  backFromVerification: PropTypes.bool,
  forgotPasswordPreviousState: PropTypes.shape({
    emailOrUsername: PropTypes.string.isRequired,
  }).isRequired,
};

AdminForgotPassword.defaultProps = {
  backFromVerification: false,
};

const mapStateToProps = (state) => ({
  forgotPasswordPreviousState: state.auth.forgotPasswordPrevious,
});

const mapDispatchToProps = {
  userForgotPassword: forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminForgotPassword);
