import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import ButtonMenu from './mobile-action-buttons-menu';
import ButtonToggle from './mobile-action-buttons-toggle';

export default function ActionButtonsMobile(props) {
  const { onHandleEdit, onHandleCopy, onHandleDelete, disabled } = props;

  const [showCategoryDropDown, setShowCategoryDropDown] = useState(false);

  const onToggleDropDown = () => {
    if (disabled) return;

    setShowCategoryDropDown(!showCategoryDropDown);
  };

  return (
    <Dropdown show={showCategoryDropDown} onToggle={onToggleDropDown}>
      <Dropdown.Toggle as={ButtonToggle} id="dropdown-split-basic" />

      <Dropdown.Menu
        as={ButtonMenu}
        onToggleDropDown={onToggleDropDown}
        className="custom-menu"
        onHandleEdit={onHandleEdit}
        onHandleCopy={onHandleCopy}
        onHandleDelete={onHandleDelete}
      />
    </Dropdown>
  );
}

ActionButtonsMobile.propTypes = {
  onHandleEdit: PropTypes.func.isRequired,
  onHandleCopy: PropTypes.func.isRequired,
  onHandleDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ActionButtonsMobile.defaultProps = {
  disabled: false,
};
