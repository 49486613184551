import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { customerDetailsProps } from '../../customer-common-props';

function CustomerInfo(props) {
  const { customerDetailsSatate } = props;

  const customerDetailsTitles = [
    'Username:',
    'Name:',
    'Email:',
    'Contact Phone Number:',
    'Time Zone:',
    'Customer Url:',
    'Threshold Token:',
    'Company Name:',
    'Customer Details: ',
  ];

  const customerDetailsValues = [
    customerDetailsSatate.username,
    customerDetailsSatate.fullName,
    customerDetailsSatate.email,
    customerDetailsSatate.contactNumber,
    customerDetailsSatate.timeZone,
    customerDetailsSatate.url,
    customerDetailsSatate.thresholdToken,
    customerDetailsSatate.companyName,
    customerDetailsSatate.details,
  ];

  return (
    <>
      {customerDetailsTitles.map((title, index) => (
        <Row className="pb-2" key={title}>
          <Col xs={6} sm={4} lg={3}>
            <p className="text-dark font-weight-bold">{title}</p>
          </Col>
          <Col xs={6} sm={8} lg={9} className="d-flex justify-content-end justify-content-sm-start">
            <OverlayTrigger
              transition={false}
              placement="bottom"
              overlay={<Tooltip>{customerDetailsValues[index]}</Tooltip>}
            >
              <span className="flex-child text-info text-truncate">
                {customerDetailsValues[index]}
              </span>
            </OverlayTrigger>
          </Col>
        </Row>
      ))}
    </>
  );
}

CustomerInfo.propTypes = {
  customerDetailsSatate: customerDetailsProps.isRequired,
};

CustomerInfo.defaultProps = {};

const mapStateToProps = (state) => ({
  customerDetailsSatate: state.customers.customerDetailsForAdmin,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfo);
