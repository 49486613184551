import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import { consumerDetailsProps } from '../consumer-common-props';

export default function TokenEdit(props) {
  const { consumerDetails, dataSaving, onCloseModal, onSubmitModal } = props;

  const [addTokenChecked, setAddTokenChecked] = useState(true);
  const [subtractTokenChecked, setSubtractTokenChecked] = useState(false);
  const [addTokenValue, setAddTokenValue] = useState(0);
  const [subtractTokenValue, setSubtractTokenValue] = useState(0);
  const [addTokenError, setAddTokenError] = useState('');
  const [subtractTokenError, setSubtractTokenError] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {};

    if (addTokenChecked && !addTokenError) {
      data.addToken = true;
      data.numberOfTokens = addTokenValue;
    } else if (subtractTokenChecked && !subtractTokenError) {
      data.subtractToken = true;
      data.numberOfTokens = subtractTokenValue;
    }

    onSubmitModal(data);
  };

  const onAddTokenRadioClick = () => {
    setSubtractTokenChecked(false);
    setAddTokenChecked(true);
    setSubtractTokenValue(0);
    setSubtractTokenError('');
    setAddTokenError('');
  };

  const onSubtractTokenRadioClick = () => {
    setSubtractTokenChecked(true);
    setAddTokenChecked(false);
    setAddTokenValue(0);
    setSubtractTokenError('');
    setAddTokenError('');
  };

  const onChangeSubtractTokenValue = (e) => {
    const re = /^[0-9\b]+$/;
    const { value } = e.target;

    if (value === '' || re.test(value)) {
      if (value !== '' && +value > +consumerDetails.tokenBalance) {
        setSubtractTokenError('* Substract token value can not be greater than current balance.');
      } else if (value === '') {
        setSubtractTokenError('* Substract token value can not be empty.');
      } else {
        setSubtractTokenError('');
      }

      setSubtractTokenValue(value ? +value : '');
    }
  };

  const onChangeAddTokenValue = (e) => {
    const re = /^[0-9\b]+$/;
    const { value } = e.target;

    if (value === '' || re.test(value)) {
      if (value === '') {
        setAddTokenError('* Add token value can not be empty.');
      } else {
        setAddTokenError('');
      }

      setAddTokenValue(value ? +value : '');
    }
  };

  return (
    <>
      {consumerDetails && (
        <Form onSubmit={onSubmit}>
          <Row className="text-dark">
            <Col xs={12} className="pb-3 font-weight-bold">
              Current Token Balance: {consumerDetails.tokenBalance}
            </Col>

            <Col xs={12} className="ml-3">
              <Form.Check.Input
                type="radio"
                checked={addTokenChecked}
                onChange={onAddTokenRadioClick}
                className="token-ckeck-input"
              />
              <Form.Check.Label className="ml-2">Add Tokens</Form.Check.Label>
              <Form.Control
                type="text"
                className="mt-2"
                placeholder="Number Of Tokens"
                value={addTokenValue}
                disabled={subtractTokenChecked}
                onChange={onChangeAddTokenValue}
                maxLength={7}
              />
            </Col>

            <Col xs={12} className="ml-3 text-primary">
              {addTokenError}
              {!addTokenError && <br />}
            </Col>

            <Col xs={12} className="ml-3 py-2">
              <Form.Check.Input
                type="radio"
                checked={subtractTokenChecked}
                onChange={onSubtractTokenRadioClick}
                className="token-ckeck-input"
              />
              <Form.Check.Label className="ml-2">Subtract Tokens</Form.Check.Label>
              <Form.Control
                type="text"
                className="mt-2"
                placeholder="Number Of Tokens"
                value={subtractTokenValue}
                disabled={addTokenChecked}
                onChange={onChangeSubtractTokenValue}
                maxLength={7}
              />
            </Col>

            <Col xs={12} className="ml-3 text-primary pb-2">
              {subtractTokenError}
              {!subtractTokenError && <br />}
            </Col>
          </Row>

          <Row className="justify-content-end mb-2">
            <Col sm={6} md={5} lg={4}>
              <Button
                size="lg"
                variant="outline-secondary"
                block
                disabled={dataSaving}
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </Col>
            <Col sm={6} md={5} lg={4} className="pt-3 pt-sm-0">
              <Button
                size="lg"
                onClick={onSubmit}
                type="submit"
                variant="secondary"
                block
                disabled={!!(dataSaving || subtractTokenError || addTokenError)}
              >
                <DataSavingSpinner
                  savingText="Processing"
                  defaultText="Save"
                  dataSaving={dataSaving}
                />
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}

TokenEdit.propTypes = {
  consumerDetails: consumerDetailsProps,
  dataSaving: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onSubmitModal: PropTypes.func.isRequired,
};

TokenEdit.defaultProps = {
  consumerDetails: {},
  dataSaving: false,
};
