import PropTypes from 'prop-types';
import {
  Menu,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  // eslint-disable-next-line prettier/prettier
  SidebarHeader
} from 'react-pro-sidebar';
import { connect } from 'react-redux';
import defaultProfilePic from '../../../assets/image/defaultProfilePic.png';
import info9000Logo from '../../../assets/image/info9000Logo.png';
import commonMethods from '../../../helper/common-methods';
import { ADMIN, ICON_SIZE } from '../../../helper/constant-values';
import { ArrowDownIcon, ArrowUpIcon } from '../../../helper/icons/common-icons';
import { userInformationProps } from '../common-props';
import UserMenuItems from './menu-items';
import MoveToDashboard from './move-to-dashboard';
import './navigation-bar.scss';

function NavigationBar(props) {
  const {
    forMobile,
    toggled,
    onToggleSidebar,
    userInfoState,
    navBarIndex,
    showProfileDropDown,
    onToggleProfileDropDown,
    onConsumerAudioFilesTabChage,
    changeAudioTab,
    setChangeAudioTab,
  } = props;

  const menuBarWidth = forMobile ? '0%' : '100%';
  const menuItemClass = 'd-flex justify-content-sm-center justify-content-lg-start menu-item';
  const sideHeaderLogoLg =
    userInfoState.type === ADMIN
      ? info9000Logo
      : commonMethods.getImageApi(userInfoState.customerLogo);
  const sideHeaderLogoSm =
    userInfoState.type === ADMIN
      ? info9000Logo
      : commonMethods.getImageApi(userInfoState.customerLogo);

  return (
    <ProSidebar
      width={toggled ? '80%' : menuBarWidth}
      className="sidebar fixed-sidebar border-right text-info mr-3"
      toggled={toggled}
      breakPoint="sm"
      onToggle={onToggleSidebar}
    >
      <SidebarHeader className="pr-5 pl-4 ml-2 pl-sm-2 ml-sm-0 pr-md-3 mr-4 pt-4 mb-3 d-block d-sm-none d-lg-flex border-bottom-0 justify-content-center menubar-img">
        <MoveToDashboard>
          <img className="menubar-image-size" src={sideHeaderLogoLg} alt="logo" />
        </MoveToDashboard>
      </SidebarHeader>
      <SidebarHeader className="px-3 pt-4 mb-3 d-none d-sm-flex d-lg-none justify-content-center border-bottom-0 menubar-img">
        <MoveToDashboard>
          <img className="menubar-image-size" src={sideHeaderLogoSm} alt="logo" />
        </MoveToDashboard>
      </SidebarHeader>

      <SidebarContent>
        <Menu popperArrow="true" iconShape="square">
          <span className={`${menuItemClass} menu-text py-3 pl-3`}>
            <p className="h6 mb-0">MENUS</p>&ensp;
          </span>
          <UserMenuItems
            menuItemClass={menuItemClass}
            activeNavIndex={navBarIndex}
            onToggleSidebar={onToggleSidebar}
            onConsumerAudioFilesTabChage={onConsumerAudioFilesTabChage}
            changeAudioTab={changeAudioTab}
            setChangeAudioTab={setChangeAudioTab}
          />
        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <div className="pl-3 d-flex justify-content-start justify-content-sm-center justify-content-lg-start py-3">
          <img
            className="img-max rounded-circle"
            src={commonMethods.getImageApi(userInfoState.profilePic) || defaultProfilePic}
            alt="logo"
          />
          <span className="d-block d-sm-none d-lg-block p-2">{userInfoState.fullName}</span>
          {showProfileDropDown && (
            <ArrowUpIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              className="ml-auto cap-icon mr-3 cursor-pointer"
              onClick={onToggleProfileDropDown}
            />
          )}

          {!showProfileDropDown && (
            <ArrowDownIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              className="ml-auto cap-icon mr-3 cursor-pointer"
              onClick={onToggleProfileDropDown}
            />
          )}
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
}

NavigationBar.propTypes = {
  forMobile: PropTypes.bool,
  toggled: PropTypes.bool,
  showProfileDropDown: PropTypes.bool.isRequired,
  navBarIndex: PropTypes.number,
  onToggleSidebar: PropTypes.func,
  onToggleProfileDropDown: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
  setChangeAudioTab: PropTypes.func.isRequired,
  onConsumerAudioFilesTabChage: PropTypes.func.isRequired,
  changeAudioTab: PropTypes.bool.isRequired,
};

NavigationBar.defaultProps = {
  forMobile: false,
  toggled: false,
  navBarIndex: 0,
  onToggleSidebar: () => {},
};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
