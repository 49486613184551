import { getIn } from 'formik';
import PropTypes from 'prop-types';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ICON_SIZE } from '../../../helper/constant-values';
import { EyeIcon, EyeNoIcon } from '../../../helper/icons/common-icons';
import './form-component.css';

function FormComponent(props) {
  const {
    type,
    placeholder,
    name,
    onIconClick,
    isPassword,
    showIcon,
    icon,
    formik,
    children,
    iconToolTip,
    autoFocusInput,
    inputColumnClass,
    errorColumnClass,
    disabled,
    textareaRowNumber,
    isDarkDisableInput,
    showInitialError,
    showLabel,
    inputClass,
  } = props;

  const { handleChange, handleBlur, values, touched, errors } = formik;

  let DefaultIcon = {};

  if (isPassword) {
    DefaultIcon = type === 'password' ? EyeIcon : EyeNoIcon;
  } else if (showIcon) {
    DefaultIcon = icon;
  }

  const iconVisible = isPassword || showIcon;
  let inputFormClass =
    (getIn(touched, name) || showInitialError) && getIn(errors, name) && !disabled
      ? 'input-fields-error-color border-3 border-primary text-primary'
      : '';

  if (iconVisible) {
    inputFormClass += ' icon-padding';
  }

  return (
    <>
      <div
        className={
          iconVisible
            ? `d-flex flex-row justify-content-end ${inputColumnClass}`
            : `${inputColumnClass}`
        }
      >
        {showLabel && <Form.Label>{placeholder}</Form.Label>}

        {type !== 'select' && type !== 'textarea' && (
          <Form.Control
            type={type}
            placeholder={showLabel || iconVisible ? '' : placeholder}
            name={name}
            value={getIn(values, name)}
            size="lg"
            onChange={handleChange}
            onBlur={handleBlur}
            className={`${inputFormClass} ${inputClass} ${
              disabled && isDarkDisableInput ? 'disable-dark-input' : ''
            }`}
            autoFocus={autoFocusInput}
            disabled={disabled}
          />
        )}

        {type === 'textarea' && (
          <Form.Control
            as="textarea"
            placeholder={showLabel ? '' : placeholder}
            name={name}
            value={getIn(values, name)}
            size="lg"
            onChange={handleChange}
            onBlur={handleBlur}
            className={`${inputFormClass} ${inputClass}`}
            autoFocus={autoFocusInput}
            disabled={disabled}
            rows={textareaRowNumber}
          />
        )}

        {type === 'select' && (
          <Form.Control
            as="select"
            className={`${'default-select'} ${inputClass}`}
            size="lg"
            name={name}
            value={getIn(values, name)}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {children}
          </Form.Control>
        )}

        {iconVisible && (
          <OverlayTrigger
            transition={false}
            trigger={['hover', 'click']}
            placement="bottom"
            overlay={<Tooltip>{iconToolTip}</Tooltip>}
          >
            <div
              className={`cursor-pointer position-absolute align-self-center icon-margin-for-safari ${
                isPassword && type === 'password' ? 'faeye-icon' : 'mr-2'
              }`}
            >
              <DefaultIcon width={ICON_SIZE} height={ICON_SIZE} onClick={onIconClick} />
            </div>
          </OverlayTrigger>
        )}
      </div>

      <div className={`${errorColumnClass}`}>
        {!disabled &&
        (getIn(touched, name) || showInitialError) &&
        getIn(errors, name) &&
        getIn(errors, name) !== ' ' ? (
          <>
            <div className="error-message text-primary">* {getIn(errors, name)}</div>
            <br />
          </>
        ) : (
          <br />
        )}
      </div>
    </>
  );
}

FormComponent.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onIconClick: PropTypes.func,
  isPassword: PropTypes.bool,
  showIcon: PropTypes.bool,
  icon: PropTypes.objectOf(PropTypes.any),
  iconToolTip: PropTypes.string,
  autoFocusInput: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.any),
  ]),
  inputColumnClass: PropTypes.string,
  errorColumnClass: PropTypes.string,
  textareaRowNumber: PropTypes.number,
  inputClass: PropTypes.string,
  disabled: PropTypes.bool,
  showInitialError: PropTypes.bool,
  isDarkDisableInput: PropTypes.bool,
  showLabel: PropTypes.bool,
  formik: PropTypes.shape({
    values: PropTypes.objectOf(PropTypes.any).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    touched: PropTypes.objectOf(PropTypes.any).isRequired,
    errors: PropTypes.objectOf(PropTypes.any).isRequired,
  }).isRequired,
};

FormComponent.defaultProps = {
  type: 'text',
  placeholder: '',
  iconToolTip: '',
  isPassword: false,
  showIcon: false,
  autoFocusInput: false,
  textareaRowNumber: 4,
  disabled: false,
  children: {},
  inputColumnClass: 'col-lg-6 col-sm-11',
  errorColumnClass: 'col-lg-8 col-sm-11',
  icon: {},
  onIconClick: () => {},
  isDarkDisableInput: false,
  showInitialError: false,
  showLabel: false,
  inputClass: '',
};

export default FormComponent;
