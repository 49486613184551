import {
  ADMIN_PROFILE_INIT,
  CONSUMER_PROFILE_INIT,
  CUSTOMER_PROFILE_INIT,
  GET_ADMIN_PROFILE,
  GET_CONSUMER_PROFILE,
  // eslint-disable-next-line prettier/prettier
  GET_CUSTOMER_PROFILE, PROFILE_PASSWORD_CHANGE
} from '../types';

const initialState = {
  consumerProfile: {
    username: '',
    email: '',
    contactNumber: '',
    smsPhoneNumber: '',
    fullName: '',
    timeZone: '',
    passwordLastUpdatedAt: '',
    defaultCategory: '',
    defaultCategoryId: 0,
    autoRenewPackage: '',
    tokenBalance: 0,
    thresholdToken: 0,
  },
  customerProfile: {
    username: '',
    email: '',
    contactNumber: '',
    fullName: '',
    timeZone: '',
    passwordLastUpdatedAt: '',
  },
  adminProfile: {
    username: '',
    email: '',
    contactNumber: '',
    fullName: '',
    passwordLastUpdatedAt: '',
  },
  adminProfilePrevious: {
    email: '',
    contactNumber: '',
    fullName: '',
  },
  customerProfilePrevious: {
    email: '',
    contactNumber: '',
    fullName: '',
    timeZone: '',
  },
  consumerProfilePrevious: {
    username: '',
    email: '',
    contactNumber: '',
    smsPhoneNumber: '',
    fullName: '',
    timeZone: '',
    defaultCategoryId: 0,
  },
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_PROFILE:
      return {
        ...state,
        customerProfile: action.data,
      };

    case GET_CONSUMER_PROFILE:
      return {
        ...state,
        consumerProfile: action.data,
      };

    case GET_ADMIN_PROFILE:
      return {
        ...state,
        adminProfile: action.data,
      };

    case ADMIN_PROFILE_INIT:
      return {
        ...state,
        adminProfilePrevious: action.data,
      };

    case CUSTOMER_PROFILE_INIT:
      return {
        ...state,
        customerProfilePrevious: action.data,
      };

    case CONSUMER_PROFILE_INIT:
      return {
        ...state,
        consumerProfilePrevious: action.data,
      };

    case PROFILE_PASSWORD_CHANGE:
      return {
        ...state,
        consumerProfile: {
          ...state.consumerProfile,
          ...action.data,
        },
        customerProfile: {
          ...state.customerProfile,
          ...action.data,
        },
      };

    default:
      return state;
  }
};

export default profileReducer;
