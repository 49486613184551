import { callAxiosWithAuth } from '../helper/axios';
import utils from '../helper/utils';
import endpoints from './endpoints';

const baseUrl = endpoints.BASE_URL;

const packageService = {
  packagePurchaseByStripe: async (purchaseData, query) => {
    const { method, endpoint } = endpoints.packagePurchaseByStripe;

    const url = utils.buildUrl(baseUrl + endpoint, query);
    const response = await callAxiosWithAuth({ method, url, data: purchaseData });

    return response;
  },

  packageCreateOrderByPaypal: async (purchaseData, query) => {
    const { method, endpoint } = endpoints.packageCreateOrderByPaypal;

    const url = utils.buildUrl(baseUrl + endpoint, query);
    const response = await callAxiosWithAuth({ method, url, data: purchaseData });

    return response;
  },

  packageCheckoutOrderByPaypal: async (purchaseData, query) => {
    const { method, endpoint } = endpoints.packageCheckoutOrderByPaypal;

    const url = utils.buildUrl(baseUrl + endpoint, query);
    const response = await callAxiosWithAuth({ method, url, data: purchaseData });

    return response;
  },

  packagePurchaseWithStripeDefaultPayment: async (purchaseData, query) => {
    const { method, endpoint } = endpoints.packagePurchaseWithStripeDefaultPayment;

    const url = utils.buildUrl(baseUrl + endpoint, query);
    const response = await callAxiosWithAuth({ method, url, data: purchaseData });

    return response;
  },

  packagePurchaseWithPaypalDefaultPayment: async (purchaseData, query) => {
    const { method, endpoint } = endpoints.packagePurchaseWithPaypalDefaultPayment;

    const url = utils.buildUrl(baseUrl + endpoint, query);
    const response = await callAxiosWithAuth({ method, url, data: purchaseData });

    return response;
  },

  getCustomerPackages: async (params, query) => {
    const { method, endpoint } = endpoints.getCustomerPackages;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  getConsumerPackages: async (query) => {
    const { method, endpoint } = endpoints.getConsumerPackages;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl });

    return response;
  },

  toggleAutoPaymentButton: async (packageData, query) => {
    const { method, endpoint } = endpoints.toggleAutoPaymentButton;

    const requestUrl = utils.buildUrl(baseUrl + endpoint, query);
    const response = await callAxiosWithAuth({ method, url: requestUrl, data: packageData });

    return response;
  },

  getPackageListForAdmin: async (query) => {
    const { method, endpoint } = endpoints.getPackageListForAdmin;

    const url = utils.buildUrl(baseUrl + endpoint, {}, query);
    const response = await callAxiosWithAuth({ method, url });

    return response;
  },

  addPackageByAdmin: async (data, query) => {
    const { method, endpoint } = endpoints.addPackageByAdmin;

    const url = utils.buildUrl(baseUrl + endpoint, {}, query);
    const response = await callAxiosWithAuth({ method, url, data });

    return response;
  },

  updatePackageByAdmin: async (data, params) => {
    const { method, endpoint } = endpoints.updatePackageByAdmin;

    const url = utils.buildUrl(baseUrl + endpoint, params);
    const response = await callAxiosWithAuth({ method, url, data });

    return response;
  },

  deletePackageByAdmin: async (params, query) => {
    const { method, endpoint } = endpoints.deletePackageByAdmin;

    const url = utils.buildUrl(baseUrl + endpoint, params, query);
    const response = await callAxiosWithAuth({ method, url });

    return response;
  },
};

export default packageService;
