module.exports = {
  customerFooter: {
    copyRights: '2023 Free Will Astrology. All rights reserved.',
    poweredBy: 'Powered by',
    companyName: 'Free Will Astrology',
  },
  consumerLandingPage: {
    adminCompanyName: 'Info 9000',
    adminCompanyDetails:
      'cardum id cras vitae nulla imperdiet felis orci mattis in. Mi, pretium id gravida id interdum vitae. Mattis viverra sodales scelerisque ultricies turpis aliquam. Vitae magnis non lacus dui, proin.',
    adminCompanyImageUrl:
      'https://s3-alpha-sig.figma.com/img/23b9/d770/b548f54bb97987a05f9b006296f5426a?Expires=1632700800&Signature=C9uVRXfP7huTDiN5i~auhESEdPR2z~vVlwbNbe8imPEr07mdwQ1o0HuGpetH~C1A7O9~Miy2xXeJ6hEq23aMbzFmlOgAXwonanbbTjCP1EAUSBCgUJAVEGJoTt-Q14QfHd69u8uTgd5MHxbfBh9TfHDPjaku9~Ouh9csSWxzB14hM0-iw-5tvGbh3cClZTyPAnhc0SxX7gex~gYzksj37PTj0N~oUX1EgnYNVTtQz9klEfFCs~mzE-oaMewEaXzrJm5HTVkewg3MBB8UiUyoPL1N7iptOLeytdu1sgjEPjBZZ1Vv78vqa2hpGIGBfrBM7bH~5cNHmnkxqduq12qn0Q__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA',
    privacyPolicy: 'Privacy Policy',
    cookiePolicy: 'Cookie Policy',
    info9000WebsiteLink: 'https://purplegator.com/',
  },
  files: {
    maximumImageUploadSize: 4000 * 1024, // in bytes (4MB)
    maximumAudioUploadSize: 15000 * 1024, // 15MB // in bytes
    maximumBulkTextUploadSize: 5000 * 1024, // in bytes (5MB)
    maximumSvgUploadSize: 40 * 1024, // in bytes (40KB)
  },
  mediaLinks: {
    facebook: 'https://www.facebook.com/Rob-Brezsnys-Free-Will-Astrology-133041234078',
    twitter: 'https://twitter.com/FreeWillAstro',
    instagram: 'https://www.instagram.com/rob.brezsny._.truthrooster/',
    youtube: 'https://www.youtube.com/user/SacredParty/videos',
    tumblr: 'https://freewillastrology.tumblr.com/archive',
    soundCloud: 'https://soundcloud.com/sacreduproar',
  },
  email: {
    supportEmail: 'freewillcs@gmail.com',
  },
  shortText: {
    scheduleDelay: 5, // minitues (min 5 min max 60 min recommended) // Same scheduleDelay need to add to the api config file
  },
};
