import { Formik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import commonMethods from '../../../../../helper/common-methods';
import { addCustomerPackageValidationSchema } from '../../../../../helper/schema-objects';
import CustomCheckbox from '../../../../common/buttons/custom-checkbox';
import FormComponent from '../../../../common/form-component/form-component';
import DataSavingSpinner from '../../../../common/loader-spinner/data-saving-spinner';
import { singlePackageProps } from '../../customer-common-props';

function CustomerPackageForm(props) {
  const {
    packageLogo,
    packageLogoError,
    isAddForm,
    packageItem,
    onPackageSubmit,
    onCloseModal,
    customerId,
  } = props;

  const [dataSaving, setDataSaving] = useState(false);
  const [checkedAutoRenewable, setCheckedAutoRenewable] = useState(
    isAddForm ? false : packageItem.isAutoRenewable
  );

  const initialValues = {
    name: isAddForm ? '' : packageItem.name,
    price: isAddForm ? '' : packageItem.price,
    numberOfTokens: isAddForm ? '' : packageItem.numberOfTokens,
    autoRenewPrice: isAddForm ? '' : packageItem.autoRenewPrice,
  };

  const onSubmit = (values, { setErrors }) => {
    const packageData = {
      ..._.pick(values, ['name', 'price', 'numberOfTokens']),
      autoRenewPrice: checkedAutoRenewable ? values.autoRenewPrice : 0,
      isAutoRenewable: checkedAutoRenewable,
      customerId,
    };

    const formData = commonMethods.getFormData(packageData, packageLogo, 'packageLogo');

    setDataSaving(true);

    const params = { packageId: packageItem.id };

    return onPackageSubmit(formData, params)
      .then(() => {
        setDataSaving(false);
        onCloseModal();
      })
      .catch((error) => {
        if (!error.response) {
          setDataSaving(false);
          return;
        }

        setDataSaving(false);

        const { isExistingName, message } = error.response.data;

        if (isExistingName) {
          setErrors({
            name: message,
          });
        }
      });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={addCustomerPackageValidationSchema({
          isAutoRenewable: checkedAutoRenewable,
        })}
        onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
        enableReinitialize
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Row className="justify-content-center">
              <Col sm={12} className="mb-3 text-center">
                <h5>Package Information</h5>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <FormComponent
                placeholder="Package Name"
                name="name"
                inputColumnClass="col-12"
                errorColumnClass="col-12"
                formik={formik}
                autoFocusInput
                showLabel
              />
            </Row>

            <Row className="justify-content-center">
              <FormComponent
                placeholder="Price"
                name="price"
                type="number"
                inputColumnClass="col-12"
                errorColumnClass="col-12"
                formik={formik}
                showLabel
              />
            </Row>

            <Row className="justify-content-center">
              <FormComponent
                placeholder="Auto-Purchase Price"
                name="autoRenewPrice"
                type="number"
                inputColumnClass="col-12"
                errorColumnClass="col-12"
                formik={formik}
                showLabel
                showInitialError={checkedAutoRenewable}
                disabled={!checkedAutoRenewable}
              />
            </Row>

            <Row className="justify-content-center">
              <FormComponent
                placeholder="Number Of Tokens"
                name="numberOfTokens"
                type="number"
                inputColumnClass="col-12"
                errorColumnClass="col-12"
                formik={formik}
                showLabel
              />
            </Row>

            <Row className="justify-content-center mb-4">
              <Col>
                <CustomCheckbox
                  className="mr-2 text-secondary"
                  checked={checkedAutoRenewable}
                  onClick={() => setCheckedAutoRenewable(!checkedAutoRenewable)}
                />
                <span className="mr-2">&emsp;Auto Purchasable</span>
              </Col>
            </Row>

            <Row className="justify-content-center mt-1 mb-2">
              <Col lg={8} className="pt-3 pt-sm-0">
                <Button
                  size="lg"
                  block
                  variant="secondary"
                  onClick={formik.handleSubmit}
                  type="submit"
                  disabled={
                    !!(
                      formik.errors.name ||
                      formik.errors.price ||
                      formik.errors.numberOfTokens ||
                      (checkedAutoRenewable && formik.errors.autoRenewPrice) ||
                      (isAddForm && packageLogo === null) ||
                      dataSaving ||
                      packageLogoError
                    )
                  }
                >
                  <DataSavingSpinner
                    savingText="Saving Changes"
                    defaultText="Save Changes"
                    dataSaving={dataSaving}
                  />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}

CustomerPackageForm.propTypes = {
  packageLogo: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  packageLogoError: PropTypes.string.isRequired,
  isAddForm: PropTypes.bool,
  packageItem: singlePackageProps,
  onPackageSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

CustomerPackageForm.defaultProps = {
  isAddForm: false,
  packageItem: {
    id: 0,
    name: '',
    price: 0,
    numberOfTokens: 0,
    imagePath: '',
    isAutoRenewable: false,
  },
  packageLogo: null,
};

const mapStateToProps = (state) => ({
  customerDetailState: state.customers.customerDetailsForAdmin,
  customerDetailPreviousState: state.customers.customerDetailsForAdmin,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPackageForm);
