const browserStorage = {
  getUserData: (name) => JSON.parse(localStorage.getItem(name)),
  setUserData: (name, data) => {
    let userData = browserStorage.getUserData(name);
    if (userData) {
      userData = { ...userData, ...data };
    } else {
      userData = data;
    }
    localStorage.setItem(name, JSON.stringify(userData));
  },
  removeUserData: (name) => {
    localStorage.removeItem(name);
  },
};

export default browserStorage;
