import PropTypes from 'prop-types';

export const singlePackageProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
  numberOfTokens: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  autoRenewPrice: PropTypes.number.isRequired,
  isAutoRenewable: PropTypes.bool.isRequired,
});

export const packageListProps = PropTypes.arrayOf(singlePackageProps.isRequired);

export const packageProps = PropTypes.shape({
  packages: PropTypes.arrayOf(singlePackageProps.isRequired).isRequired,
});

export const defaultPaymentInfoProps = PropTypes.shape({
  expMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  expYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastFourDigit: PropTypes.string,
  cardBrand: PropTypes.string,
  noDefaultPayment: PropTypes.bool,
  isStripePayment: PropTypes.bool,
  isPaypalPayment: PropTypes.bool,
  fullName: PropTypes.string,
  email: PropTypes.string,
});

export const consumerProfileProps = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired,
  smsPhoneNumber: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  passwordLastUpdatedAt: PropTypes.string.isRequired,
  defaultCategory: PropTypes.string.isRequired,
  defaultCategoryId: PropTypes.number.isRequired,
  autoRenewPackage: PropTypes.string.isRequired,
  tokenBalance: PropTypes.number.isRequired,
  thresholdToken: PropTypes.number.isRequired,
});
