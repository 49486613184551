import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginValidationSchema } from '../../../helper/schema-objects';
import { adminLogin } from '../../../redux/actions/auth-action';
import FormComponent from '../../common/form-component/form-component';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';

function AdminLogin(props) {
  const [showPassword, setShowPassword] = useState('password');
  const [loginLoading, setLoginLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const history = useHistory();

  const { login, goToForgotPassword } = props;

  const showHidePassword = () => {
    if (showPassword === 'password') {
      setShowPassword('text');
    } else {
      setShowPassword('password');
    }
  };

  const initialValues = {
    emailOrUsername: '',
    password: '',
  };

  function onLogin(values, { setErrors }) {
    const loginData = {
      usernameOrEmail: values.emailOrUsername,
      password: values.password,
      rememberMe,
    };

    setLoginLoading(true);

    login(loginData, {})
      .then(() => {
        setLoginLoading(false);
        history.push('/admin/customers');
      })
      .catch((error) => {
        setLoginLoading(false);
        if (!error.response) return;

        const { message } = error.response.data;

        setErrors({
          password: message,
          emailOrUsername: ' ',
        });
      });
  }

  return (
    <>
      <Row className="justify-content-center pb-4">
        <Col sm={11} className="text-center">
          <span className="h4">Login to your account</span>
        </Col>
      </Row>
      <Formik
        initialValues={initialValues}
        validationSchema={loginValidationSchema}
        onSubmit={(values, { setErrors }) => onLogin(values, { setErrors })}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Container>
              <Row className="justify-content-center">
                <FormComponent
                  type="text"
                  placeholder="Email or Username"
                  name="emailOrUsername"
                  formik={formik}
                  inputColumnClass="col-lg-7 col-sm-11"
                  errorColumnClass="col-lg-7 col-sm-11"
                  autoFocusInput
                  showLabel
                />
              </Row>

              <Row className="justify-content-center mt-2">
                <Col sm={11} lg={7}>
                  <Form.Label>Password</Form.Label>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <FormComponent
                  isPassword
                  type={showPassword}
                  placeholder="Password"
                  name="password"
                  fontAwesomeIconSize="lg"
                  iconToolTip="Show/hide password"
                  onIconClick={showHidePassword}
                  inputColumnClass="col-lg-7 col-sm-11"
                  errorColumnClass="col-lg-7 col-sm-11"
                  formik={formik}
                />
              </Row>
              <Row className="justify-content-center  mt-2">
                <Col sm={11} lg={7}>
                  <Row>
                    <Col xs={6} className="pr-0 pr-md-2">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          label="&ensp;Remember Me"
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={6} className="pl-0 pl-md-2">
                      <div className="text-secondary font-weight-bold d-flex justify-content-end">
                        <div
                          role="presentation"
                          onClick={goToForgotPassword}
                          className="cursor-pointer"
                        >
                          Forgot Password?
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center  mt-3 pt-lg-3">
                <Col sm={11} lg={7}>
                  <Button
                    size="lg"
                    variant="secondary"
                    block
                    onClick={formik.handleSubmit}
                    type="submit"
                    disabled={
                      !!(formik.errors.emailOrUsername || formik.errors.password || loginLoading)
                    }
                  >
                    <DataSavingSpinner defaultText="Login" dataSaving={loginLoading} />
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
}

AdminLogin.propTypes = {
  login: PropTypes.func.isRequired,
  goToForgotPassword: PropTypes.func.isRequired,
};

AdminLogin.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  login: adminLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
