import { Formik } from 'formik';
import _ from 'lodash';
import { phone } from 'phone';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import commonMethods from '../../../helper/common-methods';
import { consumerProfileValidationSchema } from '../../../helper/schema-objects';
import { updateConsumerProfile } from '../../../redux/actions/profile-action';
import DoubleFormComponent from '../../common/form-component/double-form-component';
import DataSavingSpinner from '../../common/loader-spinner/data-saving-spinner';
import VerificationCodeConfirmModal from '../../common/modals/verification-code-send-confirmation-modal/verification-code-confirmation-modal';
import {
  consumerProfilePreviousProps,
  consumerProfileProps,
  // eslint-disable-next-line prettier/prettier
  userInformationProps
} from '../profile-common-props';
import CategoryDropdown from './edit-components/category-dropdown';

function ConsumerEditProfile(props) {
  const {
    onUpdatingProfile,
    profileState,
    updateProfile,
    backFromVerification,
    profilePreviousState,
    userInfoState,
    focusEmail,
    focusSmsPhoneNumber,
    profilePicFile,
    profileImageError,
  } = props;

  const { customerUrl } = useParams();

  const [dataSaving, setDataSaving] = useState(false);
  const [showVerificationConfirmModal, setShowVerificationConfirmModal] = useState(false);
  const [profileSubmitValues, setProfileSubmitValues] = useState({});
  const [profileSubmitMethods, setProfileSubmitMethods] = useState({});
  const [isEmailVerificationNeeded, setIsEmailVerificationNeeded] = useState(false);
  const [isSMSVerificationNeeded, setIsSMSVerificationNeeded] = useState(false);
  const [isEmailAndSMSVerificationNeeded, setIsEmailAndSMSVerificationNeeded] = useState(false);
  const [contactNumber, setContactNumber] = useState(profileState.contactNumber);
  const [smsPhoneNumber, setSmsPhoneNumber] = useState(profileState.smsPhoneNumber);
  const [checkContactNumber, setCheckContactNumber] = useState(
    profileState.contactNumber === profileState.smsPhoneNumber
  );

  let initialValues = {
    username: profileState.username,
    fullName: profileState.fullName,
    email: profileState.email,
    contactNumber: profileState.contactNumber,
    smsPhoneNumber: profileState.smsPhoneNumber,
    defaultCategoryId: profileState.defaultCategoryId,
  };

  if (backFromVerification) {
    initialValues = {
      ...initialValues,
      ...profilePreviousState,
    };
  }

  const onCopyContactNumber = (checked, { values, setFieldValue, setFieldTouched }) => {
    if (checked) {
      setFieldTouched('smsPhoneNumber', true);
      setFieldValue('smsPhoneNumber', values.contactNumber);
      setSmsPhoneNumber(values.contactNumber);
    }
  };

  const onInputChange = (e, formik) => {
    formik.handleChange(e);

    if (e.target.name === 'smsPhoneNumber') {
      setSmsPhoneNumber(e.target.value);
    } else if (e.target.name === 'contactNumber') {
      setContactNumber(e.target.value);
    }
  };

  const onVerificationModalCancel = () => {
    setIsEmailVerificationNeeded(false);
    setIsSMSVerificationNeeded(false);
    setIsEmailAndSMSVerificationNeeded(false);
    setShowVerificationConfirmModal(false);
    setProfileSubmitValues({});
    setProfileSubmitMethods({});
  };

  const onVerificationModalProceed = (values, formikFunctions) => {
    const profileData = {
      ...values,
      fullName: values.fullName.trim(),
    };

    const userData = _.omit(profileData, ['username']);

    const formData = commonMethods.getFormData(userData, profilePicFile);

    const params = {
      customerUrl,
      consumerId: userInfoState.id,
    };

    setDataSaving(true);
    onVerificationModalCancel();

    updateProfile(formData, params, profileData)
      .then((data) => {
        setDataSaving(false);
        onUpdatingProfile({ ...data, ...userData });
      })
      .catch((error) => {
        if (!error.response) {
          return;
        }

        setDataSaving(false);

        const { isExistingEmail, invalidSMSPhoneNumber, message } = error.response.data;

        if (isExistingEmail) {
          formikFunctions.setErrors({
            email: message,
          });
        }

        if (invalidSMSPhoneNumber) {
          formikFunctions.setErrors({
            smsPhoneNumber: message,
          });
        }
      });
  };

  const onSubmit = (values, formikFunctions) => {
    if (
      values.email !== profileState.email ||
      phone(values.smsPhoneNumber).phoneNumber !== phone(profileState.smsPhoneNumber).phoneNumber
    ) {
      if (
        values.email !== profileState.email &&
        phone(values.smsPhoneNumber).phoneNumber !== phone(profileState.smsPhoneNumber).phoneNumber
      ) {
        setIsEmailAndSMSVerificationNeeded(true);
      } else if (values.email !== profileState.email) {
        setIsEmailVerificationNeeded(true);
      } else if (
        phone(values.smsPhoneNumber).phoneNumber !== phone(profileState.smsPhoneNumber).phoneNumber
      ) {
        setIsSMSVerificationNeeded(true);
      }

      setProfileSubmitValues(values);
      setProfileSubmitMethods(formikFunctions);
      setShowVerificationConfirmModal(true);
    } else {
      onVerificationModalProceed(values, formikFunctions);
    }
  };

  useEffect(() => {
    setCheckContactNumber(contactNumber === smsPhoneNumber);
  }, [contactNumber, smsPhoneNumber]);

  return (
    <>
      {showVerificationConfirmModal && (
        <VerificationCodeConfirmModal
          isEmailVerification={isEmailVerificationNeeded}
          isSMSVerification={isSMSVerificationNeeded}
          isEmailAndSMSVerification={isEmailAndSMSVerificationNeeded}
          isProfilePage
          verificationMedium={
            isSMSVerificationNeeded ? profileSubmitValues.smsPhoneNumber : profileSubmitValues.email
          }
          secondVerificationMedium={profileSubmitValues.smsPhoneNumber}
          showModal={showVerificationConfirmModal}
          onModalCancel={onVerificationModalCancel}
          onModalProceed={() =>
            onVerificationModalProceed(profileSubmitValues, profileSubmitMethods)
          }
        />
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={consumerProfileValidationSchema}
        onSubmit={(values, { setErrors }) => onSubmit(values, { setErrors })}
        enableReinitialize
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <DoubleFormComponent
              formik={formik}
              firstColumnName="username"
              secondColumnName="fullName"
              firstColumnPlaceHolder="Username"
              secondColumnPlaceHolder="Name"
              firstColumnDisable
              firstColumnAutoFocus
              firstColumnShowLabel
              secondColumnShowLabel
            />

            <DoubleFormComponent
              formik={{ ...formik, ...{ handleChange: (e) => onInputChange(e, formik) } }}
              firstColumnName="contactNumber"
              secondColumnName="email"
              firstColumnPlaceHolder="Contact Phone Number"
              secondColumnPlaceHolder="Email"
              secondColumnAutoFocus={focusEmail}
              firstColumnShowLabel
              secondColumnShowLabel
            />

            <Row className="justify-content-center">
              <Col lg={8}>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="&ensp;Use same number for SMS service"
                    onChange={(e) => onCopyContactNumber(e.target.checked, formik)}
                    checked={checkContactNumber}
                    className="text-muted"
                  />
                </Form.Group>
              </Col>
            </Row>

            <DoubleFormComponent
              formik={{ ...formik, ...{ handleChange: (e) => onInputChange(e, formik) } }}
              firstColumnName="defaultCategoryId"
              secondColumnName="smsPhoneNumber"
              FirstColumnChildren={CategoryDropdown}
              firstColumnType="select"
              firstColumnShowLabel
              secondColumnShowLabel
              firstColumnPlaceHolder="Astrological Sign"
              secondColumnPlaceHolder="SMS Phone Number"
              secondColumnAutoFocus={focusSmsPhoneNumber}
            />

            <Row className="justify-content-center mt-3 mb-5">
              <Col lg={8} className="pt-3 pt-sm-0">
                <Button
                  size="lg"
                  block
                  onClick={formik.handleSubmit}
                  type="submit"
                  disabled={
                    !!(
                      formik.errors.fullName ||
                      formik.errors.email ||
                      formik.errors.contactNumber ||
                      formik.errors.smsPhoneNumber ||
                      dataSaving ||
                      profileImageError
                    )
                  }
                >
                  <DataSavingSpinner
                    savingText="Saving Changes"
                    defaultText="Save Changes"
                    dataSaving={dataSaving}
                  />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}

ConsumerEditProfile.propTypes = {
  onUpdatingProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  profileState: consumerProfileProps.isRequired,
  backFromVerification: PropTypes.bool.isRequired,
  profilePreviousState: consumerProfilePreviousProps.isRequired,
  focusEmail: PropTypes.bool.isRequired,
  focusSmsPhoneNumber: PropTypes.bool.isRequired,
  userInfoState: userInformationProps.isRequired,
  profilePicFile: PropTypes.string.isRequired,
  profileImageError: PropTypes.string.isRequired,
};

ConsumerEditProfile.defaultProps = {};

const mapStateToProps = (state) => ({
  profileState: state.profile.consumerProfile,
  profilePreviousState: state.profile.consumerProfilePrevious,
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  updateProfile: updateConsumerProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerEditProfile);
