import PropTypes from 'prop-types';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { ICON_SIZE } from '../../../../helper/constant-values';
import { CloseIcon } from '../../../../helper/icons/common-icons';
import ActionButtons from '../category-common-components/action-buttons';

export default function ShortTextViewModal(props) {
  const {
    showModal,
    onCloseModal,
    headerTitle,
    textContent,
    scheduledDateTime,
    onEdit,
    onCopy,
    onDelete,
  } = props;

  return (
    <Modal
      show={showModal}
      onHide={onCloseModal}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header>
        <Col xs={10} className="text-truncate">
          <span className="h5">{headerTitle}</span>
        </Col>
        <Col xs={2} className="mt-2 mt-sm-0 text-right">
          <CloseIcon
            className="text-muted cursor-pointer"
            width={ICON_SIZE}
            height={ICON_SIZE}
            onClick={onCloseModal}
          />
        </Col>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row>
            <Col xs={12} sm={4} lg={3}>
              <span className="font-weight-bold">Text:</span>
            </Col>
            <Col xs={12} sm={8} lg={9} className="mt-2 mt-sm-0">
              <p className="text-turncate-word-break">{textContent}</p>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={4} lg={3}>
              <span className="font-weight-bold">Scheduled Date:</span>
            </Col>
            <Col xs={12} sm={8} lg={9} className="mt-2 mt-sm-0">
              {scheduledDateTime}
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="text-center">
              <ActionButtons
                onHandleEdit={onEdit}
                onHandleCopy={onCopy}
                onHandleDelete={onDelete}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

ShortTextViewModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  scheduledDateTime: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

ShortTextViewModal.defaultProps = {};
