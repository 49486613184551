import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import DropDownWithIcons from '../../../common/dropdown/dropdown-with-icon';
import { subscriptionCategoryProps } from '../consumer-dashborad-common-props';
import './category-group.css';

function CategoryGroup(props) {
  const {
    onCategoryChange,
    selectedCategory,
    onToggleCategoryDropDown,
    showCategoryDropDown,
    subscriptionCategory,
  } = props;

  return (
    <>
      {subscriptionCategory.map((category) => (
        <Col key={category.name} sm={3} lg={2} className="p-1 dashboard-button d-none d-sm-flex">
          <Button
            variant="outline-primary"
            size="lg"
            name={category.name}
            onClick={() => onCategoryChange(category.id)}
            className={
              category.id === selectedCategory
                ? 'text-primary category-button border-primary px-0'
                : 'text-muted category-button px-0'
            }
            block
          >
            <Row className="justify-content-center">
              <div
                className="category-svg-logo"
                dangerouslySetInnerHTML={{ __html: category.logoSvg }}
              />
              {category.name}
            </Row>
          </Button>
        </Col>
      ))}

      <Col className="px-0 d-sm-none">
        <DropDownWithIcons
          categoryList={subscriptionCategory}
          onCategoryChange={onCategoryChange}
          onToggleDropDown={onToggleCategoryDropDown}
          showCategoryDropDown={showCategoryDropDown}
          selectedCategory={selectedCategory}
          isSvgImage
        />
      </Col>
    </>
  );
}

CategoryGroup.propTypes = {
  onCategoryChange: PropTypes.func.isRequired,
  selectedCategory: PropTypes.number.isRequired,
  onToggleCategoryDropDown: PropTypes.func.isRequired,
  showCategoryDropDown: PropTypes.bool.isRequired,
  subscriptionCategory: subscriptionCategoryProps.isRequired,
};

CategoryGroup.defaultProps = {};

const mapStateToProps = (state) => ({
  browserState: state.browserData.view,
  subscriptionCategory: state.consumerCategory.category,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryGroup);
