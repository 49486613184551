import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import {
  AddIcon,
  AudioIcon,
  BackIcon,
  SmsIcon,
  // eslint-disable-next-line prettier/prettier
  UploadIcon
} from '../../../helper/icons/common-icons';
import routes from '../../../helper/route-details';
import DropDownWithIcons from '../../common/dropdown/dropdown-with-icon';
import AudioContainer from './audio-service/audio-container';
import ShortTextContainer from './short-text-service/short-text-container';
import AddShortText from './short-text-service/short-text-upload/add-short-text-container';

const categoryImages = {
  Audio: <AudioIcon size="1.2rem" className="ml-3" />,
  'Short Text': <SmsIcon size="1.4rem" className="ml-3" />,
};

const categoryOptionList = [
  {
    id: 0,
    name: 'Audio',
  },
  {
    id: 1,
    name: 'Short Text',
  },
];

export default function ServiceContainer(props) {
  const { categoryName, onHandleBack, isShortTextPage, isShortTextUploadView } = props;

  const [selectedCategory, setSelectedCategory] = useState(isShortTextPage ? 1 : 0);
  const [showCategoryDropDown, setShowCategoryDropDown] = useState(false);
  const [isAudio, setIsAudio] = useState(!isShortTextPage);
  const [isShortText, setIsShortText] = useState(isShortTextPage);
  const [showAddAudioModal, setShowAddAudioModal] = useState(false);
  const [uploadedAudioDataList, setUploadedAudioDataList] = useState([]);
  const [uploadedAudioData, setUploadedAudioData] = useState({
    totalItems: 0,
    data: [],
    totalPages: 1,
    currentPage: 1,
  });

  const { customerUrl, categoryId } = useParams();
  const history = useHistory();

  const queryUploadedAudioData = (
    { page, itemPerPage, isMobile },
    newData = uploadedAudioDataList
  ) => {
    const endItemNumber = page * itemPerPage;
    let startIndex = 0;

    if (!isMobile) {
      startIndex = (page - 1) * itemPerPage;
    }

    const data = newData.slice(startIndex, endItemNumber);
    const totalItems = newData.length;
    const currentPage = page ? +page : 0;
    const totalPages = Math.ceil(totalItems / itemPerPage);

    setUploadedAudioData({
      data,
      totalItems,
      totalPages,
      currentPage,
    });
  };

  const addUploadedAudioData = (data, query) => {
    setUploadedAudioDataList((preState) => {
      const newData = [...[data], ...preState];
      queryUploadedAudioData(query, newData);

      return newData;
    });
  };

  const updateUploadedAudioData = (id, data, query) => {
    setUploadedAudioDataList((preState) => {
      const newData = preState.map((item) => (item.id === id ? data : item));
      queryUploadedAudioData(query, newData);

      return newData;
    });
  };

  const deleteUploadedAudioData = (id, query) => {
    setUploadedAudioDataList((preState) => {
      const newData = preState.filter((item) => item.id !== id);
      queryUploadedAudioData(query, newData);

      return newData;
    });
  };

  const onToggleCategoryDropDown = () => {
    setShowCategoryDropDown(!showCategoryDropDown);
  };

  const gotoUploadShortTextPage = () => {
    history.push(routes.customer.category.shortText.upload(customerUrl, categoryId));
  };

  const onUploadAudio = () => {
    setShowAddAudioModal(true);
  };

  const onHandleAudioButton = () => {
    setIsAudio(true);
    setIsShortText(false);
    setSelectedCategory(0);
    history.replace(routes.customer.category.selectedCategory(customerUrl, categoryId), {});
  };

  const onHandleShortTextButton = () => {
    setIsAudio(false);
    setIsShortText(true);
    setSelectedCategory(1);
    history.replace(routes.customer.category.selectedCategory(customerUrl, categoryId), {
      viewShortTextPage: true,
    });
  };

  const onCategoryChange = (index) => {
    const categoryid = +index;
    setSelectedCategory(categoryid);
    if (categoryid === 0) {
      onHandleAudioButton();
    } else {
      onHandleShortTextButton();
    }
  };

  return (
    <>
      {isShortTextUploadView && <AddShortText />}

      {!isShortTextUploadView && (
        <>
          <Container className="container-uxl">
            <Row>
              <Col className="pl-0">
                <BackIcon
                  className="d-inline mb-2 mr-2 back-arrow-button  cursor-pointer"
                  width="2.3em"
                  height="2.3em"
                  onClick={onHandleBack}
                />
                <h4 className="pt-2 pl-1 d-inline">{categoryName}</h4>
              </Col>
            </Row>

            <Row>
              <Col className="py-3 pl-0 d-none d-sm-flex">
                <Button
                  variant={isAudio ? 'primary' : 'outline-info'}
                  className={`px-5 py-2 mr-3 ${!isAudio ? 'muted-button' : ''}`}
                  onClick={onHandleAudioButton}
                >
                  <AudioIcon width="1.6rem" height="1.6rem" className="mt-n1" /> Audio
                </Button>
                <Button
                  variant={isShortText ? 'primary' : 'outline-info'}
                  className={`px-5 py-2 ${!isShortText ? 'muted-button' : ''}`}
                  onClick={onHandleShortTextButton}
                >
                  <SmsIcon width="1.6rem" height="1.6rem" className="mr-1" />
                  Short Text
                </Button>
              </Col>

              <Col className="px-0 pt-3 d-sm-none">
                <DropDownWithIcons
                  isLogo
                  categoryList={categoryOptionList}
                  categoryImages={categoryImages}
                  onCategoryChange={onCategoryChange}
                  onToggleDropDown={onToggleCategoryDropDown}
                  showCategoryDropDown={showCategoryDropDown}
                  selectedCategory={selectedCategory}
                />
              </Col>
            </Row>

            {isAudio && (
              <AudioContainer
                showAddAudioModal={showAddAudioModal}
                setShowAddAudioModal={setShowAddAudioModal}
                uploadedAudioData={uploadedAudioData}
                queryUploadedAudioData={queryUploadedAudioData}
                addUploadedAudioData={addUploadedAudioData}
                updateUploadedAudioData={updateUploadedAudioData}
                deleteUploadedAudioData={deleteUploadedAudioData}
              />
            )}
            {isShortText && <ShortTextContainer onUploadShortText={gotoUploadShortTextPage} />}
          </Container>

          <Container fluid className="bg-white d-block d-sm-none upload-button py-3">
            <Button
              variant="primary"
              size="lg"
              block
              onClick={isAudio ? onUploadAudio : gotoUploadShortTextPage}
            >
              <Row>
                <Col xs={6}>
                  <span>{isAudio ? 'Upload Audio' : 'New Short Text'} </span>
                </Col>
                <Col className="text-right">
                  {isAudio ? (
                    <UploadIcon width="2.0rem" height="2.0rem" className="mt-n1 ml-lg-2" />
                  ) : (
                    <AddIcon width="2.0rem" height="2.0rem" className="mt-n1 ml-lg-2" />
                  )}
                </Col>
              </Row>
            </Button>
          </Container>
        </>
      )}
    </>
  );
}

ServiceContainer.propTypes = {
  categoryName: PropTypes.string.isRequired,
  onHandleBack: PropTypes.func.isRequired,
  isShortTextPage: PropTypes.bool.isRequired,
  isShortTextUploadView: PropTypes.bool,
};

ServiceContainer.defaultProps = {
  isShortTextUploadView: false,
};
