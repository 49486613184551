import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import logo from '../../../assets/image/freewill-banner.jpg';
import freewillRobBrezsny from '../../../assets/image/freewill-rob-brezsny.jpg';
import getUserInformationAndSubscriptionCategory from '../../../redux/actions/consumer-landing-action';
import PageNotFoundPage from '../../common/page-not-found';
import { consumerLandingProps } from './consumer-landing-common-props';
import Footer from './footer/landing-page-footer';
import Header from './header/landing-page-header';
import './landing-page-style.css';
import InfoPanel from './panels/info-panel';
import ServiceInfo from './service-info/service-info';

export function LandingPage(props) {
  const { consumerLanding, getCustomerLandingData } = props;
  const { customerDetails } = consumerLanding;

  const [showPageNotFound, setShowPageNotFound] = useState(false);

  const { customerUrl } = useParams();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    if (pathname !== `/${customerUrl}/` && pathname !== `/${customerUrl}`) {
      setShowPageNotFound(true);
    }

    getCustomerLandingData({ customerUrl }).catch(() => {
      setShowPageNotFound(true);
    });
  }, []);

  return (
    <>
      {showPageNotFound && <PageNotFoundPage />}

      {!showPageNotFound && (
        <Container fluid className="container-uxl landing-page-body-class whole-page">
          <Row>
            <Col className="d-flex justify-content-center px-0">
              <Container className="px-0">
                <img src={logo} alt="Logo" className="img-fluid w-100" />
              </Container>
            </Col>
          </Row>

          <Row>
            <Header />
          </Row>

          <Row className="py-5">
            <ServiceInfo />
          </Row>

          <Row className="customer-panel">
            <Col>
              <InfoPanel
                isCustomerInfo
                title={`About ${customerDetails.companyName}`}
                details={customerDetails.details}
                imageUrl={freewillRobBrezsny || customerDetails.imageUrl}
              />
            </Col>
          </Row>

          <Row className="footer">
            <Col>
              <Footer logoUrl={customerDetails.logoUrl} />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

LandingPage.propTypes = {
  consumerLanding: consumerLandingProps.isRequired,
  getCustomerLandingData: PropTypes.func.isRequired,
};

LandingPage.defaultProps = {};

const mapStateToProps = (state) => ({
  consumerLanding: state.consumerLanding,
});

const mapDispatchToProps = {
  getCustomerLandingData: getUserInformationAndSubscriptionCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
