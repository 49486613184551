import PropTypes from 'prop-types';
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import DropdownAndButtons from '../../../../common/dropdown-and-buttons/dropdown-and-buttons';
import DefaultPayment from './default-payment';
import PaypalPayment from './paypal/paypal-payment';
import StripePaymentContainer from './stripe/stripe-payment-container';

function PaymentContainer(props) {
  const { packageId, goToPackagePage, setPaymentType } = props;

  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const [selectedPaymentType, setSelectedPaymentType] = useState(1);

  const paymentTypes = [
    { id: 1, name: 'Card' },
    { id: 2, name: 'PayPal' },
    { id: 3, name: 'Venmo' },
  ];

  const onPaymentTypeChange = (typeId) => {
    setSelectedPaymentType(parseInt(typeId, 10));
    setPaymentType(parseInt(typeId, 10));
  };

  return (
    <Container>
      <DefaultPayment
        packageId={packageId}
        paymentProcessing={paymentProcessing}
        setPaymentProcessing={setPaymentProcessing}
        goToPackagePage={goToPackagePage}
      />

      <DropdownAndButtons
        types={paymentTypes}
        selectedType={selectedPaymentType}
        onTypeChange={onPaymentTypeChange}
      />

      {selectedPaymentType === 1 && (
        <StripePaymentContainer
          packageId={packageId}
          paymentProcessing={paymentProcessing}
          setPaymentProcessing={setPaymentProcessing}
          goToPackagePage={goToPackagePage}
        />
      )}

      {selectedPaymentType === 2 && (
        <PaypalPayment
          packageId={packageId}
          paymentProcessing={paymentProcessing}
          goToPackagePage={goToPackagePage}
        />
      )}

      {selectedPaymentType === 3 && (
        <PaypalPayment
          packageId={packageId}
          paymentProcessing={paymentProcessing}
          goToPackagePage={goToPackagePage}
          fundingSource="venmo"
        />
      )}
    </Container>
  );
}

PaymentContainer.propTypes = {
  packageId: PropTypes.number.isRequired,
  goToPackagePage: PropTypes.func.isRequired,
  setPaymentType: PropTypes.func.isRequired,
};

PaymentContainer.defaultProps = {};

export default PaymentContainer;
