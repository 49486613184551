/* eslint-disable import/prefer-default-export */
import { showNotification } from '../../helper/notifications';
import tokenTransactionsService from '../../services/token-transaction';
import { TOKEN_TRANSACTION_FOR_ADMIN } from '../types';

export function getTokenTransactionByAdmin(params, query, actionData) {
  return (dispatch) =>
    tokenTransactionsService
      .getTokenTransactionByAdmin(query)
      .then((response) => {
        dispatch({
          type: TOKEN_TRANSACTION_FOR_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error while getting token transaction.',
        });

        return Promise.reject();
      });
}
