import PropTypes from 'prop-types';
import { Accordion, Card, Col } from 'react-bootstrap';
import { ICON_SIZE } from '../../helper/constant-values';
import { ArrowDownIcon, ArrowUpIcon } from '../../helper/icons/common-icons';

export default function FaqRow({ faqItem, eventKey, onToggle, clickedIndex }) {
  return (
    <>
      <Card className="mt-3 border-0 rounded-corner">
        <Accordion.Toggle
          as={Card.Header}
          eventKey={eventKey}
          className="d-flex flex-row justify-content-between bg-white border-bottom-0 rounded-corner pb-0"
          onClick={() => onToggle(eventKey)}
        >
          <Col xs={11} className="p-0">
            <p className="font-weight-bold" style={{ cursor: 'pointer' }}>
              {faqItem.question}
            </p>
          </Col>
          <Col xs={1} className="d-flex justify-content-end p-0" style={{ cursor: 'pointer' }}>
            {clickedIndex === eventKey ? (
              <ArrowUpIcon width={ICON_SIZE} height={ICON_SIZE} />
            ) : (
              <ArrowDownIcon width={ICON_SIZE} height={ICON_SIZE} />
            )}
          </Col>
        </Accordion.Toggle>

        {clickedIndex === eventKey ? (
          // eslint-disable-next-line react/self-closing-comp
          <Card.Body
            className="pt-0 rounded-corner col-11"
            dangerouslySetInnerHTML={{ __html: faqItem.answer }}
          ></Card.Body>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
}

FaqRow.propTypes = {
  faqItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
  }).isRequired,
  eventKey: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  clickedIndex: PropTypes.string.isRequired,
};

FaqRow.defaultProps = {};
