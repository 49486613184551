import commonMethods from '../../helper/common-methods';
import { showNotification } from '../../helper/notifications';
import paymentService from '../../services/payments';
import {
  CONSUMER_PAYMENT_HISTORIES_BY_ADMIN,
  CONSUMER_PAYMENT_REFUND_BY_ADMIN,
  DELETE_CONSUMER_DEFAULT_PAYMENT_INFO,
  // eslint-disable-next-line prettier/prettier
  GET_CONSUMER_DEFAULT_PAYMENT_INFO
} from '../types';

export function getPaymentHistoriesForAdmin(params, query, actionData) {
  return (dispatch) =>
    paymentService
      .getPaymentHistoriesForAdmin(query)
      .then((response) => {
        dispatch({
          type: CONSUMER_PAYMENT_HISTORIES_BY_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function paymentRefundByAdmin(data, params, query, actionData) {
  return (dispatch) =>
    paymentService
      .paymentRefundByAdmin(data, query)
      .then((response) => {
        showNotification({
          type: 'success',
          text: 'Payment refunded successfully.',
        });

        dispatch({
          type: CONSUMER_PAYMENT_REFUND_BY_ADMIN,
          data: response.data,
          noPagination: actionData.noPagination,
          isMobile: actionData.isMobile,
        });
      })
      .catch(commonMethods.actionDefaultErrorResponse);
}

export function getDefaultCardInfoByConsumer(params) {
  return (dispatch) =>
    paymentService
      .getDefaultCardInfoByConsumer(params)
      .then((response) => {
        dispatch({
          type: GET_CONSUMER_DEFAULT_PAYMENT_INFO,
          data: response.data,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error while getting default card info.',
        });
      });
}

export function addStripeDefaultCardInfoByConsumer(data, params) {
  return () =>
    paymentService
      .addStripeDefaultPaymentInfoByConsumer(data, params)
      .then(() => {
        showNotification({
          type: 'success',
          text: 'Default card info added successfully.',
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error while adding default card info.',
        });

        return Promise.reject();
      });
}

export function updateStripeDefaultPaymentInfoByConsumer(data, params) {
  return () =>
    paymentService
      .updateStripeDefaultPaymentInfoByConsumer(data, params)
      .then(() => {
        showNotification({
          type: 'success',
          text: 'Default card info updated successfully.',
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error while updating default card info.',
        });

        return Promise.reject();
      });
}

export function deleteDefaultCardInfoByConsumer(params) {
  return (dispatch) =>
    paymentService
      .deleteDefaultPaymentInfoByConsumer(params)
      .then((response) => {
        dispatch({
          type: DELETE_CONSUMER_DEFAULT_PAYMENT_INFO,
          data: response.data,
        });

        showNotification({
          type: 'success',
          text: 'Default card info deleted successfully.',
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error while deleting default card info.',
        });
      });
}

export function getPaypalDefaultPaymentInfoPage(params) {
  return () =>
    paymentService
      .getPaypalDefaultPaymentInfoPage(params)
      .then((response) => response.data)
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error while getting paypal page.',
        });

        return Promise.reject();
      });
}

export function confirmPaypalDefaultPaymentInfo(data, params) {
  return () =>
    paymentService
      .confirmPaypalDefaultPaymentInfo(data, params)
      .then(() => {
        showNotification({
          type: 'success',
          text: 'Default payment added successfully.',
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error while confirming default payment.',
        });

        return Promise.reject();
      });
}
