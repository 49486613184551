import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button, Col, Container, Row } from 'react-bootstrap';
import loginLogo from '../../assets/image/consumerLoginImg.png';
import commonMethods from '../../helper/common-methods';
import './auth-wrapper.css';

export default function AuthContainer(props) {
  const {
    children,
    backButtonLabel,
    pageHeader,
    logo,
    onBackButtonClick,
    isRegistration,
    showBackButton,
  } = props;

  return (
    <>
      <Container fluid className="container-uxl">
        <Row className="d-flex d-lg-none pl-2 pl-md-3 fixed-button pt-1">
          <div className="fixed-button py-2">
            {showBackButton && (
              <Button
                variant="link"
                className="cursor-pointer button-shadow-none text-dark pl-3 pt-0 mt-0"
                onClick={onBackButtonClick}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> {backButtonLabel}
              </Button>
            )}
          </div>
        </Row>

        <Row>
          <Col lg={5} className="d-none d-lg-flex align-items-center">
            <img src={loginLogo} alt="logo" className="img-fluid w-100" />
          </Col>

          <Col>
            <div className="fixed-button py-3 d-none d-lg-flex">
              {showBackButton && (
                <Button
                  variant="link"
                  className="cursor-pointer button-shadow-none text-dark pl-0"
                  onClick={onBackButtonClick}
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> {backButtonLabel}
                </Button>
              )}
            </div>

            {!isRegistration && <div className="py-4 py-lg-0 mt-lg-0 py-md-5 mt-md-5" />}

            <div className="custom-card custom-card-margin">
              <div className="px-1 pt-3 custom-card-body">
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <img
                    src={commonMethods.getImageApi(logo, true)}
                    alt="logo"
                    className="img-fluid logo-register"
                  />
                </div>

                <h3 className="py-3 heading">{pageHeader}</h3>
                {children}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

AuthContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  backButtonLabel: PropTypes.string,
  pageHeader: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  isRegistration: PropTypes.bool.isRequired,
  showBackButton: PropTypes.bool.isRequired,
};

AuthContainer.defaultProps = {
  backButtonLabel: 'Back',
};
