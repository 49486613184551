import PropTypes from 'prop-types';
import { useState } from 'react';
import { ButtonGroup, Col, ToggleButton } from 'react-bootstrap';
import DropDownWithIcons from '../dropdown/dropdown-with-icon';

export default function DropdownAndButtons(props) {
  const { onTypeChange, selectedType, types } = props;

  const [showFileTypeDropDown, setShowFileTypeDropDown] = useState(false);

  const onToggleDropDown = () => {
    setShowFileTypeDropDown(!showFileTypeDropDown);
  };

  const toggleButtonsRender = (buttons) => (
    <>
      {buttons.map((item) => (
        <ToggleButton
          key={item.id}
          type="radio"
          variant={selectedType === item.id ? 'primary' : 'outline-info'}
          name="radio"
          value={item.id}
          className={`header-button ${selectedType !== item.id ? 'primary-button' : ''}`}
          onClick={() => onTypeChange(item.id)}
        >
          {item.name}
        </ToggleButton>
      ))}
    </>
  );

  const manyTypesComponents = (buttons) => {
    const indents = [];

    for (let i = 3; i < buttons.length; i += 3) {
      indents.push(
        <Col className="d-none d-sm-flex d-lg-none mt-n4 py-2">
          <ButtonGroup toggle>{toggleButtonsRender(types.slice(i, i + 3))}</ButtonGroup>
        </Col>
      );
    }

    return indents;
  };

  return (
    <>
      <Col className="py-4 d-none d-sm-flex d-lg-none" md={10}>
        <ButtonGroup toggle>{toggleButtonsRender(types.slice(0, 3))}</ButtonGroup>
      </Col>

      <Col className="py-4 d-none d-lg-flex" lg={9}>
        <ButtonGroup toggle>{toggleButtonsRender(types)}</ButtonGroup>
      </Col>

      <Col className="pt-2 d-sm-none pb-2">
        {types.length >= 3 && (
          <DropDownWithIcons
            categoryList={types}
            onCategoryChange={onTypeChange}
            onToggleDropDown={onToggleDropDown}
            showCategoryDropDown={showFileTypeDropDown}
            selectedCategory={selectedType}
          />
        )}

        {types.length < 3 && (
          <ButtonGroup className="pb-3" toggle>
            {toggleButtonsRender(types)}
          </ButtonGroup>
        )}
      </Col>

      {types.length > 3 && <>{manyTypesComponents(types)}</>}
    </>
  );
}

DropdownAndButtons.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onTypeChange: PropTypes.func.isRequired,
  selectedType: PropTypes.number.isRequired,
};

DropdownAndButtons.defaultProps = {};
