import PropTypes from 'prop-types';
import { ButtonGroup, Col, ToggleButton } from 'react-bootstrap';
import DropDownWithIcons from '../../../../common/dropdown/dropdown-with-icon';

export default function FilesHeaderButton(props) {
  const { onTabChange, selectedTabId, onToggleDropDown, showDropDown, headerButtons } = props;

  const toggleButtonsRender = (buttons) => (
    <>
      {buttons.map((item) => (
        <ToggleButton
          key={item.id}
          type="radio"
          variant={selectedTabId === item.id ? 'secondary' : 'outline-info'}
          name="radio"
          value={item.id}
          className={`header-button-category ${
            selectedTabId !== item.id ? 'secondary-button' : ''
          }`}
          onClick={() => onTabChange(item.id)}
        >
          {item.name}
        </ToggleButton>
      ))}
    </>
  );

  return (
    <>
      <Col className="py-4 d-none d-sm-flex" xs={12}>
        <ButtonGroup toggle>{toggleButtonsRender(headerButtons)}</ButtonGroup>
      </Col>

      <Col className="py-2 d-sm-none">
        <DropDownWithIcons
          categoryList={headerButtons}
          onCategoryChange={onTabChange}
          onToggleDropDown={onToggleDropDown}
          showCategoryDropDown={showDropDown}
          selectedCategory={selectedTabId}
          isAdmin
        />
      </Col>
    </>
  );
}

FilesHeaderButton.propTypes = {
  headerButtons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  onTabChange: PropTypes.func.isRequired,
  selectedTabId: PropTypes.number.isRequired,
  onToggleDropDown: PropTypes.func.isRequired,
  showDropDown: PropTypes.bool.isRequired,
};

FilesHeaderButton.defaultProps = {};
