import {
  faFacebook,
  faInstagram,
  faSoundcloud,
  faTumblr,
  faTwitter,
  // eslint-disable-next-line prettier/prettier
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import config from '../../../../config/default';
import commonMethods from '../../../../helper/common-methods';

export default function LogoAndSocialFooter(props) {
  const { logoUrl } = props;
  return (
    <Row className="pt-5">
      <Col
        lg={6}
        md={6}
        className="footer-logo d-flex justify-content-center justify-content-md-start pb-5 pb-sm-0"
      >
        <img src={commonMethods.getImageApi(logoUrl, true)} alt="footer-logo" />
      </Col>
      <Col lg={6} md={6}>
        <Container className="footer-socials">
          <Row className="d-flex justify-content-center pb-4 pb-sm-0 footer-logo-container">
            <Col lg={{ span: 6 }} />

            <Col lg={1} md={2} xs={3}>
              <a href={config.mediaLinks.facebook}>
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </Col>
            <Col lg={1} md={2} xs={3}>
              <a href={config.mediaLinks.twitter}>
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </Col>
            <Col lg={1} md={2} xs={3}>
              <a href={config.mediaLinks.instagram}>
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </Col>
            <Col lg={1} md={2} xs={3}>
              <a href={config.mediaLinks.youtube}>
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </Col>
            <Col lg={1} md={2} xs={3}>
              <a href={config.mediaLinks.tumblr}>
                <FontAwesomeIcon icon={faTumblr} />
              </a>
            </Col>
            <Col lg={1} md={2} xs={3}>
              <a href={config.mediaLinks.soundCloud}>
                <FontAwesomeIcon icon={faSoundcloud} />
              </a>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
}

LogoAndSocialFooter.propTypes = {
  logoUrl: PropTypes.string.isRequired,
};

LogoAndSocialFooter.defaultProps = {};
