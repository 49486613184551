/* eslint-disable prettier/prettier */
import { showNotification } from '../../helper/notifications';
import profileService from '../../services/profile';
import {
  ADMIN_PROFILE_INIT, CONSUMER_PROFILE_INIT, CUSTOMER_PROFILE_INIT,
  GET_ADMIN_PROFILE, GET_CONSUMER_DEFAULT_PAYMENT_INFO, GET_CONSUMER_PROFILE,
  GET_CUSTOMER_PROFILE
} from '../types';

export function getConsumerProfile(params) {
  return (dispatch) => {
    profileService
      .getConsumerProfile(params)
      .then((response) => {
        dispatch({
          type: GET_CONSUMER_PROFILE,
          data: response.data,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error While Getting Profile Info',
        });
      });
  };
}

export function getConsumerProfileDefaultCardInfo(params) {
  return (dispatch) => {
    profileService
      .getConsumerProfileDefaultCardInfo(params)
      .then((response) => {
        dispatch({
          type: GET_CONSUMER_DEFAULT_PAYMENT_INFO,
          data: response.data,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error While Getting Default Crad Info.',
        });
      });
  };
}

export function getCustomerProfile(params) {
  return (dispatch) => {
    profileService
      .getCustomerProfile(params)
      .then((response) => {
        dispatch({
          type: GET_CUSTOMER_PROFILE,
          data: response.data,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error While Getting Profile Info',
        });
      });
  };
}

export function getAdminProfile() {
  return (dispatch) => {
    profileService
      .getAdminProfile()
      .then((response) => {
        dispatch({
          type: GET_ADMIN_PROFILE,
          data: response.data,
        });
      })
      .catch(() => {
        showNotification({
          type: 'error',
          text: 'Error While Getting Admin Profile Info',
        });
      });
  };
}

export function profilePasswordChange(data, params) {
  return () =>
    profileService
      .profilePasswordChange(data, params)
      .then(() => {
        showNotification({
          type: 'success',
          text: 'Password changed successfully',
        });
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          text: 'Error While Updaing Password',
        });

        return Promise.reject(error);
      });
}

// eslint-disable-next-line no-unused-vars
export function adminProfilePasswordChange(data, params) {
  return () =>
    profileService
      .adminProfilePasswordChange(data)
      .then(() => {
        showNotification({
          type: 'success',
          text: 'Password changed successfully',
        });
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          text: 'Error While Updaing Password of Admin',
        });

        return Promise.reject(error);
      });
}


export function updateAdminProfile(data, currentValues) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_PROFILE_INIT,
      data: currentValues,
    });

    return profileService
      .updateAdminProfile(data)
      .then((response) =>{
        showNotification({
          type: 'success',
          text: 'Profile updated successfully',
        });

        return response.data;
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          text: 'Error While Updaing Profile',
        });

        return Promise.reject(error);
      });
  };
}

export function updateCustomerProfile(data, params, currentValues) {
  return (dispatch) => {
    dispatch({
      type: CUSTOMER_PROFILE_INIT,
      data: currentValues,
    });

    return profileService
      .updateCustomerProfile(data, params)
      .then((response) =>{
        showNotification({
          type: 'success',
          text: 'Profile updated successfully',
        });

        return response.data;
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          text: 'Error While Updating Profile',
        });

        return Promise.reject(error);
      });
  };
}

export function updateConsumerProfile(data, params, currentValues) {
  return (dispatch) => {
    dispatch({
      type: CONSUMER_PROFILE_INIT,
      data: currentValues,
    });

    return profileService
      .updateConsumerProfile(data, params)
      .then((response) =>{
        showNotification({
          type: 'success',
          text: 'Profile updated successfully',
        });

        return response.data;
      })
      .catch((error) => {
        showNotification({
          type: 'error',
          text: 'Error While Updating Profile',
        });

        return Promise.reject(error);
      });
  };
}
