import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import StripePayment from './stripe-payment';

function StripePaymentContainer(props) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

  const { packageId, goToPackagePage, paymentProcessing, setPaymentProcessing } = props;

  return (
    <Elements stripe={stripePromise}>
      <StripePayment
        packageId={packageId}
        goToPackagePage={goToPackagePage}
        paymentProcessing={paymentProcessing}
        setPaymentProcessing={setPaymentProcessing}
      />
    </Elements>
  );
}

StripePaymentContainer.propTypes = {
  packageId: PropTypes.number.isRequired,
  goToPackagePage: PropTypes.func.isRequired,
  paymentProcessing: PropTypes.bool.isRequired,
  setPaymentProcessing: PropTypes.func.isRequired,
};

StripePaymentContainer.defaultProps = {};

export default StripePaymentContainer;
