import PropTypes from 'prop-types';

export const consumerLandingProps = PropTypes.shape({
  customerDetails: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    logoUrl: PropTypes.string.isRequired,
    subscriptionCategory: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
});

export const cardProps = PropTypes.arrayOf(
  PropTypes.shape({
    cardID: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    logoSvg: PropTypes.string.isRequired,
  })
);

export const modalOptionsProps = PropTypes.arrayOf(
  PropTypes.shape({
    checkboxId: PropTypes.number.isRequired,
    desc: PropTypes.string.isRequired,
  })
);
