import PropTypes from 'prop-types';
import { useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { ICON_SIZE } from '../../../../../helper/constant-values';
import { ArrowDownIcon, ArrowUpIcon } from '../../../../../helper/icons/common-icons';
import WarningModal from '../../../../common/modals/warning-success-modal';
import { singleCategoryWithDeliverMediumProps } from '../../customer-common-props';
import CustomerCategoryModifyModal from '../../modify-customer/customer-category/customer-category-modify-modal';
import ActionButtons from '../common-components/action-buttons';
import CategoryRowBody from './category-row-body';

export default function CategoryRow({
  categoryItem,
  eventKey,
  onToggle,
  clickedIndex,
  onUpdateCategorySubmit,
  onDeleteCategorySubmit,
  customerId,
}) {
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const onHandleEdit = () => {
    setShowEditCategoryModal(true);
  };

  const onHandleDelete = () => {
    setShowDeleteCategoryModal(true);
  };

  const onCloseCategoryModifyModal = () => {
    setShowEditCategoryModal(false);
  };

  const onCloseCategoryDeleteModal = () => {
    setShowDeleteCategoryModal(false);
  };

  const onConfirmDelete = () => {
    setDataLoading(true);

    const params = {
      consumerCategoryId: categoryItem.id,
    };

    return onDeleteCategorySubmit(params)
      .then(() => {
        setDataLoading(false);
      })
      .catch(() => {
        setDataLoading(false);
      });
  };

  return (
    <>
      <CustomerCategoryModifyModal
        showModal={showEditCategoryModal}
        headerTitle="Edit Category"
        onCloseModal={onCloseCategoryModifyModal}
        onSubmit={onUpdateCategorySubmit}
        customerId={customerId}
        categoryItem={categoryItem}
      />

      <WarningModal
        heading="Delete Category"
        description={`Are you sure, you want to delete the category named ${categoryItem.name}?`}
        leftBtnText="No"
        rightBtnText="Yes"
        showModal={showDeleteCategoryModal}
        onHideModal={onCloseCategoryDeleteModal}
        processing={dataLoading}
        onModalSubmit={onConfirmDelete}
      />

      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={eventKey}
          className="d-flex flex-row justify-content-between"
          onClick={() => onToggle(eventKey)}
        >
          <Col xs={6} sm={7}>
            <p className="font-weight-bold">{categoryItem.name}</p>
          </Col>
          <Col xs={6} sm={5} className="d-flex justify-content-end">
            <Row>
              <ActionButtons onHandleEdit={onHandleEdit} onHandleDelete={onHandleDelete} />

              <Col xs={4} className="px-0 pb-1">
                <div className="mr-2 py-2 float-right">
                  {clickedIndex === eventKey ? (
                    <ArrowUpIcon width={ICON_SIZE} height={ICON_SIZE} />
                  ) : (
                    <ArrowDownIcon width={ICON_SIZE} height={ICON_SIZE} />
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Accordion.Toggle>

        {clickedIndex === eventKey ? (
          <Card.Body>
            <CategoryRowBody categoryItem={categoryItem} />
          </Card.Body>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
}

CategoryRow.propTypes = {
  categoryItem: singleCategoryWithDeliverMediumProps.isRequired,
  eventKey: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
  clickedIndex: PropTypes.number.isRequired,
  onUpdateCategorySubmit: PropTypes.func.isRequired,
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onDeleteCategorySubmit: PropTypes.func.isRequired,
};

CategoryRow.defaultProps = {};
