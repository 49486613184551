import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import colors from '../../../../../helper/colors';
import commonMethods from '../../../../../helper/common-methods';
import {
  packagePurchaseWithPaypalDefaultPayment,
  // eslint-disable-next-line prettier/prettier
  packagePurchaseWithStripeDefaultPayment
} from '../../../../../redux/actions/package';
import { getDefaultCardInfoByConsumer } from '../../../../../redux/actions/payments-action';
import { userInformationProps } from '../../../../common/common-props';
import DataSavingSpinner from '../../../../common/loader-spinner/data-saving-spinner';
import { defaultPaymentInfoProps } from '../../consumer-package-common-props';

function DefaultPayment(props) {
  const {
    packageId,
    paymentProcessing,
    userInfoState,
    defaultPaymentInfo,
    stripeDefaultPayment,
    paypalDefaultPayment,
    getDefaultCardInfo,
    setPaymentProcessing,
    goToPackagePage,
  } = props;

  const [processing, setProcessing] = useState(false);
  const { customerUrl } = useParams();

  const { id: consumerId } = userInfoState;

  const defaultPaymentProceed = () => {
    setPaymentProcessing(true);

    const paymentData = {
      packageId,
    };

    const query = {
      customerUrl,
      consumerId,
    };

    const defaultPayment = defaultPaymentInfo.isPaypalPayment
      ? paypalDefaultPayment
      : stripeDefaultPayment;

    defaultPayment(paymentData, query)
      .then(() => {
        setPaymentProcessing(false);
        goToPackagePage();
      })
      .catch(() => {
        setPaymentProcessing(false);
      });
  };

  useEffect(() => {
    const params = {
      customerUrl,
      consumerId,
    };

    setProcessing(true);

    getDefaultCardInfo(params)
      .then(() => {
        setProcessing(false);
      })
      .catch(() => {
        setProcessing(false);
      });
  }, []);

  return (
    <>
      {!defaultPaymentInfo.noDefaultPayment && !processing && (
        <>
          <Row className="border rounded-lg pb-3">
            <Col>
              <div className="p-3">
                <Row className="pb-3">Default Payment Method:</Row>

                {defaultPaymentInfo.isStripePayment && (
                  <>
                    <Row className="pl-2">
                      <span className="font-weight-bold pr-1">Payment Type:</span> Card
                    </Row>
                    <Row className="pl-2">
                      <span className="font-weight-bold pr-1">Card Type:</span>{' '}
                      {commonMethods.capitalizeFirstLetter(defaultPaymentInfo.cardBrand)}
                    </Row>
                    <Row className="pl-2">
                      <span className="font-weight-bold pr-1">Card Number:</span>
                      **** **** **** {defaultPaymentInfo.lastFourDigit}
                    </Row>
                    <Row className="pl-2">
                      <span className="font-weight-bold pr-1">Card Expire Date:</span>
                      {defaultPaymentInfo.expMonth}/{defaultPaymentInfo.expYear}
                    </Row>
                  </>
                )}

                {defaultPaymentInfo.isPaypalPayment && (
                  <>
                    <Row className="pl-2">
                      <span className="font-weight-bold pr-1">Payment Type:</span> PayPal
                    </Row>
                    <Row className="pl-2">
                      <span className="font-weight-bold pr-1">Name on PayPal:</span>{' '}
                      {defaultPaymentInfo.fullName}
                    </Row>
                    <Row className="pl-2">
                      <span className="font-weight-bold pr-1">Email on PayPal:</span>
                      {defaultPaymentInfo.email}
                    </Row>
                  </>
                )}

                <Row className="pt-4">
                  <Col className="pr-sm-0 px-0 d-flex justify-content-sm-end justify-content-between">
                    <Button
                      variant="warning"
                      className="px-4"
                      disabled={paymentProcessing}
                      onClick={defaultPaymentProceed}
                    >
                      <DataSavingSpinner
                        savingText="Processing"
                        defaultText="Purchase With Default Payment Method"
                        dataSaving={paymentProcessing}
                      />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="font-weight-bold py-4">OR</Row>
        </>
      )}

      {processing && (
        <>
          <Row className="pb-5 mb-5">
            <Col>
              <RingLoader loading size={100} color={colors.secondary} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

DefaultPayment.propTypes = {
  packageId: PropTypes.number.isRequired,
  userInfoState: userInformationProps.isRequired,
  getDefaultCardInfo: PropTypes.func.isRequired,
  stripeDefaultPayment: PropTypes.func.isRequired,
  paypalDefaultPayment: PropTypes.func.isRequired,
  defaultPaymentInfo: defaultPaymentInfoProps.isRequired,
  paymentProcessing: PropTypes.bool.isRequired,
  setPaymentProcessing: PropTypes.func.isRequired,
  goToPackagePage: PropTypes.func.isRequired,
};

DefaultPayment.defaultProps = {};

const mapStateToProps = (state) => ({
  userInfoState: state.auth.userInfo,
  defaultPaymentInfo: state.payments.consumerDefaultPaymentInfo,
});

const mapDispatchToProps = {
  getDefaultCardInfo: getDefaultCardInfoByConsumer,
  stripeDefaultPayment: packagePurchaseWithStripeDefaultPayment,
  paypalDefaultPayment: packagePurchaseWithPaypalDefaultPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPayment);
