/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

export const singleAudioForConsumerProps = PropTypes.shape({
  audioFileName: PropTypes.string.isRequired,
  audioName: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  startDateTime: PropTypes.string.isRequired,
});

export const audioForConsumerProps = PropTypes.shape({
  totalItems: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(singleAudioForConsumerProps).isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
});

export const subscriptionCategoryProps = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,

    deliveryMediums: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        numberOfTokens: PropTypes.number.isRequired,
      }).isRequired
    ),
  }).isRequired
);
