import commonMethods from '../../helper/common-methods';
import {
  GET_PACKAGES_FOR_CONSUMER,
  GET_PACKAGES_FOR_CUSTOMER,
  GET_PACKAGE_LIST_FOR_ADMIN,
  PACKAGE_ADD_BY_ADMIN,
  PACKAGE_DELETE_BY_ADMIN,
  PACKAGE_UPDATE_BY_ADMIN,
  // eslint-disable-next-line prettier/prettier
  TOGGLE_AUTO_PAYMENT_BUTTON
} from '../types';

const initialState = {
  packages: [],
  packagesForCustomer: {
    totalItems: 4,
    data: [],
    totalPages: 2,
    currentPage: 1,
  },
  packagesForAdmin: {
    totalItems: 0,
    data: [],
    totalPages: 1,
    currentPage: 1,
  },
};

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGES_FOR_CONSUMER:
      return {
        ...state,
        packages: action.data,
      };

    case TOGGLE_AUTO_PAYMENT_BUTTON:
      return {
        ...state,
        packages: action.data,
      };

    case GET_PACKAGES_FOR_CUSTOMER: {
      return commonMethods.paginationStateUpdate(state, action, 'packagesForCustomer');
    }

    case GET_PACKAGE_LIST_FOR_ADMIN:
      return commonMethods.paginationStateUpdate(state, action, 'packagesForAdmin');

    case PACKAGE_DELETE_BY_ADMIN: {
      return commonMethods.deletePaginationState(state, action, 'packagesForAdmin');
    }

    case PACKAGE_UPDATE_BY_ADMIN: {
      return commonMethods.updateOnPaginationState(state, action, 'packagesForAdmin');
    }

    case PACKAGE_ADD_BY_ADMIN: {
      return commonMethods.addPaginationState(state, action, 'packagesForAdmin');
    }

    default:
      return state;
  }
};

export default packageReducer;
