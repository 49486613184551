import { Col, Container, Row } from 'react-bootstrap';
import config from '../../../../config/default';

export default function CopyrightAndPrivacyFooter() {
  return (
    <Row className="pb-5">
      <Col sm={12}>
        <hr className="hr-class" />
      </Col>
      <Col sm={12} className="ml-1">
        <Container>
          <Row>
            <Col
              lg={{ span: 8, order: 1 }}
              md={{ span: 6, order: 1 }}
              xs={{ span: 12, order: 2 }}
              className="copyright-details text-muted ml-sm-n3"
            >
              Copyright 2022{' '}
              <a
                className="text-warning"
                href={config.consumerLandingPage.info9000WebsiteLink}
                target="_blank"
                rel="noreferrer"
              >
                Info 9000
              </a>
              . All rights reserved.
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
}

CopyrightAndPrivacyFooter.propTypes = {};

CopyrightAndPrivacyFooter.defaultProps = {};
