import { Col, Row } from 'react-bootstrap';

export default function TokenTransactionListTitle() {
  return (
    <Row className="d-none d-lg-flex header mx-2 mt-4">
      <Col>
        <Row>
          <Col lg={2}>
            <p>Date Time</p>
          </Col>
          <Col lg={2}>
            <p>Username</p>
          </Col>
          <Col lg={4}>
            <p>Description</p>
          </Col>
          <Col lg={1} className="d-lg-flex justify-content-end text-right">
            <p>Token Debit</p>
          </Col>
          <Col lg={1} className="d-lg-flex justify-content-end text-right">
            <p>Token Credit</p>
          </Col>
          <Col lg={2} className="d-lg-flex justify-content-end text-right">
            <p>Token Balance</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

TokenTransactionListTitle.propTypes = {};

TokenTransactionListTitle.defaultProps = {};
