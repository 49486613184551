import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { RingLoader } from 'react-spinners';
import moment from 'moment';
import colors from '../../../../helper/colors';
import commonMethods from '../../../../helper/common-methods';
import { getCustomerGrossSales } from '../../../../redux/actions/revenue-report-action';
import DataSavingSpinner from '../../../common/loader-spinner/data-saving-spinner';
import CustomPagination from '../../../common/pagination/custom-pagination';
import { browserViewProps, grossSalePaginationProps } from '../revenue-common-props';
import SalesTableDesktop from './sales-table-for-desktop';
import SalesTableMobile from './sales-table-for-mobile';
import './sales-table-style.css';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../helper/constant-values';

function GrossSales(props) {
  const { startDate, endDate, grossSalesCustomer, browserState, getGrossSales } = props;

  const { customerUrl } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [mobileDataLoading, setMobileDataLoading] = useState(false);
  const [mobileLastPageLoaded, setMobileLastPageLoaded] = useState(false);

  const onLoadMoreClick = () => {
    setMobileDataLoading(true);

    if (grossSalesCustomer.totalPages === page) {
      setMobileLastPageLoaded(!mobileLastPageLoaded);
    } else {
      setPage(page + 1);
    }
  };

  const getPaginationQuery = (pageChanged = false) => {
    const params = { customerUrl };

    const query = {
      page,
      itemPerPage,
      startDate: moment(startDate).format(DEFAULT_DATE_TIME_FORMAT),
      endDate: moment(endDate).format(DEFAULT_DATE_TIME_FORMAT),
    };

    return {
      isMobileView: browserState.isMobile,
      params,
      query,
      page,
      itemPerPage,
      getMethod: getGrossSales,
      pageChanged,
      data: grossSalesCustomer.data,
    };
  };

  const callCustomerGrossSalesMethod = (paginationQuery) => {
    commonMethods
      .updatePaginationData(paginationQuery)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    callCustomerGrossSalesMethod(getPaginationQuery(true));
  }, [page]);

  useEffect(() => {
    setIsLoading(true);
    callCustomerGrossSalesMethod(getPaginationQuery());
  }, [itemPerPage, mobileLastPageLoaded, startDate, endDate]);

  useEffect(() => {
    if (!browserState.isMobile) {
      setIsLoading(true);
      callCustomerGrossSalesMethod(getPaginationQuery());
    }
  }, [browserState.isMobile]);

  useEffect(() => {
    setMobileDataLoading(false);
  }, [grossSalesCustomer]);

  return (
    <Container className="mt-4 bg-white rounded-lg px-0 px-sm-4 container-uxl">
      <Row className="pt-4">
        <Col className="pb-3" xs={6} sm={3}>
          <span className="h4 pl-4 pl-sm-0">Gross Sales</span>
        </Col>
      </Row>
      {isLoading && (
        <div className="gross-sales-loder">
          <RingLoader loading size={100} color={colors.secondary} />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="d-none d-sm-block">
            <SalesTableDesktop salesData={grossSalesCustomer.data} />
            {grossSalesCustomer.totalItems >= 10 && (
              <CustomPagination
                itemPerPage={itemPerPage}
                page={page}
                setPage={setPage}
                setItemPerPage={setItemPerPage}
                totalPages={grossSalesCustomer.totalPages}
                totalItems={grossSalesCustomer.totalItems}
                customClass="gross-sales-custom-pagination"
              />
            )}
          </div>
          <div className="d-block d-sm-none">
            <SalesTableMobile salesData={grossSalesCustomer.data} />
          </div>

          {grossSalesCustomer.totalItems > grossSalesCustomer.data.length && (
            <Row className="justify-content-center justify-content-lg-start pt-4 d-sm-none">
              <Col sm={11}>
                <Button size="lg" block disabled={mobileDataLoading} onClick={onLoadMoreClick}>
                  <DataSavingSpinner
                    savingText="Loding"
                    defaultText="Load More"
                    dataSaving={mobileDataLoading}
                  />
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
}

GrossSales.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  grossSalesCustomer: grossSalePaginationProps.isRequired,
  getGrossSales: PropTypes.func.isRequired,
  browserState: browserViewProps.isRequired,
};

GrossSales.defaultProps = {};

const mapStateToProps = (state) => ({
  grossSalesCustomer: state.revenue.grossSalesCustomer,
  browserState: state.browserData.view,
});

const mapDispatchToProps = {
  getGrossSales: getCustomerGrossSales,
};

export default connect(mapStateToProps, mapDispatchToProps)(GrossSales);
