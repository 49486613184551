import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { getConsumerCategory } from '../../../redux/actions/consumer-category-action';
import MenuBarWrapper from '../../common/menu-bar-wrapper/menu-bar-wrapper';
import { subscriptionListProps, userInformationProps } from './consumer-subscription-common-props';
import SubscriptionList from './subscription-list';

function ConsumerSubscription(props) {
  const { subscriptionCategory, userInfoState, getConsumerCategoryFunc } = props;

  const { customerUrl } = useParams();

  useEffect(() => {
    const query = {
      mediums: ['sms', 'email'],
      includeSubscription: true,
    };

    const params = {
      customerUrl,
      consumerId: userInfoState.id,
    };

    getConsumerCategoryFunc(params, query);
  }, []);

  return (
    <MenuBarWrapper navBarIndex={2} headerTitle="Daily Sunbursts">
      <SubscriptionList subscriptionList={subscriptionCategory} />
    </MenuBarWrapper>
  );
}

ConsumerSubscription.propTypes = {
  subscriptionCategory: subscriptionListProps.isRequired,
  getConsumerCategoryFunc: PropTypes.func.isRequired,
  userInfoState: userInformationProps.isRequired,
};

ConsumerSubscription.defaultProps = {};

const mapStateToProps = (state) => ({
  subscriptionCategory: state.consumerCategory.category,
  userInfoState: state.auth.userInfo,
});

const mapDispatchToProps = {
  getConsumerCategoryFunc: getConsumerCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerSubscription);
